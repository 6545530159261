import React, { useEffect, useState } from "react";
import { Row, Col, Input, FormGroup, Label } from "reactstrap";
import {
  fetchOptionsList,
  fetchOptions,
} from "../../../components/Header/Data";
import { setCustomDetails } from "../../../redux/slices/ServiceInfo";
import { useDispatch, useSelector } from "react-redux";

const CustomFields = ({ setMandate }) => {
  const dispatch = useDispatch();
  const serviceInfo = useSelector((state) => state.serviceInfo);
  const [listOptions, setListOptions] = useState([]);
  const [optionsData, setOptionsData] = useState([]);
  const fetchDetailsOptions = async () => {
    try {
      const listData = await fetchOptionsList(serviceInfo.categoryId);
      setListOptions(listData);
    } catch (error) {
      console.error("Error fetching categories:", error.message);
    }
  };
  const fetchOptionsData = async () => {
    const options = {};
    for (const option of listOptions) {
      try {
        const data = await fetchOptions(option.cat_list_id);
        options[option.cat_list_id] = data;
      } catch (error) {
        console.error("Error fetching options:", error.message);
      }
    }
    console.log(options, "options");
    setOptionsData(options);
  };
  useEffect(() => {
    fetchDetailsOptions();
    // if (Object.keys(serviceInfo.customDetails).length > 0) {
    //   checkMandatoryFields();
    // }
  }, [serviceInfo.categoryId]);

  useEffect(() => {
    if (Object.keys(serviceInfo.customDetails).length > 0) {
      const checkMandatoryFields = () => {
        const areAllMandatoryFieldsFilled = listOptions.every(
          (option) =>
            option.is_mandatory !== "1" ||
            (serviceInfo.customDetails[option.cat_list_id] &&
              serviceInfo.customDetails[option.cat_list_id].length > 0)
        );
        setMandate(areAllMandatoryFieldsFilled);
      };
      checkMandatoryFields();
    }
  }, [listOptions, serviceInfo.customDetails, setMandate]);

  useEffect(() => {
    fetchOptionsData();
  }, [listOptions]);
  // const checkMandatoryFields = () => {
  //   const areAllMandatoryFieldsFilled = listOptions.every(
  //     (option) =>
  //       option.is_mandatory !== "1" ||
  //       (serviceInfo.customDetails[option.cat_list_id] &&
  //         serviceInfo.customDetails[option.cat_list_id].length > 0) ||
  //       (option.list_type === "textbox" &&
  //         serviceInfo.customDetails[option.cat_list_id].length > 0 &&
  //         serviceInfo.customDetails[option.cat_list_id][0]?.option_value !==
  //           "") ||
  //       (option.list_type === "dropdown" &&
  //         serviceInfo.customDetails[option.cat_list_id].length > 0 &&
  //         serviceInfo.customDetails[option.cat_list_id][0]?.option_value !== "")
  //   );
  //   setMandate(areAllMandatoryFieldsFilled);
  // };

  const handleInputChange = (event, option, opt) => {
    const { value } = event.target;
    const { cat_list_id } = option;

    const newCustomDetails = { ...serviceInfo.customDetails };

    if (option.list_type === "dropdown") {
      if (opt) {
        newCustomDetails[cat_list_id] = [
          { option_value: value, option_name: opt.option_name },
        ];
      } else {
        newCustomDetails[cat_list_id] = [];
      }
    } else if (option.list_type === "checkbox") {
      const existingOptions = newCustomDetails[cat_list_id] || [];
      const updatedOptions = event.target.checked
        ? [
            ...existingOptions,
            { option_value: value, option_name: opt ? opt.option_name : "" },
          ]
        : existingOptions.filter((item) => item.option_value !== value);
      newCustomDetails[cat_list_id] = updatedOptions;
    } else if (option.list_type === "textbox") {
      newCustomDetails[cat_list_id] = [
        { option_value: value, option_name: value },
      ];
    }

    dispatch(setCustomDetails(newCustomDetails));
  };

  const renderInputField = (option) => {
    const options = optionsData[option.cat_list_id] || [];
    const value = serviceInfo.customDetails[option.cat_list_id] || [];
    console.log(value, "value");
    switch (option.list_type) {
      case "dropdown":
        return (
          <Input
            type="select"
            value={value.length > 0 ? value[0]?.option_value : ""} // Ensure proper value assignment
            onChange={(event) => {
              const selectedOption = options.find(
                (opt) => opt.option_value === parseInt(event.target.value)
              );
              handleInputChange(event, option, selectedOption);
            }}
          >
            <option value="">Select option</option>
            {options.map((opt, index) => (
              <option key={index} value={opt.option_value}>
                {opt.option_name}
              </option>
            ))}
          </Input>
        );
      case "checkbox":
        return options.map((opt, index) => (
          <FormGroup check key={index} className="m-2">
            <Label check>
              <Input
                type="checkbox"
                checked={value.some(
                  (item) => item.option_name === opt.option_name
                )}
                value={opt.option_value}
                onChange={(event) => handleInputChange(event, option, opt)}
              />{" "}
              {opt.option_name}
            </Label>
          </FormGroup>
        ));
      case "textbox":
        return (
          <Input
            type="text"
            value={value.map((item) => item.option_value)}
            onChange={(event) =>
              handleInputChange(event, option, {
                option_value: event.target.value,
                option_name: event.target.value,
              })
            }
          />
        );
      case "radio":
        return options.map((opt, index) => (
          <FormGroup check key={index} className="m-2">
            <Label check>
              <Input
                type="radio"
                value={opt.option_value}
                name={option.name} // assuming there's a name property in the option
                onChange={(event) => handleInputChange(event, option, opt)}
              />{" "}
              {opt.option_name}
            </Label>
          </FormGroup>
        ));
      default:
        return null;
    }
  };

  return (
    <Row className="form-container p-5 m-3">
      <h6 className="pb-3">Custom Fields</h6>
      {listOptions.length > 0 && (
        <Col>
          {listOptions.map((option, index) => (
            <Row key={index}>
              <span>
                {option.list_name}{" "}
                {option.is_mandatory === "1" && (
                  <b className="text-danger">*</b>
                )}
              </span>
              <span style={{ fontSize: "small", padding: "10px" }}>
                {option.description}
              </span>
              <Col
                style={{ display: "flex", flexWrap: "wrap" }}
                className="pb-4"
              >
                {renderInputField(option)}
              </Col>
            </Row>
          ))}
        </Col>
      )}
    </Row>
  );
};

export default CustomFields;
