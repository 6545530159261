import React, { useEffect, useState } from "react";
import LcaCards from "./LcaCards";
import { useDispatch, useSelector } from "react-redux";
import { setActiveTab } from "../../../redux/slices/LcaSlice";
import { Button } from "reactstrap";
import BwvCards from "./BwvCards";

const LcaAndBwv = ({ scrollPopupVisible }) => {
  const dispatch = useDispatch();
  const { activeTab, finalProducts, Lca, bwv, finalBwv, selectedSubCategory } =
    useSelector((state) => state.lcAds);

  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 10;

  // useEffect(() => {
  //   if (activeTab === "Customers") {
  //     setLoading(!(finalProducts?.length > 0));
  //   } else if (activeTab === "Vendor") {
  //     setLoading(!(finalBwv?.length > 0));
  //   }
  // }, [finalProducts, finalBwv, activeTab]);

  // Pagination calculations for Customers
  const totalPagesForCustomers = Math.ceil(
    finalProducts?.length / usersPerPage
  );
  const indexOfLastUserCustomer = currentPage * usersPerPage;
  const indexOfFirstUserCustomer = indexOfLastUserCustomer - usersPerPage;
  const currentUsers = finalProducts?.slice(
    indexOfFirstUserCustomer,
    indexOfLastUserCustomer
  );

  // Pagination calculations for Vendors
  const totalPagesForVendors = Math.ceil(finalBwv?.length / usersPerPage);
  const indexOfLastUserVendor = currentPage * usersPerPage;
  const indexOfFirstUserVendor = indexOfLastUserVendor - usersPerPage;
  const currentVendors = finalBwv?.slice(
    indexOfFirstUserVendor,
    indexOfLastUserVendor
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
    }
  };

  const handleNextPage = (totalPages) => {
    if (currentPage < totalPages) {
      paginate(currentPage + 1);
    }
  };

  return (
    <>
      {selectedSubCategory &&
        selectedSubCategory.Name === "Upcoming Wedding/Event" && (
          <div
            className="col-lg-6 d-flex row mt-3 view-tabs w-100"
            style={{ marginLeft: "2px" }}
          >
            <h5
              className={`col-sm-4 ${
                activeTab === "Customers" ? "active " : ""
              } mx-0`}
              onClick={() => {
                dispatch(setActiveTab("Customers"));
                setCurrentPage(1); // Reset to first page when switching tabs
              }}
              style={{
                textDecoration:
                  activeTab === "Customers" ? "underline" : "none",
                cursor: "pointer",
                width: "50%",
              }}
            >
              View Community Ads
            </h5>
            <h5
              className={`col-sm-4 ${
                activeTab === "Vendor" ? "active" : ""
              } mx-0`}
              onClick={() => {
                dispatch(setActiveTab("Vendor"));
                setCurrentPage(1); // Reset to first page when switching tabs
              }}
              style={{
                textDecoration: activeTab === "Vendor" ? "underline" : "none",
                cursor: "pointer",
                width: "50%",
              }}
            >
              View Vendors
            </h5>
          </div>
        )}
      <div>
        {" "}
        {activeTab === "Customers" ? (
          <LcaCards
            allProducts={currentUsers}
            scrollPopupVisible={scrollPopupVisible}
          />
        ) : activeTab === "Vendor" ? (
          currentVendors && currentVendors.length > 0 ? (
            <BwvCards allProducts={currentVendors} />
          ) : (
            <div
              className="text-center py-5 text-primary"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "50px 0px",
              }}
            >
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "10px 0px",
                  fontSize: "20px",
                }}
                className="text-center py-5"
              >
                No cards to display
              </p>
            </div>
          )
        ) : null}
      </div>

      {(activeTab === "Customers" || activeTab === "Vendor") && (
        <div className="pagination pb-3 d-flex justify-content-center">
          <Button
            className="me-2"
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <span className="align-self-center">
            Page {currentPage} of{" "}
            {activeTab === "Customers"
              ? totalPagesForCustomers
              : totalPagesForVendors}
          </span>
          <Button
            className="ms-2"
            onClick={() =>
              handleNextPage(
                activeTab === "Customers"
                  ? totalPagesForCustomers
                  : totalPagesForVendors
              )
            }
            disabled={
              currentPage ===
              (activeTab === "Customers"
                ? totalPagesForCustomers
                : totalPagesForVendors)
            }
          >
            Next
          </Button>
        </div>
      )}
    </>
  );
};

export default LcaAndBwv;
