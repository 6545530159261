import listing from "../../src/assets/images/listing.png";
import pay from "../../src/assets/images/pay.png";
import profile from "../../src/assets/images/profile.png";
import banner1 from "../../src/assets/images/home/banner1.jpg";
import banner2 from "../../src/assets/images/home/banner2.jpg";
import banner3 from "../../src/assets/images/home/banner3.jpg";
import banner4 from "../../src/assets/images/home/banner4.jpg";
import banner5 from "../../src/assets/images/home/home10.jpg";

export const usaCities = [
  "New York",
  "Los Angeles",
  "Chicago",
  "Houston",
  "Phoenix",
  "Philadelphia",
  "San Antonio",
  "San Diego",
  "Dallas",
  "San Jose",
  "Austin",
  "Jacksonville",
  "Fort Worth",
  "Columbus",
  "San Francisco",
  "Charlotte",
  "Indianapolis",
  "Seattle",
  "Denver",
  "Washington, D.C.",
  "Boston",
  "El Paso",
  "Nashville",
  "Detroit",
  "Oklahoma City",
  "Portland",
  "Las Vegas",
  "Memphis",
  "Louisville",
  "Milwaukee",
  "Albuquerque",
  "Tucson",
  "Fresno",
  "Sacramento",
  "Long Beach",
  "Kansas City",
  "Mesa",
  "Atlanta",
  "Colorado Springs",
  "Virginia Beach",
  "Raleigh",
  "Omaha",
  "Miami",
  "Oakland",
  "Minneapolis",
  "Tulsa",
  "Wichita",
  "New Orleans",
];

export const howItWorksData = {
  seekers: {
    types: [
      {
        heading: "Pay Conveniently & Safely",
        image: pay,
        description:
          "Platform will release funds to vendor when they have delivered the promised service. Contact us to Submit Disputes if vendor acted unethically",
      },
      {
        heading: "Vendor Profiles",
        image: profile,
        description:
          "Read vendor reviews, view work history, view sample work & communicate via messages on the platform to keep record of your transaction details",
      },
      {
        heading: "Create Auction Listings!",
        image: listing,
        description:
          "Post your Need via Auction Listing. Set your reasonable budget and project criteria. Interested vendors will place competitive bids in attempt to win your business. At auction end, select the best bidder you prefer to work with.",
      },
    ],
  },
  providers: {
    types: [
      {
        heading: "Grow your Business!",
        image:
          "https://rebornstorage.blob.core.windows.net/uploads/5fef9f41-35f8-4922-a5d5-8da9224f158fmedium-image-cropper.webp",
        description:
          "Hundreds of couples visit our site monthly to find Indian Wedding Vendors",
      },
      {
        heading: "Get Paid Conveniently",
        image: pay,
        description:
          "The platform auto releases payment to vendor on date of service rendering",
      },
      {
        heading: "Professional Representation",
        image: listing,
        description:
          "Share hyperlink to your listing on social networks and effectively improve your lead generation and sales ratios",
      },
    ],
  },
};

export const categories = [
  // "Local Community Ads",
  "Event Decorations",
  "Event Venues",
  "Gurdwara/Temple",
  "Hair/Makeup",
  "Indian Cuisine",
  "Jewelry",
  "Mehndi",
  "Photography/Videography",
  "Wedding Clothing",
  "Transportation",
  "Dance Performances",
  "Musicians & Instruments",
  "MC/Host",
  "Turban Tying Service",
];

export const imageArray = [banner5, banner1, banner2, banner3, banner4];
