/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Input, Button } from "reactstrap";
import { fetchCategories, fetchSubCategories, fetchItems } from "../Data";
export const SearchBwv = ({ handleSearchBwv, initialSearchQuery }) => {
  const [searchQuery, setSearchQuery] = useState(initialSearchQuery || "");
  const [cat, setCat] = useState([]);
  const [subCat, setSubCat] = useState([]);
  const [item, setItem] = useState([]);
  const onSearch = () => {
    handleSearchBwv(searchQuery);
  };
  useEffect(() => {
    const fetchCat = async () => {
      const categoriesData = await fetchCategories();
      const subCategoriesData = await fetchSubCategories();
      const itemsData = await fetchItems();
      setCat(categoriesData);
      setSubCat(subCategoriesData);
      setItem(itemsData);
      console.log(categoriesData, subCategoriesData, itemsData, "cat");
    };
    fetchCat();
  }, []);
  const filteredData = [
    ...cat,
    ...subCat,
    // ...item,
  ].filter(
    (data) =>
      data.Name && data.Name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSelect = (value) => {
    setSearchQuery(value);
    handleSearchBwv(value);
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onSearch();
    }
  };
  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Input
            type="search"
            className="m-2"
            style={{
              width: "71%",
            }}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search..."
            onKeyPress={handleKeyPress}
          />
          <Button className="bg-primary m-0" onClick={onSearch}>
            Search
          </Button>
        </div>
        <div className="wc_search-result"
          style={{
            display: "flex",
            alignItems: "center",
            position: "absolute",
            zIndex: 9,
            backgroundColor: "#FAF9F6",
            color: "#000",
            left: "316px",
            width: "48%",
            borderRadius: "10px",
            
          }}
        >
          {searchQuery && (
            <ul className="list m-0 p-0"
            style={{
              height:"229px",
            overflowY:"scroll"
            }}>
              {filteredData.map((data) => (
                <li
                  key={data.id}
                  onClick={() => handleSelect(data.Name)}
                  style={{
                    cursor: "pointer",
                    padding: "10px",
                    listStyle: "none",
                  }}
                  className="text-secondary text-decoration-none text-center w-100 d-flex justify-content-between align-items-center"
                >
                  {data.Name}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </>
  );
};
