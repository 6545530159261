import { useState } from "react";
import { useParams } from "react-router-dom";
import { ResetPw } from "../components/Header/Data2";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  CardSubtitle,
  Spinner,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "./ResetPwPage.css"; // Assuming you have custom CSS
import { toast } from "react-toastify";

const ResetPwPage = () => {
  const { Id: userId } = useParams();
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);

  const toggleShowNewPass = () => setShowNewPass(!showNewPass);
  const toggleShowConfirmPass = () => setShowConfirmPass(!showConfirmPass);

  const validatePassword = (password) => {
    const errors = [];
    if (password.length < 6) {
      errors.push("Password must be at least 6 characters.");
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      errors.push("Password must contain at least one special character.");
    }
    if (!/\d/.test(password)) {
      errors.push("Password must contain at least one number.");
    }
    if (!/[a-zA-Z]/.test(password)) {
      errors.push("Password must contain at least one alphabet.");
    }
    return errors;
  };

  const handlePwReset = () => {
    let passward = {
      ChangedPassword: newPass,
    };

    const passwordErrors = validatePassword(newPass);
    if (passwordErrors.length > 0) {
      setError(passwordErrors.join(" "));
      setSuccess("");
      return;
    }

    if (!newPass) {
      setError("Please enter password.");
      return;
    }
    if (!confirmPass) {
      setError("Please enter confirm password.");
      return;
    }
    if (newPass === confirmPass) {
      setLoading(true);
      ResetPw(passward, userId)
        .then(() => {
          setSuccess("Password reset successfully!");
          setError("");
          setLoading(false);
          toast.success("Password reset successfully!");
          setNewPass("");
          setConfirmPass("");
        })
        .catch((err) => {
          setError("Failed to reset password. Please try again.");
          setSuccess("");
          setLoading(false);
        });
    } else {
      setError("Passwords do not match.");
      setSuccess("");
    }
  };

  return (
    <Row className="justify-content-md-center mt-5">
      <Col md={6}>
        <Card className="shadow-lg p-4 mb-5 bg-white rounded">
          <CardBody>
            <CardSubtitle tag="h6" className="text-muted text-center mb-4">
              Reset Password
            </CardSubtitle>
            {error && <Alert color="danger">{error}</Alert>}
            {success && <Alert color="success">{success}</Alert>}
            <Form>
              <FormGroup>
                <Label for="newPassword">New Password</Label>
                <InputGroup>
                  <Input
                    type={showNewPass ? "text" : "password"}
                    id="newPassword"
                    placeholder="Enter new password"
                    value={newPass}
                    onChange={(e) => setNewPass(e.target.value)}
                  />
                  <InputGroupText onClick={toggleShowNewPass}>
                    {showNewPass ? <FaEyeSlash /> : <FaEye />}
                  </InputGroupText>
                </InputGroup>
              </FormGroup>

              <FormGroup className="mt-3">
                <Label for="confirmPassword">Confirm Password</Label>
                <InputGroup>
                  <Input
                    type={showConfirmPass ? "text" : "password"}
                    id="confirmPassword"
                    placeholder="Confirm new password"
                    value={confirmPass}
                    onChange={(e) => setConfirmPass(e.target.value)}
                  />
                  <InputGroupText onClick={toggleShowConfirmPass}>
                    {showConfirmPass ? <FaEyeSlash /> : <FaEye />}
                  </InputGroupText>
                </InputGroup>
              </FormGroup>

              <Button
                color="primary"
                className="mt-4 w-100"
                onClick={handlePwReset}
                disabled={loading}
              >
                {loading ? <Spinner size="sm" /> : "Reset Password"}
              </Button>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default ResetPwPage;
