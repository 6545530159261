import React, { useState, useEffect } from "react";
import { Row, Col, Form, FormGroup, Input, Label, Button } from "reactstrap";
import { useSelector } from "react-redux";
import Sidebar from "../../SideBar";
import { useNavigate, useLocation } from "react-router-dom";
import { FaArrowLeft, FaPlusCircle, FaCheckCircle } from "react-icons/fa";
import {
  postSponsordAd,
  updateSponsordAd,
} from "../../../components/Header/Data";
import "../DashboardPages.css";
import { toast } from "react-toastify";
import PayPalModal from "../PayPalModal";
import PlaceHolder from "../../../assets/images/Placeholder.png";
import uploadToS3 from "../../../utils/awsS3Upload";

const AddNewAds = () => {
  const userinfo = useSelector((state) => state.user.userInfo);
  const islogin = useSelector((state) => state.user.isLogin);
  const user = islogin ? JSON.parse(userinfo) : null;
  const location = useLocation();
  const [price, setPrice] = useState(0);
  const [paymentModal, setPaymentModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [adDetails, setAdDetails] = useState({});
  const [sponsordAd, setSponsordAd] = useState({
    Header: "",
    Photo: "",
    startAt: "",
    endAt: "",
    Url: "",
    Status: 0,
    createdBy: user.UserID,
    Email: user.Email,
  });
  const navigate = useNavigate();
  const [image, setImage] = useState("");

  useEffect(() => {
    if (location.state) {
      const { startAt, endAt, Photo, id, Url, Header } = location.state;
      setSponsordAd({
        startAt: startAt.substring(0, 10),
        endAt: endAt.substring(0, 10),
        Photo: Photo,
        id: id,
        Status: 0,
        Url: Url,
        Header: Header,
      });
      setImage(Photo);
    }
  }, [location.state]);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    let folder = "sponcerAd/";
    if (file) {
      // const reader = new FileReader();
      const imageUrl = await uploadToS3(file, folder);
      // reader.onload = (e) => {
      setImage(imageUrl);
      console.log(imageUrl, "imageUrl");
      setSponsordAd((prevSponsordAd) => ({
        ...prevSponsordAd,
        Photo: imageUrl,
      }));
      // };
      // reader.readAsDataURL(file);
    }
  };

  const handleRequest = async (e) => {
    e.preventDefault();
    if (isLoading) return;
    setIsLoading(true);
    if (!sponsordAd.Header) {
      toast.error("Enter the header");
    } else if (!sponsordAd.Photo) {
      toast.error("Please upload Photo");
    } else if (!sponsordAd.startAt) {
      toast.error("Select start Date");
    } else if (!sponsordAd.endAt) {
      toast.error("Select end Date");
    } else if (!sponsordAd.Url) {
      toast.error("FPlease Enter url");
    } else if (sponsordAd.startAt === sponsordAd.endAt) {
      toast.error("Start Date must be earlier than End Date");
    } else if (sponsordAd.startAt < new Date().toISOString().split("T")[0]) {
      toast.error("Start Date must be later than today");
    } else if (sponsordAd.endAt < new Date().toISOString().split("T")[0]) {
      toast.error("End Date must be later than today");
    } else if (location.state) {
      sponsordAd.updatedBy = user.UserID;
      await updateSponsordAd(sponsordAd, sponsordAd.id);
      toast.success(`Request Updated Successfully`);
      navigate("/dashboardpage/sponsored-ads");
      window.scroll(0, 0);
    } else {
      const response = await postSponsordAd(sponsordAd);
      setPaymentModal(true);
      setAdDetails(response.data.Data);
      toast.success(`Request Sent To Admin For Approval`);
      navigate("/dashboardpage/sponsored-ads");
      window.scroll(0, 0);
    }
    setIsLoading(false);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "startAt" && sponsordAd.endAt && value > sponsordAd.endAt) {
      toast.error("Start Date must be earlier than End Date");
      return;
    }
    setSponsordAd((prevSponsordAd) => ({
      ...prevSponsordAd,
      [name]: value,
    }));
  };
  useEffect(() => {
    const calculatePrice = () => {
      if (sponsordAd.startAt && sponsordAd.endAt) {
        const startDate = new Date(sponsordAd.startAt);
        const endDate = new Date(sponsordAd.endAt);
        if (endDate < startDate) {
          setPrice(0);
          return;
        }
        const durationInMilliseconds = endDate - startDate;
        const durationInMonths =
          durationInMilliseconds / (1000 * 60 * 60 * 24 * 30);
        const price = Math.ceil(durationInMonths) * 1; // $1 per month
        setPrice(price);
      }
    };

    calculatePrice();
  }, [sponsordAd.startAt, sponsordAd.endAt]);
  return (
    <section className="home-after-login bg-white">
      <div className="container" style={{ position: "relative" }}>
        <Row>
          <Col sm={3}>
            <Sidebar />
          </Col>
          <Col sm={9} className="mt-3">
            <Row className="m-3 w-100 my-5" style={{ marginBottom: "20%" }}>
              <p
                onClick={() => {
                  navigate(-1);
                }}
                style={{
                  cursor: "pointer",
                  padding: "0px",
                  fontSize: "18px",
                  color: "#000",
                  zIndex: 999,
                  fontWeight: "bold",
                }}
                className="mb-2"
              >
                <FaArrowLeft
                  style={{
                    marginRight: "6px",
                    fontSize: "18px",
                    marginTop: "-3px",
                  }}
                />
                Back
              </p>
              <Row className="form-container p-4 mb-5">
                <Form>
                  <h4 className="mb-2">Ad Information</h4>
                  <FormGroup>
                    <Label>Type Ad Header (Service you Offer OR Seek)</Label>
                    <span style={{ color: "red" }}>*</span>
                    <Input
                      type="text"
                      name="Header"
                      value={sponsordAd.Header}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Link</Label>
                    <span style={{ color: "red" }}>*</span>
                    <Input
                      placeholder="http://www.example.com"
                      type="url"
                      name="Url"
                      value={sponsordAd.Url}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                  <Row style={{ position: "relative" }}>
                    <Col lg="4" md="12">
                      <FormGroup>
                        <Label for="imageUpload" className="m-0">
                          Ad Image<span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          type="file"
                          id="imageUpload"
                          onChange={handleImageChange}
                          style={{ display: "none" }}
                          name="images"
                        />
                      </FormGroup>
                      <div
                        className="image-box"
                        onClick={() =>
                          document.getElementById("imageUpload").click()
                        }
                        style={{ display: "flex", flexWrap: "wrap" }}
                      >
                        {image && (
                          <>
                            <img
                              src={image}
                              alt="Uploaded Image"
                              className="uploaded-image image-fluid rounded"
                              style={{ width: "100px", height: "80px" }}
                            />
                          </>
                        )}
                        {!image && <FaPlusCircle className="upload-icon" />}
                      </div>
                    </Col>
                    <Col lg="4" md="12">
                      <FormGroup>
                        <Label>Start Date</Label>
                        <span style={{ color: "red" }}>*</span>
                        <Input
                          type="date"
                          name="startAt"
                          value={sponsordAd.startAt || ""}
                          onChange={handleInputChange}
                          min={new Date().toISOString().split("T")[0]}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4" md="12">
                      <FormGroup>
                        <Label>End Date</Label>
                        <span style={{ color: "red" }}>*</span>
                        <Input
                          type="date"
                          name="endAt"
                          value={sponsordAd.endAt || ""}
                          onChange={handleInputChange}
                          min={sponsordAd.startAt}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <p className="m-2 text-center">
                      A <b className="text-primary">$ {price}</b> bill will be
                      added to you Cart
                    </p>
                  </Row>
                </Form>
              </Row>
              <Row className="form-container">
                <h4>Preview</h4>
                <div className="preview-ads row">
                  <Col
                    style={{
                      borderRight: "1px solid gray",
                      textAlign: "center",
                    }}
                    lg={3}
                  >
                    <img
                      src={sponsordAd?.Photo || PlaceHolder}
                      style={{ width: "190px ", height: "180px" }}
                      className="img-fluid rounded"
                    />
                  </Col>
                  <Col style={{ backgroundColor: "#F5F7FA" }} lg={9}>
                    <div>
                      <span>Sponsored by {user?.UserName}</span>
                      <h5>
                        <b>{sponsordAd?.Header}</b>
                      </h5>
                    </div>
                  </Col>
                </div>
              </Row>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                  padding: "10px",
                }}
              >
                <Button
                  type="submit"
                  className="btn btn-danger"
                  onClick={handleRequest}
                  disabled={isLoading}
                >
                  <FaCheckCircle />{" "}
                  {location.state ? "Update Ad" : "Request Admin Approval"}
                </Button>
                <PayPalModal
                  isOpen={paymentModal}
                  closeModal={() => setPaymentModal(false)}
                  price={price}
                  adDetails={adDetails}
                />
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default AddNewAds;
