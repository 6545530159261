import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../settingsComponents/Settings.css";
import { getCashOutAdmin } from "../../../components/Header/Data";
import { Input, Row, Col } from "reactstrap";
import { useSelector } from "react-redux";
import { getAdminPayToProiderList } from "../../../components/Header/Data";
import PaymentHistory from "./PaymentHistory";
import { FadeLoader } from "react-spinners";
import PayModal from "../../../components/Header/modals/PayModal";
// import { CSVLink } from "react-csv";

const Cashout = () => {
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  //const [payments, setPayments] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const isAdmin = user && user.Role === "admin";
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedMsg, setSelectedMsg] = useState(null);

  const fetchExpectedPaydate = async (data) => {
    try {
      const adminPayToProiderList = await getAdminPayToProiderList(data.Id);
      console.log(adminPayToProiderList, "adminPayToProiderList");
      if (adminPayToProiderList.length === 0) {
        return;
      }
      return formatDate(adminPayToProiderList[0]?.created_at);
    } catch (error) {
      console.error("Error fetching expected pay date:", error);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;

    return `${formattedMonth}/${formattedDay}/${year}`;
  }

  useEffect(() => {
    console.log(users, "users");
  }, []);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  // const handleStartDateChange = (e) => {
  //   setStartDate(e.target.value);
  // };

  // const handleEndDateChange = (e) => {
  //   setEndDate(e.target.value);
  // };

  // const onSuccess = (details, data) => {
  //   // Successfully logged in with PayPal
  //   console.log("Login Successful:", details, data);
  // };
  // const [accountStatus, setAccountStatus] = useState(null);
  // useEffect(() => {
  //   const fetchPayPalAccountStatus = async () => {
  //     try {
  //       // Replace 'YOUR_CLIENT_ID' and 'YOUR_SECRET' with your actual PayPal client credentials
  //       const clientId =
  //         "AaqY5POsphq_iOg3JGN-H5lxMige1F_HmVlqMiu_sR4RrJzYLg_B8gqjVX1n-0pOlWPe5upN2Zb3q_RU";
  //       const secret =
  //         "EBP6oI_xJnLZX9lN0U1w_Yj2Fj_f8El0yaOMOPzVacNyq10caLpZRJuRp8kZkcrca3Cc3inYwzi65h1V";
  //       const base64Credentials = btoa(`${clientId}:${secret}`);

  //       const response = await axios.post(
  //         process.env.PAYPAL_URL+"v1/oauth2/token",
  //         "grant_type=client_credentials",
  //         {
  //           headers: {
  //             "Content-Type": "application/x-www-form-urlencoded",
  //             Authorization: `Basic ${base64Credentials}`,
  //           },
  //         }
  //       );

  //       const accessToken = response.data.access_token;

  //       const accountResponse = await axios.get(
  //         process.env.PAYPAL_URL+"v1/identity/oauth2/userinfo?schema=paypalv1.1",
  //         {
  //           headers: {
  //             Authorization: `Bearer ${accessToken}`,
  //           },
  //         }
  //       );

  //       setAccountStatus(accountResponse.data);
  //     } catch (error) {
  //       console.error("Error fetching PayPal account status:", error);
  //     }
  //   };

  //   fetchPayPalAccountStatus();
  // }, []);
  // const onError = (err) => {
  //   // Handle errors
  //   console.error("Login Error:", err);
  // };

  // const onCancel = (data) => {
  //   // User canceled the login
  //   console.log("Login Canceled:", data);
  // };
  // const createOrder = (data, actions) => {
  //   // Your implementation here
  // };

  const fetchCashoutAdmin = async () => {
    setLoading(true);
    try {
      const data = await getCashOutAdmin();
      const updatedUsers = await Promise.all(
        data.map(async (user) => {
          const payDate = await fetchExpectedPaydate(user);
          return { ...user, expectedPayDate: payDate };
        })
      );
      //Sort the updatedUsers
      updatedUsers.sort((a, b) => {
        // Check if expectedPayDate is undefined or invalid
        if (!a.expectedPayDate && !b.expectedPayDate) {
          return 0; // Leave the order unchanged
        } else if (!a.expectedPayDate) {
          return 1; // Place a at the bottom
        } else if (!b.expectedPayDate) {
          return -1; // Place b at the bottom
        } else {
          const dateA = new Date(a.expectedPayDate);
          const dateB = new Date(b.expectedPayDate);
          const today = new Date();
          const diffA = Math.abs(dateA - today);
          const diffB = Math.abs(dateB - today);

          return diffA - diffB; // Sort ascending, nearest date comes first
        }
      });
      setUsers(updatedUsers);
      console.log(data, "response");
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCashoutAdmin();
    console.log(filteredUsers, "filteredUsers");
    // if (user.RoleId !== 1) {
    //   fetchPaymentHistory();
    // }

    //console.log(selectedUser, "selectedUser");
    console.log(user, "user");
  }, []);

  const handleHistory = (e, data) => {
    e.preventDefault();
    console.log(data.Id, "Data");
    setSelectedUser(data);
    if (data) {
      //const queryString = new URLSearchParams().toString();
      window.open(`/#/cashout/history?Id=${data.Id}`, "_blank");
    }
  };

  const handlePaidListing = (e, data) => {
    e.preventDefault();
    const fullName = `${data.firstName} ${data.lastName}`;
    console.log(fullName, "fullName");
    setSelectedUser(data);
    if (data) {
      window.open(
        `/#/cashout/PaidList?&id=${data.Id}&email=${data.paypalEmail}&phone=${data.Phone}&Location=${data.Location}&Name=${fullName}`,
        "_blank"
      );
    }
  };

  // const handleHistory = (data) => {
  //   setSelectedUser(data);
  //   if (data) {
  //     const url = "/cashout/history";
  //     const queryParams = `?data=${encodeURIComponent(JSON.stringify(data))}`;
  //     window.open(url + queryParams, "_blank");
  //   }
  // };
  // Filter users based on search query
  const filteredUsers = users.filter((data) => {
    const fullName = `${data.firstName} ${data.lastName}`;
    return fullName.toLowerCase().includes(searchQuery.toLowerCase());
  });

  // const updatePaymentAmount = (userId, amount) => {
  //   setPaymentAmount((prevAmounts) => ({
  //     ...prevAmounts,
  //     [userId]: amount,
  //   }));
  // };

  // const handleEmailChange = (event) => {
  //   setEmail(event.target.value);
  // };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   // Here you can perform any actions you need with the email address, such as sending it to your backend for verification
  //   console.log("Submitted email:", email);
  // };

  // const fetchPaymentHistory = async () => {
  //   try {
  //     const Id = user.UserID;
  //     const fetchedHistory = await getPaypalHistory(Id);
  //     console.log(fetchedHistory, "fetchedHistory");
  //     setPayments(fetchedHistory);
  //     //handleSearch(); // Trigger search after setting payments
  //   } catch (error) {
  //     console.error("Error fetching payment history:", error);
  //   }
  // };

  // const filterPayments = () => {
  //   const filteredData = payments.filter((payment) => {
  //     const paymentDate = new Date(payment.createdAt).getTime();
  //     const startDateTime = startDate
  //       ? new Date(startDate).setHours(0, 0, 0, 0)
  //       : 0;
  //     const endDateTime = endDate
  //       ? new Date(endDate).setHours(23, 59, 59, 999)
  //       : Infinity;
  //     return (
  //       payment.paypalEmail.toLowerCase().includes(searchName.toLowerCase()) &&
  //       paymentDate >= startDateTime &&
  //       paymentDate <= endDateTime
  //     );
  //   });
  //   setFilteredPayments(filteredData);
  // };

  function formatExpectedPayment(eventDate) {
    const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds
    const eventDateObj = new Date(eventDate);
    const paymentExpectedDateObj = new Date(eventDateObj.getTime() + oneDay);
    return formatDate(paymentExpectedDateObj);
  }

  return (
    <div className="mt-3 inter-font">
      {user && user.RoleId === 2 && <PaymentHistory userIDs={user.UserID} />}
      {users && isAdmin && (
        <div>
          <div className="mb-5 d-flex justify-content-end gap-3 align-items-center">
            <button className="mb-2 btn btn-success" onClick={toggleModal}>
              Download CSV
            </button>
            <PayModal
              isModalOpen={showModal}
              handleToggleModal={toggleModal}
              selectedMsg={selectedMsg}
            />
            {/* <Modal isOpen={showModal} toggle={toggleModal}>
              <ModalHeader toggle={toggleModal}>Download CSV</ModalHeader>
              <ModalBody>
                <div className="mb-3">
                  <label htmlFor="startDate" className="form-label">
                    Start Date:
                  </label>
                  <Input
                    type="date"
                    id="startDate"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="endDate" className="form-label">
                    End Date:
                  </label>
                  <Input
                    type="date"
                    id="endDate"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={handleDownloadCSV}>
                  Download
                </Button>{" "}
                <Button color="secondary" onClick={toggleModal}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal> */}
            <Input
              type="text"
              name="search"
              placeholder="Search"
              className="search-input w-25"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div className="d-flex flex-row ml-0 d-flex justify-content-between">
            <div className=" fw-bold  text-nowrap">Vendor Name</div>
            <div className=" fw-bold  text-nowrap">Paypal ID</div>
            <div className=" fw-bold  text-nowrap">Expected pay date</div>
            <div className=" fw-bold  text-nowrap">Status</div>
          </div>
          <div>
            {!loading ? (
              <div className="position-relative">
                {filteredUsers.length > 0 ? (
                  <>
                    {filteredUsers.map((data, i) => (
                      <Row key={i} className="mt-4 position-relative">
                        <>
                          <Col sm={3} className="p-0">
                            <div className="text-nowrap mt-3">
                              {i + 1}. {data.firstName} {data.lastName}
                              {data.expectedPayDate &&
                                (console.log(
                                  formatExpectedPayment(
                                    data.expectedPayDate,
                                    "expectedPayDate"
                                  )
                                ),
                                console.log(formatDate(new Date("05/19/2024"))),
                                (
                                  <span
                                    className={
                                      formatExpectedPayment(
                                        data.expectedPayDate
                                      ) >= formatDate(new Date())
                                        ? "blinking-circle green"
                                        : "blinking-circle red"
                                    }
                                  ></span>
                                ))}
                            </div>
                          </Col>
                          <Col sm={3} className="p-0">
                            <div className="text-nowrap mt-3">
                              {data.paypalEmail}
                            </div>
                          </Col>
                          <Col sm={3} className="p-0">
                            <div
                              className="text-nowrap mt-3 text-center"
                              style={{ marginRight: "-40px" }}
                            >
                              {data.expectedPayDate
                                ? formatExpectedPayment(data.expectedPayDate)
                                : ""}
                            </div>
                          </Col>
                          <Col
                            sm={3}
                            className="d-flex flex-row gap-4 d-flex justify-content-end"
                            style={{ marginRight: "0px" }}
                          >
                            <Row>
                              <Col sm={9}>
                                <button
                                  className="btn btn-primary mt-2 mb-3 text-nowrap text-white"
                                  style={{ marginLeft: "33px" }}
                                  onClick={(e) => handlePaidListing(e, data)} // Open modal when button is clicked
                                >
                                  View Paid List
                                </button>
                              </Col>
                              <Col sm={3}>
                                <button
                                  className="btn btn-warning mt-2 mb-3 "
                                  style={{ marginLeft: "23px" }}
                                  onClick={(e) => handleHistory(e, data)}
                                >
                                  History
                                </button>
                              </Col>
                            </Row>
                          </Col>
                          {/* <Col sm={1}>

                </Col> */}
                        </>
                      </Row>
                    ))}
                  </>
                ) : (
                  <>
                    <p
                      className="text-center h5 mt-5 position-absolute"
                      style={{ left: "45%" }}
                    >
                      No Data Found!
                    </p>
                  </>
                )}
              </div>
            ) : (
              <>
                <p
                  className="text-center h5 mt-5 position-absolute"
                  style={{ left: "60%" }}
                >
                  <FadeLoader color="#36d7b7" />
                </p>
              </>
            )}
          </div>
        </div>
      )}
      {/* {user.Role === "provider" && (
        <div style={{marginTop:"17%"}}>
          <Container className="">
          <Form onSubmit={handleSubmit} className="mt-5 d-flex align-items-center">
            <FormGroup className="d-flex justify-content-evenly" style={{marginLeft:"-60px", width:"60%"}}>
              <Label for="email" className="fw-bold mt-2 ">Email:</Label> 
              <Input
                type="email"
                id="email"
                value={email}
                onChange={handleEmailChange}
                required
                style={{width:"70%",marginLeft:"-30px"}}
              />
            </FormGroup>
            <Button color="primary" type="submit" className="mt-0 mb-3" style={{marginLeft:"-40px"}}>
              Verify Email
            </Button>
          </Form>
        </Container>
        </div>
      )} */}
    </div>
  );
  // <>
  //   <Row className="Profile-Container">
  //     <h6 style={{ fontWeight: "bold", marginBottom: "20px" }}>
  //       Funding details
  //     </h6>
  //     <span>PayPal Connect</span>
  //     <p>Connect your PayPal account in order to cash out your sales</p>
  //     <div className="message-box">
  //       You must connect to PayPal in order to sell on the marketplace. Your
  //       listings will not be visible until you connect.
  //     </div>
  //     <a href="https://www.sandbox.paypal.com/signin">
  //       <img
  //         className="w-auto"
  //         src="https://www.paypalobjects.com/devdoc/log-in-with-paypal-button.png"
  //       />
  //     </a>
  //   </Row>

  //   <div>
  //     <h1>PayPal Account Status</h1>
  //     {/* {accountStatus && (
  //       <pre>{JSON.stringify(accountStatus, null, 2)}</pre>
  //     )} */}
  //   </div>
  // </>

  // return (
  //   <Row className="Profile-Container">
  //     <h6 style={{ fontWeight: "bold", marginBottom: "20px" }}>
  //       Funding details
  //     </h6>
  //     <span>PayPal Connect</span>
  //     <p>Connect your PayPal account in order to cash out your sales</p>
  //     <div className="message-box">
  //       You must connect to PayPal in order to sell on the marketplace. Your
  //       listings will not be visible until you connect.
  //     </div>
  //     <a href="https://www.sandbox.paypal.com/signin/authorize?flowEntry=static&client_id=AaqY5POsphq_iOg3JGN-H5lxMige1F_HmVlqMiu_sR4RrJzYLg_B8gqjVX1n-0pOlWPe5upN2Zb3q_RU&scope=login&fullPage=true&redirect_uri=http://3.110.117.209:3000/dashboardpage/settings">
  //       <img src="https://www.paypalobjects.com/devdoc/log-in-with-paypal-button.png" />
  //     </a>
  //   </Row>
  // );
};

export default Cashout;
