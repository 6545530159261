import React, { useState } from "react";
import "./footer.css";
import logo from "../../../src/assets/images/eco-logo.png";
import wedLogo from "../../../src/assets/images/img/wc-logo.png";
import { Link, useNavigate } from "react-router-dom";
import {
  BsLinkedin,
  BsYoutube,
  BsFacebook,
  BsInstagram,
  BsGithub,
} from "react-icons/bs";
import { useEffect } from "react";
import axiosClient from "../../axios-client";
import newsletter from "../../assets/images/newsletter.png";
import app1 from "../../assets/images/e-cab-logo-blue.png";
import app2 from "../../assets/images/basis-logo.jpg";
import app3 from "../../assets/images/BKash-Logo.wine.png";
import app4 from "../../assets/images/dutch-bangla-rocket-logo.png";
import { useLocation } from "react-router-dom";

import Magisticlogo from "../../../src/assets/images/img/megisticLogo2.png";
import { Button } from "reactstrap";

const Footer = () => {
  const navigate = useNavigate();
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [facegook, setFacebook] = useState("");
  const [aboutus, setaboutus] = useState("");
  const [privacy, setPrivacy] = useState("");
  const [refund, setRefund] = useState("");
  const [termsCondition, setTermsConditions] = useState("");
  const [shipping, setShipping] = useState("");
  let Logo =
    "https://rebornstorage.blob.core.windows.net/uploads/785c365c-4790-4099-9083-7993ac925e74high-Test%20logo%205.webp";

  const location = useLocation();
  const isHomePage = location.pathname === "/" || location.pathname === "/home";
  const isBrowsePage = location.pathname === "/weddingvendors";
  console.log("location path");
  const route_path = location.pathname;
  let abs_path = route_path.replace(/[^a-zA-Z ]/g, "");

  const handleZoomWelcomePageClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <footer className="py-4 bg-white">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="office__info pe-5">
                <address>{address}</address>
                {isHomePage || isBrowsePage ? (
                  <img
                    className="d-lg-block wc-logo2 "
                    src={Magisticlogo}
                    alt="logo"
                  />
                ) : (
                  <img
                    className="d-lg-block wc-logo"
                    src={wedLogo}
                    alt="logo"
                  />
                )}
                <div className="social_icons d-flex align-items-center gap-15 mt-4">
                  <a
                    href="https://www.youtube.com/channel/UCDktIvmaFNB5rcAOevDXUrw"
                    target="_blank"
                  >
                    <BsYoutube className="fs-5" />
                  </a>
                  <a
                    href="https://www.facebook.com/people/Indian-Wedding-Vendors-LLC/100088337712267/"
                    target="_blank"
                  >
                    <BsFacebook className="fs-5" />
                  </a>
                  <a
                    href="https://www.instagram.com/myindiancommunity/"
                    target="_blank"
                  >
                    <BsInstagram className="fs-5" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <div className="footer-links d-flex flex-column">
                <Link className=" text-dark py-2 mb-1" to="">
                  {" "}
                  Sell{" "}
                </Link>

                <Link
                  className=" text-dark py-2 mb-1"
                  to={`about/privacy/${privacy}`}
                >
                  {" "}
                  Browse Listing{" "}
                </Link>
                <Link
                  className=" text-dark py-2 mb-1"
                  to={`about/shipping/${shipping}`}
                >
                  {" "}
                  Browse Action{" "}
                </Link>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <div className="footer-links d-flex flex-column">
                <Link className=" text-dark py-2 mb-1" to="/privacypolicies">
                  {" "}
                  Privacy Policies{" "}
                </Link>
                <Link className=" text-dark py-2 mb-1" to="aboutus/">
                  {" "}
                  About Us{" "}
                </Link>
                <Link className=" text-dark py-2 mb-1" to="contact/us/ll">
                  {" "}
                  Contact Us{" "}
                </Link>
                <Link className=" text-dark py-2 mb-1" to="pricing/">
                  {" "}
                  Pricing{" "}
                </Link>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <Link to="/need/request" className="text-decoration-underline">
                Post Your Ad
              </Link>
            </div>
            {isHomePage || isBrowsePage ? (
              <p className="footer__copyright mb-0 text-dark align-items-center">
                &copy; Majestic Events All rights reserved{" "}
              </p>
            ) : (
              <p className="footer__copyright mb-0 text-dark align-items-center">
                &copy; My Indian Community.ORG All rights reserved{" "}
              </p>
            )}
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
