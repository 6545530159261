import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Form, Row, Col, FormGroup, Label, Input, Button } from "reactstrap";
import { Link } from "react-router-dom";
import Payment from "../../../pages/DashboardPages/settingsComponents/Payment";

const HirePay = () => {
  const location = useLocation();
  const orderDetails = location.state && location.state.form;

  useEffect(() => {
    console.log(orderDetails, "orderDetails");
  }, []);

  return (
    <div className="p-5">
      <p>Back to my cart</p>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-9">
            <Payment />
          </div>
          <div className="col-lg-3">fe</div>
        </div>
      </div>
    </div>
  );
};

export default HirePay;
