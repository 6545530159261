import React, { useState, useEffect } from "react";
import { Input, Form, Label, Modal, ModalBody, FormGroup } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { UpdateQuestion } from "../../components/Header/Data2";

const UpdateQuestionModal = ({
  isOpen,
  closeModal,
  selectedQuest,
  fetchCat,
  content,
}) => {
  const navigate = useNavigate();
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [newQuestion, setNewQuestion] = useState(selectedQuest?.Question);

  useEffect(() => {
    setNewQuestion(selectedQuest?.Question);
  }, [selectedQuest]);
  const Update = async () => {
    if (content === "edit") {
      const request = {
        Question: newQuestion,
        categoryId: selectedQuest.categoryId,
        updatedBy: user.UserID,
        questionStatus: "1",
      };
      if (!newQuestion.trim()) {
        toast.error("Please enter a question.");
        return;
      }
      await UpdateQuestion(request, selectedQuest.id);
      closeModal();
      fetchCat();
      toast.success(`Question Updated`);
      setNewQuestion("");
    } else if (content === "delete") {
      const request = {
        updatedBy: user.UserID,
        questionStatus: "2",
      };
      await UpdateQuestion(request, selectedQuest.id);
      closeModal();
      fetchCat();
      toast.success(`Question Deleted`);
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        closeModal();
        setNewQuestion("");
      }}
    >
      <ModalBody>
        <button
          type="button"
          className="btn-close position-absolute top-0 end-0 m-2"
          onClick={() => {
            closeModal();
            setNewQuestion("");
          }}
          aria-label="Close"
        ></button>
        <div className="modal-body ">
          <h5 className="text-center">
            <b>{selectedQuest?.Question}</b>
          </h5>
          {content === "edit" ? (
            <div>
              <Form className="pt-3">
                <FormGroup>
                  <Label>Enter New Question</Label>
                  <Input
                    type="textarea"
                    value={newQuestion}
                    placeholder="Enter New Question"
                    required
                    autoFocus
                    size="lg"
                    onChange={(e) => setNewQuestion(e.target.value)}
                  />
                </FormGroup>
              </Form>
              <div className="d-flex justify-content-end mt-3">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    Update();
                  }}
                >
                  Update Question
                </button>
              </div>
            </div>
          ) : (
            <div>
              <p className="text-center p-3">
                Are You want to delete this question
              </p>
              <div className="d-flex justify-content-end mt-3">
                <button
                  type="button"
                  className="btn btn-success mx-1"
                  onClick={() => {
                    Update();
                  }}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => closeModal()}
                >
                  No
                </button>
              </div>
            </div>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default UpdateQuestionModal;
