// HourlyEventCalendar.js
import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment-timezone";
import Modal from "react-modal";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "./Calender.css";
import axiosClient from "../../axios-client";
import { getAllOrders, statusUpdateOrder } from "../Header/Data";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaGoogle } from "react-icons/fa";
import EventDetailsModal from "./EventDetailsModal";
const localTimeZone = "America/New_York";
const localizer = momentLocalizer(moment);

const HourlyEventCalendar = ({ calendarEvents }) => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [open, setOpen] = React.useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [eventsData, setEventsData] = useState([]);
  const [meetingsData, setMeetingsData] = useState([]);

  const events = eventsData.map((event) => ({
    Id: event.post_id,
    title: event.service_need || "Event",
    start: moment(
      `${event.reserve_date} ${event.start_time}`,
      "YYYY-MM-DD HH:mm"
    ).toDate(),
    end: moment(
      `${event.reserve_date} ${event.end_time}`,
      "YYYY-MM-DD HH:mm"
    ).toDate(),
    dec: event.service_need || "Meeting Dec",
    custmorEmail: event.need || "abc@gmail.com",
  }));
  const meetings = meetingsData.map((event) => ({
    Id: event.post_id,
    title: event.service_need || "Meeting",
    start: moment(
      `${event.reserve_date} ${event.start_time}`,
      "YYYY-MM-DD HH:mm"
    ).toDate(),
    end: moment(`${event.reserve_date} ${event.start_time}`, "YYYY-MM-DD HH:mm")
      .add(15, "minutes")
      .toDate(),
    dec: event.service_need || "Meeting Dec",
    custmorEmail: event.need || "abc@gmail.com",
  }));
  const googleEvents = calendarEvents.map((event) => ({
    Id: event.id, // Assuming Google event ID can be used as Id
    title: event.summary,
    start: moment.utc(event.start.dateTime).tz(localTimeZone).toDate(),
    end: moment.utc(event.end.dateTime).tz(localTimeZone).toDate(),
    dec: event.description,
    custmorEmail: event.organizer.email,
    isGoogleEvent: true,
  }));
  console.log(meetingsData, "response.data.Data");
  const allEvents = [...events, ...meetings, ...googleEvents];
  const fetchData = async () => {
    try {
      const response = await axiosClient.get(
        `order/BlockCalanderDetail/${user.UserID}`
      );
      if (response.data.statusCode === 200) {
        setEventsData(response.data.Data);
        console.log(response.data.Data, "response.data.Data");
      } else {
        throw new Error(response.data.msg);
      }
    } catch (error) {
      throw new Error("Error fetching Blocked Dates");
    }
    try {
      const response = await axiosClient.get(
        `order/BlockCalanderDetailMsg/${user.UserID}`
      );
      if (response.data.statusCode === 200) {
        setMeetingsData(response.data.Data);
        console.log(response.data.Data, "response.data.Data");
      } else {
        throw new Error(response.data.msg);
      }
    } catch (error) {
      throw new Error("Error fetching meetings");
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = (event) => {
    if (event.isGoogleEvent) {
      toast.info("google Event cannot be edit");
      return;
    }
    setSelectedEvent(event);
    setOpen(true);
  };
  const reject = async () => {
    const reqData = {
      orderStatus: 2,
      createdBy: user.UserID,
    };
    await statusUpdateOrder(reqData, selectedEvent.Id);
    fetchData();
    setOpen(false);
    toast.warning(`${selectedEvent.title} rejected`);
  };

  const handleGoogle = async () => {
    const client = window.google.accounts.oauth2.initCodeClient({
      client_id: process.env.REACT_APP_SSO_GOOGLE_CLIENT_ID,
      scope: "https://www.googleapis.com/auth/calendar.events",
      //ux_mode: 'popup',
      callback: async (response) => {
        try {
          console.log("====" + response.code);
          if (!response.code) {
            return;
          } else {
            const form = {
              title: selectedEvent?.title,
              description: selectedEvent?.dec,
              attendees: `${selectedEvent?.custmorEmail},${user.Email}`,
              startDate: moment(selectedEvent?.start).format(
                "YYYY-MM-DDTHH:mm"
              ),
              endDate: moment(selectedEvent?.end).format("YYYY-MM-DDTHH:mm"),
              token: response.code,
            };
            await axiosClient
              .post("auth/createanevent", form)
              .then(function (response) {
                console.log(response);
                setOpen(false);
              })
              .catch(function (error) {
                console.log(error);
              });
          }
        } catch (error) {
          console.log("error is ====" + error);
        }
      },
    });
    client.requestCode();
  };
  // }else{
  //   console.error('The object or oauth2 property is undefined');
  // }

  const form = {
    title: selectedEvent?.title,
    description: selectedEvent?.dec,
    attendees: `${selectedEvent?.custmorEmail},${user.Email}`,
    startDate: moment(selectedEvent?.start).format("YYYY-MM-DDTHH:mm"),
    endDate: moment(selectedEvent?.end).format("YYYY-MM-DDTHH:mm"),
    // token: response.code,
  };
  console.log(form, "form");

  // };
  const customWeekHeader = ({ date, label }) => {
    return (
      <div>
        <span>{label}</span>
        <span style={{ marginLeft: "10px", fontSize: "16px" }}>
          {moment(date).format("YYYY")}
        </span>
      </div>
    );
  };
  return (
    <div
      style={{
        textAlign: "center",
        display: "block",
        padding: 30,
        margin: "auto",
      }}
    >
      <h5 className="pb-3">
        View your Bookings <i class="fas fa-sync"></i>
      </h5>
      <p className="pb-3 text-muted text-center ">
        <FaGoogle /> Note: To sync your Calendar, login with your Google ID
      </p>
      <Calendar
        localizer={localizer}
        events={allEvents}
        startAccessor="start"
        endAccessor="end"
        views={{ week: true, month: true }}
        step={60}
        timeslots={1}
        defaultView="week"
        defaultDate={moment().toDate()}
        onSelectEvent={handleOpen}
        style={{ height: 500 }}
        components={{
          week: {
            header: customWeekHeader,
          },
          event: ({ event }) => {
            const eventStyle = {
              backgroundColor: event.isGoogleEvent ? "green" : "", // Assign different colors
              color: "#fff",
              borderRadius: "0px",
              border: "none",
              cursor: "pointer",
            };
            return (
              <div style={eventStyle}>
                <strong>{event.title}</strong>
              </div>
            );
          },
        }}
      />
      <EventDetailsModal
        isOpen={open}
        close={() => {
          setOpen(false);
        }}
        event={selectedEvent}
      />
    </div>
  );
};

export default HourlyEventCalendar;
