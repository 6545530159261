import React from 'react'
import "../styles/about.css";
const AboutUs = () => {

    return (
        <>

            <div className="py-5 bg-info mb-5">
                <div className="container py-5">
                    <div className="row">
                        <h1 className="text-center"><b>About My Indian Community.ORG</b></h1>
                    </div>
                </div>
            </div>
            <div className="container pb-5">
                <div className="row wd-about-desc">
                    <div className="text-center"><h1><b>We facilitate transactions within the Indian Wedding Industry
                    </b></h1></div>
                    <p className='text-dark pt-5 '>The Indian wedding industry is a vibrant and exciting part of the country's cultural landscape. With its rich traditions, elaborate ceremonies, and colorful celebrations, Indian weddings are an experience like no other. However, planning an Indian wedding can be a challenging and time-consuming process, especially when it comes to finding the right vendors.</p>
                    <p className='text-dark pt-3'>In recent years, the Indian wedding industry has seen significant growth, with more couples opting for grand, elaborate celebrations that can involve hundreds or even thousands of guests. With this growth has come an increase in the number of wedding vendors, from photographers and makeup artists to caterers and decorators.</p>
                    <p className='text-dark pt-3'>Despite this, finding the right vendors for an Indian wedding can be a challenge. With so many options available, it can be difficult for couples to navigate the market and find the vendors that are right for them. This is where our site comes in.</p>
                    <p className='text-dark pt-3'>We connect engaged couples preparing for their wedding day with the vendors they need to make their dream wedding a reality. Our platform is designed to make it easy and convenient for couples to find the right vendors, regardless of where they are in the country or what their budget is.</p>
                    <p className='text-dark pt-3'>Whether you're looking for a photographer to capture your special day, a caterer to provide delicious food for your guests, or a decorator to create a stunning backdrop for your wedding, our platform has everything you need. We work with a wide range of vendors, from small, independent businesses to large, established companies, to ensure that our clients have access to the best options available.</p>
                    <p className='text-dark pt-3'>At our site, we believe that finding the right vendors for your wedding should be an enjoyable and stress-free experience. That's why we've made it our mission to simplify the process, so that you can focus on enjoying your engagement and planning the perfect wedding. Our platform is easy to use and offers a range of features and tools to help you connect with vendors, compare prices, and book services online.</p>
                    <p className='text-dark pt-3 '>So if you're planning an Indian wedding and struggling to find the right vendors, look no further than our site. With our help, you can find the vendors you need to make your dream wedding a reality, and enjoy a stress-free planning experience along the way.

                    </p>
                </div>
            </div>
            <div className="container pb-5">
                <div className="row d-flex justify-content-center">
                    <img className='text-center' style={{ width: "400px" }} src="/home/static/media/logo_new.301c5fd084f05d9ea592.png" alt="" />
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-3">
                        <div className="card p-4">
                        <h4 className='text-dark'>Listing Services</h4>
                        <p className='text-dark pt-3 '> Anyone can become a vendor on the site and is welcome to post unlimited number of listings. Vendors are background checked and validated service providers. All listings are validated/reviewed to prevent fraud.</p>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="card p-4">
                        <h4 className='text-dark'>Order placing Process</h4>
                        <p className='text-dark pt-3 '> We have built a complete online solution for vendor selection, negotiating rates, booking services, reviewing vendor performance records, handling disputes, and communicating service requests.</p>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="card p-4">
                        <h4 className='text-dark'>Complete Satisfaction</h4>
                        <p className='text-dark pt-3 '> We are committed to ensuring a Variety of vendors to choose from with their previous job history disclosed for your reference. Clients may dispute vendors that do not deliver service</p>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="card p-4">
                        <h4 className='text-dark'>Transaction Protection</h4>
                        <p className='text-dark pt-3 '> Bookings are pre-paid, funds are held in an escrow account until date of service execution. If there is no dispute from the client, then the funds are auto disbursed to the vendor.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container py-5">
                <div className="row pb-5">
                    <div className="col-lg-6">
                        <img className='rounded' src="https://rebornstorage.blob.core.windows.net/uploads/d28bc57e-0ce3-44b0-8604-5af7a10b512bhigh-indian%20couple.webp" alt="" />
                    </div>
                    <div className="col-lg-6">
                        <h3 className='text-dark pb-4'><b>Better Prices. Wider Vendor Selection. Easy Booking process</b></h3>
                        <p className='text-dark pb-3 wd-about-btm' >Our mission is to connect buyers and service providers to help facilitate a transaction</p>
                        <p className='text-dark pb-3 wd-about-btm'>Thousands of couples utilize our service annually to book vendors for their wedding.</p>
                        <p className='text-dark pb-3 wd-about-btm'>Our service reps are available via TEXT to answer questions/concerns (800)245-3610.</p>
                        <div className="btn btn-info text-white my-4"><a href="/weddingvendors">Find Indian Wedding Vendors</a></div>
                    </div>
                </div>
            </div>
        </>

    )
};

export default AboutUs;