import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import PlaceHolder from "../../../assets/images/Placeholder.png";
import { getCouponByServiceId } from "../Data2";
import { useSelector } from "react-redux";

const BwvCoupons = () => {
  const selectedCard = useSelector((state) => state.bwvData.selectedService);
  const [coupons, setCoupons] = useState([]);

  useEffect(() => {
    const fetchCoupons = async () => {
      if (selectedCard) {
        const coupon = await getCouponByServiceId(selectedCard.Id);
        // const filterCoupon = coupon.filter((coup) => coup.endDate >= new Date());
        setCoupons(coupon);
      }
    };
    console.log(selectedCard, "selectedCard coupons");
    fetchCoupons();
  }, [selectedCard]);
  return (
    <>
      {coupons && coupons.length > 0 && (
        <>
          {coupons.map((coupon) => (
            <Row className="bwv-coupan mt-2" key={coupon.id}>
              <Col className="p-0 col-lg-1 col-3">
                <img
                  className="bwv_cupon-img"
                  src={coupon.Image ? coupon.Image : PlaceHolder}
                  alt="Coupon"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col className="text-center col-lg-11 col-9">
                <h2
                  className="bwv_cupon-title"
                  style={{ margin: "0", fontSize: "24px", color: "#333" }}
                >
                  Get {coupon.codeValue}
                  {coupon.codeValueType} Off on Your Next Purchase!
                </h2>
                <p
                  className="bwv_cupon_desc"
                  style={{ fontSize: "16px", color: "#666" }}
                >
                  Use code <strong>{coupon.Code}</strong> at checkout to redeem
                  this offer. Hurry up, the offer expires soon!
                </p>
              </Col>
            </Row>
          ))}
        </>
      )}
    </>
  );
};

export default BwvCoupons;
