import React, { useState } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  CardSubtitle,
  Spinner,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "../../ResetPwPage.css";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import axiosClient from "../../../axios-client";


const Password = () => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);

  const toggleShowNewPass = () => setShowNewPass(!showNewPass);
  const toggleShowConfirmPass = () => setShowConfirmPass(!showConfirmPass);

  const validatePassword = (password) => {
    const errors = [];
    if (password.length < 6) {
      errors.push("Password must be at least 6 characters.");
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      errors.push("Password must contain at least one special character.");
    }
    if (!/\d/.test(password)) {
      errors.push("Password must contain at least one number.");
    }
    if (!/[a-zA-Z]/.test(password)) {
      errors.push("Password must contain at least one alphabet.");
    }
    return errors;
  };

  const handlePwReset = async () => {
    let passwordData = {
      Password: newPass,
      ChangedPassword: confirmPass,
    };

    if (!newPass) {
      setError("Please enter current password.");
      return;
    }
    if (!confirmPass) {
      setError("Please enter new password.");
      return;
    }
    const passwordErrors = validatePassword(confirmPass);
    if (passwordErrors.length > 0) {
      setError(passwordErrors.join(" "));
      setSuccess("");
      return;
    }

    setLoading(true);
    try {
      const response = await axiosClient.put(
        `setting/changePassword/${user.UserID}`,
        passwordData
      );
      setSuccess("Password reset successfully!");
      setError("");
      setLoading(false);
      toast.success("Password reset successfully!");
      setNewPass("");
      setConfirmPass("");
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setError("Current password wrong.");
      } else {
        setError("Failed to reset password. Please try again.");
      }
      setSuccess("");
      setLoading(false);
    }
  };

  return (
    <Row className="justify-content-md-center mt-5">
      <Col md={6} className="w-100">
        <Card className="shadow-lg p-4 mb-5 bg-white rounded">
          <CardBody>
            <CardSubtitle tag="h6" className="text-muted text-center mb-1">
              Change Password
            </CardSubtitle>
            {error && <Alert color="danger">{error}</Alert>}
            {success && <Alert color="success">{success}</Alert>}
            <Form>
              <FormGroup>
                <Label for="newPassword">Current Password</Label>
                <InputGroup>
                  <Input
                    type={showNewPass ? "text" : "password"}
                    id="newPassword"
                    placeholder="Enter new password"
                    value={newPass}
                    onChange={(e) => setNewPass(e.target.value)}
                  />
                  <InputGroupText onClick={toggleShowNewPass}>
                    {showNewPass ? <FaEyeSlash /> : <FaEye />}
                  </InputGroupText>
                </InputGroup>
              </FormGroup>

              <FormGroup className="mt-3">
                <Label for="confirmPassword">New Password</Label>
                <InputGroup>
                  <Input
                    type={showConfirmPass ? "text" : "password"}
                    id="confirmPassword"
                    placeholder="Confirm new password"
                    value={confirmPass}
                    onChange={(e) => setConfirmPass(e.target.value)}
                  />
                  <InputGroupText onClick={toggleShowConfirmPass}>
                    {showConfirmPass ? <FaEyeSlash /> : <FaEye />}
                  </InputGroupText>
                </InputGroup>
              </FormGroup>

              <Button
                color="primary"
                className="mt-4 w-100"
                onClick={handlePwReset}
                disabled={loading}
              >
                {loading ? <Spinner size="sm" /> : "Reset Password"}
              </Button>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
export default Password;
