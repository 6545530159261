import React, { useState, useEffect } from "react";
import { Input, Row, Col, Label } from "reactstrap";
import { FaPlusCircle, FaTrashAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setAdditionalPeople } from "../../../redux/slices/ServiceInfo";
import Select from "react-select";
import { countryCodes } from "../../../components/CountryCodes";

const AdditionalDetails = () => {
  const dispatch = useDispatch();
  const serviceInfo = useSelector((state) => state.serviceInfo);
  const userinfo = useSelector((state) => state.user.userInfo);
  const islogin = useSelector((state) => state.user.isLogin);
  const user = islogin ? JSON.parse(userinfo) : null;
  const isAdmin = user && user.Role === "admin"; // Check if user is admin

  const [rows, setRows] = useState([
    {
      email: "",
      phoneNumber: "",
      countryCode: "+1",
    },
  ]);
  const countryOptions = countryCodes.map((country) => ({
    label: `${country.name} (${country.dial_code})`, // Display both name and code in the dropdown
    value: country.dial_code, // Use only the dial code as the value
  }));

  useEffect(() => {
    if (serviceInfo?.additionalPeople) {
      try {
        const initialRows = JSON.parse(serviceInfo.additionalPeople);
        if (Array.isArray(initialRows)) {
          setRows(initialRows);
        }
      } catch (error) {
        console.error("JSON Parse Error:", error);
      }
    }
  }, []);

  useEffect(() => {
    dispatch(setAdditionalPeople(JSON.stringify(rows)));
  }, [rows, dispatch]);

  const handleChange = (value, index, field) => {
    const updatedRows = [...rows];

    if (field === "phoneNumber") {
      // Replace non-numeric characters and limit to 10 digits
      const formattedValue = value.replace(/\D/g, "").slice(0, 10);
      updatedRows[index] = {
        ...updatedRows[index],
        [field]: formattedValue,
      };
    } else if (field === "countryCode") {
      // Replace non-numeric characters and limit to 4 digits
      updatedRows[index] = {
        ...updatedRows[index],
        [field]: value,
      };
    } else {
      updatedRows[index] = {
        ...updatedRows[index],
        [field]: value,
      };
    }

    setRows(updatedRows);
  };

  const handleAddData = () => {
    const lastRow = rows[rows.length - 1];
    const isEmpty = Object.values(lastRow).some((value) => value === "");
    if (isEmpty) {
      toast.error("Please fill in all details");
      return;
    }

    if (rows.length >= 5) {
      return;
    }

    setRows((prevRows) => [
      ...prevRows,
      {
        email: "",
        phoneNumber: "",
        countryCode: "+1",
      },
    ]);
  };

  const handleDeleteRow = (index) => {
    if (rows.length === 1) {
      setRows([
        {
          email: "",
          phoneNumber: "",
          countryCode: "+1",
        },
      ]);
    } else {
      const updatedRows = [...rows];
      const rowHasAddIcon = index === rows.length - 1;
      if (rowHasAddIcon) {
        updatedRows[index] = {
          email: "",
          phoneNumber: "",
          countryCode: "+1",
        };
      } else {
        updatedRows.splice(index, 1);
      }
      setRows(updatedRows);
    }
  };

  return (
    <div>
      <div>
        <p className="text-dark mb-2 " style={{ fontSize: "18px" }}>
          Additional People:
        </p>
        <Row className="d-flex flex-row">
          <Col sm={4} className="d-none d-lg-block">
            Email
          </Col>
          <Col sm={3} className="d-none d-lg-block">
            Phone Number
          </Col>
          <Col sm={3} className="d-none d-lg-block">
            Country Code
          </Col>
          <Col sm={2} className="d-none d-lg-block"></Col>
        </Row>
        {rows.map((row, index) => (
          <Row key={index} className="mt-3">
            <Col sm={4} xs="12">
              <Label className="d-block d-lg-none">Email</Label>
              <Input
                type="email"
                name="email"
                value={row.email}
                onChange={(e) => handleChange(e.target.value, index, "email")}
              />
            </Col>
            <Col sm={3} xs="12">
              <Label className="d-block d-lg-none">Phone Number</Label>
              <Input
                type="text"
                name="phoneNumber"
                value={row.phoneNumber}
                onChange={(e) =>
                  handleChange(e.target.value, index, "phoneNumber")
                }
                maxLength={10}
              />
            </Col>
            <Col sm={3} xs="12">
              <Label className="d-block d-lg-none"> Country Code</Label>
              <Select
                options={countryOptions}
                name="countryCode"
                placeholder="Select"
                value={
                  row.countryCode
                    ? {
                        label: row.countryCode,
                        value: row.countryCode,
                      }
                    : {
                        label: "+1",
                        value: "+1",
                      }
                }
                onChange={(selectedOption) => {
                  handleChange(selectedOption.value, index, "countryCode");
                }}
                className="w-100"
              />
              {/* <Input
                type="text"
                name="extension"
                value={row.extension}
                onChange={(e) =>
                  handleChange(e.target.value, index, "extension")
                }
                maxLength={4}
              /> */}
            </Col>
            <Col
              sm="2"
              xs="12"
              className="d-flex align-items-center justify-content-center m-0 gap-4"
            >
              <Label>&nbsp;</Label>
              <FaTrashAlt
                style={{ cursor: "pointer" }}
                onClick={() => handleDeleteRow(index)}
              />
              {index === rows.length - 1 && (
                <FaPlusCircle
                  size={23}
                  style={{ cursor: "pointer", color: "blue" }}
                  onClick={handleAddData}
                />
              )}
            </Col>
          </Row>
        ))}
      </div>
    </div>
  );
};

export default AdditionalDetails;
