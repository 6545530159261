import React, { useState, useEffect } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import OrderCard from "./OrderCard";

const AsClint = ({ orders }) => {
  const [pendingOrders, setPendingOrders] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);
  const [cancelOrders, setCancelOrders] = useState([]);
  const [showPendingOrders, setShowPendingOrders] = useState(true);
  const [showCompletedOrders, setShowCompletedOrders] = useState(true);
  const [showCancelOrders, setShowCancelOrders] = useState(true);
  useEffect(() => {
    if (orders) {
      const filterPendingOrders = orders.filter(
        (order) => order.orderStatus === "PENDING"
      );
      const filterCompletedOrders = orders.filter(
        (order) => order.orderStatus === "COMPLETED"
      );
      setPendingOrders(filterPendingOrders);
      setCompletedOrders(filterCompletedOrders);
    }
  }, [orders]);

  return (
    <div className="category-container d-flex flex-column gap-2 mt-3">
      <div className="category-header">
        <h6
          className="ps-3 d-flex justify-content-between p-3 rounded border border-secondary text-secondary bg-outline-secondary gap-2 mb-2 fw-bold"
          style={{ cursor: "pointer", transition: "all 0.2s ease-in-out" }}
          onClick={() => setShowPendingOrders(!showPendingOrders)}
        >
          Pending {!showPendingOrders ? <FaChevronDown /> : <FaChevronUp />}
        </h6>
        {showPendingOrders && <OrderCard orders={pendingOrders} />}
        <h6
          onClick={() => setShowCompletedOrders(!showCompletedOrders)}
          style={{ cursor: "pointer", transition: "all 0.2s ease-in-out" }}
          className="ps-3 d-flex justify-content-between p-3 rounded border border-secondary text-secondary bg-outline-secondary gap-2 mb-2 fw-bold"
        >
          Completed {!showCompletedOrders ? <FaChevronDown /> : <FaChevronUp />}
        </h6>
        {showCompletedOrders && <OrderCard orders={completedOrders} />}
        <h6
          onClick={() => setShowCancelOrders(!showCancelOrders)}
          style={{ cursor: "pointer", transition: "all 0.2s ease-in-out" }}
          className="ps-3 d-flex justify-content-between p-3 rounded border border-secondary text-secondary bg-outline-secondary gap-2 mb-2 fw-bold"
        >
          Canceled {!showCancelOrders ? <FaChevronDown /> : <FaChevronUp />}
        </h6>
        {showCancelOrders && <OrderCard orders={cancelOrders} />}
      </div>
    </div>
  );
};
export default AsClint;
