import React, { useEffect, useState } from "react";
import Sidebar from "../../SideBar";
import { Row, Col, Button, Form } from "reactstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { FaArrowLeft, FaArrowRight, FaChevronRight } from "react-icons/fa";
import BasiInformation from "./BasicInformation";
import Details from "./Details";
import SetAvailability from "./setAvailability";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCategories,
  fetchSubCategories,
  fetchItems,
  postMyService,
  fetchUserDetails,
} from "../../../components/Header/Data";
import {
  setGiftAmount,
  setGiftTitle,
  setGiftDescription,
  setGift,
} from "../../../redux/slices/GiftSlice";
import {
  setServiceTitle,
  setServiceDescription,
  setFreeService,
} from "../../../redux/slices/FreeServiceSlice";
import {
  setCategory,
  setCategoryId,
  setCustomDetails,
  setDealTerms,
  setDescription,
  setDialExtension,
  setHourlyPrice,
  setItem,
  setItemId,
  setListName,
  setPackages,
  setPdfFile,
  setPhone,
  setServiceId,
  setSpecialOffer,
  setSubCategory,
  setSubCategoryId,
  setTimeSlots,
  setUploadedImages,
  setVideos,
  setAdditionalPeople,
  resetServiceInfo,
  setPayPalEmail,
} from "../../../redux/slices/ServiceInfo";
import { setItemName } from "../../../redux/slices/RequestFormSlice";
const CreateService = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userinfo = useSelector((state) => state.user.userInfo);
  const islogin = useSelector((state) => state.user.isLogin);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [items, setItems] = useState([]);
  const location = useLocation();
  const { service } = location.state || {};
  const [mandate, setMandate] = useState(false);
  const [email, setEmail] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [selectedOption, setSelectedOption] = useState("giftAmount");
  const gift = useSelector((state) => state.gift);
  const FreeService = useSelector((state) => state.freeService);
  const serviceInfo = useSelector((state) => state.serviceInfo);
  const fetchData = async () => {
    try {
      const categoriesData = await fetchCategories();
      const subCategoriesData = await fetchSubCategories();
      const itemsData = await fetchItems();
      const userData = await fetchUserDetails(user?.UserID);
      setUserDetails(userData);
      setCategories(categoriesData);
      setSubCategories(subCategoriesData);
      setItems(itemsData);
    } catch (error) {
      console.error("Error fetching categories:", error.message);
    }
  };

  useEffect(() => {
    fetchData();
    dispatch(resetServiceInfo());
  }, []);
  useEffect(() => {
    fetchData();
    if (location.state) {
      const state = location.state;
      const packages = location.state?.Package
        ? JSON.parse(location.state.Package)
        : {};
      let videos = {};
      try {
        videos =
          typeof location.state?.Video === "string"
            ? JSON.parse(location.state.Video)
            : location.state.Video;
      } catch (error) {
        console.error("Failed to parse videos:", error);
      }
      if (!state.giftAmount) {
        setSelectedOption("freeService");
        dispatch(
          setFreeService({
            ...service,
            freeServiceTitle: state.giftTitle,
            freeServiceDescription: state.giftDescription,
          })
        );
      } else {
        dispatch(
          setGift({
            ...gift,
            giftAmount: parseFloat(state.giftAmount),
            giftTitle: state.giftTitle,
            giftDescription: state.giftDescription,
          })
        );
      }

      dispatch(setCategory(state.categoryName));
      dispatch(setSubCategory(state.subCategoryName));
      dispatch(setItem(state.itemName));
      dispatch(setHourlyPrice(state.hourlyPrice));
      dispatch(setSpecialOffer(state.specialOffer));
      dispatch(setDealTerms(state.dealTerm));
      dispatch(setCustomDetails(state.customDetails));
      dispatch(setListName(state.listName));
      dispatch(setDescription(state.Description));
      dispatch(setPhone(state.Phone));
      dispatch(setDialExtension(state.dialExtension));
      dispatch(setPackages(packages));
      dispatch(setUploadedImages(state.Photo));
      dispatch(setVideos(videos));
      dispatch(setPdfFile(state.pdfListing));
      dispatch(setCategoryId(state.categoryId));
      dispatch(setSubCategoryId(state.subCategoryId));
      dispatch(setItemId(state.itemId));
      dispatch(setTimeSlots(state.availableDays));
      dispatch(setAdditionalPeople(state.additionalPeople));
      dispatch(setPayPalEmail(state.paypalEmail));
    }
  }, []);
  const handleBasicInfoNext = (data) => {
    const hasEmptyPrice = Object.values(serviceInfo?.packages).some(
      (packageData) => packageData.price === "" || packageData.price === "0"
    );
    if (
      !serviceInfo.listName ||
      !serviceInfo.categoryId ||
      !serviceInfo.description ||
      serviceInfo.uploadedImages.length === 0
    ) {
      toast.error("Please fill in all required fields.");
      return;
    }
    if (
      selectedOption === "giftAmount" &&
      gift.giftAmount &&
      gift.giftAmount < 50
    ) {
      toast.error("Gift Amount should be greater than $50");
      return;
    }
    if (
      (serviceInfo.hourlyPrice && serviceInfo.hourlyPrice > 0) ||
      (Object.keys(serviceInfo.packages).length > 0 && !hasEmptyPrice)
    ) {
      goToNextStep();
    } else {
      toast.error("Please enter valid Hourly Price or Package Price");
    }
  };
  const isTimeSlotFilled = (timeSlots) => {
    return timeSlots.some(
      (slot) => slot.days.length > 0 && slot.from !== "" && slot.to !== ""
    );
  };
  const handleDetailsNext = (data) => {
    if (currentStep === 2 && !mandate) {
      toast.error("Please fill in all required fields.");
      return;
    }
    goToNextStep();
  };
  const handlePublish = async (data) => {
    if (isLoading) return; // If already loading, prevent multiple clicks
    if (
      serviceInfo.timeSlots.length === 0 ||
      !isTimeSlotFilled(serviceInfo.timeSlots)
    ) {
      toast.error("Please add at least one time slot.");
      return;
    }
    if (!serviceInfo.paypalEmail) {
      toast.error("Please enter PayPal ID");
      return;
    }
    setIsLoading(true);
    const formData = {
      listName: serviceInfo.listName,
      categoryId: serviceInfo.categoryId,
      subCategoryId: serviceInfo.subCategoryId,
      itemId: serviceInfo.itemId,
      Description: serviceInfo.description,
      Package: serviceInfo.packages ? JSON.stringify(serviceInfo.packages) : "",
      Photo: serviceInfo.uploadedImages ? serviceInfo.uploadedImages : "",
      Phone: serviceInfo.Phone,
      dialExtension: serviceInfo.dialExtension,
      specialOffer: serviceInfo.specialOffer,
      Video: serviceInfo.videos ? JSON.stringify(serviceInfo.videos) : "",
      hourlyOffer: serviceInfo.hourlyPrice,
      customDetails: serviceInfo.customDetails
        ? JSON.stringify(serviceInfo.customDetails)
        : "",
      pdfListing: "string",
      availableDays: serviceInfo.timeSlots
        ? JSON.stringify(serviceInfo.timeSlots)
        : "",
      Status: 1,
      dealTerm: serviceInfo.dealTerms,
      createdBy: user.UserID,
      ApplyAll: serviceInfo.ApplyAll,
      Email: user.Email,
      additionalPeople: serviceInfo.additionalPeople,
      paypalEmail: serviceInfo.paypalEmail,
    };
    if (location.state && location.state.Id) {
      dispatch(setServiceId(location.state.Id));
      formData.updatedBy = user.UserID;
      formData.serviceId = location.state.Id;
    }
    if (selectedOption === "freeService") {
      formData.giftTitle = FreeService.freeServiceTitle;
      formData.giftDescription = FreeService.freeServiceDescription;
    } else {
      formData.giftAmount = gift.giftAmount;
      formData.giftTitle = gift.giftTitle;
      formData.giftDescription = gift.giftDescription;
    }
    // if (user.RoleId !== 1) {
    //   localStorage.setItem("USER_ROLE", "provider");
    // }

    try {
      await postMyService(formData);
      navigate("/dashboardpage/my-services");
      toast.success(`Service sent to Admin for Approval`);
      dispatch(resetServiceInfo());
      dispatch(
        setFreeService({
          ...FreeService,
          freeServiceTitle: "",
          freeServiceDescription: "",
        })
      );
      dispatch(
        setGift({
          ...gift,
          giftAmount: null,
          giftTitle: "",
          giftDescription: "",
        })
      );
      window.scroll(0, 0);
    } catch (error) {
      console.error("Error publishing service:", error.message);
      toast.error("Failed to publish service. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDraft = async (data) => {
    if (isLoading) return; // If already loading, prevent multiple clicks
    setIsLoading(true);
    const formData = {
      listName: serviceInfo.listName,
      categoryId: serviceInfo.categoryId,
      subCategoryId: serviceInfo.subCategoryId,
      itemId: serviceInfo.itemId,
      Description: serviceInfo.description,
      Package: serviceInfo.packages ? JSON.stringify(serviceInfo.packages) : "",
      Photo: serviceInfo.uploadedImages ? serviceInfo.uploadedImages : "",
      Phone: serviceInfo.Phone,
      dialExtension: serviceInfo.dialExtension,
      specialOffer: serviceInfo.specialOffer,
      Video: serviceInfo.videos ? JSON.stringify(serviceInfo.videos) : "",
      hourlyOffer: serviceInfo.hourlyPrice,
      customDetails: serviceInfo.customDetails
        ? JSON.stringify(serviceInfo.customDetails)
        : "",
      pdfListing: "string",
      availableDays: serviceInfo.timeSlots
        ? JSON.stringify(serviceInfo.timeSlots)
        : "",
      Status: 0,
      dealTerm: serviceInfo.dealTerms,
      additionalPeople: serviceInfo.additionalPeople,
      createdBy: user.UserID,
      ApplyAll: serviceInfo.ApplyAll,
      Email: user.Email,
    };
    if (location.state && location.state.Id) {
      dispatch(setServiceId(location.state.Id));
    }
    if (selectedOption === "freeService") {
      formData.giftTitle = FreeService.freeServiceTitle;
      formData.giftDescription = FreeService.freeServiceDescription;
    } else {
      formData.giftAmount = gift.giftAmount;
      formData.giftTitle = gift.giftTitle;
      formData.giftDescription = gift.giftDescription;
    }
    // if (user.RoleId !== 1) {
    //   localStorage.setItem("USER_ROLE", "provider");
    // }

    try {
      await postMyService(formData);
      navigate("/dashboardpage/my-services");
      toast.success(`Saved as Draft`);
      dispatch(resetServiceInfo());
      dispatch(
        setFreeService({
          ...FreeService,
          freeServiceTitle: "",
          freeServiceDescription: "",
        })
      );
      dispatch(
        setGift({
          ...gift,
          giftAmount: null,
          giftTitle: "",
          giftDescription: "",
        })
      );
      window.scroll(0, 0);
    } catch (error) {
      console.error("Error publishing service:", error.message);
      toast.error("Failed to publish service. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const goToNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };
  const goToStep = (stepNumber) => {
    if (
      (stepNumber === 2 && !serviceInfo.listName) ||
      !serviceInfo.listName ||
      !serviceInfo.categoryId ||
      !serviceInfo.description ||
      serviceInfo.uploadedImages.length === 0
    ) {
      toast.error("Please fill all required fields.");
      return;
    } else if (
      stepNumber === 2 &&
      selectedOption === "giftAmount" &&
      gift.giftAmount &&
      gift.giftAmount < 50
    ) {
      toast.error("Gift Amount should be greater than $50");
      return;
    } else if (stepNumber === 3 && !mandate) {
      toast.error("Please fill all required fields");
      return;
    }
    setCurrentStep(stepNumber);
  };

  return (
    <section className="bg-white">
      <div className="container">
        <Row>
          <Col sm={3}>
            <Sidebar />
          </Col>
          <Col sm={9}>
            <Row>
              <div className="p-3">
                <span
                  onClick={() => {
                    navigate(-1);
                    dispatch(
                      setFreeService({
                        ...service,
                        freeServiceTitle: "",
                        freeServiceDescription: "",
                      })
                    );
                    dispatch(
                      setGift({
                        ...gift,
                        giftAmount: 0,
                        giftTitle: "",
                        giftDescription: "",
                      })
                    );
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <FaArrowLeft />
                  <p
                    style={{
                      marginLeft: "10px",
                      marginTop: "10px",
                      display: "inline",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Back
                  </p>
                </span>
                <div className="add-service">
                  <h1>Add a Service</h1>
                  <div className="row pt-4">
                    <div className="col-lg-6 d-flex align-items-center">
                      <p>
                        <span
                          onClick={() => goToStep(1)}
                          style={{
                            cursor: "pointer",
                            fontWeight: currentStep === 1 ? "bold" : "normal",
                            color: currentStep === 1 ? "black" : "GrayText",
                          }}
                        >
                          1. Basic Information
                        </span>{" "}
                        <FaChevronRight />
                        <span
                          onClick={() => goToStep(2)}
                          // onClick={() => handleBasicInfoNext(serviceInfo)}
                          style={{
                            cursor: "pointer",
                            fontWeight: currentStep === 2 ? "bold" : "normal",
                            color: currentStep === 2 ? "black" : "GrayText",
                          }}
                        >
                          2. Details
                        </span>
                        <FaChevronRight />
                        <span
                          onClick={() => goToStep(3)}
                          style={{
                            cursor: "pointer",
                            fontWeight: currentStep === 3 ? "bold" : "normal",
                            color: currentStep === 3 ? "black" : "GrayText",
                          }}
                        >
                          3. Set Availability
                        </span>
                      </p>
                    </div>
                    <div className="col-lg-6 text-end mb_ce-btns">
                      <button
                        type="button"
                        class="btn btn-outline-danger"
                        style={{ marginRight: "20px" }}
                        onClick={() => handleDraft()}
                      >
                        Save Draft
                      </button>
                      <button
                        type="button"
                        class="btn btn-danger"
                        disabled={
                          currentStep === 1 || currentStep === 2 || isLoading
                        }
                        onClick={() => handlePublish()}
                      >
                        Publish
                      </button>
                    </div>
                  </div>

                  <div>
                    <Form>
                      <a
                        href={`tel:${9999999999}`}
                        style={{
                          backgroundColor: "#F5F6FF",
                          padding: "2px 15px 2px 15px",
                          borderRadius: "5px",
                          fontSize: "small",
                          width: "auto",
                          // margin: "10px 0px",
                        }}
                      >
                        Need help? Call (800)245-3610
                      </a>

                      {currentStep === 1 && (
                        <BasiInformation
                          categories={categories}
                          subCategories1={subCategories}
                          items={items}
                          selectedOption={selectedOption}
                          setSelectedOption={setSelectedOption}
                        />
                      )}
                      {currentStep === 2 && <Details setMandate={setMandate} />}
                      {currentStep === 3 && (
                        <SetAvailability
                          setMandate={setMandate}
                          email={email}
                          setEmail={setEmail}
                          userDetails={userDetails}
                          fetchData={fetchData}
                        />
                      )}
                    </Form>
                  </div>
                </div>
              </div>
              {currentStep === 1 && (
                <Row className="go-to-details pb-5 d-flex justify-space-between">
                  <ToastContainer />
                  <Col lg="6" md="12">
                    <p className="d-flex align-items-center">
                      Just two more steps and you'll be set.
                    </p>
                  </Col>
                  <Col lg="6" md="12" className="text-end">
                    <Button
                      type="submit"
                      className="btn btn-danger me-3 mt-0"
                      onClick={() => handleBasicInfoNext(serviceInfo)}
                    >
                      Go To Details
                      <FaArrowRight />
                    </Button>
                  </Col>
                </Row>
              )}
              {currentStep === 2 && (
                <Row className="go-to-details align-items-center pb-4">
                  <ToastContainer />
                  <Col lg="6" md="12">
                    <p>
                      Your listing is almost done. Add some more information and
                      you'll be ready to Publish it!.
                    </p>
                  </Col>
                  <Col lg="6" md="12" className="text-end">
                    <Button
                      type="submit"
                      className="btn btn-danger me-4 mt-0"
                      onClick={handleDetailsNext}
                    >
                      Go To Availability
                      <FaArrowRight />
                    </Button>
                  </Col>
                </Row>
              )}
              {currentStep === 3 && (
                <Row className="go-to-details">
                  <Col lg="6" md="12">
                    <p>All Done! 🎉</p>
                  </Col>
                  <Col lg="6" md="12" className="text-end">
                    <Button
                      type="submit"
                      className="btn btn-danger"
                      onClick={() => handlePublish(serviceInfo)}
                      disabled={isLoading}
                    >
                      Publish
                      <FaArrowRight />
                    </Button>
                  </Col>
                </Row>
              )}
            </Row>
          </Col>
        </Row>
      </div>
    </section>
  );
};
export default CreateService;
