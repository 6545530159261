import React from "react";
import { Row, Col, Button, Input } from "reactstrap";
import Placeholder from "../../../assets/images/Placeholder.png";
import FreatureModal from "./FeatureModal";

const BizzList = ({ activeServices, user, fetchData, isLoading }) => {
  function getMinPackagePrice(packageData) {
    const packages = Object.values(packageData);
    const prices = packages.map((userpackage) =>
      parseFloat(userpackage.price.trim())
    );
    const minPrice = Math.min(...prices);
    return minPrice;
  }
  console.log(activeServices, "activeServices");
  return (
    <>
      {activeServices.length > 0 ? (
        <div
          className="service-container mt-4 pb-3"
          style={{ marginLeft: "0" }}
        >
          {activeServices.map((service) => (
            <div
              className="service rounded px-0 mb-0 mt-3"
              style={{ width: "97%" }}
            >
              <div key={service.id}>
                <Row>
                  <Col sm={3} xs={12} className="p-0 position-relative">
                    <img
                      className="mw-100 object-fit-cover border rounded mh-100 img-fluid img-thumbnail"
                      src={
                        typeof service.Photo[0] === "string"
                          ? JSON.parse(service?.Photo)[0]
                          : Placeholder
                      }
                      alt={service.listName}
                      style={{ width: "400px", height: "150px" }}
                    />
                    {service.featuredListing === 1 && (
                      <div className="featured">FEATURED</div>
                    )}
                  </Col>
                  <Col sm={9} xs={12} className="p-3 position-relative">
                    <Row className="  top-0 end-0 pe-1">
                      <Col sm={7} xs={12}>
                        <p>{service.categoryName}</p>
                      </Col>

                      <Col
                        sm={5}
                        xs={12}
                        className="postion-realtive text-end cursor-pointer"
                      >
                        <button
                          className="p-1 px-2 mx-1 bg-primary text-white rounded "
                          style={{ marginTop: "4px" }}
                          //   onClick={() => onEdit(service)}
                        >
                          {service?.bizzValue === 1
                            ? "Row 1"
                            : service?.bizzValue === 2
                            ? "Row 2"
                            : service?.bizzValue === 3 && "Row 3"}
                        </button>
                        {/* <span
                          style={{ fontSize: "20px", cursor: "pointer" }}
                          onClick={(e) => toggleMenu(service.Id, e)}
                        >
                          <span>
                            <VscKebabVertical style={{ marginTop: "2px" }} />
                          </span>
                        </span> */}
                        {/* {openMenuId === service.Id && (
                          <div className="service-menu p-2 shadow">
                            {user.RoleId === 2 && (
                              <a
                                onClick={() => onFeature(service)}
                                className={`btn btn-${
                                  service.featuredListing === 0
                                    ? "primary"
                                    : "secondary"
                                } btn-sm w-100 mb-2`}
                              >
                                {service.featuredListing === 0
                                  ? "Feature Listing"
                                  : "Remove Feature"}
                              </a>
                            )}
                            <Button
                              onClick={() => onDelete(service)}
                              color="danger"
                              size="sm"
                              className="w-100 btn"
                            >
                              Delete
                              <FaTrashAlt />
                            </Button>
                          </div>
                        )} */}
                      </Col>
                    </Row>

                    <div className="d-flex justify-content-between">
                      <h4 style={{ display: "inline" }}>{service.listName}</h4>
                      {/* {user.Role === "admin" && (
                          <select
                            style={{
                              width: "23%",
                              borderRadius: "5px",
                              marginRight: "14px",
                            }}
                            value={rows[service.Id] || "0"} // Bind to the specific row value for this service
                            onChange={(e) => handleRow(e.target.value, service)}
                          >
                            <option value="0">Add to Bizz Listing</option>
                            <option value="1">Row 1</option>
                            <option value="2">Row 2</option>
                            <option value="3">Row 3</option>
                            <option value="0">Deselect</option>
                          </select>
                        )} */}
                    </div>

                    <h6 style={{ color: "#616bff" }}>
                      ${" "}
                      {service.hourlyPrice
                        ? service.hourlyPrice
                        : getMinPackagePrice(JSON.parse(service.Package))}
                    </h6>
                  </Col>
                </Row>
              </div>
            </div>
          ))}
          {/* <FreatureModal
            isOpen={featureModalOpen}
            close={() => setFeatureModalOpen(false)}
          /> */}
        </div>
      ) : (
        !isLoading && <div className="ads">No Bizz Lisings</div>
      )}
    </>
  );
};

export default BizzList;
