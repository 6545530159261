import React, { useEffect } from 'react'
import Helmet from '../../components/Helmet/Helmet';
import CommonSection from '../../components/UI/CommonSection';
import { useParams } from 'react-router-dom';



const Privacy = () => {
    const { privacy } = useParams();
    useEffect(() => {
        window.scrollTo(0, 0);
    })

    return (
        <Helmet className="bg-primary" title='Privacy Policy'>
            <div className="py-5 bg-info mb-5">
                <div className="container py-5">
                    <div className="row">
                        <h1 className="text-center"><b>Privacy Policies</b></h1>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row my-3'>
                    <div className="col-md-12">
                        <div className="p-5 bg-white my-2 rounded border">

                            <section class="innerSection-section" id="accordion"><article id="article-0" class="mb-5">
                                <h5 class="mb-4"><span class="mr-1">1.</span>** ACCOUNT DEACTIVATION **</h5>
                                <p>Complete this form to delete your account: https://forms.gle/AwYSvp77Qo5YhJQe7</p>
                            </article><article id="article-1" class="mb-5">
                                    <h5 class="mb-4"><span class="mr-1">2.</span>Data Retention and Deletion:</h5>
                                    <p>We will retain user data only for as long as necessary to fulfill the purposes outlined in our Privacy Policy. Users who deactivate their accounts will have their personal information securely stored for a limited period in compliance with legal and operational requirements. After the retention period has elapsed, we will proceed to delete the user's personal data, including any associated media or content, from our systems.
                                    </p>
                                </article><article id="article-2" class="mb-5">
                                    <h5 class="mb-4"><span class="mr-1">3.</span>Third-Party Platforms:</h5>
                                    <p>Our platform utilizes Instagram for connecting vendors and customers. Users who choose to link their Instagram accounts to our platform may share their Instagram profile information and content with other users. We encourage users to review Instagram's privacy policy to understand how their data is handled by that platform. We do not have control over Instagram's data practices.
                                    </p>
                                </article><article id="article-3" class="mb-5">
                                    <h5 class="mb-4"><span class="mr-1">4.</span>Data Security:</h5>
                                    <p>We are committed to safeguarding the security of user data. Our platform employs industry-standard security measures to protect against unauthorized access, data breaches, and misuse of information. However, no data transmission over the internet or method of electronic storage is entirely secure, so we cannot guarantee absolute security.
                                    </p>
                                </article><article id="article-4" class="mb-5">
                                    <h5 class="mb-4"><span class="mr-1">5.</span>Vendor-Customer Communication:</h5>
                                    <p>Users may communicate with vendors through our platform's messaging system. While we take measures to secure these communications, users are advised not to share sensitive personal information, financial details, or passwords through this medium. Our platform shall not be held responsible for any misuse or mishandling of information shared through vendor-customer communication.
                                    </p>
                                </article><article id="article-5" class="mb-5">
                                    <h5 class="mb-4"><span class="mr-1">6.</span>User Control:</h5>
                                    <p>We respect users' rights to access, correct, and delete their personal information. Users can manage their account settings, including privacy preferences and communication preferences, by accessing the appropriate sections within the app or on our website.
                                    </p>
                                </article><article id="article-6" class="mb-5">
                                    <h5 class="mb-4"><span class="mr-1">7.</span>Updates to Privacy Policy:</h5>
                                    <p>Our privacy policy may be updated periodically to reflect changes in data practices, legal requirements, or technological advancements. Users will be notified of any significant changes to the policy. Continued use of the platform after such updates constitutes acceptance of the revised privacy policy.
                                    </p>
                                </article><article id="article-7" class="mb-5">
                                    <h5 class="mb-4"><span class="mr-1">8.</span>Contact Information:</h5>
                                    <p>Users who have questions, concerns, or requests related to their privacy or data can contact our designated privacy officer at contact@myindiancommunity.org
                                    </p>
                                </article><article id="article-8" class="mb-5">
                                    <h5 class="mb-4"><span class="mr-1">9.</span>Data Sharing with Third Parties:</h5>
                                    <p>We do not sell, rent, or trade users' personal information to third parties for marketing purposes. However, in certain cases, we may share limited user information with trusted third-party vendors and service providers who assist us in operating and improving our platform. These third parties are bound by confidentiality agreements and are only permitted to use the data for specific purposes outlined in our Privacy Policy.
                                    </p>
                                </article><article id="article-9" class="mb-5">
                                    <h5 class="mb-4"><span class="mr-1">10.</span>Analytics and Cookies:</h5>
                                    <p>We use cookies, web beacons, and similar technologies to collect information about user interactions with our platform. These technologies help us analyze user behavior, enhance user experience, and improve the quality of our services. Users can manage their cookie preferences through their browser settings. For more information, please refer to our Cookie Policy.
                                    </p>
                                </article><article id="article-10" class="mb-5">
                                    <h5 class="mb-4"><span class="mr-1">11.</span>Location Data:</h5>
                                    <p>We may collect and use location data to provide users with relevant content and services based on their geographical location. Users can control location sharing through their device settings. Location data is used in accordance with our Privacy Policy.
                                    </p>
                                </article><article id="article-11" class="mb-5">
                                    <h5 class="mb-4"><span class="mr-1">12.</span>User-generated Content:</h5>
                                    <p>Users may contribute content such as reviews, comments, photos, and videos to our platform. By submitting such content, users grant us a non-exclusive, royalty-free, worldwide license to use, reproduce, modify, and display the content for the purpose of operating and promoting our platform. Users should avoid sharing personal or sensitive information in user-generated content.</p>
                                </article><article id="article-12" class="mb-5">
                                    <h5 class="mb-4"><span class="mr-1">13.</span>Children's Privacy:</h5>
                                    <p>Our platform is not intended for children under the age of 13. We do not knowingly collect personal information from individuals under 13 years of age. If we become aware that a user is under 13 and has provided personal information, we will take steps to promptly delete such information.
                                    </p>
                                </article><article id="article-13" class="mb-5">
                                    <h5 class="mb-4"><span class="mr-1">14.</span>Data Transfer:</h5>
                                    <p>Our platform may be accessed and used globally. By using our services, users consent to the transfer of their personal information to other countries or jurisdictions, which may have different data protection laws than their home country.
                                    </p>
                                </article><article id="article-14" class="mb-5">
                                    <h5 class="mb-4"><span class="mr-1">15.</span>Consent and Opt-out:</h5>
                                    <p>By using our platform, users consent to the collection, processing, and sharing of their information as described in our Privacy Policy. Users have the right to opt-out of certain data processing activities, such as marketing communications. Opt-out options are provided in applicable sections of the app and website.
                                    </p>
                                </article><article id="article-15" class="mb-5">
                                    <h5 class="mb-4"><span class="mr-1">16.</span>Compliance with Legal Obligations:</h5>
                                    <p>We may disclose user information if required to do so by law or in response to valid legal requests, such as court orders or subpoenas. We will also cooperate with law enforcement agencies and regulatory authorities as necessary.
                                    </p>
                                </article><article id="article-16" class="mb-5">
                                    <h5 class="mb-4"><span class="mr-1">17.</span>Payment Information:</h5>
                                    <p>When users make payments on our platform, their payment details, such as credit card information or digital wallet credentials, are securely processed by third-party payment processors. We do not store or retain sensitive payment information on our servers. Users can review the payment processor's privacy policy to understand how their payment data is handled.
                                    </p>
                                </article><article id="article-17" class="mb-5">
                                    <h5 class="mb-4"><span class="mr-1">18.</span>Communication Preferences:</h5>
                                    <p>Users can choose to receive marketing communications, newsletters, and updates from us. They can manage their communication preferences by accessing their account settings or using the provided unsubscribe links in marketing emails. Even if a user opts out of marketing communications, they may still receive transactional and account-related messages.
                                    </p>
                                </article><article id="article-18" class="mb-5">
                                    <h5 class="mb-4"><span class="mr-1">19.</span>Social Sharing and Integration:</h5>
                                    <p>Our platform may offer features that allow users to share content or information on social media platforms. Users should be aware that sharing through these integrated social media features may result in data being collected by the respective platform. Users should review the privacy policies of these platforms to understand how their data is processed.
                                    </p>
                                </article><article id="article-19" class="mb-5">
                                    <h5 class="mb-4"><span class="mr-1">20.</span>User Feedback and Reviews:</h5>
                                    <p>Users can provide feedback, reviews, and ratings for vendors and services on our platform. By submitting such content, users acknowledge that it may be publicly visible and accessible to other users. Users should avoid sharing personal information or sensitive details in their reviews.
                                    </p>
                                </article><article id="article-20" class="mb-5">
                                    <h5 class="mb-4"><span class="mr-1">21.</span>Data Subject Rights:</h5>
                                    <p>Users have certain rights concerning their personal data, including the right to access, correct, delete, and restrict processing of their information. Users can exercise these rights by contacting our privacy officer or using the appropriate tools provided within the app and website.
                                    </p>
                                </article><article id="article-21" class="mb-5">
                                    <h5 class="mb-4"><span class="mr-1">22.</span>Notification Preferences:</h5>
                                    <p>Users can customize their notification preferences, such as push notifications and email alerts, through their account settings. These preferences can be adjusted to align with the user's communication preferences.
                                    </p>
                                </article><article id="article-22" class="mb-5">
                                    <h5 class="mb-4"><span class="mr-1">23.</span>User Engagement Analytics:</h5>
                                    <p>We may use analytics tools to track user engagement and interactions with our platform. This data helps us improve our services and enhance user experience. The data collected is aggregated and anonymized, and no personally identifiable information is shared with third parties for these purposes.
                                    </p>
                                </article><article id="article-23" class="mb-5">
                                    <h5 class="mb-4"><span class="mr-1">24.</span>Business Transfers:</h5>
                                    <p>In the event of a merger, acquisition, or sale of all or a portion of our assets, user information may be transferred to the acquiring entity. Users will be notified of any change in ownership or control of their personal information.
                                    </p>
                                </article><article id="article-24" class="mb-5">
                                    <h5 class="mb-4"><span class="mr-1">25.</span>Accessibility and Data Portability:</h5>
                                    <p>We strive to make our platform accessible to users with disabilities. Users can request their personal data in a structured, commonly used, and machine-readable format, facilitating data portability. Such requests can be made through our designated channels.
                                    </p>
                                </article><article id="article-25" class="mb-5">
                                    <h5 class="mb-4"><span class="mr-1">26.</span>Contact and Support Information:</h5>
                                    <p>Users can reach out to our customer support team for assistance with privacy-related queries, account management, and technical issues. Contact information for customer support is provided on our website and within the app.</p>
                                </article><article id="article-26" class="mb-5">
                                    <h5 class="mb-4"><span class="mr-1">27.</span>Communication via Text/Call : Privacy Standards:</h5>
                                    <p>Vendors and customers enrolled to the site provide their contact information such as email and mobile phone numbers.  Both email and phone number may be utilized by MyindianCommunity.ORG to communicate with site customers. </p>
                                </article></section>
                        </div>
                    </div>
                </div>
            </div>
        </Helmet>
    )
};

export default Privacy;