import React, { useState, useEffect } from "react";
import uploadToS3 from "../../../utils/awsS3Upload";
import { toast } from "react-toastify";
import { uploadCAID, fetchUserDetails } from "../../../components/Header/Data";
import { useSelector } from "react-redux";

const UploadID = () => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadedUrl, setUploadedUrl] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");

  useEffect(() => {
    // Retrieve previewUrl from localStorage if available
    const storedPreviewUrl = localStorage.getItem("previewUrl");
    if (storedPreviewUrl) {
      setPreviewUrl(storedPreviewUrl);
    }

    const fetchCAID = async () => {
      const data = await fetchUserDetails(user?.UserID);
      setPreviewUrl(data.caId);
    };

    if (user?.UserID) {
      fetchCAID();
    }
  }, [user?.UserID]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setPreviewUrl(null);
      if (selectedFile.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const preview = reader.result;
          setPreviewUrl(preview);
          // Store previewUrl in localStorage
          localStorage.setItem("previewUrl", preview);
        };
        reader.readAsDataURL(selectedFile);
      } else if (selectedFile.type === "application/pdf") {
        setPreviewUrl(URL.createObjectURL(selectedFile));
        // Store previewUrl in localStorage
        localStorage.setItem("previewUrl", URL.createObjectURL(selectedFile));
      } else {
        // Handle other file types or set default preview behavior
        setPreviewUrl(null);
        localStorage.removeItem("previewUrl");
      }
    } else {
      console.error("No file selected");
      setPreviewUrl(null);
      localStorage.removeItem("previewUrl");
    }
  };

  const uploadFile = async () => {
    const id = user?.UserID;
    const formData = {
      caId: uploadedUrl,
    };
    await uploadCAID(id, formData);
  };

  const handleUpload = async () => {
    if (!file) {
      console.error("No file selected");
      return;
    }

    let folder = "";
    if (file.type.startsWith("image/")) {
      folder = "uploadID/";
    } else if (file.type === "application/pdf") {
      folder = "uploadID/";
    }

    try {
      setUploading(true);
      const s3Url = await uploadToS3(file, folder);
      setUploadedUrl(s3Url);
      toast.success("Uploaded Successfully");
      setUploading(false);
      // Clear localStorage on successful upload
      localStorage.removeItem("previewUrl");
    } catch (error) {
      console.error("Error uploading file to S3:", error);
      setUploading(false);
    }
  };

  useEffect(() => {
    if (uploadedUrl) {
      uploadFile();
    }
  }, [uploadedUrl]);

  return (
    <div className="mt-5">
      <div className="d-flex gap-10 mx-3">
        <input
          className="form-control"
          type="file"
          onChange={handleFileChange}
        />

        <button
          onClick={handleUpload}
          className="btn btn-primary"
          disabled={!file || uploading}
        >
          {uploading ? "Uploading..." : "Upload"}
        </button>
      </div>
      <div className="text-center mt-5">
        {previewUrl && (
          <div>
            <p className="fz-bold h4">Preview:</p>
            {(file && file.type.startsWith("image/")) || previewUrl ? (
              <img
                src={previewUrl}
                alt="Preview"
                style={{ maxWidth: "100%", maxHeight: "300px" }}
                className="img-fluid rounded"
              />
            ) : file && file.type === "application/pdf" ? (
              <embed
                src={previewUrl}
                type="application/pdf"
                width="100%"
                height="300px"
              />
            ) : (
              <p>Preview not available for this file type</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadID;
