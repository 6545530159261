import React from "react";
import { Row, Col, Card, CardBody, CardImg } from "reactstrap";
import PlaceHolder from "../../../assets/images/Placeholder.png";
import { useNavigate } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import "./Recommended.css"; // Import the CSS file

const Recommended = ({ recomended }) => {
  const navigate = useNavigate();
  const handleCardClick = (ad) => {
    let Id = ad.Id;
    navigate(`/requestDetails/${Id}`, { state: { selectedCard: ad } });
  };

  if (!recomended) {
    return <FadeLoader color="#36d7b7" />;
  }

  return (
    <Row className="pt-4 recommended-row">
      {recomended.length > 0 ? (
        recomended.map((ad) => (
          <Col key={ad.Id} className="recommended-col">
            <Card
              className="recommended-card"
              onClick={() => handleCardClick(ad)}
            >
              <CardImg
                top
                src={
                  ad.Photo[0] !== "" && ad.Photo.length > 0
                    ? JSON.parse(ad.Photo)[0]
                    : PlaceHolder
                }
                alt={ad.Title}
                style={{ maxHeight: "115px" }}
                className="recommended-card-img"
              />
              <CardBody className="recommended-card-body">
                <p className="recommended-card-category">{ad.categoryName}</p>
                <h6 className="recommended-card-title">{ad.Title}</h6>
              </CardBody>
            </Card>
          </Col>
        ))
      ) : (
        <h5 className="text-center">
          <p>No Similar Ads</p>
        </h5>
      )}
    </Row>
  );
};

export default Recommended;
