import React, { useEffect, useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import "./UserProfile.css";
import UserServices from "./UserServices";
import UserNeeds from "./UserNeeds";
import { useParams } from "react-router-dom";
import { fetchUserDetails, fetchBWV, fetchLocalCommunityAds } from "../Data";
import { useSelector } from "react-redux";
import { FadeLoader } from "react-spinners";
import { FaArrowLeft, FaCheckCircle } from "react-icons/fa";
import SendInvite from "./SendInvite";
import { useLocation, useNavigate } from "react-router-dom";

const UserProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const { Id } = useParams();
  const [activeTab, setActiveTab] = useState("services");
  const [userDetails, setUserDetails] = useState([]);
  const [userServices, setUserServices] = useState([]);
  const [userNeeds, setUserNeeds] = useState([]);
  const [inviteModal, setInviteModal] = useState(false);

  const GetUserDetails = async () => {
    const details = await fetchUserDetails(Id);
    const services = await fetchBWV();
    const needs = await fetchLocalCommunityAds();
    const myServices = services.filter(
      (service) => service.createdBy == Id && service.isactive === "3"
    );

    const myneeds = needs.filter((need) => need.createdBy == Id);
    setUserDetails(details);
    setUserServices(myServices);
    setUserNeeds(myneeds);
  };

  useEffect(() => {
    if (location.state && location.state === userDetails.Id) {
      setActiveTab("needs");
    }
    GetUserDetails();
  }, [location.state, userDetails.Id]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const renderNavItems = () => {
    if (location.state && location.state === userDetails.Id) {
      return (
        <>
          <NavItem className="w-50 text-center">
            <NavLink
              className={classnames({ active: activeTab === "needs" })}
              onClick={() => {
                toggleTab("needs");
              }}
            >
              <span className="tab-text">Needs</span>
            </NavLink>
          </NavItem>
          <NavItem className="w-50 text-center">
            <NavLink
              className={classnames({ active: activeTab === "services" })}
              onClick={() => {
                toggleTab("services");
              }}
            >
              <span className="tab-text">Services</span>
            </NavLink>
          </NavItem>
        </>
      );
    } else {
      return (
        <>
          <NavItem className="w-50 text-center">
            <NavLink
              className={classnames({ active: activeTab === "services" })}
              onClick={() => {
                toggleTab("services");
              }}
            >
              <span className="tab-text">Services</span>
            </NavLink>
          </NavItem>
          <NavItem className="w-50 text-center">
            <NavLink
              className={classnames({ active: activeTab === "needs" })}
              onClick={() => {
                toggleTab("needs");
              }}
            >
              <span className="tab-text">Needs</span>
            </NavLink>
          </NavItem>
        </>
      );
    }
  };

  return (
    <>
      {userDetails && userDetails.length !== 0 ? (
        <div className="container mt-5">
          <p
            onClick={() => {
              navigate(-1);
            }}
            style={{
              cursor: "pointer",
              fontSize: "18px",
              color: "#000",
              zIndex: 999,
              paddingBottom: "10px",
            }}
          >
            <FaArrowLeft
              style={{
                marginRight: "6px",
                fontSize: "18px",
                marginTop: "-3px",
              }}
            />
            Back
          </p>
          <div className="row">
            <div className="col-md-3 ">
              <div className="user-pic-box shadow p-3 mb-5 bg-white rounded h-100 ">
                <img
                  src={
                    userDetails?.Photo
                      ? userDetails.Photo
                      : "https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
                  }
                  alt="User Pic"
                  className="img-fluid"
                />
                <div className="user-detail-box m-3">
                  <span>
                    <strong>Joined At :</strong> {userDetails?.createdAt}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="user-info-box shadow p-4 mb-5 bg-white rounded h-100">
                <Row className="mb-4">
                  <Col>
                    <h4>
                      <span>
                        {userDetails.UserName}{" "}
                        {userDetails?.isVerified === 1 && (
                          <FaCheckCircle className="text-primary ml-2" />
                        )}
                      </span>
                    </h4>
                  </Col>
                  <Col className="text-end">
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={() => setInviteModal(true)}
                    >
                      Share Profile
                    </button>
                  </Col>
                </Row>

                <SendInvite
                  isOpen={inviteModal}
                  closeModal={() => setInviteModal(false)}
                  userDetails={userDetails}
                />
                <div className="user-details row">
                  <div className="col-md-6">
                    <div className="user-detail-box mb-3">
                      <span>
                        <strong>First Name:</strong> {userDetails.FirstName}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="user-detail-box mb-3">
                      <span>
                        <strong>Last Name:</strong> {userDetails.LastName}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="user-detail-box mb-3">
                      <span>
                        <strong>Location:</strong> {userDetails.Location}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="user-detail-box">
                      <span>
                        <strong>Bio:</strong> {userDetails.BioData}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col">
              <div className="services-needs-box shadow p-3 mb-5 bg-white rounded h-100">
                <Nav tabs>{renderNavItems()}</Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="services">
                    <Row>
                      <UserServices services={userServices} />
                    </Row>
                  </TabPane>
                  <TabPane tabId="needs">
                    <Row>
                      <UserNeeds needs={userNeeds} />
                    </Row>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "50px",
          }}
        >
          <FadeLoader color="#36d7b7" />
        </div>
      )}
    </>
  );
};

export default UserProfile;
