// CarouselComponent.jsx
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Card, CardBody, Col, Row } from "reactstrap";
import "./BizzList.css";
import { fetchBWV } from "../Data";
import PlaceHolder from "../../../assets/images/Placeholder.png";

const CarouselComponent = ({ items }) => {
  const settings = {
    dots: false,
    infinite: items.length > 1,
    speed: 500,
    autoplay: true, // Enable auto-scrolling
    autoplaySpeed: 3000, // Time in milliseconds between auto-scrolls
    slidesToShow: items.length === 1 ? 1 : 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767, // Adjust the number based on your mobile breakpoint
        settings: {
          autoplay: true, // Enable auto-scrolling
          autoplaySpeed: 3000, // Time in milliseconds between auto-scrolls
          slidesToShow: 1, // Show 1 item at a time on mobile screens
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Adjust the number based on your mobile breakpoint
        settings: {
          autoplay: true, // Enable auto-scrolling
          autoplaySpeed: 3000, // Time in milliseconds between auto-scrolls
          slidesToShow: 2, // Show 1 item at a time on mobile screens
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 2400, // Adjust the number based on your mobile breakpoint
        settings: {
          autoplay: true, // Enable auto-scrolling
          autoplaySpeed: 3000, // Time in milliseconds between auto-scrolls
          slidesToShow: 2, // Show 1 item at a time on mobile screens
          slidesToScroll: 1,
        },
      },
    ],
  };
  console.log(items, "items");
  return (
    <Slider {...settings}>
      {items.map((item, index) => (
        <Card key={index} className="carousel-item">
          <CardBody
            style={{ border: "none", padding: "10px", overflow: "hidden" }}
          >
            <Row>
              <Col sm={4}>
                <div className="carousel-image">
                  <img
                    src={item?.Photo ? JSON.parse(item?.Photo)[0] : PlaceHolder}
                    alt={item.title}
                    style={{
                      width: "100%",
                      height: "150px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </Col>
              <Col sm={8} style={{ padding: 0 }}>
                <div
                  className="carousel-details text-start"
                  style={{
                    padding: 0,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "150px", // Adjust this height as needed
                  }}
                >
                  <div className="list_text" style={{ marginBottom: "8px" }}>
                    <h4
                      style={{
                        margin: "3px 0px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "wrap",
                        fontSize: "larger",
                      }}
                    >
                      {item?.listName.length > 25
                        ? item?.listName.substring(0, 25) + "..."
                        : item?.listName}
                    </h4>
                    <p
                      style={{
                        margin: "0",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "wrap",
                        fontSize: "small",
                      }}
                    >
                      {item?.Description.length > 170
                        ? item?.Description.substring(0, 170) + "..."
                        : item?.Description}
                    </p>
                  </div>
                  <div
                    className="d-flex gap-1 list_button"
                    style={{
                      maxHeight: "50px",
                      height: "50px",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <button className="btn btn-sm btn-success bizz_buttons">
                      <a href={`8002453610`} className="text-white">
                        Call: (800) 245-3610
                      </a>
                    </button>
                    <button className="btn btn-sm btn-success text-white bizz_buttons">
                      <span>Extension # {item?.dialExtension}</span>
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      ))}
    </Slider>
  );
};

export default CarouselComponent;
