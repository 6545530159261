import React, { useState } from "react";
import { Col, Button, Row } from "reactstrap";
import Placeholder from "../../../assets/images/Placeholder.png";

const LcaSlideImages = ({ selectedCard }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  let placeHolder =
    "https://st4.depositphotos.com/24297044/29972/v/600/depositphotos_299724198-stock-illustration-imege-isolated-icon-symbol-of.jpg";
  const images = selectedCard?.Photo ? JSON.parse(selectedCard.Photo) : [];
  const imageCount = images.length;
  const handleImageChange = (direction) => {
    if (imageCount <= 1) return; // No need to change if there's only one image or none
    if (direction === "left") {
      setSelectedImageIndex((prevIndex) =>
        prevIndex === 0 ? imageCount - 1 : prevIndex - 1
      );
    } else if (direction === "right") {
      setSelectedImageIndex((prevIndex) =>
        prevIndex === imageCount - 1 ? 0 : prevIndex + 1
      );
    }
  };
  console.log(imageCount, "imageCount");
  return (
    <div className="d-flex">
      <div className=" d-flex align-items-center justify-content-center">
        {imageCount === 1 || imageCount === 0 ? null : (
          <Button onClick={() => handleImageChange("left")} className="p-2">
            &lt;
          </Button>
        )}
      </div>
      <div className="d-flex align-items-center justify-content-center ">
        <img
          src={
            selectedCard?.Photo &&
            JSON.parse(selectedCard.Photo)[selectedImageIndex]
              ? JSON.parse(selectedCard.Photo)[selectedImageIndex]
              : Placeholder
          }
          className="object-fit-cover rounded-3 img-thumbnail img-fluid "
          style={{ height: "350px", width: "500px" }}
          alt={selectedCard?.Title}
        />
      </div>
      <div className=" d-flex align-items-center justify-content-center">
        {imageCount === 1 || imageCount === 0 ? (
          ""
        ) : (
          <Button onClick={() => handleImageChange("right")} className="p-2">
            &gt;
          </Button>
        )}
      </div>
    </div>
  );
};

export default LcaSlideImages;
