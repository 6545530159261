import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { FaQuestionCircle } from "react-icons/fa";
import { Row, Input, Col, FormGroup, Label, Form } from "reactstrap";
import Packages from "./Packages";
import ServiceVideos from "./ServiceVideos";
import ServiceCategories from "./ServiceCategories";
import ServiceImages from "./ServiceImages";
import ServiceGifts from "./ServiceGifts";
import { useDispatch, useSelector } from "react-redux";
import {
  setDescription,
  setHourlyPrice,
  setSpecialOffer,
} from "../../../redux/slices/ServiceInfo";
const BasiInformation = ({
  categories,
  subCategories1,
  items,
  selectedOption,
  setSelectedOption,
}) => {
  const dispatch = useDispatch();
  const serviceInfo = useSelector((state) => state.serviceInfo);
  const [isCheckedHourlyOffering, setIsCheckedHourlyOffering] = useState(false);
  const handlePriceChange = (e) => {
    let value = e.target.value.replace(/[^0-9.]/g, "");
    const decimalCount = (value.match(/\./g) || []).length;
    if (decimalCount > 1) {
      value = value.substring(0, value.lastIndexOf("."));
    }
    if (value.length <= 8) {
      const parts = value.split(".");
      if (parts.length > 1) {
        parts[1] = parts[1].slice(0, 2);
        value = parts.join(".");
      }
      dispatch(setHourlyPrice(value));
    }
  };
  useEffect(() => {
    if (serviceInfo.hourlyPrice && serviceInfo.hourlyPrice !== "") {
      setIsCheckedHourlyOffering(true);
    }
  }, [serviceInfo.hourlyPrice]);
  return (
    <Form>
      <Row className="form-container p-4 mx-1 mt-3 mt-lg-4">
        <ServiceCategories
          categories={categories}
          subCategories1={subCategories1}
          items={items}
        />
        <Row>
          <FormGroup>
            <Label>
              Description <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              type="textarea"
              placeholder="Describe your business, services and experience..."
              name="description"
              value={serviceInfo.description}
              onChange={(e) => dispatch(setDescription(e.target.value))}
            />
          </FormGroup>
          <FormGroup>
            <Label>Special Offer</Label>
            <Input
              type="textarea"
              placeholder="Describe your special offer..."
              name="specialOffer"
              value={serviceInfo?.specialOffer}
              onChange={(e) => dispatch(setSpecialOffer(e.target.value))}
            />
          </FormGroup>
        </Row>
        <hr />
        <ServiceGifts
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
        <hr />
        <h6>
          Set up Hourly offering <span style={{ color: "red" }}>*</span>
        </h6>
        <FormGroup check className="p-3">
          <Input
            type="switch"
            id="custom-switch"
            label="Toggle Switch"
            checked={isCheckedHourlyOffering}
            onChange={() => {
              setIsCheckedHourlyOffering(!isCheckedHourlyOffering);
            }}
          />
          Allow buyers to book this service at an hourly rate{" "}
        </FormGroup>
        <FormGroup>
          {isCheckedHourlyOffering && (
            <Col xs={12} className="hourly-offering p-5 d-flex">
              <Row>
                <Label>
                  Hourly Price <span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  type="number"
                  style={{ width: "40%", marginRight: "5px" }}
                  name="hourlyPrice"
                  placeholder="$00.00"
                  value={serviceInfo.hourlyPrice} // Prepend "$" before the value
                  onChange={(e) => handlePriceChange(e)}
                />
                {"   "}
                Per hour
              </Row>
              <Row className="d-wrap">
                <span>
                  Your estimated earning{" "}
                  <FaQuestionCircle style={{ color: "blue" }} />
                </span>
                <span>${serviceInfo.hourlyPrice}</span>
              </Row>
            </Col>
          )}
        </FormGroup>
        <hr />
        <Packages />
        <Col xs={12} className="highlighted-sample-work ">
          <ServiceImages />
          <ServiceVideos />
        </Col>
      </Row>
    </Form>
  );
};
export default BasiInformation;
