import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  giftAmount: 0,
  giftTitle: "",
  giftDescription: "",
};

const GiftSlice = createSlice({
  name: "gift",
  initialState,
  reducers: {
    setGiftAmount: (state, action) => {
      state.giftAmount = action.payload;
    },
    setGiftTitle: (state, action) => {
      state.giftTitle = action.payload;
    },
    setGiftDescription: (state, action) => {
      state.giftDescription = action.payload;
    },
    setGift: (state, action) => {
      const { giftAmount, giftTitle, giftDescription } = action.payload;
      state.giftAmount = giftAmount;
      state.giftTitle = giftTitle;
      state.giftDescription = giftDescription;
    },
  },
});

export const { setGiftAmount, setGiftTitle, setGiftDescription, setGift } =
  GiftSlice.actions;
export default GiftSlice.reducer;
