import React, { useState } from "react";
import { Row, Col, Input, Label, Modal, ModalBody, Button } from "reactstrap";
import { sendReview } from "../Data";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import ReactStars from "react-rating-stars-component";

const ReviewModal = ({ isOpen, closeModal }) => {
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [ratingError, setRatingError] = useState(false);
  const [reviewError, setReviewError] = useState(false);
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [testimonial, setTestimonial] = useState("");
  const selectedCard = useSelector((state) => state.bwvData.selectedService);

  const handleStarClick = (star) => {
    setRating(star);
    setRatingError(false);
  };

  const submitReview = async () => {
    if (rating === 0) {
      setRatingError(true);
      toast.error("Please select a rating.");
      return;
    }
    if (!review.trim()) {
      setReviewError(true);
      toast.error("Please provide a review.");
      return;
    }
    const formData = {
      customerId: user.UserID,
      serviceId: selectedCard.Id,
      ownerId: selectedCard.createdBy,
      Description: review,
      rating: rating,
      reviewStatus: 0,
      Testimonial: testimonial,
    };
    console.log(formData, "test video");
    await sendReview(formData);
    toast.success("Review Submitted");
    closeModal();
    setTestimonial("");
    setRating(0);
    setReview("");
  };

  return (
    <Modal isOpen={isOpen} toggle={closeModal}>
      <ModalBody>
        <div className="d-flex justify-content-end">
          <button
            type="button"
            className="btn-close"
            onClick={closeModal}
          ></button>
        </div>
        <div className="modal-body">
          <h5 className="text-center pb-3">Write a Review</h5>
          <h6>{selectedCard?.listName}</h6>
          <div className="modal-body d-flex flex-row align-items-center mb-3">
            <div
              style={{
                marginRight: "10px",
              }}
            >
              <img
                src={selectedCard?.Photo[0]}
                alt="Selected Card"
                style={{ borderRadius: "50%", width: "50px", height: "50px" }}
              />
            </div>
            <div>
              <Label>
                Rating <span style={{ color: "red" }}>*</span>
              </Label>
              <ReactStars
                count={5}
                size={24}
                // value={rating}
                activeColor="#ffd700"
                onChange={handleStarClick}
                classNames={ratingError ? "star-input error" : "star-input"}
              />
            </div>
          </div>
          <Row className="mb-3">
            <Label for="review">
              Your Review: <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              type="textarea"
              id="review"
              placeholder="Enter Review..."
              onChange={(e) => {
                setReview(e.target.value);
                setReviewError(false);
              }}
              className={reviewError ? "review-input error" : "review-input"}
            />
          </Row>
          <Row>
            <Input
              type="url"
              id="Testimonial"
              placeholder="Enter Video Url..."
              value={testimonial}
              onChange={(e) => {
                setTestimonial(e.target.value);
              }}
            />
          </Row>
          <div className="d-flex justify-content-end mt-3">
            <Button color="primary" onClick={submitReview}>
              Submit Review
            </Button>{" "}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ReviewModal;
