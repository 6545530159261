import React, { useEffect, useState } from "react";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import axiosClient from "../../../axios-client";
import { useDispatch, useSelector } from "react-redux";
import {
  setCategoryId,
  setCategoryName,
  setSubCategoryId,
  setSubCategoryName,
  setItemId,
  setItemName,
  setFullName,
  setNotifyItemList,
} from "../../../redux/slices/RequestFormSlice";
const NeedCategories = ({
  categories,
  subCategories1,
  subCategories,
  setSubCategories,
  setSelectedCat,
}) => {
  const formData = useSelector((state) => state.requestForm);
  const dispatch = useDispatch();
  const [itemsById, setItemsByID] = useState([]);
  useEffect(() => {
    if (formData.categoryId) {
      const filteredSubCategories = subCategories1.filter(
        (subCategory) =>
          subCategory.CategoryId === parseInt(formData.categoryId)
      );
      setSubCategories(filteredSubCategories);
    }
  }, [formData.categoryId, subCategories1]);
  useEffect(() => {
    if (formData.subCategoryId) {
      fetchItems(formData.subCategoryId);
    }
  }, [formData.subCategoryId]);
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? checked : value;
    let selectedCategoryData;
    let selectedSubCategoryData;
    let selectedItemData;
    if (name === "category") {
      setSubCategories([]);
      setItemsByID([]);
      selectedCategoryData = categories.find(
        (category) => category.Name === inputValue
      );
      if (selectedCategoryData) {
        setSelectedCat(selectedCategoryData.Id);
        const filteredSubCategories = subCategories1.filter(
          (subCategory) => subCategory.CategoryId === selectedCategoryData.Id
        );
        setSubCategories(filteredSubCategories);
      } else {
        setSubCategories([]);
        dispatch(setCategoryId(null));
        dispatch(setCategoryName(""));
      }
      dispatch(setCategoryId(selectedCategoryData?.Id || null));
      dispatch(setCategoryName(selectedCategoryData?.Name || ""));
      dispatch(setSubCategoryId(null));
      dispatch(setSubCategoryName(""));
      dispatch(setItemId(null));
      dispatch(setItemName(""));
      dispatch(setNotifyItemList(""));
    } else if (name === "subCategory") {
      setItemsByID([]);
      selectedSubCategoryData = subCategories1.find(
        (subCategory) => subCategory.Name === inputValue
      );
      if (selectedSubCategoryData) {
        fetchItems(selectedSubCategoryData.id);
      } else {
        setItemsByID([]);
        dispatch(setSubCategoryId(null)); // Set subCategoryId to null
        dispatch(setSubCategoryName("")); // Set subCa
      }
      console.log(selectedSubCategoryData, "selectedSubCategoryData");
      dispatch(setSubCategoryId(selectedSubCategoryData?.id || null));
      dispatch(setSubCategoryName(selectedSubCategoryData?.Name || ""));
      dispatch(setItemId(null));
      dispatch(setItemName(""));
      dispatch(setNotifyItemList(""));
    } else if (name === "item") {
      selectedItemData = itemsById.find(
        (item) => item.subCatItemName === inputValue
      );
      dispatch(setItemId(selectedItemData?.itemId || null));
      dispatch(setItemName(selectedItemData?.subCatItemName || ""));
    }
  };
  const fetchItems = async (subCategoryId) => {
    try {
      const response = await axiosClient.get(
        `sub-category-items/getSubcategoryItembySubcat/${subCategoryId}`
      );
      setItemsByID(response.data.subCategoryItems);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };
  const isLogin = useSelector((state) => state.user.isLogin);
  const userInfo = useSelector((state) => state.user.userInfo);
  const user = isLogin ? JSON.parse(userInfo) : null;
  const isAdmin = user && user.Role === "admin"; // Check if user is admin
  return (
    <div>
      <Row className="mt-4">
        <Col
          xs="12"
          sm="6"
          md={!isAdmin && formData.engagedCouple !== 1 ? "12" : "6"}
        >
          <FormGroup>
            <Label for="category">
              Your Full Name <b className="text-danger">*</b>
            </Label>
            <input
              type="text"
              name="FullName"
              id="FullName"
              className="form-control"
              placeholder="Your Full Name"
              value={formData.FullName}
              onChange={(e) => {
                dispatch(setFullName(e.target.value));
              }}
            />
          </FormGroup>
        </Col>
        {isAdmin ? (
          <Col xs="12" sm="6">
            <FormGroup>
              <Label for="category">
                Type of Service You Need <b className="text-danger">*</b>
              </Label>
              <Input
                type="select"
                name="category"
                id="category"
                style={{ borderRadius: "10px" }}
                value={formData.categoryName}
                onChange={handleInputChange}
              >
                <option value="">Select a category</option>
                {categories.map((category) => (
                  <option key={category.Id} value={category.Name}>
                    {category.Name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
        ) : (
          !isAdmin &&
          formData.engagedCouple === 1 && (
            <Col xs="12" sm="6">
              <FormGroup>
                <Label for="category">
                  Type of Service You Need <b className="text-danger">*</b>
                </Label>
                <Input
                  type="select"
                  name="category"
                  id="category"
                  style={{ borderRadius: "10px" }}
                  value={formData.categoryName}
                  onChange={handleInputChange}
                >
                  <option value="">Select a Category</option>
                  {categories.map((category) => (
                    <option key={category.Id} value={category.Name}>
                      {category.Name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          )
        )}
      </Row>
      {isAdmin && ( // Render only if user is admin
        <Row>
          <Col xs="12" sm="6">
            <FormGroup>
              <Label for="subCategory">
                Subcategory <b className="text-danger">*</b>
              </Label>
              <Input
                type="select"
                name="subCategory"
                id="subCategory"
                value={formData.subCategoryName}
                disabled={subCategories?.length === 0}
                onChange={handleInputChange}
              >
                <option value="">
                  {subCategories?.length === 0 ? "" : "Select a subcategory"}
                </option>
                {subCategories?.map((subcat) => (
                  <option key={subcat.id} value={subcat.Name}>
                    {subcat.Name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col xs="12" sm="6">
            <FormGroup>
              <Label for="item">Items</Label>
              <Input
                type="select"
                name="item"
                id="item"
                value={formData.itemName}
                disabled={itemsById.length === 0}
                onChange={handleInputChange}
              >
                <option value="" selected>
                  {itemsById.length === 0 ? "" : "Select a Item"}
                </option>
                {itemsById.map((item) => (
                  <option key={item.itemId} value={item.id}>
                    {item.subCatItemName}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default NeedCategories;
