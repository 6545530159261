import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const EventDetailsModal = ({ isOpen, close, event }) => {
  console.log(event, "event");
  return (
    <div>
      <Modal isOpen={isOpen} toggle={close}>
        <ModalHeader toggle={close}>Event Details</ModalHeader>
        <ModalBody className="text-center">
          <h3>{event?.title}</h3>
          <p>{event?.dec}</p>
          <p>{event?.custmorEmail}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="danger btn-sm" onClick={close}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EventDetailsModal;
