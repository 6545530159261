import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FormGroup, Label, Input, Button, Row, Col, Alert } from "reactstrap";
import {
  notifyProvider,
  notifySelect,
  fetchCategories,
  notifyDelete,
} from "../../../components/Header/Data";
import { toast } from "react-toastify";
import { FaPlusCircle, FaTrashAlt, FaSave } from "react-icons/fa";
import Select from "react-select";

const NotifyMe = () => {
  const isLogin = useSelector((state) => state.user.isLogin);
  const userInfo = useSelector((state) => state.user.userInfo);
  const [categories, setCategories] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [requestForm, setRequestForm] = useState({
    categoryId: null,
    categoryName: "",
    radius: null,
    duration: null,
  });

  const user = isLogin ? JSON.parse(userInfo) : null;

  const fetchAll = async () => {
    try {
      let categoriesData = await fetchCategories();
      setCategories(categoriesData);
    } catch (error) {
      console.error("Error fetching categories:", error.message);
    }
  };

  useEffect(() => {
    fetchAll();
    console.log(categories, "categories");
  }, []);

  useEffect(() => {
    fetchData();
  }, [user?.UserID]);
  const fetchData = async () => {
    try {
      const response = await notifySelect(user?.UserID);
      console.log("Response from notifySelect:", response);

      if (response && response.length > 0) {
        const categories = response.map((item) => ({
          id: item.id,
          categoryId: item.categoryId,
          radius: item.Radius,
          duration: item.Duration,
        }));

        setSelectedItems(categories);
        // setUpdateId(response[0].id);
        // const { categoryId, Radius, Duration } = response[0];
        // setRequestForm({
        //   ...requestForm,
        //   categoryId: categoryId,
        //   radius: Radius,
        //   duration: Duration,
        // });
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedItems = [...selectedItems];
    updatedItems[index] = {
      ...updatedItems[index],
      [name]: value,
    };
    setSelectedItems(updatedItems);
  };

  const handleSaveItem = async (index) => {
    if (loading) return;

    try {
      if (index !== undefined && selectedItems[index]?.id !== undefined) {
        // Updating existing item
        const itemToUpdate = selectedItems[index];

        if (
          !itemToUpdate.categoryId ||
          !itemToUpdate.radius ||
          !itemToUpdate.duration
        ) {
          toast.error("Please fill all required fields.");
          return;
        }

        const newItem = {
          Id: selectedItems[index].id,
          userId: user?.UserID,
          categoryId: parseInt(itemToUpdate.categoryId),
          Radius: parseInt(itemToUpdate.radius),
          Duration: parseInt(itemToUpdate.duration),
        };
        console.log(newItem, "newItem");
        setLoading(true);

        await notifyProvider(newItem);
        toast.success("Notification preferences updated!");
        console.log("Notification preferences updated! update", newItem);

        // Update selectedItems state
        // const updatedItems = [...selectedItems];
        // updatedItems[index] = newItem;
        // setSelectedItems(updatedItems);

        setRequestForm({
          categoryId: null,
          radius: null,
          duration: null,
        });
        setLoading(false);
      } else {
        // Adding new item

        if (
          !requestForm.categoryId ||
          !requestForm.radius ||
          !requestForm.duration
        ) {
          toast.error("Please fill all required fields.");
          // setError("Please fill all required fields");
          return;
        }

        const formData = {
          userId: user?.UserID,
          categoryId: parseInt(requestForm.categoryId),
          Radius: parseInt(requestForm.radius),
          Duration: parseInt(requestForm.duration),
        };
        console.log(formData, "formData");
        setLoading(true);
        await notifyProvider(formData);
        toast.success("Notification preferences saved!");

        //Update selectedItems state
        // const newItem = {
        //   categoryId: requestForm.categoryId,
        //   radius: requestForm.radius,
        //   duration: requestForm.duration,
        // };
        // setSelectedItems([...selectedItems, newItem]);

        // //Reset requestForm
        setRequestForm({
          categoryId: null,
          radius: null,
          duration: null,
        });
        fetchData();
        //setError("");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error saving notification preferences:", error.message);
      toast.error("Failed to save notification preferences");
    }
  };

  const handleAddItem = () => {
    if (
      !requestForm.categoryId ||
      !requestForm.radius ||
      !requestForm.duration
    ) {
      toast.error("Please fill all required fields.");
      // setError("Please fill all required fields");
      return;
    }

    const newItem = {
      categoryId: requestForm.categoryId,
      categoryName: requestForm.categoryName,
      radius: requestForm.radius,
      duration: requestForm.duration,
    };

    setSelectedItems([...selectedItems, newItem]);

    setRequestForm({
      categoryId: null,
      radius: null,
      duration: null,
    });
  };

  const handleDeleteItem = async (index) => {
    const updatedItems = [...selectedItems];
    updatedItems.splice(index, 1);
    setSelectedItems(updatedItems);
    const id = selectedItems[index].id;
    const formData = {
      userId: user?.UserID,
    };
    console.log(id, formData, "Delete");
    try {
      await notifyDelete(id, formData); // Assuming itemToDelete has an `id` field
      toast.success("Item deleted successfully!");
      //Update selectedItems state
      // const newItem = {
      //   categoryId: requestForm.categoryId,
      //   radius: requestForm.radius,
      //   duration: requestForm.duration,
      // };
      // setSelectedItems([...selectedItems, newItem]);

      //Reset requestForm
      setRequestForm({
        categoryId: null,
        radius: null,
        duration: null,
      });
      fetchData();
    } catch (error) {
      console.error("Error deleting item:", error.message);
      toast.error("Failed to delete item");
    }
  };

  return (
    <div className="position-relative notify-me-container">
      <div
        className="d-flex flex-column gap-10 notify-me-content"
        style={{ top: "0px", position: "relative" }}
      >
        <p
          style={{ marginBottom: "10px", fontWeight: "bold", fontSize: "17px" }}
          className="notify-me-title inter-font"
        >
          Please select the options to receive notifications:
        </p>

        {selectedItems.length > 0 && (
          <div className="mt-4">
            {selectedItems.map((item, index) => (
              <div key={index} className="mb-3 notify-item-enter">
                <Row>
                  <Col xs="12" sm="4">
                    <FormGroup>
                      <Label for={`category-${index}`}>Category</Label>
                      <Input
                        type="select"
                        name="categoryId"
                        id={`category-${index}`}
                        value={item.categoryId || ""}
                        onChange={(e) => handleInputChange(e, index)}
                      >
                        <option value="">Select category</option>
                        {categories.map((category) => (
                          <option key={category.Id} value={category.Id}>
                            {category.Name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="3">
                    <FormGroup>
                      <Label for={`radius-${index}`}>Radius</Label>
                      <Input
                        type="select"
                        name="radius"
                        id={`radius-${index}`}
                        value={item.radius || ""}
                        onChange={(e) => handleInputChange(e, index)}
                      >
                        <option value="">Select radius</option>
                        <option value="10">10 miles</option>
                        <option value="20">20 miles</option>
                        <option value="30">30 miles</option>
                        <option value="50">50 miles</option>
                        <option value="100">100 miles</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="3">
                    <FormGroup>
                      <Label for={`duration-${index}`}>Duration</Label>
                      <Input
                        type="select"
                        name="duration"
                        id={`duration-${index}`}
                        value={item.duration || ""}
                        onChange={(e) => handleInputChange(e, index)}
                      >
                        <option value="">Select duration</option>
                        <option value="60">60 days</option>
                        <option value="70">70 days</option>
                        <option value="80">80 days</option>
                        <option value="90">90 days</option>
                        <option value="100">100 days</option>
                        <option value="1000">Infinite</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col
                    xs="12"
                    sm="2"
                    className="d-flex align-items-center justify-content-center gap-3"
                  >
                    <Button
                      color="primary"
                      onClick={() => handleSaveItem(index)}
                      style={{ width: "5rem", marginLeft: "0px" }}
                      className="mt-3"
                      //disabled={loading}
                    >
                      Save
                    </Button>{" "}
                    <span
                      onClick={() => handleDeleteItem(index)}
                      className="mt-2"
                      style={{ cursor: "pointer" }}
                    >
                      <FaTrashAlt className="mr-1" size={20} />
                    </span>
                  </Col>
                </Row>
              </div>
            ))}
          </div>
        )}

        <Row>
          {/* {error && <Alert color="danger">{error}</Alert>} */}

          <Col xs="12" sm="4">
            <FormGroup>
              <Label for="category">Category</Label>
              <Input
                type="select"
                name="categoryName"
                id="category"
                style={{ borderRadius: "10px" }}
                value={requestForm.categoryId || ""}
                onChange={(e) =>
                  setRequestForm({
                    ...requestForm,
                    categoryId: e.target.value,
                  })
                }
              >
                <option value="">Select category</option>
                {categories.map((category) => (
                  <option key={category.Id} value={category.Id}>
                    {category.Name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col xs="12" sm="3">
            <FormGroup>
              <Label for="radius">Radius</Label>
              <Input
                type="select"
                name="radius"
                id="radius"
                value={requestForm.radius || ""}
                onChange={(e) =>
                  setRequestForm({ ...requestForm, radius: e.target.value })
                }
              >
                <option value="">Select radius</option>
                <option value="10">10 miles</option>
                <option value="20">20 miles</option>
                <option value="30">30 miles</option>
                <option value="50">50 miles</option>
                <option value="100">100 miles</option>
              </Input>
            </FormGroup>
          </Col>
          <Col xs="12" sm="3">
            <FormGroup>
              <Label for="duration">Duration</Label>
              <Input
                type="select"
                name="duration"
                id="duration"
                value={requestForm.duration || ""}
                onChange={(e) =>
                  setRequestForm({ ...requestForm, duration: e.target.value })
                }
              >
                <option value="">Select duration</option>
                <option value="60">60 days</option>
                <option value="70">70 days</option>
                <option value="80">80 days</option>
                <option value="90">90 days</option>
                <option value="100">100 days</option>
                <option value="1000">Infinite</option>
              </Input>
            </FormGroup>
          </Col>
          <Col
            xs="12"
            sm="2"
            className="d-flex align-items-center justify-content-center mt-3 gap-3"
          >
            <Button
              color="primary"
              onClick={() => handleSaveItem()} // Call handleSaveItem without an index for adding new item
              style={{ width: "7rem", marginLeft: "0px" }}
              disabled={loading}
            >
              Save
            </Button>
            {/* <span onClick={handleAddItem} className="cursor-pointer">
              <FaPlusCircle size={25} />
            </span> */}
          </Col>
        </Row>

        {/* Display selected items */}
      </div>
    </div>
  );
};

export default NotifyMe;
