import React, { useEffect, useState } from "react";
import Sidebar from "../../SideBar";
import { Row, Col } from "reactstrap";
import { FaArrowLeft } from "react-icons/fa";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import DealForm from "./DealForm";
import { fetchMyServices } from "../../../components/Header/Data";
import { addDeal, updateDeal } from "../../../components/Header/Data2";
import { toast } from "react-toastify";

const CreateDeal = () => {
  const location = useLocation();
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [myServices, setMyServices] = useState([]);
  const [dealForm, setDealForm] = useState({
    Title: "",
    Description: "",
    Code: "",
    codeValue: "",
    codeValueType: "",
    startDate: "",
    endDate: "",
    Image: "",
    homeDisplay: 0,
    serviceList: "",
    createdBy: user.UserID,
    dealStatus: 0,
  });

  useEffect(() => {
    console.log(user.UserID, "UserID");
    if (location.state) {
      let editMode = location.state.deal;
      setDealForm((prevInfo) => ({
        ...prevInfo,
        Title: editMode.Title,
        Description: editMode.Description,
        Code: editMode.Code,
        codeValue: editMode.codeValue,
        codeValueType: editMode.codeValueType,
        startDate: editMode.startDate,
        endDate: editMode.endDate,
        Image: editMode.Image,
        homeDisplay: editMode.homeDisplay,
        serviceList: editMode.serviceList,
      }));
    }
  }, [location.state]);

  const fetchServices = async () => {
    if (user.RoleId === 1) {
      let reqData = {
        roleId: 1,
      };
      const services = await fetchMyServices(reqData);
      setMyServices(services);
      console.log(services, "services");
    } else {
      let reqData = {
        createdBy: dealForm.createdBy,
      };
      const services = await fetchMyServices(reqData);
      setMyServices(services);
      console.log(services, "services");
    }
  };
  useEffect(() => {
    fetchServices();
  }, []);

  const handleAddDeal = async () => {
    if (!dealForm.serviceList) {
      toast.error("Please select one service");
      return;
    }
    if (!dealForm.Title) {
      toast.error("Please enter title");
      setIsLoading(false);
      return;
    }
    if (
      !dealForm.Code ||
      !dealForm.codeValue ||
      !dealForm.codeValueType ||
      !dealForm.startDate ||
      !dealForm.endDate
    ) {
      setIsLoading(false);
      toast.error("Please enter all mandatory details");
      return;
    }
    if (dealForm?.codeValue <= 0) {
      toast.error("Please enter valid Promo Code");
      return;
    }
    const currentDate = new Date();
    const startDate = new Date(dealForm.startDate);
    if (isNaN(startDate)) {
      toast.error("Please select a valid start date");
      return;
    }
    currentDate.setHours(0, 0, 0, 0);
    startDate.setHours(0, 0, 0, 0);
    if (startDate < currentDate) {
      toast.error("Please select a valid start date");
      return;
    }
    if (dealForm.startDate === dealForm.endDate) {
      toast.error("Please select valid dates");
      return;
    }

    if (dealForm.startDate > dealForm.endDate) {
      toast.error("ToDate should be after From Date");
      return;
    }
    if (isLoading) return;
    setIsLoading(true);
    if (location.state) {
      dealForm.updatedBy = user.UserID;
      await updateDeal(dealForm, location.state.deal.Id);
      toast.success(`Updated Request sent to Admin for Approval`);
      navigate("/dashboardpage/flash-Deals");
      window.scroll(0, 0);
    } else {
      await addDeal(dealForm);
      toast.success(`New Request sent to Admin for Approval`);
      navigate("/dashboardpage/flash-Deals");
      window.scroll(0, 0);
    }
    setIsLoading(false);
  };

  return (
    <section className="home-after-login bg-white">
      <div className="container">
        <Row>
          <Col sm={3}>
            <Sidebar />
          </Col>
          <Col sm={9} className="flashDeal-container">
            <span
              onClick={() => {
                navigate(-1);
              }}
              style={{
                cursor: "pointer",
                fontWeight: "bold",
                color: "#000",
                width: 0,
                marginLeft: "18px",
                fontSize: "14px",
              }}
            >
              <FaArrowLeft
                style={{
                  marginRight: "10px",
                  marginTop: "-3px",
                }}
              />
              Back
            </span>
            <div>
              <DealForm
                dealForm={dealForm}
                setDealForm={setDealForm}
                myServices={myServices}
              />
            </div>
            <div className="text-end">
              <button
                className="btn btn-danger mx-3"
                onClick={handleAddDeal}
                disabled={isLoading}
              >
                {location.state ? "Save & Submit" : "Submit for Admin Approval"}
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default CreateDeal;
