import React, { useState } from "react";
import { CardImg } from "reactstrap";
import PlaceHolder from "../../../assets/images/Placeholder.png";

const BwvGridImage = ({ provider }) => {
  const [cardPhotoIndexes, setCardPhotoIndexes] = useState({});

  // Parse provider.Photo if it's a string
  const photos = provider?.Photo ? JSON.parse(provider.Photo) : [];

  const handleNext = (e, selectedCard) => {
    e.stopPropagation();
    const cardId = selectedCard.Id;
    const currentIndex = cardPhotoIndexes[cardId] || 0;
    const newIndex = (currentIndex + 1) % (photos.length || 0);
    setCardPhotoIndexes({
      ...cardPhotoIndexes,
      [cardId]: newIndex,
    });
  };

  const handlePrev = (e, selectedCard) => {
    e.stopPropagation();
    const cardId = selectedCard.Id;
    const currentIndex = cardPhotoIndexes[cardId] || 0;
    const newIndex =
      (currentIndex - 1 + (photos.length || 0)) % (photos.length || 0);
    setCardPhotoIndexes({
      ...cardPhotoIndexes,
      [cardId]: newIndex,
    });
  };

  return (
    <div
      className="position-relative d-flex align-items-center justify-content-center p-0"
      style={{ width: "100%", height: "100%" }}
    >
      {photos.length > 1 && (
        <button
          onClick={(e) => handlePrev(e, provider)}
          className="arrow left-arrow position-absolute"
          style={{
            top: "50%",
            left: "10px",
            transform: "translateY(-50%)",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            border: "none",
            fontSize: "30px",
            zIndex: 1,
          }}
        >
          &#10094;
        </button>
      )}
      <CardImg
        top
        src={
          photos[cardPhotoIndexes[provider.Id] || 0] !== "" && photos.length > 0
            ? photos[cardPhotoIndexes[provider.Id] || 0]
            : PlaceHolder
        }
        alt={provider.listName}
        style={{
          objectFit: "cover",
          height: "180px",
          width: "100%",
        }}
        className="position-relative rounded img-thumbnail bwv-card-img"
      />
      {photos.length > 1 && (
        <button
          onClick={(e) => handleNext(e, provider)}
          className="arrow right-arrow position-absolute"
          style={{
            top: "50%",
            right: "10px",
            transform: "translateY(-50%)",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            border: "none",
            fontSize: "30px",
            zIndex: 1,
          }}
        >
          &#10095;
        </button>
      )}
    </div>
  );
};

export default BwvGridImage;
