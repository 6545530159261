import React, { useRef, useEffect } from "react";
import TvProductCard from "./TvProductCard";

const TvScreenProducts = ({ data }) => {
  const carouselRef = useRef(null);

  const filterByCategory = (data, category) => {
    return data.filter((product) =>
      product.subCategoryName
        ?.trim()
        .toLowerCase()
        .includes(category.trim().toLowerCase())
    );
  };

  const categories = [
    "Job Market",
    "Matrimonial/Dating",
    "Real Estate",
    "Local Business Ads",
    "Upcoming Wedding/Event",
    "Other Local Ads",
    "Child Care Services",
  ];

  const maxProducts = 40;

  return (
    <>
      {categories.map((category, index) => {
        const filteredProducts = filterByCategory(data, category);

        if (filteredProducts.length === 0) {
          return null;
        }

        let itemsToShow = [];
        while (itemsToShow.length < maxProducts) {
          itemsToShow = itemsToShow.concat(
            filteredProducts.slice(0, maxProducts - itemsToShow.length)
          );
        }

        return (
          <div key={index} className="tv-screen-products-container">
            <div className="static-category-card">
              <div className="category-title">{category}</div>
            </div>
            <div className="tv-screen-carousel" ref={carouselRef}>
              <div className="tv-screen-carousel-inner">
                {itemsToShow.map((item, idx) => (
                  <div
                    key={idx}
                    className="tv-card"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={
                      item && item.itemName
                        ? item.itemName
                        : item.subCategoryName
                        ? item.subCategoryName
                        : item.categoryName
                        ? item.categoryName
                        : ""
                    }
                  >
                    <TvProductCard item={item} />
                  </div>
                ))}
                {/* Duplicate items to create seamless effect */}
                {itemsToShow.map((item, idx) => (
                  <div
                    key={`duplicate-${idx}`}
                    className="tv-card"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={
                      item && item.itemName
                        ? item.itemName
                        : item.subCategoryName
                        ? item.subCategoryName
                        : item.categoryName
                        ? item.categoryName
                        : ""
                    }
                  >
                    <TvProductCard item={item} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default TvScreenProducts;
