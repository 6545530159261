import React, { useState, useEffect, lazy, Suspense, useRef } from "react";
import "../ZoomMeeting/ZoomMeeting.css";
//import MeetingTracker from "./ZoomMeetingTracker";
//import MeetingScreen from "./ZoomMTG_Time.JSX";

const ZoomMeeting = lazy(() => import("./ZoomMeeting"));
//import ZoomMeeting from './ZoomMeeting';

const ZoomWelcomePage = () => {
  const [showMeeting, setShowMeeting] = useState(false);
  const [attendeeName, setAttendeeName] = useState("");
  const [nameError, setNameError] = useState(false);
  const inputRef = useRef(null);


  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const handleAttendeeNameChange = (event) => {
    setAttendeeName(event.target.value);
    setNameError(false); // Reset error state when user types in input
  };

  const handleStartMeeting = () => {
    if (attendeeName.trim() === "") {
      setNameError(true);
      return;
    }
    // Store attendee name in local storage
    //localStorage.setItem('attendeeName', attendeeName);
    setShowMeeting(true);
  };

  const meetingId = "2226637344";

  return (
    <div
      className="container d-flex flex-column align-items-center justify-content-center "
      style={{ height: "90vh" }}
    >
      {showMeeting ? (
        <Suspense
          fallback={
            <>
              <span className="circle-spin-1"> </span> &nbsp;{" "}
              <span>Loading...</span>
            </>
          }
        >
          <ZoomMeeting />
        </Suspense>
      ) : (
        <div
          className="text-center position-absolute"
          style={{
            top: "45%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <h1 className="mb-4 fs-2">Welcome to Zoom Meeting</h1>
          <div className="form-floating mb-3">
            <input
              ref={inputRef}
              type="text"
              className={`form-control ${
                nameError ? "is-invalid" : ""
              } mt-3 mb-2`} //
              placeholder="Enter your name"
              value={attendeeName}
              onChange={handleAttendeeNameChange}
              required
              width={{ width: "100px" }}
            />
            <label htmlFor="floatingInput">Enter your name</label>
            {nameError && (
              <div className="invalid-feedback">Please enter your name.</div>
            )}
          </div>
          <button
            onClick={handleStartMeeting}
            className="btn btn-primary mt-3 zm"
          >
            Join
          </button>
        </div>
      )}
      {/* <div>
      <h1>Zoom Meeting Tracker</h1>
      <MeetingTracker meetingId={meetingId} />
      </div> */}
    </div>
  );
};

export default ZoomWelcomePage;
