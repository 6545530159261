import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { Link as ScrollLink } from "react-scroll";
import "./Bwv.css";
import PlaceHolder from "../../../assets/images/Placeholder.png";
import HireModal from "../modals/HireModal";
import MsgModal from "../modals/MsgModal";
import ReviewModal from "../modals/Review";
import GoogleCalendar from "../../DashboardCalenderPages/Calender";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ReactStars from "react-rating-stars-component";
import BwvMedia from "../Bwv/BwvMedia";
import BwvVideo from "../Bwv/BwvVdeos";
import BwvServiceInfo from "../Bwv/BwvServiceInfo";
import BwvReviews from "./BwvReviews";
import InviteModal from "../modals/InviteModal";
import RequestAppointment from "../modals/RequestAppointmentModal";
import { FaFacebook, FaInstagram, FaYelp } from "react-icons/fa";
import ReportBussinessModal from "../modals/ReportBussinessModal";
import moment from "moment";
import { FaSync } from "react-icons/fa";
import { MdVerified } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import { ImSpinner2 } from "react-icons/im";
import { endorsementStatus, serviceEndorsement } from "../Data";

const BwvcardDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [quotePop, setQuotePop] = useState(location.state);
  const selectedCard = useSelector((state) => state.bwvData.selectedService);
  const [quoteModal, setQuoteModal] = useState(false);
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [activeLink, setActiveLink] = useState("service-information");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMsgModalOpen, setIsMsgModalOpen] = useState(false);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const currentHour = new Date().getHours().toString().padStart(2, "0");
  const [selectedHour, setSelectedHour] = useState(`${currentHour}:00`);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [isReportingModalOpen, setIsReportingModalOpen] = useState(false);
  const [isInvite, setInvite] = useState(false);
  const [selectedRate, setSelectedRate] = useState(null);
  const [status, setStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const availableDays = selectedCard?.availableDays;
  const packages = selectedCard?.Package
    ? JSON.parse(selectedCard.Package)
    : {};
  const logUser = localStorage.getItem("USER_ROLE");
  const flatRate = (packageDetails) => {
    setSelectedRate(packageDetails);
    openHireModal();
  };
  const openHireModal = () => {
    const validAvailableDays = availableDays.filter((available) => {
      return available.days.length > 0 && available.from && available.to;
    });

    const isHourAvailable = validAvailableDays?.some((available) => {
      const days = available?.days;
      const startTime = parseInt(available.from.split(":")[0]);
      const endTime = parseInt(available.to.split(":")[0]);
      const selectedDateObj = moment(selectedDate);
      const selectedDayOfWeek = selectedDateObj.format("dddd");
      const selectedHourStart = selectedDateObj.set({
        hour: selectedHour,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      return (
        days.includes(selectedDayOfWeek) &&
        selectedHour >= startTime &&
        selectedHour <= endTime
      );
    });
    if (!islogin) {
      toast.info("Please Login");
      navigate("/login");
    } else if (!isHourAvailable) {
      toast.warning("Please Select Provider Available Date In Schedule");
    } else {
      setIsModalOpen(true);
    }
  };
  const fetchStatus = async () => {
    if (islogin) {
      let serviceId = selectedCard?.Id;
      let formData = {
        userId: user?.UserID,
      };
      const resp = await endorsementStatus(serviceId, formData);
      console.log("Endorsement status:", resp[0]);
      setStatus(resp[0]);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (selectedCard) {
      fetchStatus();
    }
  }, [selectedCard]);
  useEffect(() => {
    setQuotePop(location.state);
    if (location.state) {
      setQuoteModal(true);
    }
  }, [location.state]);
  const openQuoteModal = () => {
    const validAvailableDays = availableDays.filter((available) => {
      return available.days.length > 0 && available.from && available.to;
    });
    if (!islogin) {
      toast.info("Please Login");
      navigate("/login");
    } else if (Object.keys(validAvailableDays).length <= 0) {
      toast.warning("Provider Not Available");
    } else {
      setQuoteModal(true);
    }
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const OpenInvite = () => {
    if (!islogin) {
      toast.info("Please Login");
      navigate("/login");
    } else {
      setInvite(true);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };
  const openReviewModal = () => {
    if (!islogin) {
      toast.info("Please Login");
      navigate("/login");
    } else {
      setIsReviewModalOpen(true);
    }
  };
  const closeReviewModal = () => {
    setIsReviewModalOpen(false);
  };

  const closeInviteModal = () => {
    setInvite(false);
  };
  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  const handleReportBusiness = () => {
    setIsReportingModalOpen(!isReportingModalOpen);
  };

  if (!selectedCard) {
    return <div>Card not found!</div>;
  }
  const prices = Object.values(packages).map(
    (packageDetails) => packageDetails.price
  );
  const minimumPrice = prices.length > 0 ? Math.min(...prices) : 0;

  console.log(selectedCard, "selectedCard");

  const handleEndorse = async (Id) => {
    if (loading) return;
    setLoading(true);
    let formData = {
      endStatus: status?.end_status === 1 ? 2 : 1,
      userId: user?.UserID,
      Message: "",
    };
    console.log(formData, "formData");
    await serviceEndorsement(Id, formData);
    fetchStatus();
  };

  return (
    <Row style={{ display: "flex", margin: "0px" }}>
      <Col sm={3} xs={12} className="card-details-profile">
        <Row className="image">
          <div className="d-flex align-items-center justify-content-center p-0">
            {/* <button
              onClick={handlePrev}
              className="arrow left-arrow  d-flex justify-content-center mt-0 mx-1"
              style={{ width: "25px", fontSize: "30px" }}
            >
              &#10094;
            </button> */}
            <img
              src={
                selectedCard?.Photo
                  ? JSON.parse(selectedCard?.Photo)[0]
                  : PlaceHolder
              }
              alt="Static Image image-fluid rounded"
              // PlaceHolder={staticProfilePhoto}
              style={{ width: "310px", height: "250px", objectFit: "fill" }}
              className="rounded "
            />
            {/* <button
              onClick={handleNext}
              className="arrow right-arrow  d-flex justify-content-center mt-0 mx-1"
              style={{ width: "25px", fontSize: "30px" }}
            >
              &#10095;
            </button> */}
          </div>
        </Row>
        <Row className="bio-container">
          {" "}
          <Row style={{ cursor: "pointer", gap: 5 }}>
            <div className="">
              <h5>{selectedCard?.FullName}</h5>
              {selectedCard?.caIdStatus === 1 ? (
                <p className="btn btn-success btn-sm text-white text-nowrap mt-1">
                  <MdVerified size={20} /> &nbsp; Verified & Trusted
                </p>
              ) : null}
            </div>
            <p>
              <i className="fa-solid fa-location-dot"></i>{" "}
              {selectedCard?.Location}
            </p>
            <Col className="pb-3">
              <ReactStars
                count={5}
                size={24}
                value={selectedCard?.Rating}
                activeColor="#ffd700"
                edit={false}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <button
              type="button"
              className="col btn btn-primary btn-sm mx-1"
              style={{ fontSize: "small" }}
              onClick={() => openHireModal()}
            >
              Hire
            </button>
            <button
              type="button"
              className="col btn btn-outline-success btn-sm"
              onClick={OpenInvite}
              style={{ fontSize: "small" }}
            >
              Invite to listing
            </button>
            <HireModal
              isOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              openModal={openModal}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              selectedHour={selectedHour}
              setSelectedHour={setSelectedHour}
              closeModal={closeModal}
              selectedRate={selectedRate}
              setSelectedRate={setSelectedRate}
            />
            <InviteModal isOpen={isInvite} closeModal={closeInviteModal} />
          </Row>
          <Row className="mt-3">
            <p>
              <b>Bio</b>
            </p>
            <span>{selectedCard?.bio}</span>
          </Row>
          {selectedCard?.facebookUrl !== "" &&
            selectedCard?.instagramUrl !== "" &&
            selectedCard?.yelpUrl !== "" && (
              <Row className="mt-3">
                <p>
                  <b>Profile Links</b>
                </p>
                <div>
                  <ul className="d-inline-flex gap-10">
                    <li>
                      {selectedCard?.facebookUrl !== "" && (
                        <Link
                          to={selectedCard?.facebookUrl}
                          style={{ fontSize: "22px" }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          <FaFacebook />
                        </Link>
                      )}
                    </li>
                    <li>
                      {selectedCard?.instagramUrl !== "" && (
                        <Link
                          to={selectedCard?.instagramUrl}
                          style={{ fontSize: "22px" }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          <FaInstagram />
                        </Link>
                      )}
                    </li>
                    <li>
                      {selectedCard?.yelpUrl !== "" && (
                        <Link
                          to={selectedCard?.yelpUrl}
                          style={{ fontSize: "22px" }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FaYelp />
                        </Link>
                      )}
                    </li>
                  </ul>
                </div>
              </Row>
            )}
        </Row>
      </Col>
      <Col sm={9} xs={12} className="card-details-details">
        <div className="details p-lg-5 p-md-4 p-4">
          <h4 className="p-1">{selectedCard?.categoryName}</h4>
          <div
            className="position-sticky"
            style={{ position: "sticky", top: 0, zIndex: "4" }}
          >
            <Row className="d-flex justify-content-between align-items-center mb-2 mt-0">
              <Col sm={9} xs={12}>
                <h6 className="p-1">{selectedCard?.specialOffer}</h6>
              </Col>
              <Col sm={3} xs={12}>
                <a
                  href={`tel:  8002453610`}
                  className="btn btn-success btn-sm m-1"
                >
                  &nbsp; Call: (800) 245-3610 <br />
                  <span>Extension# {selectedCard?.dialExtension}</span>
                </a>
              </Col>
            </Row>
            <div className="wc-scroll-tabs pb-3 d-lg-flex d-md-flex flex-wrap">
              <ScrollLink
                to="service-information"
                duration={500}
                offset={-70}
                className="wc-scroll-tab"
                active
                style={{
                  color:
                    activeLink === "service-information" ? "blue" : "inherit",
                }}
                onClick={() => handleLinkClick("service-information")}
              >
                Service Information
              </ScrollLink>
              <ScrollLink
                to="availability"
                duration={500}
                offset={-70}
                className="wc-scroll-tab"
                style={{
                  color: activeLink === "availability" ? "blue" : "inherit",
                }}
                onClick={() => handleLinkClick("availability")}
              >
                Availability
              </ScrollLink>
              <ScrollLink
                to="flat-rate-services"
                duration={500}
                offset={-70}
                className="wc-scroll-tab"
                style={{
                  color:
                    activeLink === "flat-rate-services" ? "blue" : "inherit",
                }}
                onClick={() => handleLinkClick("flat-rate-services")}
              >
                Flat Rate Services
              </ScrollLink>
              <ScrollLink
                to="media"
                duration={500}
                offset={-70}
                className="wc-scroll-tab"
                style={{
                  color: activeLink === "media" ? "blue" : "inherit",
                }}
                onClick={() => handleLinkClick("media")}
              >
                {/* Media */}
                Highlight Images
              </ScrollLink>
              <ScrollLink
                to="reviews"
                duration={500}
                offset={-70}
                className="wc-scroll-tab"
                style={{
                  color: activeLink === "reviews" ? "blue" : "inherit",
                }}
                onClick={() => handleLinkClick("reviews")}
              >
                Reviews
              </ScrollLink>
              <p style={{ fontSize: "large", cursor: "pointer" }}>
                Cancellations
              </p>
            </div>
          </div>
          <div className="pb-3" style={{ fontSize: "small" }}>
            {selectedCard?.Description}
          </div>
          <Row className="service-information pb-3">
            <BwvServiceInfo />
          </Row>
          <Row className="availability pb-3">
            <h6 style={{ fontWeight: "bold" }}>
              {" "}
              Availability <i className="fas fa-sync"></i> <FaSync size={13} />
            </h6>
            <GoogleCalendar
              openQuoteModal={openQuoteModal}
              selectedDate={selectedDate}
              selectedHour={selectedHour}
              setSelectedDate={setSelectedDate}
              setSelectedHour={setSelectedHour}
            />
            <RequestAppointment
              isOpen={quoteModal}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              selectedHour={selectedHour}
              setSelectedHour={setSelectedHour}
              quotePop={quotePop}
              closeModal={() => {
                setQuoteModal(false);
                setQuotePop(false);
              }}
            />
          </Row>
          <Col className="flat-rate-services pb-3">
            <h6 style={{ fontWeight: "bold" }}>Flat Rate Services</h6>
            {Object.values(packages).length > 0 ? (
              Object.values(packages).map((packageDetails, index) => (
                <Row className="services p-lg-4 p-md-4 p-3 mt-4" key={index}>
                  <h6 className="pb-3" style={{ fontWeight: "bold" }}>
                    {packageDetails.serviceName}
                  </h6>
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Col sm={9}>{packageDetails.description}</Col>
                    <Col className="pt-3" style={{ textAlign: "end" }}>
                      <button
                        type="button"
                        className="btn btn-primary text-right"
                        onClick={() => flatRate(packageDetails)}
                        style={{ fontSize: "small" }}
                      >
                        Hire For $ {packageDetails.price}
                      </button>
                    </Col>
                  </Row>
                </Row>
              ))
            ) : (
              <p>No services available</p>
            )}
          </Col>
          <BwvVideo />
          <div className="media pb-3">
            <BwvMedia />
          </div>
          <Row className="reviews pb-3 d-flex justify-content-between">
            <Col sm="9" xs="12">
              <h6 style={{ fontWeight: "bold" }}>Reviews</h6>{" "}
            </Col>
            <Col sm="3" xs="12">
              <div className="d-flex justify-content-center align-items-center gap-3">
                <button
                  type="button"
                  className="btn btn-outline-primary btn-sm mt-1 text-nowrap"
                  onClick={openReviewModal}
                >
                  Leave a Review
                </button>
                {islogin &&
                  logUser === "provider" &&
                  user?.UserID !== parseInt(selectedCard.createdBy) && (
                    <button
                      type="button"
                      className={`btn btn-${
                        status?.end_status !== 1 ? `primary` : `success`
                      } btn-sm mt-1`}
                      onClick={() => handleEndorse(selectedCard?.Id)}
                    >
                      {status?.end_status === 1 ? (
                        <span className="text-nowrap">
                          <FaCheck /> Endorsed
                        </span>
                      ) : (
                        // ) : loading ? (
                        //   <ImSpinner2 />
                        "Endorse"
                      )}
                    </button>
                  )}
              </div>
            </Col>
          </Row>
          <BwvReviews />
          <div className="pb-3">
            <h6 className="pb-3" style={{ fontWeight: "bold" }}>
              Deal Terms
            </h6>
            <span>{selectedCard?.dealTerm}</span>
          </div>
          <h6 style={{ fontWeight: "bold" }}>File</h6>
        </div>
      </Col>
      <Col className=" d-flex justify-content-end my-2">
        <button className="btn btn-danger" onClick={handleReportBusiness}>
          Report Business
        </button>
        <ReportBussinessModal
          isReportingModalOpen={isReportingModalOpen}
          handleReportBusiness={handleReportBusiness}
        />
      </Col>
      <ReviewModal isOpen={isReviewModalOpen} closeModal={closeReviewModal} />
    </Row>
  );
};

export default BwvcardDetails;
