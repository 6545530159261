import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Payment from "../settingsComponents/Payment";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const FreatureModal = ({ isOpen, close }) => {
  const navigate = useNavigate();
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const handlePay = () => {
    if (islogin) {
      navigate("/order/summary");
    } else {
      navigate("/login");
      toast.info("Please Login");
    }
  };
  return (
    <div>
      <Modal isOpen={isOpen} toggle={close}>
        <ModalHeader toggle={close}>Feature Service</ModalHeader>
        <ModalBody className="text-center">
          To make the service featured you have to pay $10
        </ModalBody>
        <ModalFooter>
          <Button color="success btn-sm" onClick={handlePay}>
            Proceed
          </Button>
          <Button color="danger btn-sm" onClick={close}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default FreatureModal;
