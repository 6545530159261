import React, { useState } from "react";
import LcaCards from "../LocalCummunityAds/LcaCards";

const UserNeeds = ({ needs }) => {
  const [loading, setLoading] = useState(true);
  const [scrollPopupVisible, setScrollPopupVisible] = useState(false);
  return (
    <>
      {needs && needs.length > 0 ? (
        <LcaCards
          allProducts={needs}
          loading={loading}
          setLoading={setLoading}
          scrollPopupVisible={scrollPopupVisible}
        />
      ) : (
        <p className="text-center m-5">No needs</p>
      )}
    </>
  );
};

export default UserNeeds;
