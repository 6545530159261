import React, { useEffect, useState } from "react";
import { AiFillCaretDown } from "react-icons/ai";

const BwvOptions = ({
  listOptions,
  optionsData,
  origibalBwv,
  setBwv,
  isReset,
}) => {
  const [selectedOption, setSelectedOption] = useState({});
  const [expandedItem, setExpandedItem] = useState(null);
  useEffect(() => {
    if (isReset) {
      setSelectedOption({});
    }
  }, [isReset]);
  // const handleOptionChange = (cat_list_id, option) => {
  //   if (selectedOption[cat_list_id] === option) {
  //     setSelectedOption((prevOptions) => {
  //       const updatedOptions = { ...prevOptions };
  //       delete updatedOptions[cat_list_id];
  //       return updatedOptions;
  //     });
  //   } else {
  //     setSelectedOption((prevOptions) => ({
  //       ...prevOptions,
  //       [cat_list_id]: option,
  //     }));
  //   }
  //   const filterBasedOnOptions = origibalBwv.filter((bwv) => {
  //     const customDetails = bwv.customDetails || {};
  //     return Object.entries(selectedOption).every(([key, value]) => {
  //       return (
  //         customDetails[key] && customDetails[key].includes(value.toString())
  //       );
  //     });
  //   });
  //   console.log(filterBasedOnOptions, "filterBasedOnOptions");
  //   setBwv(filterBasedOnOptions);
  // };
  const handleOptionChange = (cat_list_id, option) => {
    if (selectedOption[cat_list_id] === option) {
      setSelectedOption((prevOptions) => {
        const updatedOptions = { ...prevOptions };
        delete updatedOptions[cat_list_id];
        return updatedOptions;
      });
    } else {
      setSelectedOption((prevOptions) => ({
        ...prevOptions,
        [cat_list_id]: option,
      }));
      console.log(selectedOption, "selectedOption");
    }
    const filterBasedOnOptions = origibalBwv.filter((bwv) => {
      const customDetails = bwv.customDetails || {};

      // Check if any of the customDetails arrays contain the selected option
      return Object.entries(selectedOption).every(([key, value]) => {
        const optionsArray = customDetails[key] || [];
        return optionsArray.some(
          (option) => option.option_value === value.toString()
        );
      });
    });

    console.log(filterBasedOnOptions, "filterBasedOnOptions");
    setBwv(filterBasedOnOptions);
  };
  const handleToggleOptions = (index) => {
    setExpandedItem((prevItem) => (prevItem === index ? null : index));
  };
  return (
    <div>
      {listOptions.map((listItem, index) => (
        <div key={index}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              justifyContent: "start",
              fontSize: "15px",
            }}
            onClick={() => handleToggleOptions(index)}
            className="pb-4"
          >
            <strong>{listItem.list_name}</strong>
            <AiFillCaretDown style={{ marginLeft: "5px" }} />
          </div>
          {expandedItem === index && (
            <div>
              <ul>
                {optionsData[listItem.cat_list_id] &&
                  optionsData[listItem.cat_list_id].map((option) => (
                    <li
                      style={{
                        background:
                          selectedOption[listItem.cat_list_id] ===
                          option.option_value
                            ? "#967cf3"
                            : "#fff",
                        color:
                          selectedOption[listItem.cat_list_id] ===
                          option.option_value
                            ? "#fff"
                            : "#000",
                        cursor: "pointer",
                        padding: "10px",
                        borderRadius: "0px 25px",
                      }}
                      key={option.option_id}
                      onClick={() =>
                        handleOptionChange(
                          listItem.cat_list_id,
                          option.option_value
                        )
                      }
                    >
                      {option.option_name}
                    </li>
                  ))}
              </ul>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default BwvOptions;
