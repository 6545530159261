import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col } from "reactstrap";
import Sidebar from "../SideBar";
import { notifyDatalList } from "../../components/Header/Data";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import PlaceHolder from "../../assets/images/Placeholder.png";
import { FadeLoader } from "react-spinners";

const NotifyList = () => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [list, setList] = useState([]);
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const handleCardClick = (selectedCard) => {
    if (selectedCard.engagedCouple === 1) {
      navigate(`/engagedCouple/${selectedCard.createdBy}`);
    } else {
      navigate(`/requestDetails/${selectedCard.Id}`, {
        state: { selectedCard },
      });
    }
  };
  const fetchData = async () => {
    setloading(true);
    const response = await notifyDatalList(user?.UserID);
    setList(response);
    setloading(false);
    window.scroll(0, 0);
  };

  useEffect(() => {
    fetchData();
    // console.log(list, "listPackage");
    console.log(user?.UserID, "user");
  }, [user?.UserID]);
  console.log(list, "listPackage");
  const parsePhoto = (photo) => {
    try {
      if (photo) {
        const parsedPhoto = JSON.parse(photo);
        if (Array.isArray(parsedPhoto) && parsedPhoto.length > 0) {
          return parsedPhoto[0]; // Use the first photo in the array
        }
      }
    } catch (e) {
      console.error("Failed to parse photo JSON", e);
    }
    return PlaceHolder; // Return placeholder if parsing fails or array is empty
  };
  return (
    <section className="home-after-login bg-white">
      <div className="container">
        <Row>
          <Col lg={3}>
            <Sidebar />
          </Col>
          <Col lg={9} className="position-relative px-3 py-4">
            <div className="">
              {loading ? (
                <FadeLoader
                  css={{ margin: "0 auto" }}
                  color={"#36D7B7"}
                  loading={loading}
                  className="position-absolute top-50 start-50 translate-middle"
                />
              ) : (
                <>
                  {list.length > 0 ? (
                    list.map((ad) => {
                      const photoUrl = parsePhoto(ad.Photo); // Get the parsed photo URL
                      return (
                        <div key={ad.id} className="card">
                          <div
                            className="d-lg-flex flex-lg-row"
                            onClick={() => handleCardClick(ad)}
                          >
                            <img
                              className="wc_egcpl-ad"
                              src={photoUrl}
                              alt={ad.Title}
                              style={{
                                height: "150px",
                                width: "150px",
                                flexBasis: "170px",
                                objectFit: "cover",
                              }}
                            />
                            <div className="row w-100">
                              <div className="col-12 col-lg-8 px-4">
                                <Link style={{ marginBottom: "10px" }}>
                                  <h5 style={{ fontSize: "medium" }}>
                                    <b>{ad.Title}</b>
                                  </h5>
                                </Link>
                                <p>{ad.Description}</p>
                                <div className="d-lg-flex">
                                  <p
                                    style={{ marginRight: "30px" }}
                                    className="mt-2"
                                  >
                                    <FontAwesomeIcon icon={faMapMarkerAlt} />{" "}
                                    {ad.Zipcode}
                                    {ad.Location ? ` - ${ad.Location}` : ""}
                                  </p>
                                  <p
                                    style={{ marginRight: "30px" }}
                                    className="mt-2"
                                  >
                                    {ad.categoryName && (
                                      <p>Service Needed : {ad.categoryName}</p>
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="">
                      <p
                        className="position-absolute translate-middle inter-font fs-5 text-secondary"
                        style={{ left: "52%", top: "40%" }}
                      >
                        No Lists Added
                      </p>
                    </div>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default NotifyList;
