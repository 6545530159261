import React, { useState, useEffect } from "react";
import { Row, Col, Input, Label, Modal, ModalBody, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { UpdateCategory, UpdateItem, UpdateSubCategory } from "../Header/Data";

const UpdateCatModal = ({ isOpen, closeModal, category, type, fetchCat }) => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [newCat, setNewCat] = useState("");
  const [shortName, setShortName] = useState("");
  const AddNewCategory = async () => {
    if (type === "cat") {
      if (newCat === "") {
        toast.error("Please enter Category Name");
        return;
      }
      const request = {
        Name: newCat,
        UpdatedBy: user.UserID,
        // shortName: shortName,
      };
      await UpdateCategory(request, category.Id);
      closeModal();
      fetchCat();
      toast.info(`${newCat} Updated`);
      setNewCat("");
      // setShortName("");
    } else if (type === "subCat") {
      if (newCat === "") {
        toast.error("Please enter Sub Category Name");
        return;
      }
      const request = {
        Name: newCat,
        CategoryId: category.CategoryId,
        UpdatedBy: user.UserID,
        shortName: shortName,
      };
      await UpdateSubCategory(request, category.id);
      closeModal();
      toast.info(`${newCat} Updated`);
      setNewCat("");
      setShortName("");
    } else if (type === "item") {
      if (newCat === "") {
        toast.error("Please enter Item Name");
        return;
      }
      console.log(category, "category");
      const request = {
        itemName: newCat,
        UpdatedBy: user.UserID,
        // shortName: shortName,
      };
      await UpdateItem(request, category.itemId);
      closeModal();
      toast.info(`${newCat} Updated`);
      setNewCat("");
      // setShortName("");
    }
  };
  useEffect(() => {
    setNewCat(
      type === "cat"
        ? category?.Name
        : type === "subCat"
        ? category?.Name
        : category?.subCatItemName
    );
    setShortName(category?.shortName);
  }, [category]);
  return (
    <Modal isOpen={isOpen} toggle={closeModal}>
      <ModalBody>
        <button
          type="button"
          className="btn-close position-absolute top-0 end-0 m-2"
          onClick={closeModal}
          aria-label="Close"
        ></button>
        <div className="modal-body ">
          <Label className="p-1 mt-1 mb-1">
            Enter New{" "}
            {type === "cat"
              ? "Category"
              : type === "subCat"
              ? "Sub Category"
              : "Item"}{" "}
            Name
          </Label>
          <Input
            type="text"
            value={newCat}
            onChange={(e) => setNewCat(e.target.value)}
          />
          {type === "subCat" && (
            <>
              <Label className="p-1 mt-1 mb-1">Short Name</Label>
              <Input
                type="text"
                placeholder="add short name..."
                value={shortName}
                onChange={(e) => {
                  setShortName(e.target.value);
                }}
              ></Input>
            </>
          )}
          <div className="d-flex justify-content-end mt-3">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                AddNewCategory();
              }}
            >
              Update
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default UpdateCatModal;
