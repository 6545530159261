/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Row, Col, Input, Button } from "reactstrap";
import BwvSideBar from "./BwvSideBar";
import { useLocation, useNavigate } from "react-router-dom";
import "./BrowseWeddingVendors.css";
import BwvContent from "./BwvContent";
import { SearchBwv } from "./SearchBwv";

import {
  fetchCategories,
  fetchSubCategories,
  fetchItems,
  fetchBWV,
  fetchOptionsList,
  fetchOptions,
} from "../Data";

const BrowseWeddingVendors = () => {
  const [isReset, setIsReset] = useState(false);
  const [categories, setCategories] = useState([]);
  const location = useLocation();
  const [subCategories, setSubCategories] = useState([]);
  const [items, setItems] = useState([]);
  const [listOptions, setListOptions] = useState([]);
  const [optionsData, setOptionsData] = useState([]);
  const [bwv, setBwv] = useState([]);
  const [origibalBwv, setOrigibalBwv] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  console.log("bwv", bwv);
  console.log("origibalBwv", origibalBwv);
  const handleclear = () => {
    setSearchTerm("");
    setBwv(origibalBwv);
    setIsReset(true);
    console.log("reset");
    console.log(searchTerm);
  };
  const handleSearchBwv = (searchQuery) => {
    const trimmedQuery = searchQuery.trim().toLowerCase();
    if (origibalBwv) {
      const filteredBwv = origibalBwv.filter((bwv) => {
        const listNameMatch =
          bwv.listName && bwv.listName.toLowerCase().includes(trimmedQuery);
        const categoryNameMatch =
          bwv.categoryName &&
          bwv.categoryName.toLowerCase().includes(trimmedQuery);
        const subCategoryNameMatch =
          bwv.subCategoryName &&
          bwv.subCategoryName.toLowerCase().includes(trimmedQuery);
        return listNameMatch || categoryNameMatch || subCategoryNameMatch;
      });
      console.log("Filtered BWV:", filteredBwv);
      setBwv(filteredBwv);
      const state = {
        searchResults: filteredBwv,
        cat: null,
      };
      navigate("/weddingvendors", { state });
    }
  };
  const fetchOptionsData = async () => {
    const options = {};
    for (const item of bwv) {
      const listData = await fetchOptionsList(item.categoryId);
      setListOptions((prevListOptions) => [...prevListOptions, listData]);
      for (const option of listData) {
        const data = await fetchOptions(option.cat_list_id);
        options[option.cat_list_id] = data;
      }
    }
    setOptionsData(options);
    console.log(options, listOptions, "options");
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchOptionsData();
  }, []);
  const fetchData = async () => {
    setIsLoading(true);
    const categoriesData = await fetchCategories();
    const subCategoriesData = await fetchSubCategories();
    const itemsData = await fetchItems();
    const bwvData = await fetchBWV();
    console.log(bwvData, "bwvData");
    const filterBwvData = bwvData.filter((vendor) => vendor.isactive === "3");
    setCategories(categoriesData);
    setSubCategories(subCategoriesData);
    setItems(itemsData);
    if (location.state && location.state.cat) {
      const filterByCatID = filterBwvData.filter(
        (vendor) => vendor.categoryId == location.state.cat.Id
      );
      setBwv(filterByCatID);
    } else if (location.state && location.state.searchResults) {
      setBwv(location.state.searchResults);
    } else {
      setBwv(filterBwvData);
    }
    setOrigibalBwv(filterBwvData);
    console.log(filterBwvData, "filterBwvData");
    setIsLoading(false);
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (isReset && searchTerm === "") {
      setIsReset(false);
      navigate("/weddingvendors");
    }
  }, [isReset, searchTerm]);

  return (
    <div className="bg-white">
      <div className="container">
        <Row className="Browse-wedding-vendors-container">
          <SearchBwv handleSearchBwv={handleSearchBwv} />
          <Col sm={3} className="bwv-sidebar mt-4" id="bwv-sidebar">
            {" "}
            <BwvSideBar
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              categories={categories}
              subCategories={subCategories}
              items={items}
              bwv={bwv}
              setBwv={setBwv}
              origibalBwv={origibalBwv}
              listOptions={listOptions}
              optionsData={optionsData}
              selectCat={location.state}
              isReset={isReset}
            />
          </Col>
          <Col lg={9}>
            <BwvContent
              bwv={bwv}
              setBwv={setBwv}
              handleclear={handleclear}
              origibalBwv={origibalBwv}
              listOptions={listOptions}
              optionsData={optionsData}
              isLoading={isLoading}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default BrowseWeddingVendors;
