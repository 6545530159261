import React, { useEffect, useState } from "react";
import PlaceHolder from "../../../assets/images/Placeholder.png";
import { getAdminPayToProiderList } from "../../../components/Header/Data";
import "./Settings.css";
import { useLocation } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter, Input } from "reactstrap";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { FadeLoader } from "react-spinners";
import PayModal from "../../../components/Header/modals/PayModal";

const PaidList = () => {
  const [providerlist, setproviderlist] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredProviderList, setFilteredProviderList] =
    useState(providerlist);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const Id = queryParams.get("id");
  const Name = queryParams.get("Name");
  const Email = queryParams.get("email");

  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;

  const [isLoading, setIsLoading] = useState(false);

  const fetchAdminPayToProiderList = async () => {
    setIsLoading(true);
    const adminPayToProiderList = await getAdminPayToProiderList(Id);

    // adminPayToProiderList.sort(
    //   (a, b) => new Date(b.reserve_date) - new Date(a.reserve_date)
    // );
    setproviderlist(adminPayToProiderList);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAdminPayToProiderList();
    console.log(providerlist, "providerlist");
    console.log(Id, "id");
  }, []);

  useEffect(() => {
    setFilteredProviderList(providerlist);
  }, [providerlist]);

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  useEffect(() => {
    if (!searchInput && !startDate && !endDate) {
      setFilteredProviderList(providerlist);
    }
  }, [searchInput, startDate, endDate]);

  const handleSearchButtonClick = () => {
    if (startDate && !endDate) {
      toast.error("Please provide an end date.");
      return;
    }
    if (!startDate && endDate) {
      toast.error("Please provide a start date.");
      return;
    }

    if (startDate && endDate && new Date(startDate) > new Date(endDate)) {
      toast.error("Start date cannot be after end date.");
      return;
    }

    const filteredList = providerlist.filter((item) => {
      console.log(
        parseInt(searchInput) === parseInt(item.total_amount),
        "amount"
      );
      const customerNameMatch = item.customer_name
        .toLowerCase()
        .includes(searchInput.toLowerCase());
      const eventNameMatch = item.list_name
        .toLowerCase()
        .includes(searchInput.toLowerCase());
      const amountMatch =
        item.total_amount &&
        parseInt(searchInput) === parseInt(item.total_amount);

      const paidStartDateMatch =
        !startDate || item.created_at.split("T")[0] >= startDate.split("T")[0];
      const paidEndDateMatch =
        !endDate || item.created_at.split("T")[0] <= endDate.split("T")[0];

      return (
        (customerNameMatch || eventNameMatch || amountMatch) &&
        paidStartDateMatch &&
        paidEndDateMatch
      );
    });

    setFilteredProviderList(filteredList);
  };

  const handleSort = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  useEffect(() => {
    console.log(providerlist, "providerlist");
  }, []);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;

    return `${formattedMonth}/${formattedDay}/${year}`;
  }

  const openModal = (data) => {
    console.log(data.id, "paidAmount");
    console.log(data, "paidAmount");
    // const numericAmount = parseFloat(paymentAmount[data.Id]);
    // if (isNaN(numericAmount)) {
    //   toast.error("Please enter payment amount");
    //   return;
    // }
    // if (data.id <= 0 || !Number.isInteger(data.id)) {
    //   toast.error("Please enter a valid amount");
    //   return;
    // }
    setSelectedUser(data);
    setShowModal(true);
  };

  // Sorting function
  //   const sortedData = yourData.sort((a, b) => {
  //     if (sortField) {
  //       // Assuming yourData is an array of objects with fields like PayPalID, CustomerName, EventDate, OrderAmount, and Status
  //       if (sortOrder === "asc") {
  //         return a[sortField].localeCompare(b[sortField]);
  //       } else {
  //         return b[sortField].localeCompare(a[sortField]);
  //       }
  //     } else {
  //       return 0; // No sorting field selected
  //     }
  //   });

  // Filter function
  //   const filteredData = sortedData.filter((item) => {
  //     const searchMatch =
  //       item.PayPalID.includes(searchInput) ||
  //       item.CustomerName.toLowerCase().includes(searchInput.toLowerCase());
  //     const startDateMatch = !startDate || item.EventDate >= startDate;
  //     const endDateMatch = !endDate || item.EventDate <= endDate;
  //     return searchMatch && startDateMatch && endDateMatch;
  //   });

  useEffect(() => {
    if (sortField) {
      const sortedList = [...filteredProviderList].sort((a, b) => {
        if (sortField === "total_amount") {
          // If sorting by amount, parse numbers and compare
          return sortOrder === "asc"
            ? a[sortField] - b[sortField]
            : b[sortField] - a[sortField];
        } else if (sortField === "customer_name" || sortField === "list_name") {
          // If sorting by customer name or event name, use localeCompare for alphabetical sorting
          return sortOrder === "asc"
            ? a[sortField].localeCompare(b[sortField], "en", {
                sensitivity: "base",
              })
            : b[sortField].localeCompare(a[sortField], "en", {
                sensitivity: "base",
              });
        } else {
          // If sorting by date, convert to Date objects and compare
          const dateA = new Date(a[sortField]);
          const dateB = new Date(b[sortField]);
          return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
        }
      });
      setFilteredProviderList(sortedList);
    }
  }, [sortField, sortOrder]);

  function formatExpectedPayment(eventDate) {
    const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds
    const eventDateObj = new Date(eventDate);
    const paymentExpectedDateObj = new Date(eventDateObj.getTime() + oneDay);
    return formatDate(paymentExpectedDateObj);
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchButtonClick();
    }
  };

  const clearResults = () => {
    setSearchInput("");
    setStartDate("");
    setEndDate("");
    setFilteredProviderList(providerlist);
  };

  return (
    <div className="container mt-4 inter-font" style={{ height: "100%" }}>
      <div className="text-end"></div>
      <h1 className="text-center mb-4">Payments of {Name}</h1>
      <h6 className="text-center mb-4 fw-bold">PayPal ID: {Email}</h6>
      {/* <div className="d-flex justify-content-end mt-3 mx-0">
        No of Records:&nbsp; {filteredProviderList.length}
      </div> */}
      <div className="mb-3 d-flex justify-content-between w-100 mt-5">
        <div className="d-flex justify-content-end">
          <Input
            type="search"
            placeholder="search by cust.name, service and amount.."
            className="form-control mr-2"
            style={{ width: "370px" }}
            onKeyPress={handleKeyPress}
            value={searchInput}
            onChange={handleSearchInputChange}
          />
        </div>
        <div className="d-flex gap-3">
          <input
            type="date"
            placeholder="Start Date"
            className="form-control mr-2"
            value={startDate}
            onChange={handleStartDateChange}
          />
          <input
            type="date"
            placeholder="End Date"
            className="form-control mr-2 mt-0"
            value={endDate}
            onChange={handleEndDateChange}
          />
          {/* <button
            onClick={handleClearFilters}
            className="btn btn-warning text-nowrap"
            style={{ marginTop: "auto" }}
          >
            Clear Filters
          </button> */}
          <button
            className="btn btn-primary mt-0"
            //style={{ marginTop: "auto" }}
            onClick={handleSearchButtonClick}
          >
            Search
          </button>
          <button
            className="btn btn-warning text-nowrap mt-0"
            onClick={clearResults}
          >
            Clear Results
          </button>
        </div>
      </div>
      <div
        style={{
          maxHeight: "400px",
          overflowY: "scroll",
          position: "relative",
        }}
      >
        <table className="table">
          <thead>
            <tr className="position-sticky top-0 text-center">
              <th>
                Customer Name{" "}
                <FontAwesomeIcon
                  icon={faSort}
                  onClick={() => handleSort("customer_name")}
                />
              </th>
              <th>
                Service Type{" "}
                <FontAwesomeIcon
                  icon={faSort}
                  onClick={() => handleSort("list_name")}
                />
              </th>
              <th>
                Paid by Customer{" "}
                <FontAwesomeIcon
                  icon={faSort}
                  onClick={() => handleSort("created_at")}
                />
              </th>
              <th>
                Event Date{" "}
                <FontAwesomeIcon
                  icon={faSort}
                  onClick={() => handleSort("reserve_date")}
                />
              </th>
              <th>
                Payment Expected{" "}
                <FontAwesomeIcon
                  icon={faSort}
                  //onClick={() => handleSort("reserve_date")}
                />
              </th>
              <th>
                Amount{" "}
                <FontAwesomeIcon
                  icon={faSort}
                  onClick={() => handleSort("total_amount")}
                />
              </th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody style={{ height: "200px" }}>
            {isLoading ? (
              <span>
                <FadeLoader // FadeLoader component
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    translate: "-50% -50%",
                  }}
                  color={"#36D7B7"}
                  loading={isLoading}
                  // className="position-absolute top-50 start-50 translate-middle bg-none"
                />
              </span>
            ) : (
              <>
                {filteredProviderList.length > 0 ? (
                  <>
                    {filteredProviderList.map((item, i) => (
                      <tr
                        key={i}
                        style={{
                          verticalAlign: "baseline",
                          textAlign: "center",
                        }}
                      >
                        <td
                          style={{
                            maxWidth: "105px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {item.customer_name}
                        </td>
                        <td>{item.list_name}</td>
                        <td>{formatDate(item.created_at.split("T")[0])}</td>
                        <td>{formatDate(item.reserve_date)}</td>
                        <td>{formatExpectedPayment(item.reserve_date)}</td>
                        <td>{item.total_amount}</td>
                        <td>
                          <button
                            className="btn btn-success m-0"
                            onClick={() => openModal(item)}
                          >
                            Pay
                          </button>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <p className="text-center h4 position-absolute top-50 start-50 translate-middle">
                    No payments
                  </p>
                )}
              </>
            )}

            <PayModal
              isModalOpen={showModal}
              handleToggleModal={() => setShowModal(false)}
              selectedMsg={selectedUser}
            />

            {/* <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
              <ModalHeader toggle={() => setShowModal(false)}>
                Payment
              </ModalHeader>
              <ModalBody>
                <div>
                  <form className="">
                    <p className="fw-bold mb-3" style={{ fontSize: "20px" }}>
                      Please check below details:
                    </p>
                    <div className="form-group pb-2">
                      <label htmlFor="providerName">Provider Name:</label>
                      <span>{Name}</span>
                    </div>
                    <div className="form-group pb-2">
                      <label htmlFor="paypalId">PayPal ID (Email):</label>
                      <span> {Email}</span>
                    </div>
                    <div className="form-group pb-2">
                      <label htmlFor="phoneNumber">Phone Number:</label>
                      <span> {phone}</span>
                    </div>
                    <>
                      <div className="form-group pb-2">
                        <label htmlFor="eventName">Service Type:</label>
                        <span>{selectedUser.list_name}</span>
                      </div>
                      <div className="form-group pb-2">
                        <label htmlFor="eventDate">Event Date:</label>
                        <span>{formatDate(selectedUser.reserve_date)}</span>
                      </div>
                      <div className="form-group pb-2">
                        <label htmlFor="paidDate">Paid by Customer:</label>
                        <span>{formatDate(selectedUser.created_at)}</span>
                      </div>
                      <div className="form-group pb-2">
                        <label htmlFor="amountToBePaid">
                          Amount to be paid:
                        </label>
                        <span>{selectedUser.total_amount}</span>
                      </div>
                    </>
                    <div className="form-group pb-2 mb-2">
                      <label htmlFor="location">Location:</label>
                      <span>{Location}</span>
                    </div>
                    <div>
                      <input type="checkbox" onChange={(e) => handleCheck(e)} />
                      <span className="mx-2">I accept terms & conditions</span>
                    </div>
                  </form>
                </div>
              </ModalBody>

              <ModalFooter className="justify-content-center p-0 pb-2">
                <div>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={addPayment}
                    disabled={paymentInProgress}
                  >
                    {paymentInProgress ? "Processing..." : "Pay"}
                  </button>
                </div>
              </ModalFooter>
            </Modal> */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PaidList;
