import React, { useState } from "react";
import { Input } from "reactstrap";
import { useSelector } from "react-redux";
import { SavePayPalAccountDetail } from "../../../components/Header/Data2";
import { toast } from "react-toastify";
import Payment from "./Payment";

const ProfilePayment = ({ userDetails }) => {
  const [payment, setPayment] = useState(null);
  const [email, setEmail] = useState("");
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const saveEmail = async () => {
    console.log("Entered email:", email);
    if (!email) {
      toast.error("please enter PayPal ID");
      return;
    }
    let formData = {
      // id: user.UserID,
      paypalEmail: email,
    };
    const response = await SavePayPalAccountDetail(formData, user.UserID);
    toast.success("PayPal Id Updated");
    console.log(response, "response");
    window.location.reload();
    // Here you can perform any other operations with the entered email
  };
  return (
    <div className="m-5 inter-font">
      {console.log(user, "user")}
      {user && user.Role === "provider" ? (
        <>
          <div className="d-flex mt-4 align-items-center justisy-content-center mx-5">
            <h5> Enter PayPal ID: </h5>
            <Input
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              //onChange={handleEmailChange}
              required
              style={{ width: "38%", marginLeft: "10px" }}
              className="mx-2"
            />
            <button className="btn btn-primary mt-0" onClick={saveEmail}>
              Save
            </button>
          </div>
          {userDetails.paypalEmail && (
            <h5 className="text-center mt-5 ">
              {" "}
              PayPal ID :{" "}
              <span className="fw-bold text-danger">
                {userDetails.paypalEmail}
              </span>
            </h5>
          )}
        </>
      ) : (
        <>
          <Input
            onChange={(e) => setPayment(e.target.value)}
            value={payment}
            type="number"
            placeholder="Enter payment amount"
          />
          <Payment totalPrice={payment} order={""} />
        </>
      )}
    </div>
  );
};

export default ProfilePayment;
