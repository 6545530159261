import React, { useEffect, useState } from "react";
import { FaPlusCircle, FaTrashAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FormGroup, Input } from "reactstrap";
import { setVideos } from "../../../redux/slices/ServiceInfo";

const ServiceVideos = () => {
  const dispatch = useDispatch();
  const serviceInfo = useSelector((state) => state.serviceInfo);
  useEffect(() => {
    if (Object.keys(serviceInfo.videos).length === 0) {
      dispatch(setVideos({ 0: "" }));
    }
  }, [serviceInfo.videos, setVideos]);

  const handleAddInputField = () => {
    const lastVidIndex = Object.keys(serviceInfo.videos).length - 1;
    const lastVid = serviceInfo.videos[lastVidIndex];
    if (!lastVid) {
      toast.error("Please fill the current URL before adding a new one");
      return;
    }
    const newVid = {
      ...serviceInfo.videos,
      [`${Object.keys(serviceInfo.videos).length}`]: "",
    };
    dispatch(setVideos(newVid));
  };

  const handleRemoveVideo = (videoId) => {
    const newVid = { ...serviceInfo.videos };
    delete newVid[videoId];
    dispatch(setVideos(newVid));
  };

  const handleVideosChange = (e, index) => {
    const { value } = e.target;
    const newVid = { ...serviceInfo.videos };
    newVid[index] = value;
    dispatch(setVideos(newVid));
  };
  if (Object.keys(serviceInfo.videos).length === 0) {
    return (
      <div>
        <h6>Elevator Pitch Video</h6>
        <span style={{ fontSize: "small", padding: "10px" }}>
          Add a YouTube URL showcasing sample work & reasons for customers to
          select you
        </span>
        <FormGroup className="d-flex">
          <Input
            type="text"
            name="videos"
            value=""
            onChange={(e) => handleVideosChange(e, 0)}
          />
          <FaPlusCircle
            style={{
              fontSize: "xx-large",
              color: "blue",
              padding: "5px",
            }}
            onClick={handleAddInputField}
          />
        </FormGroup>
      </div>
    );
  }
  return (
    <div>
      <h6>Elevator Pitch Video</h6>
      <span style={{ fontSize: "small", padding: "10px" }}>
        Add a YouTube URL showcasing sample work & reasons for customers to
        select you
      </span>
      {Object.entries(serviceInfo.videos).map(([key, value], index) => (
        <FormGroup className="d-flex" key={key}>
          <Input
            type="text"
            name="videos"
            value={value}
            onChange={(e) => handleVideosChange(e, key)} // Handle change event
          />
          <FaTrashAlt
            style={{ fontSize: "xx-large", padding: "5px" }}
            onClick={() => handleRemoveVideo(key)}
          />
          {parseInt(key) === Object.keys(serviceInfo.videos).length - 1 && (
            <FaPlusCircle
              style={{
                fontSize: "xx-large",
                color: "blue",
                padding: "5px",
              }}
              onClick={handleAddInputField}
            />
          )}
        </FormGroup>
      ))}
    </div>
  );
};

export default ServiceVideos;
