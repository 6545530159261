import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "../DashboardCalenderPages/Calender.css";
import moment from "moment";
import axiosClient from "../../axios-client";
import { useSelector } from "react-redux";

const CustomerAvaliableCalander = ({
  reserveDate,
  startTime,
  setReserveDate,
  setStartTime,
  availableDays,
  ownerId,
}) => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [blockedMeetings, setBlockedMeetings] = useState([]);

  const initializeReserveDate = (date) => {
    const parsedDate = new Date(date);
    return isNaN(parsedDate.getTime()) ? new Date() : parsedDate;
  };

  const [internalReserveDate, setInternalReserveDate] = useState(() =>
    initializeReserveDate(reserveDate)
  );

  const handleDateChange = (date) => {
    setInternalReserveDate(date);
    setReserveDate(moment(date).format("YYYY-MM-DD")); // Format reserveDate as YYYY-MM-DD
  };

  const handleHourChange = (hour, minute) => {
    setStartTime(
      `${hour.toString().padStart(2, "0")}:${minute
        .toString()
        .padStart(2, "0")}`
    );
  };

  const preprocessBlockedMeetings = (blockedMeetings) => {
    const blockedTimeRanges = [];
    blockedMeetings.forEach((meeting) => {
      const { reserve_date, start_time, end_time } = meeting;

      if (!start_time) return;

      const meetingStartMoment = moment(`${reserve_date} ${start_time}`);
      const meetingEndMoment = end_time
        ? moment(`${reserve_date} ${end_time}`)
        : moment(meetingStartMoment).add(30, "minutes"); // Default end time if not provided

      blockedTimeRanges.push({
        start: meetingStartMoment,
        end: meetingEndMoment,
      });
    });

    return blockedTimeRanges;
  };

  const isSlotBlocked = (slotTime, blockedTimeRanges) => {
    return blockedTimeRanges.some((range) => {
      return (
        slotTime.isSameOrAfter(range.start) && slotTime.isBefore(range.end)
      );
    });
  };

  useEffect(() => {
    setStartTime("");
  }, [reserveDate]);

  const generateHourSlots = () => {
    const slots = [];
    const now = moment(); // Current time in local timezone
    const selectedDate = moment(internalReserveDate); // Selected date in local timezone
    const isToday = now.isSame(selectedDate, "day");
    const blockedTimeRanges = preprocessBlockedMeetings(blockedMeetings);

    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 60; j += 30) {
        let formattedHour;
        let period;

        if (i < 12) {
          formattedHour = i === 0 ? "12" : `${i}`;
          period = "AM";
        } else {
          formattedHour = i === 12 ? "12" : `${i - 12}`;
          period = "PM";
        }

        let startendblocked = "";
        const isSelected =
          parseInt(startTime.split(":")[0]) === i &&
          parseInt(startTime.split(":")[1]) === j;

        // Create slotTime in local time zone
        const slotTime = moment(selectedDate).set({
          hour: i,
          minute: j,
          second: 0,
          millisecond: 0,
        });

        const isAvailable = availableDays?.some((available) => {
          const days = available?.days;
          const [startHour, startMinute] = available?.from
            .split(":")
            .map(Number);
          const [endHour, endMinute] = available?.to.split(":").map(Number);
          const currentDay = selectedDate.format("dddd");

          if (days.includes(currentDay)) {
            const slotMinutes = i * 60 + j;
            const startTimeMinutes = startHour * 60 + startMinute;
            const endTimeMinutes = endHour * 60 + endMinute;

            if (
              slotMinutes >= startTimeMinutes &&
              slotMinutes < endTimeMinutes
            ) {
              startendblocked = "";
              return true;
            } else if (slotMinutes === endTimeMinutes && endMinute === 0) {
              startendblocked = "";
              return true;
            } else {
              startendblocked = "blocked";
            }
          }
          return false;
        });

        const isCompleted = isToday && slotTime.isBefore(now);

        const isBlocked = isSlotBlocked(slotTime, blockedTimeRanges);

        slots.push(
          <div
            key={`${i}:${j}`}
            className={`${startendblocked} hour-slot ${
              isSelected ? "selected" : ""
            } ${isAvailable && !isCompleted && !isBlocked ? "available" : ""} ${
              i === now.hour() && j === 0 ? "focus" : ""
            }`}
            onClick={() =>
              isAvailable &&
              !isCompleted &&
              !isBlocked &&
              handleHourChange(i, j)
            }
          >
            {formattedHour.padStart(2, "0")}:{j.toString().padStart(2, "0")}{" "}
            {period}
          </div>
        );
      }
    }
    return slots;
  };

  useEffect(() => {
    const fetchBlockedMeetings = async () => {
      try {
        const response = await axiosClient.get(
          `order/BlockCalanderDetailMsg/${ownerId}`
        );
        const data = response.data.Data;

        // Filter only today's and future blocked meetings
        const now = moment(); // Current time in local timezone
        const today = moment().startOf("day"); // Start of today

        const todayAndFutureBlockedMeetings = data.filter((meeting) => {
          const meetingDate = moment(meeting.reserve_date);
          if (meetingDate.isBefore(today, "day")) return false; // Skip past dates

          const meetingStartMoment = moment(
            `${meeting.reserve_date} ${meeting.start_time}`
          );
          return meetingStartMoment.isSameOrAfter(now); // Include only future meetings
        });

        setBlockedMeetings(todayAndFutureBlockedMeetings);
      } catch (error) {
        console.error(error);
      }
    };

    fetchBlockedMeetings();
  }, [ownerId]);

  const tileClassName = ({ date, view }) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Set current date to midnight for comparison
    if (view === "month" && date.getTime() === internalReserveDate.getTime()) {
      return "selected-date";
    }
    return date < currentDate ? "completed-date" : null;
  };

  var date = new Date();
  date.setDate(date.getDate() - 1);

  return (
    <div className="google-calendar">
      <div className="calendar-container">
        <div className="date-picker">
          <Calendar
            onChange={handleDateChange}
            value={internalReserveDate}
            tileClassName={tileClassName}
            minDate={date}
          />
        </div>
        <div className="time-slot">
          <div className="hour-slots">{generateHourSlots()}</div>
        </div>
        <div></div>
      </div>
      <div className="d-flex justify-content-end mx-5">
        <div className="d-flex my-3" style={{ gap: "5px", fontSize: "10px" }}>
          <div
            style={{
              padding: "5px",
              backgroundColor: "#25cae7",
              borderRadius: "50%",
              height: "20px",
              width: "20px",
            }}
          ></div>{" "}
          Available
          <div
            style={{
              padding: "5px",
              backgroundColor: "#d4d4d4",
              borderRadius: "50%",
              height: "20px",
              width: "20px",
            }}
          ></div>
          Not available
          <div
            style={{
              padding: "5px",
              backgroundColor: "#47d240",
              borderRadius: "50%",
              height: "20px",
              width: "20px",
            }}
          ></div>
          Selected Hour
        </div>
      </div>
    </div>
  );
};

export default CustomerAvaliableCalander;
