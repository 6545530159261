import React, { useState, useEffect } from "react";
import { Row, Col, Input, Form, FormGroup } from "reactstrap";
import { FaPlusCircle, FaTrashAlt } from "react-icons/fa";
import Select from "react-select";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setAvailableFrom } from "../../../redux/slices/RequestFormSlice";

const CustmorAvailability = React.memo(() => {
  const formData = useSelector((state) => state.requestForm);
  const dispatch = useDispatch();

  const initialTimeSlots = Array.isArray(formData.availableFrom)
    ? formData.availableFrom
    : [{ days: [], from: "", to: "" }];
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const [timeSlots, setTimeSlots] = useState(initialTimeSlots);
  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const handleAddSlot = () => {
    const hasEmptySlot = timeSlots.some(
      (slot) => slot.days.length === 0 || slot.from === "" || slot.to === ""
    );
    if (hasEmptySlot) {
      toast.error("Please fill the required details");
      return;
    }
    const lastSlot = timeSlots[timeSlots.length - 1];
    if (lastSlot && lastSlot.to < lastSlot.from) {
      toast.error('"To" time cannot be before "From" time.');
      return;
    }

    setTimeSlots([...timeSlots, { days: [], from: "", to: "" }]);
  };

  const handleRemoveSlot = (index) => {
    const updatedSlots = [...timeSlots];
    updatedSlots.splice(index, 1);
    setTimeSlots(updatedSlots);
  };

  const handleChange = (index, field, value) => {
    const updatedSlots = [...timeSlots];
    if (field === "days") {
      updatedSlots[index] = { ...updatedSlots[index], days: value };
    } else {
      updatedSlots[index] = { ...updatedSlots[index], [field]: value };
    }
    setTimeSlots(updatedSlots);
  };

  useEffect(() => {
    dispatch(setAvailableFrom(JSON.stringify(timeSlots)));
  }, [timeSlots]);

  useEffect(() => {
    if (formData?.availableFrom) {
      try {
        const initialTimeSlots = JSON.parse(formData?.availableFrom);
        setTimeSlots(Array.isArray(initialTimeSlots) ? initialTimeSlots : []);
      } catch (error) {
        console.error("JSON Parse Error:", error);
      }
    }
  }, []);

  function convertTo12HourFormat(time24) {
    if (!time24) {
      return "";
    }
    const [hour24, minute] = time24.split(":").map(Number);
    if (isNaN(hour24) || isNaN(minute)) {
      return "";
    }
    const period = hour24 >= 12 ? "PM" : "AM";
    const hour12 = hour24 % 12 || 12;
    return `${hour12.toString().padStart(2, "0")}:${minute
      .toString()
      .padStart(2, "0")} ${period}`;
  }
  return (
    <Col>
      <Form>
        <Row className="form-container p-4 mt-2 mb-4 mx-0">
          <h6 className="pb-3">
            Select when you are available to receive calls from vendors
          </h6>
          <Row
            style={{
              display: "flex",
              justifyContent: "space-around",
              margin: "2px",
              //gap: "5px",
            }}
          >
            <Col sm={3} xs={12} md={3} lg={3} className="d-none d-lg-block">
              Days<b className="text-danger">*</b>
            </Col>
            <Col sm={3} xs={12} md={3} lg={3} className="d-none d-lg-block">
              From<b className="text-danger">*</b>
            </Col>
            <Col sm={3} xs={12} md={3} lg={3} className="d-none d-lg-block">
              To<b className="text-danger">*</b>
            </Col>
            <Col
              sm={3}
              xs={12}
              md={3}
              lg={3}
              className="d-none d-lg-block"
            ></Col>
          </Row>
          {timeSlots.map((slot, index) => (
            <Row
              key={index}
              style={{
                display: "flex",
                justifyContent: "space-around",
                margin: "2px",
                paddingTop: "10px",
                //gap:"5px"
              }}
            >
              <Col sm={3} xs={12} md={3} lg={3}>
                <p className="d-block d-lg-none">
                  Days <b className="text-danger">*</b>
                </p>
                {index === timeSlots.length - 1 ? (
                  <Select
                    isMulti={true}
                    options={weekdays.map((day) => ({
                      value: day,
                      label: day,
                    }))}
                    value={slot.days.map((day) => ({
                      value: day,
                      label: day,
                    }))}
                    onChange={(selectedOptions) =>
                      handleChange(
                        index,
                        "days",
                        selectedOptions
                          ? selectedOptions.map((option) => option.value)
                          : []
                      )
                    }
                    menuIsOpen={menuIsOpen}
                    onFocus={() => setMenuIsOpen(true)} // Open the menu when focused
                    onBlur={() => setMenuIsOpen(false)}
                  />
                ) : (
                  <span style={{ fontWeight: "bold" }}>
                    {slot.days.join(", ")}
                  </span>
                )}
              </Col>
              <Col sm={3} xs={12} md={3} lg={3}>
                <p className="d-block d-lg-none">
                  From <b className="text-danger">*</b>
                </p>
                {index === timeSlots.length - 1 ? (
                  <Input
                    type="time"
                    value={slot.from}
                    onChange={(e) =>
                      handleChange(index, "from", e.target.value)
                    }
                    style={{ width: "100%" }}
                  />
                ) : (
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    {convertTo12HourFormat(slot.from)}
                  </span>
                )}
              </Col>
              <Col sm={3} xs={12} md={3} lg={3}>
                <p className="d-block d-lg-none">
                  To <b className="text-danger">*</b>
                </p>
                {index === timeSlots.length - 1 ? (
                  <Input
                    type="time"
                    value={slot.to}
                    onChange={(e) => handleChange(index, "to", e.target.value)}
                    style={{ width: "100%" }}
                  />
                ) : (
                  <span style={{ fontWeight: "bold" }}>
                    {convertTo12HourFormat(slot.to)}
                  </span>
                )}
              </Col>
              <Col sm={3} xs={12} md={3} lg={3} className="p-1">
                {index === timeSlots.length - 1 && (
                  <span
                    className="wc_add-timeslot d-flex align-items-center justify-content-center"
                    onClick={handleAddSlot}
                  >
                    Click to Add timeslot <FaPlusCircle />
                  </span>
                )}
                {index !== timeSlots.length - 1 && (
                  <span
                    onClick={() => handleRemoveSlot(index)}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <FaTrashAlt />
                  </span>
                )}
              </Col>
            </Row>
          ))}
        </Row>
      </Form>
    </Col>
  );
});

export default CustmorAvailability;
