import React, { useState } from "react";
import ProductCard from "./ProductCard";

const SmallScreenProducts = ({ data, itemsPerPage, tvScreen }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageData = data.slice(startIndex, endIndex);

  return (
    <>
      <div className="general__card">
        {currentPageData.map((item, index) => (
          <div className="general__box text-center" key={index}>
            {!tvScreen && (
              <p
                className="text-secondary text-wrap mt-2 border-primary"
                style={{ fontSize: "13px", width: "auto", height: "30px" }}
              >
                Type of Ad:{" "}
                {item.itemName
                  ? item.itemName
                  : item.subCategoryName
                  ? item.subCategoryName
                  : item.categoryName
                  ? item.categoryName
                  : ""}
              </p>
            )}
            <ProductCard item={item} />
          </div>
        ))}
      </div>
      <div className="pagination">
        <button onClick={handlePrevPage} disabled={currentPage === 1}>
          Previous
        </button>
        <span>{currentPage}</span>
        <button onClick={handleNextPage} disabled={endIndex >= data.length}>
          Next
        </button>
      </div>
    </>
  );
};

export default SmallScreenProducts;
