import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import UnReadMsgs from "./UnReadMsgs";

const MyRecordings = ({ rec, isLoading, FadeLoader }) => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const filterParents = rec.filter((rec) => rec.parentId === 0);
  return (
    <div>
      {isLoading ? (
        <FadeLoader // FadeLoader component
          css={{ margin: "0 auto" }}
          color={"#36D7B7"}
          loading={isLoading}
          className="position-absolute translate-middle"
          style={{ left: "60%", top: "40%" }}
        />
      ) : (
        <UnReadMsgs allMessages={filterParents} />
      )}
    </div>
  );
};

export default MyRecordings;
