import React from "react";
import { Col, Row } from "reactstrap";
import Sidebar from "../../SideBar";

const DashboardFreeGifts = () => {
  return (
    <section className="home-after-login bg-white">
      <div className="container">
        <Row>
          <Col sm={3}>
            <Sidebar />
          </Col>
          <Col sm={9}>dwqdqe</Col>
        </Row>
      </div>
    </section>
  );
};

export default DashboardFreeGifts;
