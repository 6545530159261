import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { setGift } from "../../../redux/slices/GiftSlice";
import { setFreeService } from "../../../redux/slices/FreeServiceSlice";

const ServiceGifts = ({ selectedOption, setSelectedOption }) => {
  const dispatch = useDispatch();
  const gift = useSelector((state) => state.gift);
  const service = useSelector((state) => state.freeService);

  const handleAmount = (e) => {
    const value = e.target.value.replace(/[^\d\.]/g, "");
    if (value.length <= 5) {
      dispatch(
        setGift({
          ...gift,
          giftAmount: parseFloat(value),
        })
      );
    }
  };

  return (
    <Form className="free-gifts">
      <Row>
        <h6 className="my-2">Free Gift / Sample Service</h6>
        <FormGroup tag="fieldset">
          <FormGroup check inline>
            <Label check>
              <Input
                type="radio"
                name="option"
                value="giftAmount"
                checked={selectedOption === "giftAmount"}
                onChange={(e) => setSelectedOption(e.target.value)}
              />
              Gift Amount
            </Label>
          </FormGroup>
          <FormGroup check inline>
            <Label check>
              <Input
                type="radio"
                name="option"
                value="freeService"
                checked={selectedOption === "freeService"}
                onChange={(e) => setSelectedOption(e.target.value)}
              />
              Free Service
            </Label>
          </FormGroup>
        </FormGroup>
      </Row>
      {selectedOption === "giftAmount" && (
        <div>
          <Row>
            <Label>Gift Amount</Label>
            <Col sm="6" xs="12">
              <FormGroup>
                <Input
                  type="text"
                  placeholder="$00.00"
                  name="giftAmount"
                  // className="w-25"
                  value={gift.giftAmount ? `$${gift.giftAmount}` : null}
                  onChange={(e) => handleAmount(e)}
                />
              </FormGroup>
            </Col>
            <Col sm="6" xs="12">
              <FormGroup>
                <Input
                  type="text"
                  placeholder="Enter Title..."
                  name="giftTitle"
                  value={gift.giftTitle || null}
                  onChange={(e) => {
                    dispatch(
                      setGift({
                        ...gift,
                        giftTitle: e.target.value,
                      })
                    );
                  }}
                />
              </FormGroup>
            </Col>
          </Row>

          <FormGroup>
            <Input
              type="textarea"
              placeholder="Enter Descreption..."
              name="giftDescription"
              value={gift.giftDescription || null}
              onChange={(e) => {
                dispatch(
                  setGift({
                    ...gift,
                    giftDescription: e.target.value,
                  })
                );
              }}
            />
          </FormGroup>
        </div>
      )}
      {selectedOption === "freeService" && (
        <Row>
          <Label>Free Service</Label>
          <FormGroup>
            <Input
              type="text"
              placeholder="Enter Title..."
              name="serviceTitle"
              value={service.freeServiceTitle || null}
              onChange={(e) => {
                dispatch(
                  setFreeService({
                    ...service,
                    freeServiceTitle: e.target.value,
                  })
                );
              }}
            />
          </FormGroup>

          <FormGroup>
            <Input
              type="textarea"
              placeholder="Enter Descreption..."
              name="serviceDescription"
              value={service.freeServiceDescription || null}
              onChange={(e) => {
                dispatch(
                  setFreeService({
                    ...service,
                    freeServiceDescription: e.target.value,
                  })
                );
              }}
            />
          </FormGroup>
        </Row>
      )}
    </Form>
  );
};

export default ServiceGifts;
