import React, { useState, useEffect } from "react";
import { Label } from "reactstrap";

const LcaCardQuestions = ({ ad }) => {
  const answers = ad?.categoryAnswer ? JSON.parse(ad.categoryAnswer) : {};
  console.log(answers, "answers");
  console.log(ad, "answers");
  return (
    <div className="p-3">
      <h6 className="text-center fw-bold ">
        Questions Regarding This Request{" "}
      </h6>
      {Object.keys(answers).length > 0 ? (
        <div className="m-4" style={{ textAlign: "left" }}>
          {answers.map((ans, index) => (
            <div key={index} className="mb-1 ">
              <Label className="text-secondary fw-bold m-0">
                {index + 1} . {ans.question}
              </Label>
              <p className="text-primary" style={{ fontSize: "small" }}>
                <b>Response: {ans.answer}.</b>
              </p>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center">No Data</div>
      )}
    </div>
  );
};

export default LcaCardQuestions;
