import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { useLocation } from "react-router-dom";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import YouTube from "react-youtube";
import { useSelector } from "react-redux";

const BwvVideo = () => {
  const location = useLocation();
  const selectedCard = useSelector((state) => state.bwvData.selectedService);
  let videos = {};
  try {
    if (selectedCard?.Video) {
      videos =
        typeof selectedCard?.Video === "string"
          ? JSON.parse(selectedCard?.Video)
          : selectedCard?.Video;
    }
  } catch (error) {
    console.error("Failed to parse videos:", error);
  }
  console.log(videos, "videosfromservice");

  const hasVideos = Object.keys(videos).length > 0;
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(null);

  const openLightbox = (index) => {
    setSelectedVideoIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setSelectedVideoIndex(null);
  };

  const goToNextVideo = () => {
    setSelectedVideoIndex(
      (prevIndex) => (prevIndex + 1) % Object.keys(videos).length
    );
  };

  const goToPreviousVideo = () => {
    setSelectedVideoIndex(
      (prevIndex) =>
        (prevIndex - 1 + Object.keys(videos).length) %
        Object.keys(videos).length
    );
  };

  const getVideoId = (url) => {
    if (!url || typeof url !== "string") return "";
    const lastIndex = url.lastIndexOf("/");
    return lastIndex !== -1 ? url.substring(lastIndex + 1) : "";
  };

  return (
    <div>
      <div className="pb-3">
        <h6 style={{ fontWeight: "bold" }}>Listing Video</h6>
        <Row className="services mt-4">
          {hasVideos ? (
            <div className="wc-youtube">
              <YouTube
                opts={{
                  width: "100%",
                  height: "auto",
                }}
                videoId={getVideoId(videos[Object.keys(videos)[0]])}
                containerClassName="video-container"
              />
            </div>
          ) : (
            <p>No videos available</p>
          )}
        </Row>
      </div>
      <div className="pb-3">
        <h6 className="pb-3" style={{ fontWeight: "bold" }}>
          More Videos
        </h6>
        {hasVideos ? (
          <Row
            className="flex-nowrap overflow-auto p-1 gap-10 border rounded"
            style={{ position: "relative" }}
          >
            {Object.keys(videos).map((videoKey, index) => (
              <Col sm={4} xs={12} key={index}>
                <a onClick={() => openLightbox(index)}>
                  <YouTube
                    opts={{ width: "100%", height: "auto" }}
                    videoId={getVideoId(videos[videoKey])}
                    containerClassName="video-container"
                  />
                  <>{videos[videoKey]}</>
                </a>
              </Col>
            ))}

            {lightboxOpen && (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "10px",
                    transform: "translateY(-50%)",
                    zIndex: 1,
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                  onClick={goToPreviousVideo}
                >
                  <FaChevronLeft style={{ color: "#fff", fontSize: 24 }} />
                </button>

                {selectedVideoIndex !== null && (
                  <>
                    {" "}
                    <YouTube
                      opts={{ width: "80%", height: "80%" }}
                      videoId={getVideoId(
                        videos[Object.keys(videos)[selectedVideoIndex]]
                      )}
                    />
                    <>{videos[Object.keys(videos)[selectedVideoIndex]]}</>
                  </>
                )}

                <button
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                    zIndex: 1,
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                  onClick={goToNextVideo}
                >
                  <FaChevronRight style={{ color: "#fff", fontSize: 24 }} />
                </button>
                <div
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  onClick={closeLightbox}
                />
              </div>
            )}
          </Row>
        ) : (
          <p>No videos available</p>
        )}
      </div>
    </div>
  );
};

export default BwvVideo;
