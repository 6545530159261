import React, { useState } from "react";
import { useSelector } from "react-redux";
import BwvList from "../WeddingVendors/BwvList";
import { addFavourite } from "../Data";

const UserServices = ({ services }) => {
  const islogin = useSelector((state) => state.user.isLogin);
  const [likedCards, setLikedCards] = useState({});
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;

  function getMinPackagePrice(packageData) {
    const packages = Object.values(packageData);
    const prices = packages.map((userpackage) =>
      parseFloat(userpackage.price.trim())
    );
    const minPrice = Math.min(...prices);
    return minPrice;
  }
  const handleFavourite = async (e, card) => {
    e.stopPropagation();
    const formData = {
      userId: user.UserID,
      postId: card.Id,
      postType: "Service",
    };
    await addFavourite(formData);
    console.log(formData, "formData");
    setLikedCards((prevLikedCards) => ({
      ...prevLikedCards,
      [card.Id]: !prevLikedCards[card.Id], // Toggle liked state for the clicked card
    }));
  };

  return (
    <>
      {services && services.length > 0 ? (
        <BwvList
          currentCards={services}
          islogin={islogin}
          handleFavourite={handleFavourite}
          likedCards={likedCards}
          getMinPackagePrice={getMinPackagePrice}
        />
      ) : (
        <p className="text-center m-5">No services</p>
      )}
    </>
  );
};

export default UserServices;
