import React from "react";

const LcaCardsEvents = ({ ad }) => {
  console.log(ad, "lcaeventsAdsD");
  const eventDetails = ad?.eventDetails ? JSON.parse(ad.eventDetails) : [];
  function convertTo12HourFormat(time24) {
    if (!time24) {
      return "";
    }
    const [hour24, minute] = time24.split(":").map(Number);
    if (isNaN(hour24) || isNaN(minute)) {
      return "";
    }
    const period = hour24 >= 12 ? "PM" : "AM";
    const hour12 = (hour24 % 12 || 12).toString().padStart(2, "0"); // Added padStart
    return `${hour12}:${minute.toString().padStart(2, "0")} ${period}`;
  }
  function convertDate(dateStr) {
    if (!dateStr) {
      return ""; // Return a placeholder or indication for an empty date
    }
    let date = new Date(dateStr);
    let monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let formattedDate = `${
      monthNames[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`;

    return formattedDate;
  }
  console.log(eventDetails, "eventDetails");

  const filteredEvents = eventDetails.filter(
    (event) =>
      event.eventDate ||
      event.eventLocation ||
      event.eventTime ||
      event.eventType ||
      event.totalGuestCount
  );
  return (
    <div className="lca-cards-events">
      {filteredEvents.length > 0 ? (
        <div className="my-3 m-3 table-responsive">
          <table className="w-100 table table-light border border-light">
            <thead className="">
              <tr>
                <th>S.No.</th>
                <th>Event Date</th>
                <th>Event Time</th>
                <th>Event Type</th>
                <th>Event Location</th>
                <th>Guest Count</th>
              </tr>
            </thead>
            <tbody className="">
              {filteredEvents &&
                filteredEvents.map((event, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{convertDate(event.eventDate)}</td>{" "}
                    <td>{convertTo12HourFormat(event.eventTime)}</td>
                    <td>{event.eventType}</td> <td>{event.eventLocation}</td>
                    <td>{event.totalGuestCount}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="text-center my-3">No Events</div>
      )}
    </div>
  );
};

export default LcaCardsEvents;
