import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  selectedService: null,
  likedCards: [],
  listOptions: [],
};

const BwvDataSlice = createSlice({
  name: "serviceDetails",
  initialState,
  reducers: {
    resetVendorDetailsState: (state) => initialState,
    setSelectedService: (state, action) => {
      state.selectedService = action.payload;
    },
    setLikedCards: (state, action) => {
      state.likedCards = action.payload;
    },
    setListOptions: (state, action) => {
      state.listOptions = action.payload;
    },
  },
});

export const {
  resetVendorDetailsState,
  setLikedCards,
  setSelectedService,
  setListOptions,
} = BwvDataSlice.actions;
export default BwvDataSlice.reducer;
