import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaCaretDown, FaSlidersH } from "react-icons/fa";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import PlaceHolder from "../../../assets/images/Placeholder.png";
import { FadeLoader } from "react-spinners";
import MsgModal from "../modals/MsgModal";
import MakeOfferModal from "../modals/MakeOfferModal";
import axios, { all } from "axios";
import {
	Row,
	Col,
	Card,
	CardBody,
	CardTitle,
	CardText,
	Button,
	Container,
} from "reactstrap";
import LcaCardQuestions from "./LcaCardsQuestions";
import LcaCardsEvents from "./LcaCardsEvents";
import "./LcaCards.css"; // Import your custom CSS file
import moment from "moment-timezone";
import "moment-timezone/builds/moment-timezone-with-data";
import { myOffers } from "../Data2";
import LcaCardButtons from "./LcaCardButtons";
import { fetchBWV, fetchUserDetails } from "../Data";
import CarouselComponent from "./BizzList";

const LcaCards = ({ allProducts, scrollPopupVisible }) => {
	const location = useLocation();
	const islogin = useSelector((state) => state.user.isLogin);
	const userdata = useSelector((state) => state.user);
	const userinfo = useSelector((state) => state.user.userInfo);
	const user = islogin ? JSON.parse(userinfo) : null;
	const [isMessage, setIsMessage] = useState(false);
	const [isMsgModalOpen, setIsMsgModalOpen] = useState(false);
	const [isOfferModalOpen, setIsOfferModalOpen] = useState(false);
	const [selectedAd, setSelectedAd] = useState(null);
	const [schedule, setSchedule] = useState(false);
	const navigate = useNavigate();
	const [questionsVisible, setQuestionsVisible] = useState({});
	const [eventsVisible, setEventsVisible] = useState({});
	const [resp, setResp] = useState("");
	const [imageIndex, setImageIndex] = useState({});
	const [offerData, setOfferData] = useState(null);
	const { lcaLoading } = useSelector((state) => state.lcAds);
	const [carouselData, setCarouselData] = useState({
		row1: [],
		row2: [],
		row3: [],
	});
	const { activeTab, finalProducts, Lca, bwv, finalBwv, selectedSubCategory } =
		useSelector((state) => state.lcAds);
	const shuffleArray = (array) => {
		let shuffled = array.slice();
		for (let i = shuffled.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
		}
		return shuffled;
	};
	const fetchData = async () => {
		const row1 = [];
		const row2 = [];
		const row3 = [];
		bwv.forEach((vendor) => {
			if (vendor.bizzValue === 1) {
				row1.push(vendor);
			} else if (vendor.bizzValue === 2) {
				row2.push(vendor);
			} else if (vendor.bizzValue === 3) {
				row3.push(vendor);
			}
		});
		const shuffledRow1 = shuffleArray(row1);
		const shuffledRow2 = shuffleArray(row2);
		const shuffledRow3 = shuffleArray(row3);
		setCarouselData({
			row1: shuffledRow1,
			row2: shuffledRow2,
			row3: shuffledRow3,
		});
	};
	console.log(carouselData, "shuffledRow1");
	useEffect(() => {
		if (islogin) {
			fetchMyOffers();
		}
	}, []); // Only run on component mount
	useEffect(() => {
		fetchData();
	}, [bwv]);

	const handleCardClick = (selectedCard) => {
		const screenWidth = window.innerWidth;
		const isIpadPro = screenWidth === 1024; // Check for iPad Pro screen width

		if (!isMsgModalOpen && !isOfferModalOpen) {
			if (
				selectedCard.engagedProfile === 1 ||
				selectedCard.engagedCouple === 1
			) {
				if (islogin) {
					console.log(isIpadPro, "isIpadPro");
					console.log(screenWidth, "isIpadPro");
					if (isIpadPro) {
						navigate(`/engagedCouple/${selectedCard.createdBy}`);
					} else {
						window.open(`#/engagedCouple/${selectedCard.createdBy}`, "_blank");
					}
				} else {
					window.open("#/login", "_blank");
					toast.info("Please Login");
				}
			} else {
				if (islogin) {
					console.log(isIpadPro, "isIpadPro");
					console.log(screenWidth, "isIpadPro");
					if (isIpadPro) {
						navigate(`/requestDetails/${selectedCard.Id}`);
					} else {
						window.open(`#/requestDetails/${selectedCard.Id}`, "_blank");
					}
				} else {
					navigate("/login");
					toast.info("Please Login");
				}
			}
		}
	};

	const openOfferModal = (event, ad) => {
		event.stopPropagation(); // Stop event propagation here
		if (!islogin) {
			toast.info("Please Login");
			navigate("/login");
		} else {
			setIsMessage(false);
			setIsOfferModalOpen(true);
			setSelectedAd(ad);
		}
	};

	const toggleQuestions = (event, ad) => {
		event.stopPropagation();
		const currentQuestionsVisible = questionsVisible[ad.Id] || false;
		const currentEventsVisible = eventsVisible[ad.Id] || false;
		if (currentEventsVisible) {
			setEventsVisible({ ...eventsVisible, [ad.Id]: false });
		}
		const updatedQuestionsVisible = {};
		Object.keys(questionsVisible).forEach((cardId) => {
			updatedQuestionsVisible[cardId] =
				cardId === ad.Id ? !currentQuestionsVisible : false;
		});
		setQuestionsVisible({
			...updatedQuestionsVisible,
			[ad.Id]: !currentQuestionsVisible,
		});
	};

	const toggleEvents = (event, ad) => {
		event.stopPropagation();
		const currentQuestionsVisible = questionsVisible[ad.Id] || false;
		const currentEventsVisible = eventsVisible[ad.Id] || false;
		if (currentQuestionsVisible) {
			setQuestionsVisible({ ...questionsVisible, [ad.Id]: false });
		}
		const updatedEventsVisible = {};
		Object.keys(eventsVisible).forEach((cardId) => {
			updatedEventsVisible[cardId] =
				cardId === ad.Id ? !currentEventsVisible : false;
		});
		setEventsVisible({
			...updatedEventsVisible,
			[ad.Id]: !currentEventsVisible,
		});
	};

	const openMsgModal = (event, ad) => {
		event.stopPropagation();
		if (!islogin) {
			toast.info("Please Login");
			navigate("/login");
		} else {
			setIsMessage(true);
			setIsMsgModalOpen(true);
			setSelectedAd(ad);
		}
	};
	const closeModal = () => {
		setIsMsgModalOpen(false);
		setIsOfferModalOpen(false);
		setSelectedAd(null);
		setSchedule(false);
	};
	const fetchMyOffers = async () => {
		const type = {
			Type: "provider",
		};
		let id = user?.UserID;
		const response = await myOffers(id, type);
		setOfferData(response);
	};
	// const gotCustomer = offerData.some((offer) => offer.Id === ad.Id);
	const isAdInOfferData = (adId) => {
		const offer = offerData && offerData.some((offer) => offer.Id === adId);
		return offer;
	};

	function convertTo12HourFormat(time24) {
		if (!time24) {
			return "";
		}
		const [hour24, minute] = time24.split(":").map(Number);
		if (isNaN(hour24) || isNaN(minute)) {
			return "";
		}
		const period = hour24 >= 12 ? "PM" : "AM";
		const hour12 = (hour24 % 12 || 12).toString().padStart(2, "0"); // Added padStart
		return `${hour12}:${minute.toString().padStart(2, "0")} ${period}`;
	}

	function formatDate(inputDate) {
		let date = new Date(inputDate);
		let monthNames = [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		];
		let year = date.getFullYear();
		let month = monthNames[date.getMonth()];
		let day = date.getDate();
		let formattedDay = day < 10 ? "0" + day : day;
		let formattedDate = `${month} ${formattedDay}, ${year}`;
		return formattedDate;
	}

	function convertToUserTimezone(utcTime) {
		let timezone = "America/Los_Angeles"; // Default to system timezone
		if (islogin && user?.Timezone) {
			timezone = user?.Timezone.split(",")[0];
		}
		const localTime = moment
			.utc(utcTime)
			.tz(timezone)
			.format("YYYY-MM-DD HH:mm:ss");
		return localTime;
	}

	const handleNextImage = (event, ad) => {
		event.stopPropagation();
		const currentIndex = imageIndex[ad.Id] || 0;
		const images = JSON.parse(ad.Photo);
		const nextIndex = (currentIndex + 1) % images.length;
		setImageIndex({ ...imageIndex, [ad.Id]: nextIndex });
	};

	const handlePrevImage = (event, ad) => {
		event.stopPropagation();
		const currentIndex = imageIndex[ad.Id] || 0;
		const images = JSON.parse(ad.Photo);
		const prevIndex = (currentIndex - 1 + images.length) % images.length;
		setImageIndex({ ...imageIndex, [ad.Id]: prevIndex });
	};

	if (lcaLoading) {
		return (
			<div
				className="d-flex justify-content-center align-items-center"
				style={{ height: "40vh" }}
			>
				<FadeLoader color={"#36D7B7"} />
			</div>
		);
	}
	return (
		<Container>
			{allProducts && allProducts.length > 0 ? (
				<>
					{allProducts.map((ad, index) => (
						<div key={ad.Id}>
							<p className="mb-1" style={{ fontSize: "medium" }}>
								{ad?.FullName}
							</p>
							<Card className="shadow-sm position-realtive">
								<Row
									className="g-0"
									// style={
									//   scrollPopupVisible && index === 0
									//     ? {
									//         animation: "flashingRed 2s infinite",
									//       }
									//     : {}
									// }
								>
									<div
										className="position-absolute"
										style={{
											backgroundColor: "white",
											width: "auto",
											padding: "0 3px 3px 0",
										}}
									>
										<img
											className=""
											style={{
												width: "20px",
											}}
											src={ad?.profilePhoto ? ad?.profilePhoto : PlaceHolder}
											alt={ad?.firstName}
										/>
										{/* <p className="mt-2 me-1" style={{ fontSize: "medium" }}>
                {ad?.FullName}
              </p> */}
									</div>
									<Col lg="3" md="3" className="d-flex align-items-center ">
										<div className="position-relative">
											{ad.Photo && JSON.parse(ad.Photo).length > 1 && (
												<button
													className="arrow left-arrow position-absolute"
													style={{
														top: "50%",
														left: "3px",
														transform: "translateY(-50%)",
														backgroundColor: "rgba(255, 255, 255, 0.5)",
														border: "none",
														fontSize: "30px",
														zIndex: 1,
													}}
													onClick={(event) => handlePrevImage(event, ad)}
												>
													&#10094;
												</button>
											)}
										</div>
										<img
											className="wc_egcpl-ad img-fluid"
											src={
												ad.Photo && JSON.parse(ad.Photo).length > 0
													? JSON.parse(ad.Photo)[imageIndex[ad.Id] || 0]
													: PlaceHolder
											}
											alt={ad.Title}
											style={{
												objectFit: "fill",
												height: "220px",
												width: "220px",
											}}
										/>
										<div className="position-relative">
											{ad.Photo && JSON.parse(ad.Photo).length > 1 && (
												<button
													className="arrow right-arrow position-absolute"
													style={{
														top: "50%",
														right: "3px",
														transform: "translateY(-50%)",
														backgroundColor: "rgba(255, 255, 255, 0.5)",
														border: "none",
														fontSize: "30px",
														zIndex: 1,
													}}
													onClick={(event) => handleNextImage(event, ad)}
												>
													&#10095;
												</button>
											)}
										</div>
									</Col>
									<Col lg="7" md="7">
										<CardBody
											onClick={() => handleCardClick(ad)}
											className="lca-card-body"
										>
											<CardTitle
												tag="h5"
												className="mb-2"
												style={{
													fontSize: "large",
													overflow: "hidden",
													textOverflow: "ellipsis",
													whiteSpace: "nowrap",
												}}
											>
												<b>
													{" "}
													{ad.Title.length > 100
														? `${ad.Title.substring(0, 100)}...`
														: ad.Title}
												</b>
											</CardTitle>
											<CardText
												style={{
													fontSize: "medium",
													// overflow: "hidden",
													// textOverflow: "ellipsis",
													// whiteSpace: "nowrap",
													marginLeft: 0,
												}}
											>
												{ad.Description}
											</CardText>
											<div className="d-lg-flex">
												<CardText
													className="text-nowrap mx-0"
													style={{ fontSize: "medium" }}
												>
													<FontAwesomeIcon icon={faMapMarkerAlt} />
													<span> {ad.Zipcode}</span> -{" "}
													<span className=""> {ad.placeName}</span>
												</CardText>
												{ad.categoryName && (
													<CardText className="" style={{ fontSize: "medium" }}>
														<b>Service Needed:</b>{" "}
														{ad.itemName
															? ad.itemName
															: ad.subCategoryName
															? ad.subCategoryName
															: ad.categoryName
															? ad.categoryName
															: ""}
													</CardText>
												)}
											</div>
											<div>
												<p
													className="mb-0 posting"
													style={{ fontSize: "small" }}
												>
													Posting Date/Time:{" "}
													{/* {formatDate(ad?.createdAt.split("T")[0])} &nbsp;
                      {convertTo12HourFormat(
                        ad?.createdAt.split("T")[1].split("T")[0]
                      )}
                      &nbsp; &nbsp; */}
													{formatDate(
														convertToUserTimezone(ad?.createdAt).split(" ")[0]
													)}
													&nbsp;
													{convertTo12HourFormat(
														convertToUserTimezone(ad?.createdAt).split(" ")[1]
													)}
												</p>
											</div>
											{/* <div className=" d-flex align-items-center gap-3 mt-1">
                    <img
                      className=""
                      style={{
                        width: "20px",
                      }}
                      src={ad?.profilePhoto ? ad?.profilePhoto : PlaceHolder}
                      alt={ad?.firstName}
                    />
                    <p className="mt-2 me-1" style={{ fontSize: "medium" }}>
                      {ad?.FullName}
                    </p>
                  </div> */}
										</CardBody>
									</Col>
									<Col
										lg="2"
										md="2"
										className="d-flex flex-column align-items-center justify-content-center"
									>
										{ad.engagedCouple === 1 ? (
											<div className="w-100 text-center engagedCouple">
												{isAdInOfferData(ad.Id) ? (
													<>
														<Button
															color="success"
															size="sm"
															className="w-100 mb-2"
															onClick={() => toast.info("Twilo")}
														>
															Call Me
														</Button>
														<Button
															color="primary"
															size="sm"
															className="w-100 mb-2"
															onClick={(event) => openOfferModal(event, ad)}
														>
															Send message
														</Button>
													</>
												) : (
													<Button
														color="primary"
														size="sm"
														className="w-100 mb-2"
														onClick={(event) => openOfferModal(event, ad)}
													>
														Get Customer!
													</Button>
												)}
												<Button
													color={
														questionsVisible[ad.Id] ? "secondary" : "warning"
													}
													size="sm"
													className="w-100 mb-2"
													onClick={(event) => toggleQuestions(event, ad)}
												>
													{questionsVisible[ad.Id] ? "Hide Q&A" : "Show Q&A"}
												</Button>
												<Button
													color={eventsVisible[ad.Id] ? "secondary" : "success"}
													size="sm"
													className="w-100"
													onClick={(event) => toggleEvents(event, ad)}
												>
													{eventsVisible[ad.Id]
														? "Hide Event Details"
														: "Show Event Details"}
												</Button>
											</div>
										) : (
											<div className="w-100 text-center need">
												<a
													href={`tel: 8002453610`}
													className="btn btn-success btn-sm mb-2 w-100"
												>
													Call: (800) 245-3610 <br />
													<span>Extension # {ad.dialExtension}</span>
												</a>
												<Button
													color="primary"
													size="sm"
													className="w-100"
													onClick={(event) => openMsgModal(event, ad)}
												>
													Send Message
												</Button>
											</div>
										)}
									</Col>
								</Row>

								{questionsVisible[ad.Id] && (
									<CardBody>
										<LcaCardQuestions ad={ad} />
									</CardBody>
								)}
								{eventsVisible[ad.Id] && (
									<CardBody>
										<LcaCardsEvents ad={ad} />
									</CardBody>
								)}
							</Card>
							{/* Add carousel data at specified indices */}
							{index === 2 && carouselData.row1.length > 0 && (
								<Card className="my-2 mb-0 border-white">
									<CarouselComponent items={carouselData.row1} />
								</Card>
							)}
							{index === 5 && carouselData.row2.length > 0 && (
								<Card className="my-2 mb-0 border-white">
									<CarouselComponent items={carouselData.row2} />
								</Card>
							)}
							{index === 8 && carouselData.row3.length > 0 && (
								<Card className="my-2 mb-0 border-white">
									<CarouselComponent items={carouselData.row3} />
								</Card>
							)}
						</div>
					))}
				</>
			) : (
				<div
					className="text-center py-5 text-primary"
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						margin: "50px 0px",
					}}
				>
					<p
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							margin: "10px 0px",
							fontSize: "20px",
						}}
						className="text-center py-5"
					>
						No cards to display
					</p>
				</div>
			)}
			<MakeOfferModal
				isOpen={isOfferModalOpen}
				closeModal={closeModal}
				selectedCard={selectedAd}
				setSchedule={setSchedule}
				schedule={schedule}
			/>
			<MsgModal
				isOpen={isMsgModalOpen}
				closeModal={closeModal}
				selectedCard={selectedAd}
			/>
		</Container>
	);
};

export default LcaCards;
