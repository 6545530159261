import Sidebar from "../SideBar";
import { Row, Col } from "reactstrap";
import "./DashboardPages.css";
import HourlyCalendar from "../../components/DashboardCalenderPages/CalenderDashboard";
import axios from "axios";
import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
const CalendarPage = () => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [calendarEvents, setCalendarEvents] = useState([]);
  const fetchEvents = async () => {
    try {
      const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
      const calendarId = user.Email;
      const response = await axios.get(
        `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events?key=${apiKey}`
      );

      setCalendarEvents(response.data.items);
    } catch (error) {
      console.error("Error fetching calendarEvents:", error);
    }
  };
  useEffect(() => {
    fetchEvents();
  }, []);
  return (
    <section className="home-after-login bg-white">
      <div className="container">
        <Row>
          <Col sm={3}>
            <Sidebar />
          </Col>
          <Col sm={9}>
            <HourlyCalendar calendarEvents={calendarEvents} />
          </Col>
        </Row>
      </div>
    </section>
  );
};
export default CalendarPage;
