import React, { useEffect, useState } from "react";
import { Row, Col, Button, Input } from "reactstrap";
import {
  Featured,
  fetchCategories,
  SaveBizz,
  statusUpdateService,
} from "../../../components/Header/Data";
import { useNavigate } from "react-router-dom";
import { MdEdit, MdDelete } from "react-icons/md";
import { VscKebabVertical } from "react-icons/vsc";
import Placeholder from "../../../assets/images/Placeholder.png";
import "./Services.css";
import { FaTrashAlt } from "react-icons/fa";
import FreatureModal from "./FeatureModal";
import { useDispatch } from "react-redux";
import { setFeatureService } from "../../../redux/slices/PaymentSlice";
const ActiveServices = ({ activeServices, user, fetchData, isLoading }) => {
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [openMenuId, setOpenMenuId] = useState(null);
  const [featureModalOpen, setFeatureModalOpen] = useState(false);
  const [rows, setRows] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fetchCatData = async () => {
    const categoriesData = await fetchCategories();
    setCategories(categoriesData);
  };
  const onEdit = async (service) => {
    navigate("/create-service", { state: service });
  };
  const onDelete = async (service) => {
    const reqData = {
      Status: "2",
      createdBy: user.UserID,
    };
    await statusUpdateService(reqData, service.Id);
    setOpenMenuId(null);
    fetchData();
  };

  const onFeature = async (service) => {
    setOpenMenuId(null);
    if (service.featuredListing === 0) {
      setFeatureModalOpen(true);
      dispatch(setFeatureService(service));
    } else {
      const reqData = {
        featuredListing: 0,
        updatedBy: user.UserID,
      };
      await Featured(reqData, service.Id);
      fetchData();
    }
  };
  useEffect(() => {
    fetchCatData();
  }, []);

  useEffect(() => {
    if (categories.length > 0 && activeServices.length > 0) {
      const categoryIds = activeServices.map((service) => service.categoryId);
      const filtered = categories.filter((category) =>
        categoryIds.includes(category.Id.toString())
      );
      setFilteredCategories(filtered);
    }
  }, [categories, activeServices]);
  const toggleMenu = (adId, event) => {
    event.stopPropagation();
    setOpenMenuId(openMenuId === adId ? null : adId);
    if (openMenuId === adId) {
      document.addEventListener("click", handleDocumentClick);
    } else {
      document.removeEventListener("click", handleDocumentClick);
    }
  };

  const handleDocumentClick = (event) => {
    if (event.target.closest(".service-menu") === null) {
      setOpenMenuId(null);
    }
  };
  function getMinPackagePrice(packageData) {
    const packages = Object.values(packageData);
    const prices = packages.map((userpackage) =>
      parseFloat(userpackage.price.trim())
    );
    const minPrice = Math.min(...prices);
    return minPrice;
  }

  const handleRow = async (value, service) => {
    const bizzValue = value;

    setRows((prevRows) => ({
      ...prevRows,
      [service.Id]: bizzValue,
    }));
    const formData = {
      serviceId: service.Id,
      bizzValue: bizzValue,
    };

    await SaveBizz(formData);
    console.log(formData, "formData");
  };

  useEffect(() => {
    const initialRows = activeServices.reduce((acc, service) => {
      acc[service.Id] = service.bizzValue || "0"; // Replace with your logic to get bizzValue
      return acc;
    }, {});
    setRows(initialRows);
    console.log(initialRows, "initialRows");
  }, [activeServices]);

  console.log(activeServices, "activeservices");
  return (
    <>
      {activeServices.length > 0 ? (
        <div
          className="service-container mt-4 pb-3"
          style={{ marginLeft: "0" }}
        >
          {activeServices.map((service) => (
            <div
              className="service rounded px-0 mb-0 mt-3"
              style={{ width: "97%" }}
            >
              <div key={service.id}>
                <Row>
                  <Col sm={3} xs={12} className="p-0 position-relative">
                    <img
                      className="mw-100 object-fit-cover border rounded mh-100 img-fluid img-thumbnail"
                      src={
                        typeof service.Photo[0] === "string"
                          ? JSON.parse(service?.Photo)[0]
                          : Placeholder
                      }
                      alt={service.listName}
                      style={{ width: "400px", height: "150px" }}
                    />
                    {service.featuredListing === 1 && (
                      <div className="featured">FEATURED</div>
                    )}
                  </Col>
                  <Col sm={9} xs={12} className="p-3 position-relative">
                    <Row className="  top-0 end-0 pe-1">
                      <Col sm={7} xs={12}>
                        <p>{service.categoryName}</p>
                      </Col>

                      <Col
                        sm={5}
                        xs={12}
                        className="postion-realtive text-end cursor-pointer"
                      >
                        <button
                          className="p-1 px-2 mx-1 bg-primary text-white rounded "
                          style={{ marginTop: "4px" }}
                          onClick={() => onEdit(service)}
                        >
                          Edit <MdEdit />
                        </button>
                        <span
                          style={{ fontSize: "20px", cursor: "pointer" }}
                          onClick={(e) => toggleMenu(service.Id, e)}
                        >
                          <span>
                            <VscKebabVertical style={{ marginTop: "2px" }} />
                          </span>
                        </span>
                        {openMenuId === service.Id && (
                          <div className="service-menu p-2 shadow">
                            {user.RoleId === 2 && (
                              <a
                                onClick={() => onFeature(service)}
                                className={`btn btn-${
                                  service.featuredListing === 0
                                    ? "primary"
                                    : "secondary"
                                } btn-sm w-100 mb-2`}
                              >
                                {service.featuredListing === 0
                                  ? "Feature Listing"
                                  : "Remove Feature"}
                              </a>
                            )}
                            <Button
                              onClick={() => onDelete(service)}
                              color="danger"
                              size="sm"
                              className="w-100 btn"
                            >
                              Delete
                              <FaTrashAlt />
                            </Button>
                          </div>
                        )}
                      </Col>
                    </Row>
                    {user.Role === "admin" && (
                      <div className="d-flex justify-content-between">
                        <h4 style={{ display: "inline" }}>
                          {service.listName}
                        </h4>
                        <select
                          style={{
                            width: "175px",
                            borderRadius: "5px",
                            marginRight: "14px",
                          }}
                          className="btn btn-success text-white text-start text-nowrap form-select"
                          value={rows[service.Id] || "0"} // Bind to the specific row value for this service
                          onChange={(e) => handleRow(e.target.value, service)}
                        >
                          <option value="0">Add to Bizz Listing</option>
                          <option value="1">Row 1</option>
                          <option value="2">Row 2</option>
                          <option value="3">Row 3</option>
                          <option value="4">Deselect</option>
                        </select>
                      </div>
                    )}
                    <h6 style={{ color: "#616bff" }}>
                      ${" "}
                      {service.hourlyPrice
                        ? service.hourlyPrice
                        : getMinPackagePrice(JSON.parse(service.Package))}
                    </h6>
                  </Col>
                </Row>
              </div>
            </div>
          ))}
          <FreatureModal
            isOpen={featureModalOpen}
            close={() => setFeatureModalOpen(false)}
          />
        </div>
      ) : (
        !isLoading && <div className="ads">No Active Services</div>
      )}
    </>
  );
};

export default ActiveServices;
