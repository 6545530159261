import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import BwvcardDetails from "./BwvCardDetails";
import { Row, Col } from "reactstrap";
import {
  fetchBWV,
  BlockCalander,
  fetchOptionsList,
  fetchOptions,
} from "../Data";
import BwvCoupons from "./BwvCoupons";
import { useDispatch, useSelector } from "react-redux";
import { FadeLoader } from "react-spinners";
import { setSelectedService } from "../../../redux/slices/BwvData";

const VendorDetails = () => {
  const dispatch = useDispatch();
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [bwv, setBwv] = useState([]);
  const { Id: cardId } = useParams();

  const selectedCard = bwv.find((card) => card.Id === parseInt(cardId));

  let videos = {};
  try {
    if (selectedCard?.Video) {
      videos =
        typeof selectedCard?.Video === "string"
          ? JSON.parse(selectedCard?.Video)
          : selectedCard?.Video;
    }
  } catch (error) {
    console.error("Failed to parse videos:", error);
  }
  const fetchData = async () => {
    const bwvData = await fetchBWV();
    const filterBwvData = bwvData.filter((vendor) => vendor.isactive === "3");
    setBwv(filterBwvData);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedCard) {
      window.scrollTo(0, 0);
      dispatch(setSelectedService(selectedCard));
    }
  }, [selectedCard]);
  return (
    <div className={`${selectedCard ? "bg-white" : ""}`}>
      <div className="container ">
        {selectedCard ? (
          <>
            {" "}
            <BwvCoupons />
            <BwvcardDetails />
          </>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "50px 0px",
              height: "80vh",
            }}
          >
            <FadeLoader color="#36d7b7" />
          </div>
        )}
      </div>
    </div>
  );
};

export default VendorDetails;
