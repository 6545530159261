import React, { useEffect, useState } from "react";
import axiosClient from "../../../axios-client";
import { FormGroup, Label, Input } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { setNotifyItemList } from "../../../redux/slices/RequestFormSlice";

const WhomToNotify = () => {
  const [allItems, setAllItems] = useState([]);
  const [itemsById, setItemsByID] = useState([]);
  const formData = useSelector((state) => state.requestForm);
  const [selectedItems, setSelectedItems] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (formData.notifyItemList && formData.notifyItemList !== "") {
      const notifyItems = JSON.parse(formData.notifyItemList);
      setSelectedItems(notifyItems);
    } else {
      setSelectedItems([]);
    }
  }, []);

  useEffect(() => {
    if (formData.subCategoryId) {
      fetchItems(formData.subCategoryId);
      //setSelectedItems([]); // Reset selected items when subcategory changes
    }
  }, [formData.subCategoryId]);

  useEffect(() => {
    setItemsByID([]);
  }, [formData.categoryId]);

  const fetchItems = async (subCatId) => {
    try {
      const response = await axiosClient.get(
        `sub-category-items/getSubcategoryItembySubcat/${subCatId}`
      );
      setAllItems(response.data.subCategoryItems);
      setItemsByID(response.data.subCategoryItems);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  const handleCheckboxChange = (item) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(item.itemId)) {
        return prevSelectedItems.filter((itemId) => itemId !== item.itemId);
      } else {
        return [...prevSelectedItems, item.itemId];
      }
    });
  };

  useEffect(() => {
    dispatch(setNotifyItemList(JSON.stringify(selectedItems)));
  }, [selectedItems]);

  return (
    <div>
      <FormGroup>
        <Label for="items">
          Select the people in which category you need to notify
        </Label>
        {itemsById.length === 0 ? (
          <div>No items available</div>
        ) : (
          itemsById.map((item) => (
            <div key={item.itemId}>
              <Input
                type="checkbox"
                id={`item-${item.itemId}`}
                checked={selectedItems.includes(item.itemId)}
                onChange={() => handleCheckboxChange(item)}
              />
              <Label for={`item-${item.itemId}`} className="ml-2">
                {item.subCatItemName}
              </Label>
            </div>
          ))
        )}
      </FormGroup>
    </div>
  );
};

export default WhomToNotify;
