import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Card, CardBody, CardImg } from "reactstrap";
import Sidebar from "../SideBar";
import { useNavigate } from "react-router-dom";
import { listFavourite } from "../../components/Header/Data";
import PlaceHolder from "../../assets/images/Placeholder.png";
import { FadeLoader } from "react-spinners";
import "./DashboardPages.css";
const MyFavourites = () => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [loading, setloading] = useState(false);
  const [list, setList] = useState([]);
  const navigate = useNavigate();
  console.log(user, "user");

  const handleCardClick = (selectedCard) => {
    let Id = selectedCard.serviceId;
    navigate(`/vendordetails/${Id}`);
  };

  const fetchData = async () => {
    setloading(true);
    const response = await listFavourite(user?.UserID);
    setList(response);
    setloading(false);
  };

  useEffect(() => {
    fetchData();
    console.log(list, "listPackage");
  }, []);

  function getMinPackagePrice(packageData) {
    console.log(packageData, "packageData");
    const packages = Object.values(packageData);
    const prices = packages.map((userpackage) =>
      parseFloat(userpackage.price.trim())
    );
    const minPrice = Math.min(...prices);
    return minPrice;
  }

  return (
    <section className="home-after-login bg-white">
      <div className="container">
        <Row>
          <Col lg={3}>
            <Sidebar />
          </Col>
          <Col lg={9} className="position-relative px-3 py-4">
            {/* <div className="text-center inter-font">
              <h4 className="fw-bold mt-3 text-center">My Favourite Vendors</h4>
            </div> */}
            {loading ? (
              <FadeLoader // FadeLoader component
                css={{ margin: "0 auto" }}
                color={"#36D7B7"}
                loading={loading}
                className="position-absolute top-50 start-50 translate-middle"
                //style={{ left: "0%", top: "40%" }}
              />
            ) : (
              <>
                {list.length > 0 ? (
                  <div className="d-flex flex-wrap gap-10 position-relative">
                    {list &&
                      list?.map((item, i) => (
                        <Card
                          key={i}
                          onClick={() => handleCardClick(item)}
                          className="myFavoutire"
                        >
                          {/* <span
                    style={{
                      position: "absolute",
                      right: "4%",
                      top: "2%",
                      zIndex: 1,
                    }}
                    //onClick={(e) => handleFavourite(e, provider)}
                  >
                    {likedCards[provider.Id] ? (
                      <FaHeart size={24} style={{ color: "red" }} />
                    ) : (
                      <FaRegHeart size={24} />
                    )}
                  </span> */}
                          {/* <span
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "2%",
                      fontWeight: "bold",
                      color: "#fff",
                    }}
                  >
                    {list.listName}
                  </span> */}
                          <div className="">
                            <div className="d-flex align-items-center justify-content-center p-0 mt-2 mb-2 ">
                              {" "}
                              {/* {provider?.Photo && provider?.Photo.length > 1 && (
                      <button
                        onClick={(e) => handlePrev(e, provider)}
                        id="arrow"
                        className="arrow left-arrow d-flex justify-content-center mt-0 mx-1"
                        style={{
                          width: "20px",
                          fontSize: "30px",
                        }}
                      >
                        &#10094;
                      </button>
                    )} */}
                              <CardImg
                                top
                                src={
                                  item.servicePhoto
                                    ? item.servicePhoto
                                    : PlaceHolder
                                }
                                alt={item.listName}
                                style={{
                                  objectFit: "cover",
                                  height: "200px",
                                  width: "250px",
                                }}
                                className="position-relative rounded"
                              />
                              {/* {provider?.Photo && provider?.Photo.length > 1 && (
                      <button
                        onClick={(e) => handleNext(e, provider)}
                        id="arrow"
                        className="arrow right-arrow d-flex justify-content-center mt-0 mx-1"
                        style={{
                          width: "20px",
                          fontSize: "30px",
                        }}
                      >
                        &#10095;
                      </button>
                    )} */}
                            </div>
                          </div>
                          <p
                            style={{
                              position: "absolute",
                              top: "45%",
                              left: "50%",
                              textWrap: "nowrap",
                              color: "black",
                              transform: "translateX(-50%)",
                            }}
                            className="bg-secondary text-white rounded-pill px-3 py-2 text-center"
                          >
                            {item?.categoryName}
                          </p>
                          <CardBody
                            style={{
                              height: "200px",
                              overflow: "hidden",
                              gap: 10,
                            }}
                          >
                            {/* <p>{provider.description}</p> */}
                            <p
                              style={{
                                fontSize: "small",
                                marginBottom: "0.5rem",
                                marginTop: 0,
                              }}
                              className="mt-2 text-nowrap"
                            >
                              {item.listName}
                            </p>
                            <h6
                              className="provider-name"
                              style={{
                                color: "black",
                                marginBottom: "0.5rem",
                                marginTop: 0,
                              }}
                            >
                              {item?.specialOffer}
                            </h6>
                            <div
                              style={{
                                position: "absolute",
                                bottom: "13px",
                                left: "0",
                                width: "100%",
                                padding: "10px 15px",
                                //backgroundColor: "rgba(255, 255, 255, 0.8)",
                              }}
                            >
                              <Row>
                                {/* <Col
                        sm={6}
                        xs={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <ReactStars
                          count={5}
                          size={22}
                          value={provider.Rating}
                          activeColor="#ffd700"
                          edit={false}
                        />
                      </Col> */}
                                <Col
                                  sm={12}
                                  xs={12}
                                  style={{
                                    color: "blue",
                                    textAlign: "right",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <h6>
                                    Price: $
                                    {item?.hourlyPrice
                                      ? item?.hourlyPrice
                                      : getMinPackagePrice(
                                          JSON.parse(item?.Package)
                                        )}
                                  </h6>
                                </Col>
                              </Row>
                            </div>
                          </CardBody>
                        </Card>
                      ))}
                  </div>
                ) : (
                  <div className="">
                    <p
                      className="position-absolute translate-middle inter-font fs-5 text-secondary"
                      style={{ left: "52%", top: "40%" }}
                    >
                      No Favorites
                    </p>
                  </div>
                )}
              </>
            )}
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default MyFavourites;
