// GoogleCalendar.js
import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "./Calender.css";
import moment from "moment";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { BlockCalander, fetchCalanderEvents } from "../Header/Data";
import { Col, Row } from "reactstrap";

const GoogleCalendar = ({
  openQuoteModal,
  selectedDate,
  selectedHour,
  setSelectedDate,
  setSelectedHour,
}) => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const selectedCard = useSelector((state) => state.bwvData.selectedService);
  const user = islogin ? JSON.parse(userinfo) : null;
  const availableDays = selectedCard?.availableDays;
  const [blockedDates, setBlockedDates] = useState([]);
  const [calendarEvents, setCalendarEvents] = useState([]);
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleHourChange = (hour) => {
    setSelectedHour(hour);
  };
  const fetchBlockedData = async () => {
    const dates = await BlockCalander(selectedCard?.createdBy);
    setBlockedDates(dates);
  };
  useEffect(() => {
    if (selectedCard) {
      fetchBlockedData();
    }
  }, [selectedCard]);
  const handleQuote = () => {
    const validAvailableDays = availableDays.filter((available) => {
      return available.days.length > 0 && available.from && available.to;
    });

    const isHourAvailable = validAvailableDays?.some((available) => {
      const days = available?.days;
      const startTime = parseInt(available.from.split(":")[0]);
      const endTime = parseInt(available.to.split(":")[0]);
      const selectedDateObj = moment(selectedDate);
      const selectedDayOfWeek = selectedDateObj.format("dddd");
      const selectedHourStart = selectedDateObj.set({
        hour: selectedHour,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      return (
        days.includes(selectedDayOfWeek) &&
        selectedHour >= startTime &&
        selectedHour <= endTime
      );
    });
    if (!isHourAvailable) {
      toast.warning("Provider Not Available");
    } else {
      openQuoteModal();
    }
  };
  const fetchEvents = async () => {
    const calendarId = user?.Email;
    const events = await fetchCalanderEvents(calendarId);
    console.log(events, "calendarEvents");
    setCalendarEvents(events);
  };
  useEffect(() => {
    fetchEvents();
  }, []);

  const currentHour = new Date().getHours();
  // const Hour = date.getHours()
  const generateHourSlots = () => {
    const slots = [];
    const now = new Date();
    const selectDate = new Date(selectedDate);
    const isToday = moment(now).isSame(selectDate, "day");
    for (let i = 0; i < 24; i++) {
      let formattedHour;
      let period;
      if (i < 12) {
        formattedHour = i === 0 ? "12" : `${i}`;
        period = "AM";
      } else {
        formattedHour = i === 12 ? "12" : `${i - 12}`;
        period = "PM";
      }
      let startendblocked = "";
      const isSelected = i === selectedHour;
      const isAvailable = availableDays?.some((available) => {
        const days = available?.days;
        const startTime = parseInt(available?.from.split(":")[0]);
        const endTime = parseInt(available?.to.split(":")[0]);

        const currentDay = moment(selectDate).format("dddd");
        if (days.includes(currentDay) && i >= startTime && i <= endTime) {
          startendblocked = ""; // Reset to empty if available for the current item
          return true; // Indicates availability for the current item
        } else {
          startendblocked = "blocked";
        }

        return false; // Indicates not available for the current item
      });
      const isBlockedByCalendarEvents =
        calendarEvents &&
        calendarEvents.some((event) => {
          const eventStartHour = moment.utc(event.start.dateTime).hour();
          const eventEndHour = moment.utc(event.end.dateTime).hour();
          return i >= eventStartHour && i < eventEndHour;
        });

      const isBlockedByBlockedDates = blockedDates.some((blockedDate) => {
        const startDate = new Date(
          `${blockedDate.reserve_date} ${blockedDate.start_time}`
        );
        const endDate = new Date(
          `${blockedDate.reserve_date} ${blockedDate.end_time}`
        );
        const currentDate = new Date(selectDate);
        currentDate.setHours(i);
        return currentDate >= startDate && currentDate <= endDate;
      });
      const isBlocked = isBlockedByBlockedDates || isBlockedByCalendarEvents;
      const slotTime = new Date(selectDate);
      slotTime.setHours(i, 0, 0, 0);
      const isCompleted = isToday && slotTime < now;

      slots.push(
        <div
          key={i}
          className={`${startendblocked} hour-slot ${
            isSelected ? "selected" : ""
          }  ${isAvailable && !isCompleted ? "available" : ""}${
            isCompleted ? "completed" : ""
          } ${i == currentHour ? "focus" : ""}`}
          onClick={() =>
            !isBlocked && !isCompleted && isAvailable && handleHourChange(i)
          } // Only allow click if not blocked
        >
          {formattedHour.padStart(2, "0")} {period}
        </div>
      );
    }
    return slots;
  };
  let initialTime = new Date();
  initialTime.setHours(15, 0, 0);
  const tileClassName = ({ date, view }) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Set current date to midnight for comparison
    if (view === "month" && date.getTime() === selectedDate?.getTime()) {
      return "selected-date";
    }
    return date < currentDate ? "completed-date" : null;
  };
  var date = new Date();
  date.setDate(date.getDate() - 1);

  return (
    <div className="google-calendar">
      <div className="calendar-container">
        <div className="date-picker">
          <Calendar
            onChange={handleDateChange}
            value={selectedDate}
            tileClassName={tileClassName}
            minDate={date}
          />
        </div>
        <div className="time-slot">
          <div className="hour-slots">{generateHourSlots()}</div>
        </div>
      </div>
      <Row className="d-flex justify-content-between align-items-center">
        <Col>
          <button className="btn btn-primary" onClick={() => handleQuote()}>
            Request In Person Appointment
          </button>
        </Col>

        <Col className="d-flex my-3" style={{ gap: "5px", fontSize: "10px" }}>
          <div
            style={{
              padding: "5px",
              backgroundColor: "#25cae7",
              borderRadius: "50%",
              height: "20px",
              width: "20px",
            }}
          ></div>{" "}
          Avaliable
          <div
            style={{
              padding: "5px",
              backgroundColor: "#d4d4d4",
              borderRadius: "50%",
              height: "20px",
              width: "20px",
            }}
          ></div>
          Not avaliable
          <div
            style={{
              padding: "5px",
              backgroundColor: "#47d240",
              borderRadius: "50%",
              height: "20px",
              width: "20px",
            }}
          ></div>
          Selected Hour
        </Col>
      </Row>
    </div>
  );
};

export default GoogleCalendar;
