import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { fetchSponsordAdList } from "../Data";
import { useNavigate } from "react-router-dom";
import Placeholder from "../../../assets/images/Placeholder.png";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
const SpAds = () => {
  const navigate = useNavigate();
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [sponsordAds, setSponsordAds] = useState([]);
  const [currentAdIndex, setCurrentAdIndex] = useState(0);
  const fetchAds = async () => {
    let roleId = { roleId: 1 };
    const ads = await fetchSponsordAdList(roleId);
    const filterAds = ads.filter((ad) => ad.Status === "1");
    setSponsordAds(filterAds);
    console.log(filterAds, "filterAds");
  };
  useEffect(() => {
    fetchAds();
    const interval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * sponsordAds.length);
      setCurrentAdIndex(randomIndex);
    }, 2200);
    return () => clearInterval(interval);
  }, []);
  const handleAdClick = (url) => {
    console.log(url, "adUrl");
    if (url) {
      window.open(url, "_blank");
    } else {
      console.error("Ad URL is not available.");
    }
  };
  const handleAdCreate = () => {
    if (islogin) {
      navigate("/ad/sponsored/new");
    } else {
      navigate("/login");
      toast.info("Please Login");
    }
  };
  return (
    <>
      <div className="row">
        <div className="text-end">
          <h6
            onClick={handleAdCreate}
            className="text-primary ad-taret-link py-3 d-inline-block"
            style={{ cursor: "pointer" }}
          >
            Create your own Sponsored ad
          </h6>
        </div>
      </div>
      <Row>
        <div className="col-lg-6">
          <div
            className="card"
            onClick={() => handleAdClick(sponsordAds?.[currentAdIndex]?.Url)}
          >
            <div className="row position-relative">
              <div className="col-lg-5">
                <img
                  className="w-100"
                  style={{ height: "150px" }}
                  src={
                    sponsordAds?.[currentAdIndex]?.Photo
                      ? sponsordAds?.[currentAdIndex]?.Photo
                      : Placeholder
                  }
                  alt="ad"
                />
              </div>
              <div className="col-lg-7 d-flex align-items-center">
                <div className="">
                  <div
                    style={{ gap: "20px" }}
                    className="d-flex align-items-center"
                  >
                    <img
                      style={{ width: "40px" }}
                      src={
                        sponsordAds?.[currentAdIndex]?.profilePhoto
                          ? sponsordAds?.[currentAdIndex]?.profilePhoto
                          : Placeholder
                      }
                      alt="user"
                      className="rounded-circle"
                    />
                    <p>
                      sponsored by{" "}
                      {sponsordAds?.[currentAdIndex]?.firstName
                        ? `${sponsordAds[currentAdIndex].firstName} ${sponsordAds[currentAdIndex].lastName}`
                        : sponsordAds?.[currentAdIndex]?.userName}
                    </p>
                  </div>
                  <h4 className="p-4">
                    {sponsordAds?.[currentAdIndex]?.Header}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div
            className="card"
            onClick={() =>
              handleAdClick(sponsordAds?.[currentAdIndex + 1]?.Url)
            }
          >
            <div className="row position-relative">
              <div className="col-lg-5">
                <img
                  className="w-100"
                  style={{ height: "150px" }}
                  src={
                    sponsordAds?.[currentAdIndex + 1]?.Photo
                      ? sponsordAds?.[currentAdIndex + 1]?.Photo
                      : Placeholder
                  }
                  alt="ad"
                />
              </div>
              <div className="col-lg-7 d-flex align-items-center">
                <div className="">
                  <div
                    style={{ gap: "20px" }}
                    className="d-flex align-items-center"
                  >
                    <img
                      style={{ width: "40px" }}
                      src={
                        sponsordAds?.[currentAdIndex + 1]?.profilePhoto
                          ? sponsordAds?.[currentAdIndex + 1]?.profilePhoto
                          : Placeholder
                      }
                      alt="user"
                      className="rounded-circle"
                    />
                    <p>
                      sponsored by{" "}
                      {sponsordAds?.[currentAdIndex + 1]?.firstName
                        ? `${sponsordAds[currentAdIndex + 1].firstName} ${
                            sponsordAds[currentAdIndex + 1].lastName
                          }`
                        : sponsordAds?.[currentAdIndex + 1]?.userName}
                    </p>
                  </div>
                  <h4 className="p-4">
                    {sponsordAds?.[currentAdIndex + 1]?.Header}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Col></Col>
      </Row>
    </>
  );
};

export default SpAds;
