import React, { useEffect, useState } from "react";
import Sidebar from "../../pages/SideBar";
import { useSelector } from "react-redux";
import CatModal from "../../components/Categories/CatModal";
import { Row, Col } from "reactstrap";
import AddNewQuestionModal from "./AddQuestionModal";
import QuestionsCards from "./QuestionsCards";
import { fetchCategories } from "../../components/Header/Data";
import { GetAllQuestions } from "../../components/Header/Data2";

const Questions = () => {
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const basepath = useSelector((state) => state.setting.basepath);
  const [categories, setCategories] = useState([]);
  const [questions, setQuestions] = useState([]);
  const fetchCat = async () => {
    const cat = await fetchCategories();
    const questionsData = await GetAllQuestions();
    setQuestions(questionsData);
    setCategories(cat);
  };
  useEffect(() => {
    fetchCat();
  }, []);

  const closeModal = () => {
    setShowModal(false);
    fetchCat();
    // setSelectedCategoryId(false);
  };
  return (
    <>
      <div className="bg-white">
        <div className="container">
          <Row>
            <Col sm={4} lg={3}>
              <Sidebar />
            </Col>
            <Col sm={8} lg={9} className="pt-4">
              <div className="text-end mb-3">
                <a
                  className="btn btn-primary all_cate_product text-right"
                  onClick={() => setShowModal(true)}
                >
                  Add New Question
                </a>
              </div>
              <QuestionsCards
                questionsList={questions}
                categories={categories}
                fetchCat={fetchCat}
                selectedCategoryId={selectedCategoryId}
                setSelectedCategoryId={setSelectedCategoryId}
              />
              <AddNewQuestionModal
                isOpen={showModal}
                closeModal={closeModal}
                categories={categories}
                fetchCat={fetchCat}
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Questions;
