import React, { useState, useEffect } from "react";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import {
  fetchSubCatByCatId,
  fetchItemsBySubCatID,
  deleteCategory,
  UpdateCategory,
  deleteSubCat,
  deleteItem,
} from "../Header/Data";
import { toast } from "react-toastify";
import UpdateCatModal from "./UpdateCatModal";
import { Row, Col } from "reactstrap";

const CategoryDetails = ({ categories, fetchCat }) => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [selectedCat, setSelectedCat] = useState(null);
  const [selectedSubCat, setSelectedSubCat] = useState(null);
  const [selectedSubCatItem, setSelectedSubCatItem] = useState(null);
  //const [openMenuForCat, setOpenMenuForCat] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [subCategoryItems, setSubCategoryItems] = useState({});
  const [items, setItems] = useState([]);
  const [updateModal, setUpdateModal] = useState(false);
  const [openedSubCategories, setOpenedSubCategories] = useState({});
  const [updateCat, setUpdateCat] = useState(null);
  const [type, setType] = useState("");

  const onCatEdit = async (e, category) => {
    e.stopPropagation();
    setType("cat");
    setUpdateCat(category);
    setUpdateModal(true);
  };
  const onCatDelete = async (e, category) => {
    e.stopPropagation();
    await deleteCategory(category.Id);
    toast.info(`${category.Name} Deleted`);
    fetchCat();
  };
  const onSubEdit = async (e, subCategory) => {
    e.stopPropagation();
    setType("subCat");
    setUpdateCat(subCategory);
    setUpdateModal(true);
  };
  const onSubDelete = async (e, subCategory) => {
    e.stopPropagation();
    let req = {
      DeletedBy: user.UserID,
    };
    await deleteSubCat(subCategory.id, req);
    toast.info(`${subCategory?.Name} Deleted`);
    fetchSubCatData();
  };
  const onItemEdit = async (e, Item) => {
    e.stopPropagation();
    setType("item");
    setUpdateCat(Item);
    setUpdateModal(true);
  };
  const onItemDelete = async (e, item) => {
    console.log(item, "Item");
    e.stopPropagation();
    let req = {
      updatedBy: user.UserID,
      Deleted: 1,
    };
    await deleteItem(item.itemId, req);
    toast.info(`${item.subCatItemName} Deleted`);
    fetchItem(selectedSubCat);
  };
  const fetchSubCatData = async (categoryId) => {
    const subCat = await fetchSubCatByCatId(categoryId);
    setSubCategories(subCat);
  };
  useEffect(() => {
    if (selectedCat) {
      fetchSubCatData(selectedCat.Id);
    }
  }, [selectedCat, categories]);

  const handleCategoryToggle = (category) => {
    if (selectedCat && selectedCat.Id === category.Id) {
      setSelectedCat(null);
    } else {
      setSelectedCat(category);
    }
  };

  const handleSubCategoryToggle = async (subCategory, subIndex) => {
    if (openedSubCategories[subCategory?.id]) {
      setOpenedSubCategories((prevOpenedSubCategories) => ({
        ...prevOpenedSubCategories,
        [subCategory.id]: false,
      }));
    } else {
      setOpenedSubCategories((prevOpenedSubCategories) => ({
        ...prevOpenedSubCategories,
        [subCategory.id]: true,
      }));
      fetchItem(subCategory);
    }
  };
  const fetchItem = async (subCategory) => {
    setSelectedSubCat(subCategory);
    const itemData = await fetchItemsBySubCatID(subCategory?.id);
    setSubCategoryItems((prevItems) => ({
      ...prevItems,
      [subCategory.id]: itemData,
    }));
  };
  const CloseUpdateModal = () => {
    setUpdateModal(false);
    // fetchItem(selectedSubCat);
  };

  return (
    <div className="categories__card category_card_position rounded inter-font">
      <ul>
        {categories.map((category, index) => (
          <li key={index}>
            <div
              className={`box d-flex rounded justify-content-around ${
                selectedCat && selectedCat.Id === category.Id
                  ? "bg-primary text-white"
                  : ""
              }`}
              onClick={() => handleCategoryToggle(category)}
            >
              <Row className="categoryitemstyle d-flex justify-content-between m-2">
                <Col sm={8} className="d-inline-block text-truncate">
                  {category.Name}
                </Col>
                <Col
                  sm={4}
                  className="d-flex justify-content-end gap-2 align-self-center text-end mt-0 align-items-center"
                >
                  <button
                    onClick={(e) => onCatEdit(e, category)}
                    className="edit px-3 my-1 btn-success"
                    style={{ fontSize: "14px" }}
                  >
                    Edit <AiFillEdit />
                  </button>
                  <button
                    onClick={(e) => onCatDelete(e, category)}
                    className="edit px-3 my-1 btn-danger"
                    style={{ fontSize: "14px" }}
                  >
                    Delete <AiFillDelete />
                  </button>
                  {selectedCat && selectedCat.Id === category.Id ? (
                    <BsCaretUpFill />
                  ) : (
                    <BsCaretDownFill />
                  )}
                </Col>
              </Row>
            </div>
            {selectedCat && selectedCat.Id === category.Id && (
              <ul>
                {subCategories.map((subCategory, subIndex) => (
                  <li key={subIndex}>
                    <div
                      className={`box d-flex rounded justify-content-around ${
                        openedSubCategories[subCategory.id]
                          ? "bg-secondary text-white"
                          : ""
                      }`}
                      onClick={() =>
                        handleSubCategoryToggle(subCategory, subIndex)
                      }
                      style={{ marginLeft: "20px" }}
                    >
                      <Row className="categoryitemstyle d-flex justify-content-between m-2">
                        <Col sm={8} className="d-inline-block text-truncate">
                          {subCategory.Name}
                        </Col>
                        <Col
                          sm={4}
                          className="d-flex justify-content-end gap-2 align-self-center text-end mt-0 align-items-center"
                        >
                          <button
                            onClick={(e) => onSubEdit(e, subCategory)}
                            className="edit px-2 my-1 btn-success"
                            style={{ fontSize: "14px" }}
                          >
                            <AiFillEdit />
                          </button>
                          <button
                            onClick={(e) => onSubDelete(e, subCategory)}
                            className="edit px-2 my-1 btn-danger"
                            style={{ fontSize: "14px" }}
                          >
                            <AiFillDelete />
                          </button>
                          {selectedSubCat &&
                          selectedSubCat.id === subCategory.id ? (
                            <BsCaretUpFill />
                          ) : (
                            <BsCaretDownFill />
                          )}
                        </Col>
                      </Row>
                    </div>
                    {openedSubCategories[subCategory.id] && (
                      <ul>
                        {subCategoryItems[subCategory.id] &&
                          subCategoryItems[subCategory.id].map(
                            (item, itemIndex) => (
                              <li key={itemIndex}>
                                <div
                                  className={`box d-flex rounded justify-content-around ${
                                    selectedSubCatItem &&
                                    selectedSubCatItem.Id === item.Id
                                      ? "bg-secondary text-white"
                                      : ""
                                  }`}
                                  style={{ marginLeft: "40px" }}
                                >
                                  <Row className="categoryitemstyle d-flex justify-content-between m-2">
                                    <Col
                                      sm={8}
                                      className="d-inline-block text-truncate"
                                    >
                                      {item.subCatItemName}
                                    </Col>
                                    <Col
                                      sm={4}
                                      className="d-flex justify-content-end gap-2 align-self-center text-end mt-0 align-items-center"
                                    >
                                      {" "}
                                      <button
                                        onClick={(e) => onItemEdit(e, item)}
                                        className="edit px-2 my-1 btn-success"
                                        style={{ fontSize: "14px" }}
                                      >
                                        <AiFillEdit />
                                      </button>
                                      <button
                                        onClick={(e) => onItemDelete(e, item)}
                                        className="edit px-2 my-1 btn-danger"
                                        style={{ fontSize: "14px" }}
                                      >
                                        <AiFillDelete />
                                      </button>
                                    </Col>
                                  </Row>
                                </div>
                              </li>
                            )
                          )}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
        <UpdateCatModal
          isOpen={updateModal}
          closeModal={CloseUpdateModal}
          category={updateCat}
          type={type}
          fetchCat={fetchCat}
        />
      </ul>
    </div>
  );
};

export default CategoryDetails;
