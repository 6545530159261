import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormGroup, Input, Label } from "reactstrap";
import {
  setCategoryAnswer,
  setUpdatedBy,
} from "../../../redux/slices/RequestFormSlice";

const NeedQuestions = ({ catQuestions, editMode }) => {
  const [answers, setAnswers] = useState([]);
  const formData = useSelector((state) => state.requestForm);
  const dispatch = useDispatch();
  useEffect(() => {
    if (Object.keys(editMode).length > 0 && editMode.categoryAnswer !== "") {
      setAnswers(JSON.parse(editMode.categoryAnswer));
    }
  }, [editMode]);
  const handleAnswerChange = (question, answer) => {
    const updatedAnswers = answers.map((qa) => {
      if (qa.questionId === question.id) {
        return { ...qa, answer };
      }
      return qa;
    });
    const answerExists = updatedAnswers.some(
      (qa) => qa.questionId === question.id
    );
    if (!answerExists) {
      updatedAnswers.push({
        questionId: question.id,
        answer,
        question: question.Question,
      });
    }
    setAnswers(updatedAnswers);
    dispatch(setCategoryAnswer(JSON.stringify(updatedAnswers)));
  };

  return (
    <div>
      <h5 className="pb-3">Questions Regarding This Request</h5>
      {catQuestions.length > 0 ? (
        catQuestions.map((quest, index) => (
          <div className="mb-3 form-check" key={quest.id}>
            <FormGroup>
              <Label for={`answer-${quest.id}`} className="form-check-label">
                {index + 1}. {quest.Question}
              </Label>
              <Input
                type="text"
                name="answer"
                maxLength={200}
                // placeholder="Enter Answer"
                id={`answer-${quest.id}`}
                onChange={(e) => handleAnswerChange(quest, e.target.value)}
                value={
                  answers.find((qa) => qa.questionId === quest.id)?.answer || ""
                }
                // required
                className="form-control"
              />
            </FormGroup>
          </div>
        ))
      ) : (
        <div className="mb-3 form-check mt-3">No Questions</div>
      )}
    </div>
  );
};

export default NeedQuestions;
