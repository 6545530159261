import React, { useState, useEffect } from "react";
import Helmet from "../components/Helmet/Helmet";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import "../styles/authentications.css";
import axiosClient from "../axios-client";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { userAction } from "../redux/slices/userSlice";
import { toast } from "react-toastify";
import { getWishcount } from "../redux/slices/settingSlice";
import { LoginSocialFacebook, LoginSocialGoogle } from "reactjs-social-login";
import { FaFacebook, FaGoogle } from "react-icons/fa";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import Select from "react-select";
import LoginImage from "../assets/images/login-img/login_couple.png";
import { countryCodes } from "../components/CountryCodes";
import { fetchBWV } from "../components/Header/Data";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { ForgotPw } from "../components/Header/Data2";
<script src="https://accounts.google.com/gsi/client"></script>;
// import jwt from "jsonwebtoken";

const Login = ({ open, setOpen }) => {
  const totalAmount = useSelector((state) => state.cart.totalAmount);
  const islogin = useSelector((state) => state.user.islogin);
  const dispatch = useDispatch();
  const [responseData, setResponseData] = useState({});
  const [smData, setSmData] = useState({});
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [forgot, setForgot] = useState(false);
  const [showOTPForm, setShowOTPForm] = useState(false);
  const [step, setStep] = useState(1);
  const [mobile, setMobile] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("+1");
  const [receivedOtp, setReceivedOtp] = useState("");
  const [loginError, setLoginError] = useState(false);
  const navigate = useNavigate();
  const [user, setUser] = React.useState(null);
  // const [jwtData, setJwtData] = useState(null);
  const [decodedJwt, setDecodedJwt] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const countryOptions = countryCodes.map((country) => ({
    label: `${country.name} (${country.dial_code}) `,
    value: country.dial_code,
  }));

  useEffect(() => {
    // Reset to step 1 if open is 1, regardless of the current step
    if (open === 1) {
      setStep(1);
      setOpen(0);
      setEmail("");
      setPassword("");
      setSelectedCountry("+1");
      setMobile("");
    }
  }, [open, step, setOpen]);

  const handleSuccess = (response) => {
    console.log("Google login successful", response);
    // setJwtData(response.credential);
    let token = response.credential;
    try {
      if (token) {
        const [headerBase64, payloadBase64, signature] = token.split(".");
        const decodedPayload = JSON.parse(window.atob(payloadBase64));
        smLogin(decodedPayload);
        console.log(decodedPayload, "user details");
      } else {
        console.error("Token is null or undefined");
      }
    } catch (error) {
      console.error("Error decoding JWT:", error.message);
    }
  };
  const smLogin = async (decodedPayload) => {
    console.log(decodedPayload, "email");
    try {
      const response = await axiosClient.post("auth/login", {
        email: decodedPayload.email,
        password: null,
        ssologin: true,
      });
      if (response.data.statusCode === 200) {
        setSmData(response.data);
        setEmail(response.data.user.Email);
        setStep(2);
      } else {
        // Instead of logging an error, call the signup API here
        await callSignupApi(decodedPayload);
      }
    } catch (error) {
      // console.error("Error during login:", error);
      // toast.error("user not exist please register first", {
      //   theme: "colored",
      // });
      smRegister(decodedPayload);
    }
  };
  const smRegister = async (decodedPayload) => {
    console.log(decodedPayload, "decodedPayload");
    const base64Url = btoa(decodedPayload.picture);
    const decodedUrl = atob(base64Url);
    console.log(decodedPayload.picture === decodedUrl);
    try {
      const response = await axiosClient.post("auth/register", {
        Email: decodedPayload.email,
        ssologin: true,
        RoleID: 2,
        UserName: decodedPayload.email,
        Photo: base64Url,
      });
      console.log(response, "response");
      console.log(base64Url, "base64Url");
      if (response.data.statusCode === 200) {
        setSmData(response.data);
        setStep(2);
      } else if (response.data.statusCode === 404) {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error during login:", error);
      toast.error("Error during login", {
        theme: "colored",
      });
    }
  };
  const callSignupApi = async (decodedPayload) => {
    try {
      const signupResponse = await axiosClient.post("auth/register", {
        UserName: decodedPayload.name,
        Email: decodedPayload.email,
        CountryCode: "",
        Phone: "",
        Password: "string",
        RoleID: 2,
        isVerified: 0,
      });
      console.log(signupResponse);

      if (signupResponse.data.statusCode === 200) {
        // Successful signup, you can handle it accordingly
        setStep(2); // or any other logic
      } else {
        toast.error(signupResponse.message, { theme: "colored" });
        console.log("Signup failed");
      }
    } catch (error) {
      console.error("Error during signup:", error);
      toast.error("Error during signup");
    }
  };
  const handleError = () => {
    toast.error("cont login");
  };
  const ForgotLink = async () => {
    await ForgotPw(email);
    toast.success("Reset link send to email");
  };
  const handleLoginSubmit = (e) => {
    e.preventDefault();
    if (forgot) {
      ForgotLink();
    } else {
      submitForm();
    }
  };
  const handleOtpSent = async (e) => {
    e.preventDefault();
    if (isLoading) return;
    setIsLoading(true);
    if (mobile && selectedCountry && mobile.length === 10) {
      console.log(mobile, selectedCountry, email);
      try {
        const userData = {
          email: email,
          Phone: mobile,
          country_code: selectedCountry,
        };
        await axiosClient
          .post("otp/SendOtp", userData)
          .then(function (response) {
            if (response.data.statusCode == 200) {
              setReceivedOtp(response.data.data.otp);
              toast.success(`Code sent to Phone ${mobile}`);
              setStep(3);
            } else {
              toast.error(response.msg, { theme: "colored" });
            }
          });
      } catch (error) {
        console.error("Error during OTP sending:", error);
        toast.error("Error during OTP sending");
      }
    } else if (mobile.length !== 0) {
      toast.error("Enter valid mobile number");
    } else {
      toast.error("Select the country code");
    }
    setIsLoading(false);
    // setStep(3);
  };
  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    const socialMedia = smData.token && smData.user && smData.user.Role;
    if (e.target.value.length == 4) {
      if (e.target.value == receivedOtp) {
        if (socialMedia) {
          console.log(smData);
          localStorage.setItem("ACCESS_TOKEN", smData.token);
          localStorage.setItem("isLogin", true);
          localStorage.setItem("USER_DATA", JSON.stringify(smData.user));
          localStorage.setItem("USER_ROLE", smData?.user.Role);
          dispatch(getWishcount());
          if (smData.Role == "admin") {
            // navigate("/admin", { replace: true });
            navigate("/dashboardpage", { replace: true });
            window.location.reload();
          } else {
            navigate("/home", { replace: true });
            window.location.reload();
          }
        } else if (responseData && responseData.user) {
          localStorage.setItem("ACCESS_TOKEN", responseData.token);
          localStorage.setItem("isLogin", true);
          localStorage.setItem("USER_DATA", JSON.stringify(responseData.user));
          localStorage.setItem("USER_ROLE", responseData.user.Role);
          dispatch(getWishcount());
          if (responseData.user.Role == "admin") {
            navigate("/dashboardpage", { replace: true });
            window.location.reload();
          } else {
            navigate("/dashboardpage", { replace: true });
            window.location.reload();
          }
        } else {
          console.log("No valid user data found");
        }
        toast.success("Login Successfully");
      } else {
        console.log("OTP not matched");
      }
    }
  };
  const submitForm = async () => {
    try {
      const response = await axiosClient.post("auth/login", {
        email: email,
        password: password,
        ssologin: false,
      });
      console.log(response.data);
      if (response.data.statusCode === 200) {
        console.log(response);
        setResponseData(response.data);
        console.log(response.data.user.Role);
        setStep(2);
      } else {
        toast.error(response.message, { theme: "colored" });
      }
    } catch (error) {
      console.error("Error during login:", error);
      toast.error("Error during login");
    }
  };
  const handleFBSuccess = (response) => {
    console.log("FB login successful", response);
  };
  const handleFbFail = (error) => {
    console.log("Login Failed!", error);
    toast.error("can't login");
  };
  const handleFBProfileSuccess = (response) => {
    console.log("profile", response);
    smLogin(response);
  };
  return (
    <Helmet title="Login">
      <section style={{ overflowX: "hidden" }} className="login-main">
        <div className="container login-container p-lg-5 p-3">
          <div className="login-wrapper">
            <Row className="login-feture justify-content-center">
              <Col lg={6}>
                <img className="w-100 login-img" src={LoginImage} alt="Login" />
              </Col>
              <Col lg={6} className="p-lg-5 position-relative">
                <div className="">
                  {step === 1 && (
                    <form className="auth__form p-3 mb-3">
                      <div className="row w-100 m-auto">
                        <div className="col-lg-6">
                          <Row className="mt-4 m-lg-0">
                            <Col
                              className="mt-2 fb-lg-container pb-2"
                              style={{ width: "100%" }}
                            >
                              <div className="fb-styles">
                                <FaFacebook />
                                <FacebookLogin
                                  appId={
                                    process.env.REACT_APP_SSO_FACEBOOK_CLIENT_ID
                                  }
                                  onSuccess={handleFBSuccess}
                                  onFail={handleFbFail}
                                  onProfileSuccess={handleFBProfileSuccess}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div className="col-lg-6">
                          <Row className="pb-3">
                            <Col
                              style={{
                                backgroundColor: "#fff",
                                color: "#000",
                                padding: "10px",
                                borderRadius: "5px",
                                cursor: "pointer",
                                fontSize: "small",
                                textAlign: "center",
                              }}
                            >
                              <div
                                style={{
                                  borderRadius: "5px",
                                  // border: "1px solid lightgray",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <GoogleOAuthProvider
                                  clientId={
                                    process.env.REACT_APP_SSO_GOOGLE_CLIENT_ID
                                  }
                                >
                                  <div>
                                    {/* Google Login button */}
                                    <GoogleLogin
                                      onSuccess={handleSuccess}
                                      onFailure={handleError}
                                      redirectUri={
                                        process.env
                                          .REACT_APP_SSO_GOOGLE_REDIRECT_URI
                                      }
                                      responseType="code"
                                      flow="auth-code"
                                      isSignedIn={true}
                                    >
                                      Login with Google
                                    </GoogleLogin>
                                  </div>
                                </GoogleOAuthProvider>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <Row
                        className="d-flex w-100 m-auto"
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <hr style={{ width: "100%", marginRight: "10px" }} />
                        <span className="inst_socl-login">or Login</span>
                      </Row>

                      <FormGroup className="form__group">
                        {/* <Label>Email address</Label> */}
                        <Input
                          className="form-control"
                          required
                          type="email"
                          name="email"
                          placeholder="Enter Your Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </FormGroup>

                      {!forgot ? (
                        <FormGroup className="form__group">
                          {/* <Label>Password</Label> */}
                          <Input
                            type="password"
                            name="password"
                            required
                            placeholder="Enter Your Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </FormGroup>
                      ) : (
                        <p className="mx-2 text-danger">
                          Enter Email to Send Reset Link
                        </p>
                      )}

                      <FormGroup className="form__group d-flex justify-content-end mt-1">
                        <button
                          onClick={handleLoginSubmit}
                          className="auth__btn btn"
                        >
                          Submit
                        </button>
                      </FormGroup>
                      <Row className="text-center">
                        <Col className="m-0 p-0">
                          Not A Member ? {""}
                          <Link className="text-primary fw-bold" to="/signup">
                            {" "}
                            SignUp{" "}
                          </Link>
                        </Col>
                        <Col
                          className="text-primary fw-bold m-0 p-0"
                          onClick={() => {
                            setForgot(!forgot);
                          }}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          {!forgot ? `Forgot Password ?` : `Login`}
                        </Col>
                      </Row>
                    </form>
                  )}
                  {step === 2 && (
                    <form className="auth__form">
                      <FormGroup className="form__group">
                        <Label for="mobile" className="mb-0">
                          Mobile Number
                        </Label>
                        <div className="d-flex align-items-center login-ctr-select">
                          <Select
                            options={countryOptions}
                            placeholder="Select"
                            value={
                              selectedCountry
                                ? {
                                    label: selectedCountry,
                                    value: selectedCountry,
                                  }
                                : null
                            }
                            onChange={(selectedOption) =>
                              setSelectedCountry(selectedOption.value)
                            }
                            styles={{
                              control: (styles) => ({
                                ...styles,
                                width: "100px",
                              }),
                              option: (styles) => ({
                                ...styles,
                                color: "black",
                                fontSize: "x-small",
                                width: "150px",
                              }),
                              menu: (styles) => ({
                                ...styles,
                                width: "150px", // Adjust the width of the menu as needed
                              }),
                            }}
                          />
                          <Input
                            className="form-control"
                            required
                            type="number"
                            name="mobile"
                            id="mobile"
                            placeholder="Enter Your Mobile Number"
                            value={mobile}
                            onChange={(e) => {
                              if (e.target.value.length <= 10) {
                                setMobile(e.target.value);
                              }
                            }}
                            maxLength={10}
                          />
                        </div>
                      </FormGroup>

                      <FormGroup className="form__group mt-2">
                        <button
                          onClick={handleOtpSent}
                          className="auth__btn"
                          disabled={isLoading}
                        >
                          Send Verification Code
                        </button>
                      </FormGroup>
                    </form>
                  )}

                  {step === 3 && (
                    <form className="auth__form ">
                      <div className="">
                        <p
                          onClick={() => {
                            setMobile("");
                            setSelectedCountry("+1");
                            setStep(2);
                          }}
                          style={{
                            cursor: "pointer",
                            position: "absolute",
                            top: "15px",
                            right: "56px",
                            fontSize: "18px",
                            color: "#000",
                            zIndex: 999,
                          }}
                        >
                          <FaArrowLeft
                            style={{
                              marginRight: "6px",
                              marginTop: "-5px",
                            }}
                          />
                          Back
                        </p>
                      </div>
                      <FormGroup className="form__group">
                        <Label>OTP</Label>
                        <Input
                          className="form-control"
                          required
                          type="text"
                          name="UserOTP"
                          placeholder="Enter OTP"
                          // value={otp}
                          onChange={handleVerifyOtp}
                        />
                      </FormGroup>

                      {/* <FormGroup className="form__group">
                    <button onClick={handleVerifyOtp} className="auth__btn">
                      Continue
                    </button>
                  </FormGroup> */}
                      <Link onClick={handleOtpSent}>Resend Otp</Link>
                    </form>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
    </Helmet>
  );
};

export default Login;
