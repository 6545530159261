import React, { useEffect, useState } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { FaCopy } from "react-icons/fa";
import { fetchSettings } from "../../../components/Header/Data2";

const PaymentSettings = ({
  clientId,
  setClientId,
  secretKey,
  setSecretKey,
}) => {
  const fetchSet = async () => {
    const settings = await fetchSettings();
    console.log(settings, "settings");
    const filterSet = settings.filter((set) => set.setting_name === "paypal");
    console.log(filterSet, "filterSet");
    setClientId(filterSet[0]?.client_id);
    setSecretKey(filterSet[0]?.key);
  };
  useEffect(() => {
    fetchSet();
  }, []);
  const handleClientIdCopy = () => {
    navigator.clipboard.writeText(clientId);
  };

  const handleSecretKeyCopy = () => {
    navigator.clipboard.writeText(secretKey);
  };

  return (
    <Form className="Profile-Container">
      <h6
        style={{
          fontWeight: "bold",
          marginBottom: "20px",
          textAlign: "center",
        }}
      >
        PayPal Access
      </h6>
      <div>
        <FormGroup>
          <Label className="lead mb-2">Client ID: </Label>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Input
              type="text"
              style={{ width: "100%" }}
              name="clientId"
              value={clientId}
              className="input-width"
              onChange={(e) => setClientId(e.target.value)}
            />
            <Button
              onClick={handleClientIdCopy}
              color="primary"
              style={{ marginLeft: "10px", marginTop: "-2px" }}
            >
              <FaCopy />
            </Button>
          </div>
        </FormGroup>
        <FormGroup>
          <Label className="lead mb-2">Secret Key: </Label>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Input
              type="password"
              style={{ width: "100%" }}
              name="secretKey"
              value={secretKey}
              onChange={(e) => setSecretKey(e.target.value)}
              className="input-width"
            />
            <Button
              onClick={handleSecretKeyCopy}
              color="primary"
              style={{ marginLeft: "10px", marginTop: "0px" }}
            >
              <FaCopy />
            </Button>
          </div>
        </FormGroup>
      </div>
    </Form>
  );
};

export default PaymentSettings;
