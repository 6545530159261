// utils/awsS3Upload.js

import AWS from 'aws-sdk';


// Configure AWS
AWS.config.update({
  region: "eu-north-1",
  credentials: {
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  }
});

console.log(process.env.REACT_APP_AWS_ACCESS_KEY_ID, "accessKey")
console.log(process.env.REACT_APP_AWS_SECRET_ACCESS_KEY, "secreatKey")

const s3 = new AWS.S3();

const uploadToS3 = async (file,folder) => {
  const params = {
    Bucket: "indiancommunity",
    Key: folder + `${Date.now()}_${file.name}`,
    Body: file,
    //ACL: 'public-read', // Set permissions as needed
    ContentType: file.type
  };

  try {
    const data = await s3.upload(params).promise();
    console.log('Upload successful', data.Location);
    return data.Location; // Return the image URL
  } catch (error) {
    console.error('Error uploading file', error);
    throw error;
  }
};

export default uploadToS3;
