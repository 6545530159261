import { toast } from "react-toastify";
import axiosClient from "../../axios-client";

// api to add a deal
export const addDeal = async (formData) => {
  try {
    const response = await axiosClient.post("deal/addDeal", formData);
    console.log(response, "response");
    if (response.data.statusCode === 200) {
      return response.data;
    } else if (response.statusCode === 422) {
      console.log("response.data");
      return toast.info("response.data");
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    console.log("response");
    throw new Error("Error adding new deal");
  }
};
// api to update deal
export const updateDeal = async (formData, id) => {
  try {
    const response = await axiosClient.put(`deal/UpdateDeal/${id}`, formData);
    if (response.data.statusCode === 200) {
      return response.data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching all deals");
  }
};
// api to fetch all deals
export const getAllDeals = async (formData) => {
  try {
    const response = await axiosClient.put(`deal/getAllDeals`, formData);
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching all deals");
  }
};
// Api to update the deal status
export const StatusUpdateDeal = async (id, formData) => {
  try {
    const response = await axiosClient.put(
      `deal/StatusUpdateDeal/${id}`,
      formData
    );
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error updating deal status");
  }
};
// api to get coupon by service id
export const getCouponByServiceId = async (id) => {
  try {
    const response = await axiosClient.put(`deal/couponBasedOnServiceId/${id}`);
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching Coupons");
  }
};
// api to apply the coupon
export const applyCoupon = async (formData) => {
  try {
    const response = await axiosClient.post("deal/applyCoupon", formData);
    if (response.data.statusCode === 200) {
      return response.data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error Applying Deal");
  }
};
// api to recive order

export const reciveOrder = async (formData, id) => {
  try {
    const response = await axiosClient.put(
      `order/ReceiveOrder/${id}`,
      formData
    );
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error recive order");
  }
};
export const ZoomStatusUpdate = async (formData, id) => {
  try {
    const response = await axiosClient.put(
      `message/ZoomStatusUpdate/${id}`,
      formData
    );
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error updating zoomStatus");
  }
};
export const GetZoomLink = async (formData, id) => {
  try {
    const response = await axiosClient.put(
      `message/GetZoomLink/${id}`,
      formData
    );
    if (response.data.meetingResponse) {
      return response.data.meetingResponse;
    } else {
      throw new Error("Error fetching Link: Meeting response not found");
    }
  } catch (error) {
    console.error("Error in GetZoomLink function:", error);
    throw new Error("Error fetching Link");
  }
};

//api to get conversation messages by parent id

export const getConvoById = async (id) => {
  try {
    const response = await axiosClient.put(`message/MsgDetailById/${id}`);
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching messages");
  }
};

//api to get conversation messages by parent id

export const MessageSeen = async (id, request) => {
  try {
    const response = await axiosClient.put(
      `message/MsgSeenMark/${id}`,
      request
    );
    if (response.data.statusCode === 200) {
      return response.data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching messages");
  }
};
//api to invite
export const InviteProvider = async (formData) => {
  try {
    const response = await axiosClient.post("services/inviteListing", formData);
    if (response.data.statusCode === 200) {
      return response.data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error Applying Deal");
  }
};
export const AdPaymentUpdate = async (id, request) => {
  try {
    const response = await axiosClient.put(
      `ads/updateAdsPaymentStatus/${id}`,
      request
    );
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching messages");
  }
};
// api to get settings admin
export const fetchSettings = async () => {
  try {
    const response = await axiosClient.get("setting/getSettingList");
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching gurdwaras");
  }
};
// update paypal settings admin
export const updatePaypalSettings = async (formData) => {
  try {
    const response = await axiosClient.put(`setting/updateSetting/2`, formData);
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching gurdwaras");
  }
};
// share need
export const ShareNeedToMail = async (id, request) => {
  try {
    const response = await axiosClient.put(`need/ShareNeed/${id}`, request);
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching gurdwaras");
  }
};
//api to delete profile
export const DeleteUser = async (id, request) => {
  try {
    const response = await axiosClient.put(
      `setting/deleteUserProfile/${id}`,
      request
    );
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching gurdwaras");
  }
};
//api to Invite profile
export const InviteUser = async (id, request) => {
  try {
    const response = await axiosClient.put(
      `/setting/inviteProfile/${id}`,
      request
    );
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error invitation");
  }
};
//api to Schedule call
export const ScheduleCall = async (id, request) => {
  try {
    console.log(id, request, "id, request");
    const response = await axiosClient.put(
      `message/UpdateMsgZoomScheduled/${id}`,
      request
    );
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error Scheduling");
  }
};
//api to Report Customer
export const ReportCustomer = async (id) => {
  try {
    const response = await axiosClient.put(`message/ReportCustomer/${id}`);
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error Report Customer");
  }
};
//api to Report Customer
export const ReportMissedCall = async (email) => {
  try {
    const response = await axiosClient.put(
      `message/MissedCallNotification/${email}`
    );
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error Report Missed call");
  }
};
export const SavePayPalAccountDetail = async (formData, id) => {
  try {
    const response = await axiosClient.put(
      `setting/SavePayPalAccountDetail/${id}`,
      formData
    );
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error Report Missed call");
  }
};
// api to post  new question
export const AddNewCatQuestion = async (request) => {
  try {
    const response = await axiosClient.post(
      `category/addCategoryQuestion`,
      request
    );
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error Report Missed call");
  }
};

// api to get all questions
export const GetAllQuestions = async (request) => {
  try {
    const response = await axiosClient.get(
      `category/getAllCategoryQuestion`,
      request
    );
    if (response.data.statusCode === 200) {
      return response.data.categoryList;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error Report Missed call");
  }
};
// api to get questions by category id
export const GetQuestionsById = async (id) => {
  try {
    const response = await axiosClient.get(
      `category/getAllQuestionBasedonCategory/${id}`
    );
    if (response.data.statusCode === 200) {
      return response.data.categoryList;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching questions");
  }
};
// api to update question
export const UpdateQuestion = async (request, id) => {
  try {
    const response = await axiosClient.put(
      `category/updateCategoryQuestion/${id}`,
      request
    );
    if (response.data.statusCode === 200) {
      return response.data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error Report Missed call");
  }
};
// api to update Review
export const UpdateReview = async (request, id) => {
  try {
    const response = await axiosClient.put(
      `review/StatusUpdateReview/${id}`,
      request
    );
    if (response.data.statusCode === 200) {
      return response.data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error Report Missed call");
  }
};
// api to get review data by order Id
export const GetReviewDatilsByOrderId = async (id) => {
  try {
    const response = await axiosClient.get(`review/ReviewOnOrderId/${id}`);
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching order details");
  }
};
// api for forget passward
export const ForgotPw = async (mail) => {
  try {
    const response = await axiosClient.put(`setting/forgotPassword/${mail}`);
    console.log(response, "response");
    console.log(response.status, "response");
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    console.log(error, "Error Sending Link");
    throw new Error("Error Sending Link");
  }
};
// api for reset passward
export const ResetPw = async (password, id) => {
  try {
    const response = await axiosClient.put(
      `setting/resetPassword/${id}`,
      password
    );
    console.log(response, "response");
    if (response.status === 200) {
      return response;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    console.log(error, "Error Sending Link");
    throw new Error("Error Sending Link");
  }
};
// api for reset passward
export const changePw = async (password, id) => {
  try {
    const response = await axiosClient.put(
      `setting/changePassword/${id}`,
      password
    );
    if (response.status === 200) {
      return response;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    console.log(error, "Error Sending Link");
    throw new Error("Error Sending Link");
  }
};
export const myOffers = async (id, type) => {
  try {
    const response = await axiosClient.put(`need/AdminApproveNeed/${id}`, type);
    if (response.status === 200) {
      return response.data.Data;
    } else {
      throw new Error(response);
    }
  } catch (error) {
    console.log(error, "Error Sending Link");
    throw new Error("Error Sending Link");
  }
};
