import React, { useState, useEffect } from "react";
import { Row, Col, Input, Label, Modal, ModalBody, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  AddNewCat,
  fetchCategories,
  fetchSubCatByCatId,
  AddNewSubCat,
  AddNewSubCatItem,
} from "../Header/Data";

const CatModal = ({ isOpen, closeModal }) => {
  const navigate = useNavigate();
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [newCat, setNewCat] = useState("");
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [shortName, setShortName] = useState("");

  const AddNewCategory = async () => {
    if (newCat === "") {
      toast.error("Please Enter New Category Name");
      return;
    }
    const categoryExists = categories.some(
      (category) => category.Name === newCat
    );
    if (categoryExists) {
      toast.error("Category already exists.");
      return;
    }
    if (selectedCategory === null && selectedSubCategory === null) {
      const newCatData = {
        Name: newCat,
        CreatedBy: user.UserID,
        shortName: shortName,
      };
      console.log(newCatData, "newCatData");
      await AddNewCat(newCatData);
      closeModal();
      setNewCat("");
      setSelectedCategory(null);
      setSelectedSubCategory(null);
      setShortName("");
      toast.success(`${newCat} Added`);
    } else if (selectedSubCategory === null) {
      const subCategoryExists = subCategories.some(
        (category) => category.Name === newCat
      );
      if (subCategoryExists) {
        toast.error("Sub Category already exists.");
        return;
      }
      const newCatData = {
        Name: newCat,
        CategoryId: selectedCategory,
        shortName: shortName,
      };
      console.log(newCatData, "newCatData");
      await AddNewSubCat(newCatData);
      closeModal();
      setNewCat("");
      setSelectedCategory(null);
      setSelectedSubCategory(null);
      setShortName("");
      toast.success(`${newCat} Added`);
    } else {
      const newItemData = {
        itemName: newCat,
        CategoryId: parseInt(selectedCategory),
        subCategoryId: parseInt(selectedSubCategory),
        CreatedBy: user.UserID,
        shortName: shortName,
      };
      console.log(newItemData, "newItemData");
      await AddNewSubCatItem(newItemData);
      closeModal();
      setNewCat("");
      setSelectedCategory(null);
      setSelectedSubCategory(null);
      setShortName("");
      toast.success(`${newCat} Added`);
    }
    fetchCat();
    fetchSubCat(selectedCategory);
  };

  const fetchCat = async () => {
    const cat = await fetchCategories();
    setCategories(cat);
  };

  const fetchSubCat = async (categoryId) => {
    console.log(categoryId, "categoryId");
    const subCat = await fetchSubCatByCatId(categoryId);
    setSubCategories(subCat);
  };

  useEffect(() => {
    fetchCat();
    fetchSubCat(selectedCategory);
    console.log(selectedCategory, selectedSubCategory, "Both");
    // setSelectedCategory(null || "");
    // setSelectedSubCategory(null || "");
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      fetchSubCat(selectedCategory);
    }
  }, [selectedCategory]); // Fetch subcategories when selected category changes

  return (
    <Modal isOpen={isOpen} toggle={closeModal}>
      <ModalBody>
        <button
          type="button"
          className="btn btn-close position-absolute top-0 end-0 m-2"
          onClick={closeModal}
          aria-label="Close"
        ></button>
        <div className="modal-body ">
          <Label className="p-1">
            Enter New Category Name<b className="text-danger">*</b>
          </Label>
          <Input type="text" onChange={(e) => setNewCat(e.target.value)} />
          <Label className="p-1 mt-1 mb-1">Select Category</Label>
          <Input
            type="select"
            onChange={(e) => {
              setSelectedCategory(e.target.value);
              console.log(e.target.value, "e.target.value");
            }}
          >
            <option value="">Select Category</option>
            {categories.map((category, index) => (
              <option key={index} value={category.Id}>
                {category.Name}
              </option>
            ))}
          </Input>
          <Label className="p-1 mt-1 mb-1">Select Subcategory</Label>
          <Input
            type="select"
            onChange={(e) => {
              setSelectedSubCategory(e.target.value);
              console.log(e.target.value, "e.target.value");
            }}
          >
            <option value="">Select Subcategory</option>
            {subCategories.map((subcategory, index) => (
              <option key={index} value={subcategory.id}>
                {subcategory.Name}
              </option>
            ))}
          </Input>
          {/* {selectedCategory !== "" && selectedSubCategory !== "" && (
            <> */}
          {/* <Label className="p-1 mt-1 mb-1">Short Name</Label>
          <Input
            type="text"
            placeholder="add short name..."
            onChange={(e) => {
              setShortName(e.target.value);
            }}
          /> */}
          {/* </>
          )} */}
          <div className="d-flex justify-content-end mt-3">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                AddNewCategory();
              }}
            >
              Add Category
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CatModal;
