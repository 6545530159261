import React, { useEffect, useRef, useState } from "react";
import { Loader } from "@googlemaps/js-api-loader";

const Maps = ({ zipCode, setCity }) => {
  const mapContainerRef = useRef(null);
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);

  useEffect(() => {
    if (zipCode) {
      const loader = new Loader({
        apiKey: "AIzaSyDROtwqL701TVqixE8NPJAqxu6h5HIDWVE",
        libraries: ["places"],
      });
      loader.load().then(() => {
        const newMap = new window.google.maps.Map(mapContainerRef.current, {
          center: { lat: 0, lng: 0 },
          zoom: 10,
          mapTypeId: "roadmap",
          zoomControl: true, // Explicitly enable zoom controls
          zoomControlOptions: {
            position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
          },
          fullscreenControl: true, // Explicitly enable fullscreen control
          fullscreenControlOptions: {
            position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
          },
          streetViewControl: true, // Explicitly enable street view control
          streetViewControlOptions: {
            position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
          },
          mapTypeControl: true, // Explicitly disable map type control
          mapTypeControlOptions: {
            position: window.google.maps.ControlPosition.TOP_LEFT,
          },
          scaleControl: true, // Explicitly disable scale control
          scaleControlOptions: {
            position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
          },
          rotateControl: true, // Explicitly disable rotate control
          rotateControlOptions: {
            position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
          },
        });
        setMap(newMap);
        fetchCoordinates(zipCode, newMap);
      });
    }
  }, [zipCode]);
  const fetchCoordinates = async (zipCode, map) => {
    try {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: zipCode }, (results, status) => {
        if (status === "OK" && results[0]) {
          const location = results[0].geometry.location;
          const displayName = results[0].formatted_address;
          setCity(displayName);
          map.setCenter(location);
          map.setZoom(11);
          if (map) {
            map.panTo(location);
            map.setZoom(11);
          }
          if (marker) {
            marker.setMap(null);
          }
          const newMarker = new window.google.maps.Marker({
            position: location,
            map: map,
            title: `Location for Zip Code ${zipCode}`,
          });
          setMarker(newMarker);
        } else {
          console.error("No results found for ZIP Code:", zipCode);
          setCity("No data found");
        }
      });
    } catch (error) {
      console.error("Error fetching coordinates:", error);
      setCity("Error fetching location");
    }
  };

  return (
    <div
      ref={mapContainerRef}
      className="w-100"
      style={{ width: "auto", height: "220px", marginTop: "20px" }}
    />
  );
};

export default Maps;
