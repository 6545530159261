import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Col } from "reactstrap";
import { fetchOptions, fetchOptionsList } from "../Data";

const BwvServiceInfo = () => {
  const selectedCard = useSelector((state) => state.bwvData.selectedService);
  const [listOptions, setListOptions] = useState([]);
  const [optionsData, setOptionsData] = useState([]);
  const fetchOptionsData = async () => {
    const listData = await fetchOptionsList(selectedCard?.categoryId);
    setListOptions(listData);
    const options = {};
    for (const option of listData) {
      try {
        const data = await fetchOptions(option.cat_list_id);
        options[option.cat_list_id] = data;
      } catch (error) {
        console.error("Error fetching options:", error.message);
      }
    }
    setOptionsData(options);
  };
  useEffect(() => {
    fetchOptionsData();
  }, [selectedCard]);
  return (
    <>
      {listOptions.map((option, index) => (
        <Col sm={5} className="d-flex pb-3" key={index}>
          <div style={{ marginRight: "25%" }}>
            <p
              style={{ fontSize: "large", fontWeight: "bold", color: "black" }}
            >
              {option.list_name}
            </p>
            <div>
              {selectedCard?.customDetails[option.cat_list_id]?.length > 0 ? (
                selectedCard?.customDetails[option.cat_list_id].map(
                  (item, idx) => (
                    <button
                      key={idx}
                      type="button"
                      className="btn btn-outline-secondary small-button"
                    >
                      {item.option_name}
                    </button>
                  )
                )
              ) : (
                <p>N/A</p>
              )}
            </div>
          </div>
        </Col>
      ))}
    </>
  );
};

export default BwvServiceInfo;
