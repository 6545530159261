import React, { useState } from "react";
import { toast } from "react-toastify";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { reportBusiness } from "../Data";
import { useSelector } from "react-redux";

const ReportBussinessModal = ({
  isReportingModalOpen,
  handleReportBusiness,
}) => {
  const selectedCard = useSelector((state) => state.bwvData.selectedService);
  const [message, setMessage] = useState("");
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  console.log(selectedCard?.Id, "serviceId");
  console.log(user?.UserID, "userId");
  console.log(message, "Message");
  console.log(selectedCard?.listName, "serviceName");
  const handlereport = async () => {
    if (!message) {
      toast.error("Please enter your feedback");
      return;
    }
    const formData = {
      serviceId: selectedCard?.Id,
      userId: user?.UserID,
      Message: message,
      serviceName: selectedCard?.listName,
    };
    console.log(formData, "formData");
    await reportBusiness(formData);
    toast.success("Thanks for Reporting...");
    handleReportBusiness();
  };
  return (
    <>
      <Modal isOpen={isReportingModalOpen} toggle={handleReportBusiness}>
        <ModalBody>
          <div className="d-flex text-center">
            <textarea
              rows={4}
              cols={100}
              placeholder="Enter your complaint or feedback..."
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <div className="d-flex gap-2 justify-content-end">
            <Button color="primary" onClick={handlereport}>
              Submit
            </Button>
            <Button color="secondary" onClick={handleReportBusiness}>
              Cancel
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ReportBussinessModal;
