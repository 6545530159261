import React, { useState, useEffect, useRef } from "react";
import { getPaypalHistory } from "../../../components/Header/Data";
import { useLocation } from "react-router-dom";
import "./Settings.css";
import PlaceHolder from "../../../assets/images/Placeholder.png";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { Input } from "reactstrap";
import { FadeLoader } from "react-spinners";
import { toast } from "react-toastify";

const PaymentHistory = ({ userIDs }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [payments, setPayments] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filteredPayments, setFilteredPayments] = useState([]);
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortedColumn, setSortedColumn] = useState(null);
  const Id = queryParams.get("Id");
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const isAdmin = user && user.Role === "admin";
  const tableRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchPaymentHistory = async () => {
    setIsLoading(true);
    let fetchedHistory;
    if (isAdmin) {
      fetchedHistory = await getPaypalHistory(Id);
    } else {
      fetchedHistory = await getPaypalHistory(userIDs);
    }
    fetchedHistory.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
    setIsLoading(false);
    setPayments(fetchedHistory);
    setFilteredPayments(fetchedHistory); // Show all payments on page load
  };

  useEffect(() => {
    fetchPaymentHistory();
  }, []);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  // const filterPayments = () => {
  //   const filteredData = payments.filter((payment) => {
  //     const paymentDate = new Date(payment.createdAt);
  //     const startDateObj = startDate ? new Date(startDate) : null;
  //     const endDateObj = endDate ? new Date(endDate) : null;

  //     if (startDateObj && endDateObj) {
  //       // Filter payments within the date range
  //       return (
  //         paymentDate >= startDateObj &&
  //         paymentDate <= endDateObj &&
  //         (payment.paypalEmail
  //           .toLowerCase()
  //           .includes(searchQuery.toLowerCase()) ||
  //           payment.Amount.toString().includes(searchQuery.toLowerCase()) ||
  //           payment.paypalStatus
  //             .toLowerCase()
  //             .includes(searchQuery.toLowerCase()))
  //       );
  //     } else {
  //       // If no date range is selected, filter by other criteria only
  //       return (
  //         payment.paypalEmail
  //           .toLowerCase()
  //           .includes(searchQuery.toLowerCase()) ||
  //         payment.Amount.toString().includes(searchQuery.toLowerCase()) ||
  //         payment.paypalStatus.toLowerCase().includes(searchQuery.toLowerCase())
  //       );
  //     }
  //   });

  //   setFilteredPayments(filteredData);
  // };

  // useEffect(() => {
  //   filterPayments();
  // }, [payments, searchQuery, startDate, endDate]);
  const clearResults = () => {
    setSearchQuery("");
    setStartDate("");
    setEndDate("");
    setFilteredPayments(payments);
  };
  const handleSearch = () => {
    if (startDate && !endDate) {
      toast.error("Please provide an end date.");
      return;
    }
    if (!startDate && endDate) {
      toast.error("Please provide a start date.");
      return;
    }

    if (startDate && endDate && new Date(startDate) > new Date(endDate)) {
      toast.error("Start date cannot be after end date.");
      return;
    }

    const filteredData = payments.filter((payment) => {
      console.log(startDate, endDate, payment.createdAt.split("T")[0], "dates");
      const paymentDate = payment.createdAt.split("T")[0];

      if (
        (startDate &&
          endDate &&
          paymentDate >= startDate &&
          paymentDate <= endDate) || // Changed this condition
        (!startDate && !endDate)
      ) {
        // Check for search query in amount, status, and payment date only
        return (
          parseInt(payment.Amount) === parseFloat(searchQuery) ||
          payment.paypalStatus
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          formatDate(payment.createdAt).includes(searchQuery.toLowerCase())
        );
      } else {
        return false; // Exclude payments outside the date range
      }
    });

    setFilteredPayments(filteredData);
  };

  // useEffect(() => {
  //   // Set start date to createdAt date from the first payment object in payments array
  //   if (payments.length > 0) {
  //     setStartDate(new Date(payments[0].createdAt).toISOString().split("T")[0]);
  //   }
  // }, [payments]);

  const handleSort = (column) => {
    let direction = "asc";
    if (column === sortedColumn) {
      direction = sortDirection === "asc" ? "desc" : "asc";
    }
    const sortedData = [...filteredPayments];
    sortedData.sort((a, b) => {
      if (column === "Amount") {
        return direction === "asc" ? a.Amount - b.Amount : b.Amount - a.Amount;
      } else if (column === "Payment Date" || column === "Completed Date") {
        return direction === "asc"
          ? new Date(a[column]) - new Date(b[column])
          : new Date(b[column]) - new Date(a[column]);
      } else {
        return direction === "asc"
          ? a[column].localeCompare(b[column])
          : b[column].localeCompare(a[column]);
      }
    });
    setFilteredPayments(sortedData);
    setSortDirection(direction);
    setSortedColumn(column);
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;

    return `${formattedMonth}/${formattedDay}/${year}`;
  }

  useEffect(() => {
    if (!searchQuery && !startDate && !endDate) {
      setFilteredPayments(payments);
    }
  }, [searchQuery, startDate, endDate]);

  return (
    <div className="container mt-3 paymentHistory-container inter-font">
      {/* <div className=" text-end pt-0 d-flex justify-content-start">
        <div
          type="button"
          className="btn btn-danger"
          onClick={() => {
            navigate("/dashboardpage/settings");
          }}
        >
          <FaArrowLeft /> Back
        </div>
      </div> */}
      <h2>Payment History</h2>
      {/* <div className="d-flex justify-content-end mt-3 mx-0">
        No of Records:&nbsp; {filteredPayments.length}
      </div> */}
      <div className="mb-3 w-25 d-flex justify-content-between w-100 mt-5">
        <div className="d-flex justify-content-end">
          <Input
            type="search"
            style={{ width: user.RoleId === 2 ? "210px" : "275px" }}
            className="form-control mr-2"
            placeholder="search by amount and status.."
            onKeyPress={handleKeyPress}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div className="d-flex gap-2">
          <input
            type="date"
            className="form-control mr-2"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <input
            type="date"
            className="form-control mr-2 mt-0"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
          <button
            className="btn btn-primary"
            onClick={handleSearch}
            style={{ marginTop: "auto" }}
          >
            Search
          </button>
          <button
            className="btn btn-warning text-nowrap mt-0"
            onClick={clearResults}
          >
            Clear Results
          </button>
        </div>
      </div>
      <div>
        <div
          style={{
            maxHeight: "400px",
            overflowY: "scroll",
            position: "relative",
          }}
        >
          <table className="table">
            <thead>
              <tr
                className={`position-sticky top-0 text-center ${
                  user.RoleId !== 1 && "text-nowrap"
                }`}
              >
                <th>
                  <span>PayPal ID</span>{" "}
                  <FontAwesomeIcon
                    icon={faSort}
                    onClick={() => handleSort("paypalEmail")}
                  />
                </th>
                <th>
                  <span>Amount</span>{" "}
                  <FontAwesomeIcon
                    icon={faSort}
                    onClick={() => handleSort("Amount")}
                  />
                </th>
                <th>
                  <span>Status</span>{" "}
                  <FontAwesomeIcon
                    icon={faSort}
                    onClick={() => handleSort("paypalStatus")}
                  />
                </th>
                <th className={``}>
                  <span>Payment Confirmation ID: </span>{" "}
                  <FontAwesomeIcon
                    icon={faSort}
                    //onClick={() => handleSort("paypalStatus")}
                  />
                </th>
                <th>
                  <span>Payment Date</span>{" "}
                  <FontAwesomeIcon
                    icon={faSort}
                    onClick={() => handleSort("createdAt")}
                  />
                </th>
                <th>
                  <span>Completed Date</span>{" "}
                  <FontAwesomeIcon
                    icon={faSort}
                    onClick={() => handleSort("updatedAt")}
                  />
                </th>
              </tr>
            </thead>
            <tbody
              className="paymentHistory-table-container"
              ref={tableRef}
              style={{ height: "200px" }}
            >
              {isLoading ? (
                <span>
                  <FadeLoader // FadeLoader component
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      translate: "-50% -50%",
                    }}
                    color={"#36D7B7"}
                    loading={isLoading}
                    // className="position-absolute top-50 start-50 translate-middle bg-none"
                  />
                </span>
              ) : (
                <>
                  {filteredPayments.length > 0 ? (
                    <>
                      {filteredPayments.map((payment) => (
                        <tr key={payment.Id} className="text-center">
                          <td>{payment.paypalEmail}</td>
                          <td>{payment.Amount}</td>
                          <td>{payment.paypalStatus}</td>
                          <td>{payment.paypalBatchId}</td>
                          <td>
                            {payment.createdAt
                              ? formatDate(payment.createdAt?.split("T")[0])
                              : ""}
                          </td>
                          <td>
                            {payment.updatedAt
                              ? formatDate(payment.updatedAt?.split("T")[0])
                              : ""}
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <p className="text-center h4 position-absolute top-50 start-50 translate-middle">
                      No history
                    </p>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PaymentHistory;
