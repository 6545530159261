import React from "react";
import Modal from "react-modal";
// import { sendMessage, MakeAnOffer } from "../Data";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Payment from "./settingsComponents/Payment";
import PlaceHolder from "../../assets/images/Placeholder.png";

const PayPalModal = ({ isOpen, closeModal, price, adDetails }) => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  console.log(adDetails, "adDetails in modal");
  let order = {};
  return (
    <Modal
      isOpen={isOpen}
      toggle={closeModal}
      onRequestClose={closeModal}
      className="hire-modal"
      style={{
        content: {
          width: "50%",
          margin: "auto",
          backgroundColor: "#fff",
          padding: "40px",
          position: "absolute",
          top: "30px",
          left: "50%",
          transform: "translateX(-50%)",
          border: "1px solid gray",
          borderRadius: "20px",
        },
      }}
      size="lg"
    >
      <div className="d-flex justify-content-end">
        <button
          type="button"
          className="btn-close"
          onClick={closeModal}
        ></button>
      </div>
      <div className="modal-body">
        <div className="d-flex flex-row align-items-center pb-3">
          <div style={{ width: "50px", height: "50px", marginRight: "30px" }}>
            <img
              src={
                adDetails && adDetails?.Photo ? adDetails?.Photo : PlaceHolder
              }
              // alt="Selected Card"
              style={{ height: "100%", width: "100%", borderRadius: "50%" }}
            />
          </div>
          <div>
            <h5>{adDetails ? adDetails.Header : ""}</h5>
          </div>
        </div>
        <p className="p-2 text-center">
          Ad will start from{" "}
          {new Date(adDetails?.startAt).toLocaleDateString("en-US")} To{" "}
          {new Date(adDetails?.endAt).toLocaleDateString("en-US")}
        </p>
        <h5 className="p-2 text-center">
          total price you have to pay <b className="text-primary">$ {price} </b>{" "}
        </h5>

        <div className="d-flex align-items-center justify-content-center m-4">
          <Payment totalPrice={price} order={order} adDetails={adDetails} />
        </div>
      </div>
    </Modal>
  );
};

export default PayPalModal;
