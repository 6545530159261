import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { useSelector } from "react-redux";
import { fetchMyNeedsOrRequests } from "../Data";
import PlaceHolder from "../../../assets/images/Placeholder.png";
import { InviteProvider } from "../Data2";
import { toast } from "react-toastify";

const InviteModal = ({ isOpen, closeModal }) => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const selectedCard = useSelector((state) => state.bwvData.selectedService);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [myNeeds, setMyNeeds] = useState([]);
  const [selectedMyNeeds, setSelectedMyNeeds] = useState([]);
  const fetchNeeds = async () => {
    if (islogin) {
      const request = {
        createdBy: user?.UserID,
      };
      const needs = await fetchMyNeedsOrRequests(request);
      setMyNeeds(needs);
    }
  };
  useEffect(() => {
    fetchNeeds();
  }, []);

  const handleMyNeedsChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedMyNeeds([...selectedMyNeeds, value]);
    } else {
      setSelectedMyNeeds(selectedMyNeeds.filter((item) => item !== value));
    }
    console.log(selectedMyNeeds, "selectedMyNeeds");
  };
  const Invite = async () => {
    toast.success("Invite sent successfully");
    closeModal();
    setSelectedMyNeeds([]);
    const request = {
      needId: selectedMyNeeds,
      Email: "provider.wed2024@gmail.com",
    };
    const response = await InviteProvider(request);
    console.log(response, "response");
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={closeModal}
      className="hire-modal"
      size="lg"
      centered
    >
      <ModalHeader toggle={closeModal}>
        Invite this vendor to make an offer to the listing you posted
      </ModalHeader>
      <ModalBody>
        <div className="d-flex flex-row align-items-center pb-3">
          <div style={{ width: "50px", height: "50px", marginRight: "30px" }}>
            <img
              src={
                selectedCard && selectedCard?.profilePhoto
                  ? selectedCard?.profilePhoto
                  : PlaceHolder
              }
              style={{ height: "100%", width: "100%", borderRadius: "50%" }}
              alt="Selected Card"
            />
          </div>
          <div>
            <h5>{selectedCard ? selectedCard.userName : ""}</h5>
          </div>
        </div>
        <div>
          {myNeeds.map((item) => (
            <div key={item.Id} className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value={item.Id}
                id={`checkbox-${item.Id}`}
                checked={selectedMyNeeds.includes(String(item.Id))}
                onChange={handleMyNeedsChange}
              />
              <label
                className="form-check-label"
                htmlFor={`checkbox-${item.Id}`}
              >
                {item.Title}
              </label>
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-end mt-3">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => Invite()}
          >
            Invite
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default InviteModal;
