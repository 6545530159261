import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { Card, CardBody, CardImg } from "reactstrap";
import { topFiveVendorDetails } from "../../components/Header/Data";
import ReactStars from "react-rating-stars-component";
import { RibbonContainer, Ribbon } from "react-ribbons";
import GitHubForkRibbon from "react-github-fork-ribbon";
import PlaceHolder from "../../assets/images/Placeholder.png";

const RecommendedVendors = () => {
  const [list, setList] = useState([]);

  const fecthData = async () => {
    const data = await topFiveVendorDetails();
    setList(data);
  };

  useEffect(() => {
    fecthData();
  }, []);

  return (
    <div className="NewListings">
      <h3>Top 5 Vendors</h3>
      <hr />
      <Row className="flex-nowrap overflow-auto pb-2">
        {list.map((vendor, index) => (
          <Col key={index} className="mb-4" xs={12} md={3}>
            <Card
              style={{
                width: "100%",
                height: "100%",
                overflow: "hidden",
              }}
            >
              <GitHubForkRibbon color="green" position="left">
                Recommended
              </GitHubForkRibbon>
              {/* <RibbonContainer>
                <span style={{ fontSize: "15px" }}>
                  <Ribbon
                    side="left"
                    type="corner"
                    size="large"
                    backgroundColor="#00cc00"
                    color="white"
                    fontSize="30px"
                    fontFamily="sans"
                    withStripes={false}
                  >
                    Recommended
                  </Ribbon>
                </span> */}
              <CardImg
                top
                src={vendor?.Photo ? vendor.Photo : PlaceHolder}
                alt={vendor?.userName}
                style={{ objectFit: "cover", height: "200px", width: "100%" }}
              />
              <CardBody style={{ gap: 10 }}>
                <p style={{ fontSize: "small" }}>{vendor.userName}</p>
                <h5 style={{ color: "black" }}>
                  {vendor.firstName} {vendor.lastName}
                </h5>
                <Row className="pt-2">
                  <Col
                    sm={12}
                    style={{ fontSize: "small" }}
                    className="d-flex justify-content-center"
                  >
                    <ReactStars
                      count={5}
                      size={30}
                      value={Math.round(vendor.Rating)}
                      activeColor="#ffd700"
                      edit={false}
                    />
                  </Col>
                  {/* <Col sm={7} style={{ color: "blue" }} className="mt-1">
                      <h6>Price: $ </h6>
                    </Col> */}
                </Row>
              </CardBody>
              {/* </RibbonContainer> */}
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default RecommendedVendors;
