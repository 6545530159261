/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { Row, Col, Input, Label } from "reactstrap";
import { FaPlusCircle } from "react-icons/fa";
import { postOrder, sendRequestQuote } from "../Data";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const HireModal = ({
  isOpen,
  setIsModalOpen,
  selectedDate,
  setSelectedDate,
  selectedHour,
  setSelectedHour,
  closeModal,
  selectedRate,
  setSelectedRate,
}) => {
  const navigate = useNavigate();
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const selectedCard = useSelector((state) => state.bwvData.selectedService);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("hourly");
  const packages = selectedCard?.Package
    ? JSON.parse(selectedCard.Package)
    : {};
  const [images, setImages] = useState(Array(5).fill(null));
  const [form, setForm] = useState({
    deadline: "",
    message: "",
    images: [],
    budget: "",
    startTime: "",
    endTime: "",
    date: new Date(),
    service: selectedRate,
  });
  function convertTo12HourFormat(time) {
    if (!time) {
      return "";
    }
    const [hour24, minute] = time.split(":").map(Number);
    if (isNaN(hour24) || isNaN(minute)) {
      return "";
    }
    const period = hour24 >= 12 ? "PM" : "AM";
    const hour12 = (hour24 % 12 || 12).toString().padStart(2, "0"); // Added padStart
    return `${hour12}:${minute.toString().padStart(2, "0")} ${period}`;
  }
  useEffect(() => {
    let time = `${selectedHour}:00`;
    console.log(time, "time");
    const formattedHour = (parseInt(time) % 12 || 12)
      .toString()
      .padStart(2, "0");
    setForm((prevForm) => ({
      ...prevForm,
      startTime: convertTo12HourFormat(time),
    }));
    console.log(form, "form");
  }, [selectedHour]);
  useEffect(() => {
    if (selectedDate) {
      setForm({
        ...form,
        date: new Date(
          selectedDate?.getTime() - selectedDate?.getTimezoneOffset() * 60000
        )
          .toISOString()
          .split("T")[0],
      });
    }
  }, [selectedDate]);
  useEffect(() => {
    if (selectedRate) {
      console.log(selectedRate, "selectedRate location.state");
      setSelectedOption("flatService");
      setForm((prevForm) => ({
        ...prevForm,
        service: selectedRate,
      }));
    }
  }, [selectedRate]);
  const handleServiceChange = (e) => {
    const selectedService = Object.values(packages).find(
      (pkg) => pkg.serviceName === e.target.value
    );
    setSelectedRate(selectedService);
    setForm((prevForm) => ({
      ...prevForm,
      service: selectedService,
    }));
    console.log("selectedRate", form);
  };

  const resetForm = () => {
    setForm({
      ...form,
      deadline: "",
      message: "",
      images: [],
      budget: "",
      endTime: "",
      date: new Date(),
    });
    setImages(Array(5).fill(null));
  };
  const handleImageChange = (index, e) => {
    const files = e.target.files;
    if (files.length > 0) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const newImages = [...images];
        newImages[index] = e.target.result; // Base64 data URI
        setImages(newImages);
        setForm((prevInfo) => ({
          ...prevInfo,
          images: newImages.filter(Boolean), // Filter out null values
        }));
      };
      reader.readAsDataURL(files[0]);
    }
  };
  useEffect(() => {
    if (!selectedCard?.hourlyPrice) {
      setSelectedOption("flatService");
    } else if (Object.values(packages).length < 0) {
      setSelectedOption("hourly");
    }
  }, [selectedCard]);
  useEffect(() => {
    if (selectedOption === "hourly") {
      setSelectedRate(null);
    }
  }, [selectedOption]);
  const handleHire = async () => {
    console.log(isToastOpen, "isToastOpen");
    if (isToastOpen) return;
    setIsToastOpen(true);
    if (selectedOption === "hourly" && !selectedDate) {
      toast.error("Select date");

      return;
    } else if (
      selectedOption === "hourly" &&
      (!form.startTime || !form.endTime)
    ) {
      toast.error("Select Start time and end time");
      return;
    } else if (
      selectedOption === "hourly" &&
      selectedDate.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)
    ) {
      toast.error("Start date cannot be a past date");
      return;
    } else if (
      selectedOption === "hourly" &&
      new Date(`01/01/2024 ${form.endTime}`) <=
        new Date(`01/01/2024 ${form.startTime}`)
    ) {
      toast.error("End time cannot be before start time");
      return;
    } else if (selectedOption === "flatService" && !form.service) {
      toast.error("please select service");
      return;
    } else if (!form?.message) {
      toast.error("please leave a message");
      if (form?.message) setIsToastOpen(false);
      return;
    }
    setIsModalOpen(false);
    if (islogin) {
      navigate("/order/summary", {
        state: {
          isOpen,
          selectedCard,
          selectedDate,
          selectedHour,
          form,
          selectedRate,
        },
      });
    } else {
      navigate("/login");
      toast.info("Please Login");
    }
  };

  const firstServiceName = Object.values(packages)[0]?.serviceName || "";
  return (
    <Modal
      isOpen={isOpen}
      toggle={closeModal}
      onRequestClose={closeModal}
      className="hire-modal"
      style={{
        content: {
          width: "50%",
          margin: "auto",
          backgroundColor: "#fff",
          padding: "40px",
          position: "absolute",
          top: "30px",
          left: "50%",
          transform: "translateX(-50%)",
          height: "80vh",
          overflowY: "scroll",
          border: "1px solid #B6B6B4",
          borderRadius: "10px",
        },
      }}
      size="lg"
    >
      <div className="d-flex justify-content-end">
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            closeModal();
            resetForm();
          }}
        ></button>
      </div>

      <div className="modal-body">
        <h5 className="pb-3">Hire {selectedCard?.listName}</h5>
        <p className="pb-3">{selectedCard?.userName}</p>
        <div className="d-flex gap-30">
          {selectedCard?.hourlyPrice && (
            <div>
              <Input
                type="radio"
                id="hourly"
                className="mb-3"
                checked={selectedOption === "hourly"}
                onChange={() => setSelectedOption("hourly")}
              />{" "}
              <Label htmlFor="hourly" className="mr-3">
                Hourly
              </Label>
            </div>
          )}
          {Object.keys(packages).length > 0 && (
            <div>
              <Input
                type="radio"
                id="flatService"
                className="mb-3"
                checked={selectedOption === "flatService"}
                onChange={() => setSelectedOption("flatService")}
              />{" "}
              <Label htmlFor="flatService">Flat Fee services</Label>
            </div>
          )}
        </div>

        {selectedOption === "flatService" ? (
          <Row className="pb-3">
            <Col xs={12} md={6}>
              <Label>Service</Label>
              {/* <Input
                type="select"
                value={form.service?.serviceName}
                onChange={(e) => setForm({ ...form, service: e.target.value })}
              >
                <option value="">Select Service</option>
                {Object.values(packages).map((pkg, index) => (
                  <option key={index} value={pkg.serviceName}>
                    {pkg.serviceName}
                  </option>
                ))}
              </Input> */}
              <Input
                type="select"
                value={form.service?.serviceName}
                onChange={handleServiceChange} // Call handleServiceChange on change
              >
                <option value="">Select Service</option>
                {Object.values(packages).map((pkg, index) => (
                  <option key={index} value={pkg.serviceName}>
                    {pkg.serviceName}
                  </option>
                ))}
              </Input>
            </Col>
            <Col xs={6} md={6}>
              <Label>Deadline</Label>
              <Input
                type="date"
                min={new Date().toISOString().split("T")[0]}
                value={
                  selectedDate
                    ? new Date(
                        selectedDate.getTime() -
                          selectedDate.getTimezoneOffset() * 60000
                      )
                        .toISOString()
                        .split("T")[0]
                    : ""
                }
                disabled={selectedDate}
                onChange={(e) => {
                  console.log("New date selected:", e.target.value);
                  const newDate = new Date(e.target.value);
                  console.log("New JavaScript Date object:", newDate);
                  setForm({
                    ...form,
                    date: newDate.toISOString().split("T")[0],
                  });
                  setSelectedDate(newDate);
                  console.log("Updated selectedDate state:", selectedDate);
                  console.log("Updated form state:", form);
                }}
              />
            </Col>
          </Row>
        ) : (
          <Row className="pb-3">
            <Col xs={6} md={4}>
              <Label>Date</Label>
              <Input
                type="date"
                min={new Date().toISOString().split("T")[0]}
                value={
                  selectedDate
                    ? new Date(
                        selectedDate.getTime() -
                          selectedDate.getTimezoneOffset() * 60000
                      )
                        .toISOString()
                        .split("T")[0]
                    : ""
                }
                disabled
                // onChange={(e) => {
                //   const inputValue = e.target.value;
                //   if (inputValue) {
                //     // Check if the input value is not empty
                //     const newDate = new Date(inputValue);
                //     setForm({
                //       ...form,
                //       date: newDate.toISOString().split("T")[0],
                //     });
                //     setSelectedDate(newDate);
                //   } else {
                //     // Handle the case when the input field is cleared
                //     setForm({
                //       ...form,
                //       date: "",
                //     });
                //     setSelectedDate(null);
                //   }
                // }}
              />
            </Col>
            <Col xs={6} md={4}>
              <Label>Start Time</Label>
              <Input
                type="text"
                name="startTime"
                value={form.startTime}
                onChange={(e) =>
                  setForm({ ...form, startTime: e.target.value })
                }
                disabled={form.startTime}
              />
            </Col>
            <Col xs={6} md={4}>
              <Label>End Time</Label>
              <Input
                type="time"
                onChange={(e) => {
                  setForm({ ...form, endTime: e.target.value });
                  setIsToastOpen(false);
                }}
              />
            </Col>
          </Row>
        )}
        <Row className="pb-3">
          <Label>Message</Label>
          <p>Describe what you expect from the service</p>
          <Input
            type="textarea"
            // value={form.message}
            onChange={(e) => {
              setForm({ ...form, message: e.target.value });
              setIsToastOpen(false);
            }}
          />
        </Row>
        <Row className="pb-3">
          <Label>Add Images</Label>
          <p>You can add images for reference (optional)</p>
          <div className="mt-2" style={{ display: "flex", flexWrap: "wrap" }}>
            {images.map((image, index) => (
              <Col sm={2} className="image-input" key={index}>
                <Input
                  type="file"
                  id={`imageUpload${index + 1}`}
                  onChange={(e) => handleImageChange(index, e)}
                  style={{ display: "none" }}
                  name="images"
                />
                <div
                  className="image-box"
                  onClick={() =>
                    document.getElementById(`imageUpload${index + 1}`).click()
                  }
                >
                  {image && (
                    <>
                      <img
                        src={image}
                        alt={`Highlighted Image ${index + 1}`}
                        className="uploaded-image"
                        style={{ width: "100%" }}
                      />
                      {/* <FaTrashAlt
              className="delete-icon"
              onClick={() => handleDeleteImage(index)}
            /> */}
                    </>
                  )}
                  {!image && <FaPlusCircle className="upload-icon" />}
                </div>
              </Col>
            ))}
          </div>
        </Row>
        <div className="d-flex justify-content-end mt-3">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              handleHire();
            }}
          >
            Submit Hire Request
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default HireModal;
