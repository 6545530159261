import React, { useEffect, useState } from "react";
import Sidebar from "../../SideBar";
import { useSelector } from "react-redux";
import { Row, Col, Button } from "reactstrap";
import { BiPlusCircle } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { fetchSponsordAdList } from "../../../components/Header/Data";
import "../DashboardPages.css";
import ActiveAds from "./ActiveAds";
import InActiveAds from "./InActiveAds";
const SponsoredAds = () => {
  const [adsList, setAdsList] = useState([]);
  const navigate = useNavigate();
  const userinfo = useSelector((state) => state.user.userInfo);
  const islogin = useSelector((state) => state.user.isLogin);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [activeTab, setActiveTab] = useState("Active");
  const [isLoading, setIsLoading] = useState(true);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const fetchData = async () => {
    setIsLoading(true);
    if (user.RoleId === 1) {
      const req = {
        roleId: user.RoleId,
      };
      const ads = await fetchSponsordAdList(req);
      setAdsList(ads);
    } else {
      const req = {
        createdBy: user.UserID,
      };
      const ads = await fetchSponsordAdList(req);
      setAdsList(ads);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const activeAds = adsList.filter((ad) => ad.Status === "1");
  const inactiveAds = adsList.filter((ad) => ad.Status === "0");

  console.log(adsList, "adsList");
  console.log(inactiveAds, "inactiveAds");

  return (
    <section className="home-after-login bg-white">
      <div className="container">
        <Row>
          <Col sm={3}>
            <Sidebar />
          </Col>
          <Col
            sm={9}
            className="ads-container position-relative"
            style={{ padding: "15px" }}
          >
            <div className="text-end px-3 pt-3" style={{ marginTop: "7px" }}>
              <Button
                type="button"
                className="btn btn-danger text-end"
                onClick={() => {
                  navigate("/ad/sponsored/new");
                }}
              >
                <BiPlusCircle /> Add New
              </Button>
            </div>
            <div
              className="d-flex justify-space-between row m-2 mt-3 wc_ib-tabs"
              style={{ marginLeft: "2px" }}
            >
              <h5
                className={`col-sm-4 ${
                  activeTab === "Active" ? "active" : ""
                } mx-0`}
                onClick={() => handleTabChange("Active")}
                style={{
                  textDecoration: activeTab !== "Inactive " && "none",
                  cursor: "pointer",
                  width: "50%",
                  fontSize: "14px",
                }}
              >
                Approved
              </h5>
              <h5
                className={`col-sm-4 ${
                  activeTab === "Inactive" ? "active" : ""
                } mx-0`}
                onClick={() => handleTabChange("Inactive")}
                style={{
                  textDecoration: activeTab !== "Active" && "none",
                  cursor: "pointer",
                  width: "50%",
                  fontSize: "14px",
                }}
              >
                Pending
              </h5>
            </div>
            {activeTab === "Active" && (
              <ActiveAds
                activeAds={activeAds}
                fetchData={fetchData}
                adsList={adsList}
                isLoading={isLoading}
              />
            )}
            {activeTab === "Inactive" && (
              <InActiveAds
                inactiveAds={inactiveAds}
                fetchData={fetchData}
                adsList={adsList}
                isLoading={isLoading}
              />
            )}
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default SponsoredAds;
