import React, { useState } from "react";
import OrderInfo from "./OrderInfo";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Order.css"; // Additional custom CSS if needed

const OrderCard = ({ orders }) => {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isMsgModalOpen, setIsMsgModalOpen] = useState(false);

  const openMsgModal = (order) => {
    setSelectedOrder(order);
    setIsMsgModalOpen(true);
  };

  const closeMsgModal = () => {
    setIsMsgModalOpen(false);
  };

  return (
    <div className="container mt-4">
      <div className="row">
        {orders.map((order) => (
          <div
            key={order.id}
            className="col-lg-4 col-md-6 mb-4"
            onClick={() => openMsgModal(order)}
          >
            <div className="card h-100">
              <img
                src={
                  order.profilePhoto ||
                  "https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
                }
                className="card-img-top"
                alt={order.userName}
              />
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center ">
                  <h5 className="card-title">{order.userName}</h5>
                  <p
                    className={`badge ${
                      order.orderStatus.toLowerCase() === "pending"
                        ? "badge-warning"
                        : order.orderStatus.toLowerCase() === "completed"
                        ? "badge-success"
                        : "badge-danger"
                    } p-2 mb-0`}
                  >
                    {order.orderStatus}
                  </p>
                </div>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    <b>Created At:</b>{" "}
                    {new Date(order.createdAt).toLocaleDateString()}
                  </li>
                  <li className="list-group-item">
                    <b>Total Amount:</b> $
                    {parseFloat(order.totalAmount).toFixed(2)}
                  </li>
                  <li className="list-group-item">
                    <b>Discount:</b>{" "}
                    {order.Discount
                      ? `$${parseFloat(order.Discount).toFixed(2)}`
                      : "0:00"}
                  </li>
                  <li className="list-group-item">
                    <b>Paid Amount:</b>{" "}
                    {order.paidAmount
                      ? `$${parseFloat(order.paidAmount).toFixed(2)}`
                      : `$${parseFloat(
                          order.totalAmount - order.Discount
                        ).toFixed(2)}`}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
      {selectedOrder && (
        <OrderInfo
          order={selectedOrder}
          isOpen={isMsgModalOpen}
          closeModal={closeMsgModal}
        />
      )}
    </div>
  );
};

export default OrderCard;
