import React, { useState } from "react";
import { Row, Col, Form, Input, Label, FormGroup } from "reactstrap";
import { FaArrowLeft, FaPlusCircle } from "react-icons/fa";
import { toast } from "react-toastify";
import uploadToS3 from "../../../utils/awsS3Upload";
import PlaceHolder from "../../../assets/images/Placeholder.png";
const DealForm = ({ dealForm, setDealForm, myServices }) => {
  const handleChange = async (e) => {
    console.log(dealForm, "dealForm");
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? (checked ? 1 : 0) : value;
    // if (name === "startDate" && dealForm.endDate && value >= dealForm.endDate) {
    //   toast.info("From Date should be before To Date.");
    //   return;
    // }
    // if (
    //   name === "endDate" &&
    //   dealForm.startDate &&
    //   value <= dealForm.startDate
    // ) {
    //   toast.info("To Date should be after From Date.");
    //   return;
    // }
    // if (name === "startDate" && new Date(value) <= new Date()) {
    //   toast.error("Start date cannot be before today");
    //   return;
    // }
    setDealForm({
      ...dealForm,
      [name]: newValue,
    });
    if (type === "file") {
      const file = e.target.files[0];
      let folder = "deal/";
      const imageUrl = await uploadToS3(file, folder);
      console.log(imageUrl, "imageUrl");
      // const reader = new FileReader();
      // reader.onloadend = () => {
      setDealForm({
        ...dealForm,
        Image: imageUrl,
      });
      // };
      // reader.readAsDataURL(file);
    }
  };

  return (
    <div className="deals-form">
      <Form>
        <Row>
          <Col lg={6}>
            {" "}
            <h5 className="pb-3" style={{ textDecoration: "none" }}>
              Deal Information
            </h5>
          </Col>
          <Col lg={6} className="text-end">
            <FormGroup>
              <Input
                type="checkbox"
                name="homeDisplay"
                value={dealForm.homeDisplay}
                onChange={handleChange}
                checked={dealForm.homeDisplay === 1 ? true : false} // Conditionally set checked attribute
              />{" "}
              <Label>Display on Home Page</Label>
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Label>
            Select Your Listing <b className="text-danger">*</b>
          </Label>
          <Input
            type="select"
            name="serviceList"
            value={dealForm.serviceList}
            onChange={handleChange}
          >
            <option value="">Select Service</option>
            {myServices.map((service) => (
              <option key={service.Id} value={service.Id}>
                {service.listName}
              </option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label>
            Title <b className="text-danger">*</b>
          </Label>
          <Input
            type="text"
            name="Title"
            placeholder="Enter Deal Title. It must be a Hot Deal or Admin will not approve the flash deal listing"
            value={dealForm.Title}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label>Description</Label>
          <Input
            type="textarea"
            name="Description"
            placeholder="Deal Description.."
            value={dealForm.Description}
            onChange={handleChange}
          />
        </FormGroup>
        <Row>
          <Col lg={4}>
            <FormGroup>
              <Label>
                Promo Code <b className="text-danger">*</b>
              </Label>
              <Input
                type="text"
                name="Code"
                placeholder="Enter Unique Code"
                value={dealForm.Code}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
          <Col lg={4}>
            <FormGroup>
              <Label>
                Promo Code Value <b className="text-danger">*</b>
              </Label>
              <Input
                type="number"
                name="codeValue"
                placeholder="Enter Value"
                value={dealForm.codeValue}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
          <Col lg={4} className="pt-2">
            <FormGroup>
              <Label> </Label>
              <Input
                type="select"
                name="codeValueType"
                value={dealForm.codeValueType}
                onChange={handleChange}
              >
                <option value="">Offer type</option>
                <option value="%">%</option>
                <option value="$">$</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label for="imageUpload" className="m-0">
                Select Your Flash Deal Image
              </Label>
              <Input
                type="file"
                id="imageUpload"
                onChange={handleChange}
                style={{ display: "none" }}
                name="Image"
              />
            </FormGroup>
            <div
              className="image-box"
              onClick={() => document.getElementById("imageUpload").click()}
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              {dealForm?.Image && (
                <>
                  <img
                    src={dealForm.Image ? dealForm.Image : PlaceHolder}
                    alt="Uploaded Image"
                    className="uploaded-image img-fluid rounded"
                    style={{ width: "100px", height: "80px" }}
                  />
                </>
              )}
              {!dealForm.Image && <FaPlusCircle className="upload-icon" />}
            </div>
          </Col>
          <Col>
            <FormGroup>
              <Label>
                From Date <b className="text-danger">*</b>
              </Label>
              <Input
                type="date"
                name="startDate"
                value={dealForm.startDate}
                onChange={handleChange}
                min={new Date().toISOString().split("T")[0]}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>
                To Date <b className="text-danger">*</b>
              </Label>
              <Input
                type="date"
                name="endDate"
                value={dealForm.endDate}
                onChange={handleChange}
                min={new Date().toISOString().split("T")[0]}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default DealForm;
