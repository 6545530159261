import React from 'react';

const ZoomEndPage = () => {
  return (
    <div className="container" style={{ marginTop: '50px' , height:"90vh"}}>
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <h2>Thank you!</h2> 
          <p className='my-4'>Thank you for joining our Zoom meeting!</p>
          <p className='my-4'>Before you leave, please take a moment to provide your feedback:</p>
          <a href="#" className="btn btn-primary">Leave Feedback</a> 
          <p className='my-4'>If you have any questions or need further assistance, feel free to contact us.</p>
          <p className='my-4'>Stay connected with us on social media:</p><br />
          <div className="social-links my-1 d-flex flex-row gap-3">
            <a href="#" className="btn btn-outline-primary mr-2">Twitter</a>
            <a href="#" className="btn btn-outline-primary mr-2">Facebook</a>
            <a href="#" className="btn btn-outline-primary">LinkedIn</a>
          </div>
          <p className='my-4'>Thank you for choosing Zoom!</p>
          <a href="#" className="btn btn-danger">Leave Meeting</a>
        </div>
      </div>
    </div>
  );
}

export default ZoomEndPage;
