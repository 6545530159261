import React, { useEffect, useState } from "react";
import { Col, Input, Row } from "reactstrap";
import "./questions.css";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";
// import UpdateQuestionModal from "./UpdateQuestionModal";
import UpdateQuestionModal from "./UpdateModal";
import AddNewQuestionModal from "./AddQuestionModal";

const QuestionsCards = ({
  questionsList,
  categories,
  fetchCat,
  selectedCategoryId,
  setSelectedCategoryId,
}) => {
  // const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [updateModal, setUpdateModal] = useState(false);
  const [selectedQuest, setSelectedQuest] = useState(null);
  const [content, setContent] = useState("");
  const selectCat = (CatID) => {
    const categoryId = CatID;
    setSelectedCategoryId(categoryId);
    if (categoryId) {
      const filteredQuestions = questionsList.filter(
        (question) => question.categoryId === parseInt(categoryId)
      );
      setQuestions(filteredQuestions);
      console.log(filteredQuestions);
    } else {
      setQuestions(questionsList);
    }
  };
  useEffect(() => {
    if (selectedCategoryId) {
      selectCat(selectedCategoryId);
    }
  }, [questionsList]);
  const onEdit = (quest) => {
    setSelectedQuest(quest);
    setUpdateModal(true);
    setContent("edit");
  };
  const onCatDelete = (quest) => {
    setSelectedQuest(quest);
    setUpdateModal(true);
    setContent("delete");
  };

  return (
    <div>
      <Input
        type="select"
        value={selectedCategoryId}
        required
        size="sm"
        className="mb-3 w-50"
        onChange={(e) => selectCat(e.target.value)}
      >
        <option value="">Select Category</option>
        {categories.map((category, index) => (
          <option key={index} value={category.Id}>
            {category.Name}
          </option>
        ))}
      </Input>
      {selectedCategoryId && questions.length > 0 ? (
        <>
          {questions.map((quest, index) => (
            <div key={quest.id}>
              <div>
                <ul>
                  <li className="m-1 question-card">
                    <Row>
                      <Col sm={8}>
                        <b>{index + 1} . </b>
                        {quest.Question}
                      </Col>
                      <Col
                        sm={4}
                        className="d-flex justify-content-end gap-2 align-self-center text-end mt-0 align-items-center"
                      >
                        <button
                          onClick={() => onEdit(quest)}
                          className="edit px-3 my-1 btn-success"
                          style={{ fontSize: "14px" }}
                        >
                          Edit <AiFillEdit />
                        </button>
                        <UpdateQuestionModal
                          isOpen={updateModal}
                          closeModal={() => setUpdateModal(false)}
                          selectedQuest={selectedQuest}
                          fetchCat={fetchCat}
                          content={content}
                        />
                        <button
                          onClick={() => onCatDelete(quest)}
                          className="edit px-3 my-1 btn-danger"
                          style={{ fontSize: "14px" }}
                        >
                          Delete <AiFillDelete />
                        </button>
                      </Col>
                    </Row>
                  </li>
                </ul>
              </div>
            </div>
          ))}
        </>
      ) : selectedCategoryId ? (
        <div className="text-center p-5">
          {" "}
          <b>No Questions Added to this Category</b>
        </div>
      ) : (
        <div className="text-center p-5">
          {" "}
          <b>Please select a category to view the Questions</b>
        </div>
      )}
    </div>
  );
};

export default QuestionsCards;
