import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  featureService: null,
  selectedService: null,
  message: null,
  reqPhone: null,
  offerAmount: null,
  totalPrice: null,
  finalPrice: null,
  featureAmount: null,
  paymentType: "",
};
// paymentType === "Feature";
// paymentType === "Hire";
// paymentType === "ReqPhone";
// paymentType === "Offer";
// paymentType === "Package";

const PaymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    resetPaymentState: (state) => initialState,
    setFeatureService: (state, action) => {
      state.featureService = action.payload;
    },
    setSelectedService: (state, action) => {
      state.selectedService = action.payload;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
    setReqPhone: (state, action) => {
      state.reqPhone = action.payload;
    },
    setOfferAmount: (state, action) => {
      state.offerAmount = action.payload;
    },
    setTotalPrice: (state, action) => {
      state.totalPrice = action.payload;
    },
    setFinalPrice: (state, action) => {
      state.finalPrice = action.payload;
    },
    setFeatureAmount: (state, action) => {
      state.featureAmount = action.payload;
    },

    setPaymentType: (state, action) => {
      state.paymentType = action.payload;
    },
  },
});

export const {
  resetPaymentState,
  setFeatureService,
  setTotalPrice,
  setFinalPrice,
  setSelectedService,
  setMessage,
  setReqPhone,
  setOfferAmount,
  setFeatureAmount,
  setPaymentType,
} = PaymentSlice.actions;
export default PaymentSlice.reducer;
