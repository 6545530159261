import React, { useEffect, useState } from "react";
import Sidebar from "../../pages/SideBar";
import "./categories.css";
import { useSelector } from "react-redux";
import { fetchCategories, AddNewCat } from "../Header/Data";
import CatModal from "./CatModal";
import { useNavigate } from "react-router-dom";
// import CategorySideBar from "./CategorySideBar";
import CategoryDetails from "./CategoryDetails";
import { Row, Col } from "reactstrap";

const Categories = (props) => {
  const [categories, setCategories] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const basepath = useSelector((state) => state.setting.basepath);

  const fetchCat = async () => {
    const catData = await fetchCategories();
    setCategories(catData);
  };
  useEffect(() => {
    fetchCat();
  }, []);
  const closeModal = () => {
    setShowModal(false);
    fetchCat();
  };
  return (
    <>
      <div className="bg-white">
        <div className="container">
          <Row>
            <Col sm={4} lg={3}>
              <Sidebar />
            </Col>
            <Col sm={8} lg={9} className="pt-4">
              <div className="text-end mb-3">
                <a
                  className="btn btn-primary all_cate_product text-right"
                  onClick={() => setShowModal(true)}
                >
                  Add New Category
                </a>
              </div>

              {/* <div className="all_cate_product text-center m-3" onClick={openModal}>
            <b>Add New Category</b>
          </div> */}
              <CategoryDetails categories={categories} fetchCat={fetchCat} />
              <CatModal isOpen={showModal} closeModal={closeModal} />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Categories;
