import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { FaCaretDown } from "react-icons/fa";

const SortVendors = ({ origibalBwv, bwv, setBwv }) => {
  const [sortBy, setSortBy] = useState("Price: Higher to Lower"); // Add state for sorting
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleSortBy = (option) => {
    setSortBy(option);
    let sortedBwv = [...origibalBwv]; // Make a copy of the original array

    switch (option) {
      case "Price: Higher to Lower":
        sortedBwv.sort((a, b) => {
          const priceA = getPriceForSorting(a);
          const priceB = getPriceForSorting(b);
          return priceB - priceA;
        });
        break;
      case "Price: Lower to Higher":
        sortedBwv.sort((a, b) => {
          const priceA = getPriceForSorting(a);
          const priceB = getPriceForSorting(b);
          return priceA - priceB;
        });
        break;
      case "Date: Newer to Older":
        sortedBwv.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        break;
      case "Date: Older to Newer":
        sortedBwv.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        break;
      case "Rating: Higher to Lower":
        sortedBwv.sort((a, b) => b.Rating - a.Rating);
        break;
      default:
        break;
    }
    setBwv(sortedBwv);
  };

  // Function to get price for sorting
  const getPriceForSorting = (vendor) => {
    if (vendor.hourlyPrice !== undefined && vendor.hourlyPrice !== "") {
      return parseFloat(vendor.hourlyPrice.trim()) || 0;
    } else if (vendor.Package !== undefined && vendor.Package !== "") {
      return getMinPackagePrice(vendor.Package) || 0;
    } else {
      return 0;
    }
  };

  // Function to get minimum price from packages
  const getMinPackagePrice = (packageData) => {
    if (!packageData) return null;
    const packages = Object.values(JSON.parse(packageData));
    const prices = packages.map((userpackage) =>
      parseFloat(userpackage.price.trim())
    );
    return Math.min(...prices);
  };

  return (
    <div>
      {" "}
      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
        <DropdownToggle
          // caret
          style={{
            backgroundColor: "#fff",
            color: "#000",
            // border: "1px solid #007BFF",
          }}
          className="wd_short m-0"
        >
          {sortBy.charAt(0).toUpperCase() + sortBy.slice(1)}
          <FaCaretDown />
        </DropdownToggle>
        <DropdownMenu style={{ color: "#fff", zIndex: 99999 }}>
          <DropdownItem onClick={() => handleSortBy("Price: Higher to Lower")}>
            Price: Higher to Lower
          </DropdownItem>
          <DropdownItem onClick={() => handleSortBy("Price: Lower to Higher")}>
            Price: Lower to Higher
          </DropdownItem>
          <DropdownItem onClick={() => handleSortBy("Date: Newer to Older")}>
            Date: Newer to Older
          </DropdownItem>
          <DropdownItem onClick={() => handleSortBy("Date: Older to Newer")}>
            Date: Older to Newer
          </DropdownItem>
          <DropdownItem onClick={() => handleSortBy("Rating: Higher to Lower")}>
            Rating: Higher to Lower
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default SortVendors;
