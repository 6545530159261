import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "reactstrap";
import {
  fetchUserDetails,
  updateProfile,
  fetchCategories,
  fetchSubCategories,
  fetchItems,
} from "../../../components/Header/Data";
import Sidebar from "../../SideBar";
import { useSelector, useDispatch } from "react-redux";
import axiosClient from "../../../axios-client";
import { Notifications, Profile } from "./Profile";
import Password from "./ChangePassward";
import Cashout from "./CashOut";
import "../DashboardPages.css";
import "./Settings.css";
import { toast } from "react-toastify";
import PaymentSettings from "./PaymentSettings";
import { updatePaypalSettings } from "../../../components/Header/Data2";
import ProfilePayment from "./ProfilePayment";
import { Link, useParams, useLocation } from "react-router-dom";
import NotifyMe from "./NotifyMe";
import UploadID from "./UploadID";
import VerifyID from "./VerifyID";

const Settings = () => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [userDetails, setUserDetails] = useState({});
  const [searchTerm, setSearchTerm] = useState(userDetails.Location);
  // const [categories, setCategories] = useState([]);
  // //const [subCategories, setSubCategories] = useState([]);
  // // const [items, setItems] = useState([]);
  // const [selectedCat, setSelectedCat] = useState(null);
  // const [requestForm, setRequestForm] = useState({
  //   categoryId: null,
  //   //subCategoryId: null,
  //   // itemId: null,
  //   categoryName: "",
  //   //subCategoryName: "",
  //   // itemName: "",
  //   radius: "",
  //   duration: "",
  // });
  const location = useLocation();
  const logUser = localStorage.getItem("USER_ROLE");
  //const [selectedItem, setSelectedItem] = useState("profile");
  const [clientId, setClientId] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const { tab } = useParams(); // Get the active tab from URL
  const defaultTab = tab || "profile";
  let payment = 100;

  // const fetchAll = async () => {
  //   try {
  //     let categoriesData = await fetchCategories();
  //     //let subCategoriesData = await fetchSubCategories();
  //     // let itemsData = await fetchItems();
  //     setCategories(categoriesData);
  //     //setSubCategories(subCategoriesData);
  //     // setItems(itemsData);
  //   } catch (error) {
  //     console.error("Error fetching categories:", error.message);
  //   }
  // };
  // useEffect(() => {
  //   fetchAll();
  //   console.log(categories, "categories");
  // }, []);

  // useEffect(() => {
  //   if (location.state) {
  //     const state = location.state;
  //     setSelectedCat(state.categoryId);
  //     setRequestForm((prevInfo) => ({
  //       ...prevInfo,
  //       categoryId: state.categoryId,
  //       //subCategoryId: state.subCategoryId,
  //       // itemId: state.itemId,
  //       categoryName: state.categoryName,
  //       //subCategoryName: state.subCategoryName,
  //       // itemName: state.itemName,
  //       radius: state.radius,
  //       duration: state.duration,
  //     }));
  //   }
  // }, [location.state]);

  useEffect(() => {
    localStorage.setItem("activeTab", defaultTab);
  }, [tab]);
  useEffect(() => {
    setSearchTerm(userDetails.Location || ""); // Set searchTerm to userDetails.Location or empty string if userDetails.Location is undefined
  }, [userDetails.Location]);

  const [Notification, setNotification] = useState(0);
  const fetchData = async () => {
    const userData = await fetchUserDetails(user?.UserID);
    setUserDetails(userData);
    setNotification(userData.Notification);
    window.scroll(0, 0);
  };
  const updatepPayPal = async () => {
    if (!clientId) {
      toast.error("Enter Client Id");
      return;
    } else if (!secretKey) {
      toast.error("Enter Secret Key");
      return;
    }
    const formData = {
      updatedBy: user.UserID,
      clientId: clientId,
      Key: secretKey,
    };
    await updatePaypalSettings(formData);
    toast.success("Paypal Settings Updated Successfully");
  };
  useEffect(() => {
    fetchData();
  }, []);
  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    if (defaultTab === "profile") {
      if (
        !userDetails.FirstName ||
        !userDetails.LastName ||
        !userDetails.UserName ||
        !userDetails.Email ||
        !userDetails.ZipCode ||
        !userDetails.Radius
      ) {
        toast.error("please fill details");
      } else {
        const userData = {
          FirstName: userDetails.FirstName,
          LastName: userDetails.LastName,
          UserName: userDetails.UserName,
          Email: userDetails.Email,
          Photo: userDetails.Photo,
          Phone: userDetails.Phone,
          BioData: userDetails.BioData,
          yelpUrl: userDetails.yelpUrl,
          facebookUrl: userDetails.facebookUrl,
          instagramUrl: userDetails.instagramUrl,
          Location: searchTerm,
          Radius: userDetails.Radius,
          UpdatedBy: user.UserID,
          TimeZone: userDetails.TimeZone,
          CountryCode: userDetails.CountryCode,
          EngagedCoupled: userDetails.EngagedCoupled,
          eventDetails: userDetails.eventDetails || [],
          ZipCode: userDetails.ZipCode,
        };
        await updateProfile(userData, user.UserID);
        console.log(userData, "userData");
        toast.success(`Profile Updated Successfully`);
        window.location.reload();
      }
    } else if (defaultTab === "notifications") {
      await axiosClient.put(`setting/updateNotification/${user.UserID}`, {
        Notification: Notification,
      });
      toast.success(`Notifications`);
      window.location.reload();
    } else if (defaultTab === "paymentsettings") {
      updatepPayPal();
    }
  };
  const selectedForm = () => {
    switch (defaultTab) {
      case "payment":
        return <ProfilePayment userDetails={userDetails} />;
      case "cashout":
        return <Cashout />;
      case "password":
        return <Password />;
      case "paymentsettings":
        return (
          <PaymentSettings
            clientId={clientId}
            setClientId={setClientId}
            secretKey={secretKey}
            setSecretKey={setSecretKey}
          />
        );
      case "notifications":
        return (
          <Notifications
            Notification={Notification}
            setNotification={setNotification}
          />
        );
      case "notifyMe":
        return (
          <NotifyMe
          // categories={categories}
          // //subCategories1={subCategories}
          // // items={items}
          // requestForm={requestForm}
          // setRequestForm={setRequestForm}
          // //selectedCat={selectedCat}
          // // setSelectedCat={setSelectedCat}
          />
        );
      case "uploadID":
        return <UploadID />;
      case "verifyID":
        return <VerifyID />;
      default:
        return (
          <Profile
            userDetails={userDetails}
            setUserDetails={setUserDetails}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            user={user}
          />
        );
    }
  };
  console.log(user, "user");
  return (
    <section className="home-after-login bg-white">
      <div className="container">
        <Row className="settings-container px-0">
          <Col sm={3} className="stgs_sbar mt-2 mx-0">
            <Sidebar />
          </Col>
          <Col sm={9} className="stgs_content mt-2">
            <Col className="settings-nav">
              <Row>
                <div className="mb-3 d-flex justify-content-end">
                  <Button
                    className="btn btn-success m-0 "
                    onClick={handleUpdateProfile}
                    disabled={
                      defaultTab === "notifyMe" ||
                      defaultTab === "password" ||
                      defaultTab === "uploadID" ||
                      defaultTab === "verifyID"
                    }
                  >
                    Save Changes
                  </Button>
                </div>
                <nav>
                  <ul className="horizontal-list hnav-list d-flex justify-content-start">
                    <Link
                      to="/dashboardpage/settings/profile"
                      style={{ textDecoration: "none" }}
                    >
                      <li
                        className={defaultTab === "profile" ? "selected" : ""}
                      >
                        Profile
                      </li>
                    </Link>
                    <Link
                      to="/dashboardpage/settings/payment"
                      style={{ textDecoration: "none" }}
                    >
                      <li
                        className={defaultTab === "payment" ? "selected" : ""}
                      >
                        PayPal
                      </li>
                    </Link>
                    <Link
                      to="/dashboardpage/settings/cashout"
                      style={{ textDecoration: "none" }}
                    >
                      <li
                        className={defaultTab === "cashout" ? "selected" : ""}
                      >
                        Cashout
                      </li>
                    </Link>
                    <Link
                      to="/dashboardpage/settings/password"
                      style={{ textDecoration: "none" }}
                    >
                      <li
                        className={defaultTab === "password" ? "selected" : ""}
                      >
                        Password
                      </li>
                    </Link>
                    <Link
                      to="/dashboardpage/settings/notifications"
                      style={{ textDecoration: "none" }}
                    >
                      <li
                        className={
                          defaultTab === "notifications" ? "selected" : ""
                        }
                      >
                        Notifications
                      </li>
                    </Link>
                    {logUser === "provider" && (
                      <>
                        <Link
                          to="/dashboardpage/settings/notifyMe"
                          style={{ textDecoration: "none" }}
                        >
                          <li
                            className={
                              defaultTab === "notifyMe" ? "selected" : ""
                            }
                          >
                            Notify Me
                          </li>
                        </Link>
                        <Link
                          to="/dashboardpage/settings/uploadID"
                          style={{ textDecoration: "none" }}
                        >
                          <li
                            className={
                              defaultTab === "uploadID" ? "selected" : ""
                            }
                          >
                            Upload ID
                          </li>
                        </Link>
                      </>
                    )}
                    {user?.Role === "admin" && (
                      <>
                        <Link
                          to="/dashboardpage/settings/paymentsettings"
                          style={{ textDecoration: "none" }}
                        >
                          <li
                            className={
                              defaultTab === "paymentsettings" ? "selected" : ""
                            }
                          >
                            Payment Settings
                          </li>
                        </Link>
                        <Link
                          to="/dashboardpage/settings/verifyID"
                          style={{ textDecoration: "none" }}
                        >
                          <li
                            className={
                              defaultTab === "verifyID" ? "selected" : ""
                            }
                          >
                            Verify ID
                          </li>
                        </Link>
                      </>
                    )}
                  </ul>
                </nav>
              </Row>
              <Row
                style={{
                  width: `${
                    user.RoleId !== 1 && defaultTab === "cashout"
                      ? "935px"
                      : "auto"
                  }`,
                }}
              >
                <Col>{selectedForm()}</Col>
              </Row>
            </Col>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Settings;
