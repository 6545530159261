import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import Placeholder from "../../../assets/images/Placeholder.png";
import { useSelector } from "react-redux";
import {
  duplicateRequest,
  statusOfRequest,
} from "../../../components/Header/Data";
import { FaMapMarker } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./InActiveNeeds.css"; // Import CSS file for additional styling

const ExpireOrPauseNeeds = ({ expiredOrPauseNeeds, fetchData }) => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const isAdmin = user && user.Role === "admin"; // Check if user is admin
  const [openMenuId, setOpenMenuId] = useState(null);
  const navigate = useNavigate();
  const toggleMenu = (adId) => {
    setOpenMenuId(openMenuId === adId ? null : adId);
  };
  const onDuplicate = async (need) => {
    const needId = {
      needId: need.Id,
    };
    await duplicateRequest(needId);
    toast.success(`${need.Title} Duplicate created`);
    fetchData();
  };
  const onEdit = async (need) => {
    if (isAdmin) {
      const url = `/#/needs-requests/request?needId=${need.Id}`;
      window.open(url, "_blank", "noopener,noreferrer");
    } else {
      navigate(`/needs-requests/request?needId=${need.Id}`);
    }

    window.scroll(0, 0);
  };
  const onShare = async (need) => {};
  const onDelete = async (need) => {
    const data = {
      Status: 2,
      createdBy: user.UserID,
    };
    await statusOfRequest(data, need.Id);
    toast.success(`${need.Title} Deleted`);
    fetchData();
  };
  const onActivate = async (need) => {
    console.log(need, "pending needs");
    if (need?.categoryName === null) {
      toast.error("Please add Category");
      return;
    }
    const userId = { createdBy: user.UserID, Status: 1 };
    await statusOfRequest(userId, need.Id);
    toast.success(`${need.Title} Activated`);
    fetchData();
  };
  function formatDate(isoString) {
    const date = new Date(isoString);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  }
  console.log(expiredOrPauseNeeds, "expiredOrPauseNeeds");
  return (
    <Row className="ads" style={{ margin: "0 7px", padding: "20px" }}>
      {expiredOrPauseNeeds.map((need) => (
        <Row key={need.Id} className="need-card">
          <Col className="img needs-image p-0">
            <img
              className="w-100"
              style={{ height: "185px" }}
              src={
                need.Photo && need.Photo.length > 0 && need.Photo[0] !== ""
                  ? JSON.parse(need.Photo)[0]
                  : Placeholder
              }
              alt={need.Title}
            />
          </Col>
          <Col className="need-info">
            <Row>
              <Col
                sm="6"
                xs="12"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <p className="pt-2">{need.categoryName}</p>
              </Col>
              <Col sm="6" xs="12" className="action-buttons">
                {user.RoleId === 1 && (
                  <button
                    className="activate-button btn-sm"
                    onClick={() => onActivate(need)}
                  >
                    Activate
                  </button>
                )}
                <button
                  className="edit-button btn-sm"
                  onClick={() => onEdit(need)}
                >
                  Edit
                </button>
                <div className="menu-container">
                  <span
                    className="menu-toggle"
                    onClick={() => toggleMenu(need.Id)}
                  >
                    ⋮
                  </span>
                  {openMenuId === need.Id && (
                    <div className="menu">
                      <p onClick={() => onDuplicate(need)}>Duplicate</p>
                      <p onClick={() => onShare(need)}>Share</p>
                      <p onClick={() => onDelete(need)}>Delete</p>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <div className="row">
              <div className="col-sm-6">
                <h6>{need.Title}</h6>
                <p>
                  <FaMapMarker /> {need.Zipcode}
                </p>
              </div>
              <div className="col-sm-6">
                {isAdmin && (
                  <div>
                    <p className="mb-0">Email: {need?.Email}</p>
                    <p className="mb-0">Full Name: {need?.FullName}</p>
                    <p className="mb-0">
                      Ad Posted: {formatDate(need?.createdAt)}
                    </p>
                    <p className="mb-0">
                      Last Updated: {formatDate(need?.updatedAt)}
                    </p>
                    <p className="mb-0">Call: {need?.Phone}</p>
                    <p className="mb-0">Extension # {need?.dialExtension}</p>
                  </div>
                )}
              </div>
            </div>
            {/* <div className="need-footer">
              <p>
                <FaMapMarker /> {need.Zipcode}
              </p>
            </div> */}
          </Col>
        </Row>
      ))}
    </Row>
  );
};

export default ExpireOrPauseNeeds;
