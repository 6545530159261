import React, { useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useSelector } from "react-redux";

const BwvMedia = () => {
  const selectedCard = useSelector((state) => state.bwvData.selectedService);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const openLightbox = (index) => {
    setSelectedImageIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setSelectedImageIndex(null);
  };

  const goToNextImage = () => {
    setSelectedImageIndex(
      (prevIndex) => (prevIndex + 1) % selectedCard.Photo.length
    );
  };

  const goToPreviousImage = () => {
    setSelectedImageIndex(
      (prevIndex) =>
        (prevIndex - 1 + selectedCard.Photo.length) % selectedCard.Photo.length
    );
  };

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  return (
    <div>
      <h6 style={{ fontWeight: "bold" }} className="pb-4">
        Highlight Images
      </h6>
      {selectedCard?.Photo !== "" &&
      JSON.parse(selectedCard?.Photo)[0] !== "string" &&
      JSON.parse(selectedCard?.Photo).length > 0 ? (
        <div style={{ position: "relative" }}>
          <div
            style={{
              display: "flex",
              overflowX: "auto", // Enable horizontal scrolling
            }}
          >
            {JSON.parse(selectedCard?.Photo).map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Media ${index}`}
                style={{
                  height: "250px", // Set a specific height for each image
                  marginRight: 5,
                  borderRadius: "3px",
                  objectFit: "cover", // Maintain aspect ratio and cover the space
                  maxWidth: "250px",
                  cursor: "pointer", // Add cursor pointer to indicate clickable
                }}
                onClick={() => openLightbox(index)} // Open lightbox on click
              />
            ))}
          </div>
          {lightboxOpen && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "10px",
                  transform: "translateY(-50%)",
                  zIndex: 1,
                  backgroundColor: "transparent",
                  border: "none",
                }}
                onClick={goToPreviousImage}
              >
                <FaChevronLeft style={{ color: "#fff", fontSize: 24 }} />
              </button>
              <img
                src={selectedCard.Photo[selectedImageIndex]}
                alt="Selected"
                style={{
                  maxHeight: "90%",
                  maxWidth: "90%",
                  objectFit: "contain",
                }}
                onClick={stopPropagation} // Stop propagation of click event
              />
              <button
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "10px",
                  transform: "translateY(-50%)",
                  zIndex: 1,
                  backgroundColor: "transparent",
                  border: "none",
                }}
                onClick={goToNextImage}
              >
                <FaChevronRight style={{ color: "#fff", fontSize: 24 }} />
              </button>
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                onClick={closeLightbox} // Close lightbox on click outside image
              />
            </div>
          )}
        </div>
      ) : (
        <p>No Media Available.</p>
      )}
    </div>
  );
};

export default BwvMedia;
