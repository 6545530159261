import React, { useState } from "react";
import { useSelector } from "react-redux";
import { MessageStatusUpdate } from "../../../../components/Header/Data";
import AdminResponseModal from "./AdminResponseModal";
import OfferDetailsModal from "./OfferDetailsModal";
import NegotiateModal from "./NegotiateModal";
import { toast } from "react-toastify";

const OfferDetails = ({ msg, selectedMsg, fetchData, setSelectedMsg }) => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [negotiate, setNegotiate] = useState(false);
  const [accept, setAccept] = useState(false);
  const [offerDetailsModalOpen, setOfferDetailsModalOpen] = useState(false);
  const [viewNegoHistory, setViewNegoHistory] = useState(false);
  const [adminResponse, setAdminResponse] = useState(false);
  const [adminResponseType, setAdminResponseType] = useState("");

  const handleApproveAdmin = async (event, msg) => {
    event.stopPropagation();
    const status = {
      messageStatus: 3,
      updatedBy: user.UserID,
      customerId: msg.ownerId,
      needName: msg.postName,
      Budget: msg.Budget,
      Message: msg.Message,
      Email: msg.customerEmail,
      createdBy: msg.createdBy,
    };
    await MessageStatusUpdate(status, msg.Id);
    fetchData();
    toast.success("Offer Accepted");
  };

  const ViewOffer = (event, msg) => {
    event.stopPropagation();
    setSelectedMsg(msg);
    setOfferDetailsModalOpen(true);
  };
  const closeAdminModal = () => {
    setAdminResponse(false);
    setAdminResponseType("");
  };

  const closeAcceptModal = (e) => {
    setNegotiate(false);
    setOfferDetailsModalOpen(false);
  };
  return (
    <>
      {/* messageStatus === 0 or messageStatus === 1 message sent for approve */}
      {user.RoleId === 1 &&
        msg.postType === 3 &&
        (msg.messageStatus === 0 || msg.messageStatus === 1) && (
          <div className="d-flex flex-row gap-1 justify-content-start">
            <div className="d-flex justify-content-start align-items-center">
              <button
                className="btn btn-success btn-sm text-nowrap m-0"
                onClick={(event) => {
                  handleApproveAdmin(event, msg);
                }}
              >
                Approve
              </button>{" "}
            </div>
            <div className="d-flex justify-content-start align-items-center ">
              <button
                className="btn btn-danger btn-sm text-nowrap m-0"
                onClick={() => {
                  setAdminResponse(true);
                  setAdminResponseType("reject");
                  setSelectedMsg(msg);
                }}
              >
                Reject
              </button>{" "}
              <AdminResponseModal
                isOpen={adminResponse}
                closeModal={closeAdminModal}
                adminResponseType={adminResponseType}
                msg={selectedMsg}
                fetchData={fetchData}
              />
            </div>
          </div>
        )}
      {/* messageStatus === 3 view offer */}
      {msg.messageStatus === 3 &&
        msg.ownerId === user.UserID &&
        msg.postType === 3 && (
          <div div className="d-flex justify-content-start align-items-center ">
            <button
              className="btn btn-primary btn-sm text-nowrap m-0"
              onClick={(event) => ViewOffer(event, msg)}
            >
              View Offer
            </button>{" "}
            <OfferDetailsModal
              isOpen={offerDetailsModalOpen}
              closeModal={(e) => closeAcceptModal(e)}
              negotiate={negotiate}
              setNegotiate={setNegotiate}
              accept={accept}
              setAccept={setAccept}
              msg={selectedMsg}
              fetchData={fetchData}
            />
          </div>
        )}
      {/* messageStatus === 6 customer Negotiated  view Negotiation*/}
      {msg.messageStatus === 6 && user.RoleId !== 1 && msg.postType === 3 && (
        <>
          {msg.ownerId === user.UserID ? (
            <div className="d-flex justify-content-start align-items-center ">
              <button
                className="btn btn-warning btn-sm text-nowrap m-0"
                // onClick={() => setViewNegoHistory(true)}
                disabled
              >
                You Negotiated
              </button>
            </div>
          ) : (
            <div className="d-flex justify-content-start align-items-center ">
              <button
                className="btn btn-warning btn-sm text-nowrap m-0 "
                onClick={() => setViewNegoHistory(true)}
              >
                View Negotiation
              </button>{" "}
              <NegotiateModal
                isOpen={viewNegoHistory}
                closeModal={() => setViewNegoHistory(false)}
                msg={msg}
                fetchData={fetchData}
              />
            </div>
          )}
        </>
      )}

      {/* messageStatus === 7 provider Negotiated  view Negotiation*/}
      {msg.messageStatus === 7 && user.RoleId !== 1 && msg.postType === 3 && (
        <>
          {msg.ownerId === user.UserID ? (
            <div className="d-flex justify-content-start align-items-center ">
              <button
                className="btn btn-warning btn-sm text-nowrap m-0 "
                onClick={() => setViewNegoHistory(true)}
              >
                View Negotiation
              </button>{" "}
              <NegotiateModal
                isOpen={viewNegoHistory}
                closeModal={() => setViewNegoHistory(false)}
                msg={msg}
                fetchData={fetchData}
              />
            </div>
          ) : (
            <div className="d-flex justify-content-start align-items-center ">
              <button
                className="btn btn-warning btn-sm text-nowrap m-0"
                // onClick={() => setViewNegoHistory(true)}
                disabled
              >
                You Negotiated
              </button>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default OfferDetails;
