import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  fetchAllUsers,
  CAIdStatusUpdate,
} from "../../../components/Header/Data";

import { VscEye } from "react-icons/vsc";
import { IoMdDownload } from "react-icons/io";
import { FadeLoader } from "react-spinners";
import { toast } from "react-toastify";
import {
  Col,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
} from "reactstrap";

const VerifyID = () => {
  const isLogin = useSelector((state) => state.user.isLogin);
  const userInfo = useSelector((state) => state.user.userInfo);
  const user = isLogin ? JSON.parse(userInfo) : null;
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [rejectUser, setRejectUser] = useState(null);
  const [rejectMessage, setRejectMessage] = useState("");

  useEffect(() => {
    const fetchUsers = async () => {
      const fetchedUsers = await fetchAllUsers();
      setUsers(fetchedUsers);
      setIsLoading(false); // Set loading state to false after data is fetched
    };
    fetchUsers();
  }, []);

  const handleApprove = async (user) => {
    const Id = user.Id;
    const formData = {
      caIdStatus: 1,
      Email: user?.Email,
      caIdComment: "",
    };
    try {
      await CAIdStatusUpdate(Id, formData);
      toast.success(`CA ID Approved`);
      setUsers((prevUsers) =>
        prevUsers.map((user) => {
          if (user.Id === user.Id) {
            return { ...user, caIdStatus: 1 };
          }
          return user;
        })
      );
    } catch (error) {
      toast.error(`Error approving user with ID ${user.Id}`);
    }
  };

  // Open reject modal
  const openRejectModal = (user) => {
    setRejectUser(user);
    setRejectModalOpen(true);
  };

  // Close reject modal
  const closeRejectModal = () => {
    setRejectUser(null);
    setRejectMessage("");
    setRejectModalOpen(false);
  };

  // Handle reject with message
  const handleReject = async () => {
    if (!rejectUser) return;
    if (!rejectMessage) {
      toast.error("Please enter a message");
      return;
    }
    const email = rejectUser.Email;
    const formData = {
      caIdStatus: 2,
      caIdComment: rejectMessage, // Include message in the formData
      Email: email,
    };
    console.log(formData, "formData");
    try {
      await CAIdStatusUpdate(rejectUser.Id, formData);
      toast.error("CA ID Rejected");
      setUsers((prevUsers) =>
        prevUsers.map((user) => {
          if (user.Id === rejectUser.Id) {
            return { ...user, caIdStatus: 2 };
          }
          return user;
        })
      );
      closeRejectModal();
    } catch (error) {
      toast.error(`Error rejecting user with ID ${rejectUser.Id}`);
    }
  };

  const handleView = (userId) => {
    const userToView = users.find((user) => user.Id === userId);
    if (userToView) {
      window.open(userToView.caId, "_blank");
    }
  };

  const handleDownload = async (userId) => {
    const userToDownload = users.find((user) => user.Id === userId);
    if (userToDownload) {
      try {
        const response = await fetch(userToDownload.caId);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const blob = await response.blob();
        const urlParts = userToDownload.caId.split("/");
        const filenameFromUrl = urlParts[urlParts.length - 1];
        const extension = filenameFromUrl.split(".").pop();

        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.setAttribute(
          "download",
          `${userToDownload.FirstName}_${userToDownload.LastName}_ID.${extension}`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(blobUrl);
      } catch (error) {
        toast.error(`Error downloading image for user ${userId}`);
      }
    }
  };
  console.log(user, "user");
  return (
    <div className="mt-5">
      {isLoading ? (
        <div className="d-flex justify-content-center mt-5">
          <FadeLoader color="#36d7b7" />
        </div>
      ) : users.length > 0 ? (
        <Row>
          <>
            {users
              .filter((user) => user?.caId !== null)
              .map((user) => (
                <div className="position-relative" key={user.Id}>
                  <div className="d-flex flex-row mb-3 py-3 shadow bg-white rounded">
                    <Col sm={8}>
                      <h5 className="mt-1 mx-4">
                        {user?.FirstName} {user?.LastName}
                      </h5>
                    </Col>
                    <Col sm={2} className="text-center">
                      <div className=" d-flex ">
                        {user.caIdStatus === 0 && (
                          <>
                            <button
                              onClick={() => handleApprove(user)}
                              className="btn btn-success me-2"
                            >
                              Approve
                            </button>
                            <button
                              onClick={() => openRejectModal(user)}
                              className="btn btn-danger me-2"
                            >
                              Reject
                            </button>
                          </>
                        )}
                        {user.caIdStatus === 1 && (
                          <button className="btn btn-success me-2" disabled>
                            Approved
                          </button>
                        )}
                        {user.caIdStatus === 2 && (
                          <button className="btn btn-danger me-2" disabled>
                            Rejected
                          </button>
                        )}
                      </div>
                    </Col>{" "}
                    <Col sm={2} className="text-center">
                      <div className="d-flex align-items-center justify-content-center px-0">
                        <button
                          onClick={() => handleView(user.Id)}
                          className="btn border-0 px-1"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="View Image"
                        >
                          <VscEye size={27} />
                        </button>
                        <button
                          onClick={() => handleDownload(user.Id)}
                          className="btn border-0 px-1 "
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Download"
                        >
                          <IoMdDownload size={27} />
                        </button>
                      </div>
                    </Col>
                  </div>
                </div>
              ))}
          </>
        </Row>
      ) : (
        <h5 className="d-flex justify-content-center mt-5">No Users</h5>
      )}

      {/* Reject Modal */}
      <Modal isOpen={rejectModalOpen} toggle={closeRejectModal}>
        {/* <ModalHeader toggle={closeRejectModal}>Reject User</ModalHeader> */}
        <ModalBody>
          <h6 className="mt-3">
            Please comment below: <b className="text-danger">*</b>
          </h6>
          <textarea
            className="form-control mt-3"
            rows="4"
            placeholder="Enter reject message..."
            value={rejectMessage}
            onChange={(e) => setRejectMessage(e.target.value)}
          />
          <div className="d-flex justify-content-end mt-3 gap-2">
            <Button color="secondary" onClick={closeRejectModal}>
              No
            </Button>
            <Button color="primary" onClick={handleReject}>
              Yes
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default VerifyID;
