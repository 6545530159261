import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  freeServiceTitle: "",
  freeServiceDescription: "",
};

const FreeServiceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    setFreeServiceTitle: (state, action) => {
      state.freeServiceTitle = action.payload;
    },
    setFreeServiceDescription: (state, action) => {
      state.freeServiceDescription = action.payload;
    },
    setFreeService: (state, action) => {
      const { freeServiceTitle, freeServiceDescription } = action.payload;
      state.freeServiceTitle = freeServiceTitle;
      state.freeServiceDescription = freeServiceDescription;
    },
  },
});

export const { setFreeServiceTitle, setFreeServiceDescription, setFreeService } =
  FreeServiceSlice.actions;
export default FreeServiceSlice.reducer;
