import React, { useState, useEffect } from "react";
import { Input, Form, Label, Modal, ModalBody, FormGroup } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { AddNewCatQuestion } from "../../components/Header/Data2";

const AddNewQuestionModal = ({ isOpen, closeModal, categories, fetchCat }) => {
  const navigate = useNavigate();
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [newQuestion, setNewQuestion] = useState("");
  const [newSelectedCategoryId, setNewSelectedCategoryId] = useState(null);

  const AddNewQuestion = async () => {
    const request = {
      Question: newQuestion,
      categoryId: newSelectedCategoryId,
      createdBy: user.UserID,
      questionStatus: "1",
    };
    if (!newQuestion.trim()) {
      toast.error("Please enter a question.");
      return;
    }
    if (!newSelectedCategoryId) {
      toast.error("Please select a category.");
      return;
    }
    await AddNewCatQuestion(request);
    closeModal();
    fetchCat();
    toast.success(`New Question Added`);
    setNewQuestion("");
    setNewSelectedCategoryId(null);
  };
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        closeModal();
        setNewQuestion("");
        setNewSelectedCategoryId(null);
      }}
    >
      <ModalBody>
        <button
          type="button"
          className="btn-close position-absolute top-0 end-0 m-2"
          onClick={() => {
            closeModal();
            setNewQuestion("");
            setNewSelectedCategoryId(null);
          }}
          aria-label="Close"
        ></button>
        <div className="modal-body ">
          <h5 className="text-center">
            <b>Add New Question</b>
          </h5>
          <Form className="pt-3">
            <FormGroup>
              {" "}
              <Label>Select Category</Label>
              <Input
                type="select"
                value={newSelectedCategoryId}
                required
                size="lg"
                onChange={(e) => {
                  setNewSelectedCategoryId(e.target.value);
                  console.log(e.target.value, "e.target.value");
                }}
              >
                <option value="">Select Category</option>
                {categories.map((category, index) => (
                  <option key={index} value={category.Id}>
                    {category.Name}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>Enter New Question</Label>
              <Input
                type="textarea"
                value={newQuestion}
                placeholder="Enter New Question"
                required
                autoFocus
                size="lg"
                onChange={(e) => setNewQuestion(e.target.value)}
              />
            </FormGroup>
          </Form>
          <div className="d-flex justify-content-end mt-3">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                AddNewQuestion();
              }}
            >
              Add Question
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AddNewQuestionModal;
