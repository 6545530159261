import React, { useEffect, useState } from "react";
import Sidebar from "../../pages/SideBar";
import { Row, Col } from "reactstrap";
import { fetchAllUsers } from "../../components/Header/Data";
import UserCard from "./UserCard";
import "./user.css";
const User = () => {
  const [allUsers, setAllUsers] = useState([]);

  const fetchUsers = async () => {
    const users = await fetchAllUsers();
    setAllUsers(users);
    console.log(users, "all users");
  };
  useEffect(() => {
    fetchUsers();
  }, []);
  return (
    <section className="bg-white">
      <div className="container">
        <Row>
          <Col sm={3}>
            <Sidebar />
          </Col>
          <Col sm={9} className="p-0">
            <UserCard allUsers={allUsers} fetchUsers={fetchUsers} />
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default User;
