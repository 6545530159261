import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import {
  fetchCategories,
  statusUpdateService,
  Featured,
} from "../../../components/Header/Data";
import { useNavigate } from "react-router-dom";
import Placeholder from "../../../assets/images/Placeholder.png";
import { toast } from "react-toastify";
import { VscKebabVertical } from "react-icons/vsc";
import { MdEdit, MdDelete } from "react-icons/md";
import { FcApprove } from "react-icons/fc";
import "./Services.css";
const PendingServices = ({ pending, user, fetchData, isLoading }) => {
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [openMenuId, setOpenMenuId] = useState(null);
  const navigate = useNavigate();
  // const history = useHistory();
  const fetchCatData = async () => {
    try {
      const categoriesData = await fetchCategories();
      setCategories(categoriesData);
    } catch (error) {
      console.error("Error fetching categories:", error.message);
    }
  };
  const onEdit = async (service) => {
    navigate("/create-service", { state: service });
  };
  const onDelete = async (service) => {
    const reqData = {
      Status: "2",
      createdBy: user.UserID,
    };
    await statusUpdateService(reqData, service.Id);
    toast.success(`${service.listName} Deleted`);
    fetchData();
  };
  const onApprove = async (service) => {
    console.log(service, "service");
    if (service.dialExtension === null) {
      toast.error("Please add Dial Extension");
      return;
    }
    const reqData = {
      Status: "3",
      createdBy: user.UserID,
      roleId: user.RoleId,
    };
    await statusUpdateService(reqData, service.Id);
    toast.success(`${service.listName} Approved`);
    fetchData();
  };

  useEffect(() => {
    fetchCatData();
    console.log(pending, "pending");
  }, []);

  useEffect(() => {
    // Filter categories based on pending data
    if (categories.length > 0 && pending.length > 0) {
      const categoryIds = pending.map((service) => service.categoryId);
      const filtered = categories.filter((category) =>
        categoryIds.includes(category.Id.toString())
      );
      setFilteredCategories(filtered);
    }
  }, [categories, pending]);

  const toggleMenu = (adId, event) => {
    event.stopPropagation();
    setOpenMenuId(openMenuId === adId ? null : adId);
    if (openMenuId === adId) {
      document.addEventListener("click", handleDocumentClick);
    } else {
      document.removeEventListener("click", handleDocumentClick);
    }
  };

  const handleDocumentClick = (event) => {
    if (event.target.closest(".service-menu") === null) {
      setOpenMenuId(null);
    }
  };

  function getMinPackagePrice(packageData) {
    const packages = Object.values(packageData);
    const prices = packages.map((userpackage) =>
      parseFloat(userpackage.price.trim())
    );
    const minPrice = Math.min(...prices);
    return minPrice;
  }

  console.log(pending, "pending");

  return (
    <>
      {pending.length > 0 ? (
        <div
          // sm={9}
          className="service-container mt-4 pb-3"
          style={{ marginLeft: "0" }}
        >
          {pending.map((service) => (
            <div className="service position-relative rounded px-0 mb-0 mt-3">
              <Row key={service.id}>
                <Col sm={3} xs={12} className=" p-0 position-relative">
                  <img
                    className="mw-100 object-fit-cover border rounded mh-100 img-fluid img-thumbnail"
                    src={
                      typeof service.Photo[0] === "string"
                        ? JSON.parse(service?.Photo)[0]
                        : Placeholder
                    }
                    style={{ width: "400px", height: "150px" }}
                    alt={service.listName}
                  />
                  {/* {service.featuredListing === 1 && (
                      <div className="featured">FEATURED</div>
                    )} */}
                </Col>
                <Col sm={9} xs={12} className=" p-3 position-relative">
                  <Row
                    className="top-0 end-0 pe-1"
                    style={{ right: "10px", top: "10px" }}
                  >
                    <Col sm={7} xs={12}>
                      <p>{service.categoryName}</p>
                    </Col>
                    <Col
                      sm={5}
                      xs={12}
                      className="postion-realtive text-end cursor-pointer"
                    >
                      {user.Role === "admin" && (
                        <button
                          className="p-1 px-2 mx-1 rounded text-white bg-opacity-75 btn-sm"
                          style={{ backgroundColor: "#1fa31f" }}
                          onClick={() => onApprove(service)}
                        >
                          <FcApprove /> Approve
                        </button>
                      )}
                      <button
                        className="p-1 px-2 mx-1 bg-primary text-white rounded btn-sm"
                        onClick={() => onEdit(service)}
                      >
                        Edit <MdEdit />
                      </button>
                      <span
                        style={{ fontSize: "20px", cursor: "pointer" }}
                        onClick={(e) => toggleMenu(service.Id, e)}
                      >
                        <span>
                          <VscKebabVertical style={{ marginTop: "2px" }} />
                        </span>
                      </span>
                      {openMenuId === service.Id && (
                        <div className="service-menu cursor-pointer">
                          <p
                            onClick={() => onDelete(service)}
                            className="bg-danger rounded text-white p-1 "
                            style={{ cursor: "pointer" }}
                          >
                            Delete <MdDelete />
                          </p>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <h4>{service.listName}</h4>
                  <h6 style={{ color: "#616bff" }}>
                    ${" "}
                    {
                      service.hourlyPrice
                        ? service.hourlyPrice
                        : getMinPackagePrice(JSON.parse(service.Package))

                      // ? service.hourlyPrice
                      // : calculateMinimumPrice(JSON.parse(service.Package)) ===
                      //   0
                      // ? service.hourlyPrice
                      // : calculateMinimumPrice(JSON.parse(service.Package))
                    }
                  </h6>
                </Col>
              </Row>
            </div>
          ))}
        </div>
      ) : (
        !isLoading && <div className="ads">No Pending Services</div>
      )}
    </>
  );
};

export default PendingServices;
