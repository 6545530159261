import React, { useEffect } from "react";
import PlaceHolder from "../../../assets/images/Placeholder.png";
import { Row, Col, Button } from "reactstrap";
import { useSelector } from "react-redux";
import { StatusUpdateDeal } from "../../../components/Header/Data2";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const CurrentDeals = ({ currentDeals, fetchData, isLoading }) => {
  const navigate = useNavigate();
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  useEffect(() => {
    console.log(currentDeals, "currentDeals");
  }, []);
  const onPause = async (deal) => {
    const formData = {
      dealStatus: 3,
      updatedBy: user.UserID,
      createdBy: deal.createdBy,
    };
    await StatusUpdateDeal(deal.Id, formData);
    fetchData();
    toast.success(`${deal.Title} Paused`);
  };
  const onRemove = async (deal) => {
    console.log(deal.Code, "Code");
    const formData = {
      dealStatus: 2,
      updatedBy: user.UserID,
      createdBy: deal.createdBy,
      Code: deal.Code,
    };
    console.log(formData, "formData");
    await StatusUpdateDeal(deal.Id, formData);
    fetchData();
    toast.success(`${deal.Title} Removed`);
  };
  const onEdit = async (deal) => {
    navigate("/flash-deals/new-deal", { state: { deal } });
  };
  return (
    <>
      {currentDeals && currentDeals.length > 0 ? (
        <div className="container-fluid">
          {currentDeals.map((deal) => (
            <div className="row wc_deals-wrapper my-3">
              <div className="col-lg-3 p-0">
                <img
                  className="w-80 wc_dls-img"
                  src={deal.Image ? deal.Image : PlaceHolder}
                  alt={deal.Title}
                />
              </div>
              <div className="col-lg-9 p-3">
                <Row>
                  <Col lg={6}>
                    <h4>{deal.Title}</h4>
                  </Col>
                  <Col lg={2}>
                    {/* {deal.createdBy === user.UserID && ( */}
                    <button
                      className="btn btn-outline-warning btn-sm px-3"
                      onClick={() => onPause(deal)}
                    >
                      Pause
                    </button>
                    {/* )} */}
                  </Col>
                  <Col lg={2}>
                    {/* {deal.createdBy === user.UserID && ( */}
                    <button
                      className="btn btn-outline-danger btn-sm px-3"
                      onClick={() => onRemove(deal)}
                    >
                      Remove
                    </button>
                    {/* )} */}
                  </Col>
                  <Col lg={2}>
                    {/* {deal.createdBy === user.UserID && ( */}
                    <button
                      className="btn btn-outline-primary btn-sm px-3"
                      onClick={() => onEdit(deal)}
                    >
                      Edit
                    </button>
                    {/* )} */}
                  </Col>
                </Row>
                <p className="p-2">{deal.Description}</p>
                <div className="row pt-3">
                  <div className="col-lg-5">
                    <p>
                      <b>Avaliable :</b>
                    </p>
                    <div className="d-flex justify-content-between">
                      <p className="text-dark text-center">
                        <b>{deal.startDate}</b>
                      </p>{" "}
                      To
                      <p className="text-dark text-center">
                        <b>{deal.endDate}</b>
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div
                      className="d-flex justify-content-between"
                      style={{ gap: "30px" }}
                    >
                      <div>
                        <p className="text-dark">Promo Code</p>
                        <p className="text-dark wc_dealcode">
                          <b>{deal.Code}</b>
                        </p>
                      </div>
                      <div>
                        <p className="text-dark">Promo Value </p>
                        <p className="text-dark py-2">
                          <b>
                            up to {deal.codeValue} {deal.codeValueType}
                          </b>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        !isLoading && <h5>No Current Deals</h5>
      )}
    </>
  );
};

export default CurrentDeals;
