import React, { useEffect, useState } from "react";
import { ReviewsById } from "../Data";
import ReactStars from "react-rating-stars-component";
import YouTube from "react-youtube";
import "./Bwv.css";
import { useSelector } from "react-redux";

const BwvReviews = () => {
  const selectedCard = useSelector((state) => state.bwvData.selectedService);
  const [reviews, setReviews] = useState([]);
  const [showAllReviews, setShowAllReviews] = useState(false);

  const fetchReviews = async () => {
    const serviceId = selectedCard?.Id;
    const fetchedReviews = await ReviewsById(serviceId);
    setReviews(fetchedReviews);
  };

  useEffect(() => {
    fetchReviews();
  }, [selectedCard]);

  const displayReviews = showAllReviews ? reviews : reviews.slice(0, 3);

  const extractVideoId = (url) => {
    const urlObj = new URL(url);
    if (urlObj.hostname === "youtu.be") {
      return urlObj.pathname.slice(1);
    } else if (
      urlObj.hostname === "www.youtube.com" ||
      urlObj.hostname === "youtube.com"
    ) {
      return urlObj.searchParams.get("v");
    }
    return null;
  };
  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const options = { day: "2-digit", month: "long", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };
  return (
    <div className="reviews-wrapper">
      <div className="reviews-container">
        {displayReviews.map((review) => (
          <div key={review.Id} className="review-card">
            <div className="review-header">
              <img
                src={
                  review.profilePhoto && review.profilePhoto !== ""
                    ? review.profilePhoto
                    : "https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
                }
                alt={``}
                className="review-profile-picture"
              />
              <span className="review-username">
                {review.userName ? review.userName : "Customer Name"}
              </span>
            </div>
            <p>Reviewed Date : {formatDate(review.createdAt)}</p>
            <ReactStars
              count={5}
              size={24}
              value={review?.rating}
              activeColor="#ffd700"
              edit={false}
            />
            <p className="review-message">{review.Message}</p>
            <div className="review-video">
              {review.Testimonial ? (
                <YouTube
                  videoId={extractVideoId(review.Testimonial)}
                  opts={{ width: "250", height: "140" }}
                />
              ) : (
                <p
                  className="text-center p-4"
                  style={{ width: "250px", height: "140px" }}
                >
                  No Video
                </p>
              )}
            </div>
          </div>
        ))}
      </div>
      {!showAllReviews && reviews.length > 3 && (
        <button
          className="show-more-btn"
          onClick={() => setShowAllReviews(true)}
        >
          Show More
        </button>
      )}
    </div>
  );
};

export default BwvReviews;
