// BwvSideBar.jsx
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Input, Row } from "reactstrap";
import Slider from "rc-slider";
import BwvOptions from "./BwvOptions";
import { fetchOptions, fetchOptionsList } from "../Data";
import PlacesSearch from "../../PlacesSearch";
import PlaceSearch from "../../PlacesSearch";
const BwvSideBar = ({
  categories,
  subCategories,
  items,
  bwv,
  setBwv,
  origibalBwv,
  selectCat,
  isReset,
  searchTerm,
  setSearchTerm,
}) => {
  const [priceRange, setPriceRange] = useState([0, 10000]);
  const [minPriceInput, setMinPriceInput] = useState(0);
  const [maxPriceInput, setMaxPriceInput] = useState(10000);
  const [radius, setRadius] = useState([1, 100]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCat, setSelectedSubCat] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [listOptions, setListOptions] = useState([]);
  const [optionsData, setOptionsData] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const navigate = useNavigate();
  const fetchOptionsData = async (id) => {
    const listData = await fetchOptionsList(id);
    setListOptions(listData);
    const options = {};
    for (const option of listData) {
      try {
        const data = await fetchOptions(option.cat_list_id);
        options[option.cat_list_id] = data;
      } catch (error) {
        console.error("Error fetching options:", error.message);
      }
    }
    setOptionsData(options);
  };

  useEffect(() => {
    if (selectCat) {
      handleCategoryClick(selectCat);
    }
    setSelectedCategory(selectCat);
    console.log(selectedCategory, "selectedCategory");
    if (isReset) {
      setSelectedCategory(null);
      setSelectedSubCat(null);
      setSelectedItem(null);
    }
  }, [isReset]);

  const handleCategoryClick = (category) => {
    setSelectedCategory((prevCategory) =>
      prevCategory === category ? null : category
    );
    if (category.Id) {
      fetchOptionsData(category.Id);
    }
    if (category.Id === 1) {
      navigate("/Localcommunityads");
    }
    console.log("selectedCategory", selectedCategory);
    console.log("selectedCategory", category);
    const filterBwvByCatId = origibalBwv.filter(
      (vendor) => +vendor.categoryId === +category.Id
    );
    setBwv(filterBwvByCatId);
    console.log(filterBwvByCatId, "filterbwvbyCatId");
    console.log(category, "category");
  };
  const handleItemClick = (item, ev) => {
    setSelectedItem((prev) => (prev === item ? null : item));
    const filterBwvByitem = origibalBwv.filter(
      (vendor) => +vendor.itemId === +item.id
    );
    setBwv(filterBwvByitem);
    ev.stopPropagation();
  };
  const handleSubCatClick = (subCat, ev) => {
    setSelectedSubCat((prevSubCat) => (prevSubCat === subCat ? null : subCat));
    const filterBwvBySubCatId = origibalBwv.filter(
      (vendor) => +vendor.subCategoryId === +subCat.id
    );
    setBwv(filterBwvBySubCatId);
    const filit = items.filter((item) => item.subCatId === subCat.id);
    ev.stopPropagation();
  };

  function getMinPackagePrice(packageData) {
    const packages = Object.values(packageData);
    const prices = packages.map((userpackage) =>
      parseFloat(userpackage.price.trim())
    );
    const minPrice = Math.min(...prices);
    return minPrice;
  }
  const handlePriceChange = (value) => {
    setPriceRange(value);
    setMinPriceInput(value[0]);
    setMaxPriceInput(value[1]);

    const filterBwvByPrice = origibalBwv.filter((vendor) => {
      const minPackagePrice = getMinPackagePrice(JSON.parse(vendor.Package));
      const price = +vendor.hourlyPrice || minPackagePrice;
      return price >= +value[0] && price <= +value[1];
    });
    setBwv(filterBwvByPrice);
  };

  const handleMinPriceInputChange = (event) => {
    const minValue = parseInt(event.target.value, 10);
    setMinPriceInput(minValue);
    setPriceRange([minValue, priceRange[1]]);
    const filterBwvByPrice = origibalBwv.filter(
      (vendor) =>
        +vendor.hourlyPrice >= minValue && +vendor.hourlyPrice <= priceRange[1]
    );
    setBwv(filterBwvByPrice);
  };
  const handleMaxPriceInputChange = (event) => {
    const maxValue = parseInt(event.target.value, 10);
    setMaxPriceInput(maxValue);
    setPriceRange([priceRange[0], maxValue]);
    const filterBwvByPrice = origibalBwv.filter(
      (vendor) =>
        +vendor.hourlyPrice >= priceRange[0] && +vendor.hourlyPrice <= maxValue
    );
    setBwv(filterBwvByPrice);
  };
  const filterByLocation = () => {
    if (!searchTerm) {
      setBwv(origibalBwv);
      return;
    }
    const filterBwvByLocation = origibalBwv.filter(
      (vendor) =>
        vendor.Location &&
        vendor.Location.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setBwv(filterBwvByLocation);
    tgl_filter_close();
  };
  const tgl_filter_close = () => {
    var myele = document.getElementById("bwv-sidebar");
    myele.classList.remove("bwvfilter-show");
    myele.classList.add("bwvfilter-close");
  };
  useEffect(() => {
    const filterBwvByLocation = origibalBwv.filter(
      (vendor) =>
        vendor.Location &&
        vendor.Location.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setBwv(filterBwvByLocation);
  }, [searchTerm]);
  const handleRadius = (value) => {
    setRadius([1, value[1]]); // Update the state to store only the maximum value
    console.log(radius, "radius");
    const filterBwvByRadius = origibalBwv.filter(
      (vendor) => vendor.Radius < value[1]
    );
    setBwv(filterBwvByRadius);
    console.log(filterBwvByRadius, "filterBwvByLocation");
    // tgl_filter_close()
  };

  const initialCategoriesToShow = 7;
  const [visibleCategories, setVisibleCategories] = useState(
    categories.slice(0, initialCategoriesToShow)
  );

  useEffect(() => {
    setVisibleCategories(categories.slice(0, initialCategoriesToShow));
  }, [categories, initialCategoriesToShow]);
  const handleToggleShowAll = () => {
    if (showAll) {
      setVisibleCategories(categories.slice(0, initialCategoriesToShow));
    } else {
      setVisibleCategories(categories);
    }
    setShowAll(!showAll);
  };
  console.log(origibalBwv, "origibalBwv");
  return (
    <div className="sidebar-for">
      <div className="categoryList">
        <span
          onClick={tgl_filter_close}
          className="tglbwv-filter-close text-end"
          id="tglbwv-filter-close"
        >
          X
        </span>
        <h5 className="pb-3">Category</h5>
        <ul
          style={{
            marginBottom: "10px",
            backgroundColor: "#ffffff",
            color: "#000000",
            borderRight: "1px solid rgb(224, 224, 224)",
            padding: "5px 5px 0px 0px",
            height: "100%",
          }}
          className="sidebar"
        >
          {visibleCategories.map((category) => (
            <li
              key={category.Id}
              onClick={() => handleCategoryClick(category)}
              className={
                selectedCategory && selectedCategory.Id === category.Id
                  ? "selected"
                  : ""
              }
              style={{
                cursor: "pointer",
                listStyleType: "none",
                borderBottom: "1px solid rgb(224, 224, 224)",
                borderRadius: "0px 25px 0px 25px",
                padding: "15px",
                fontWeight: "bold",
              }}
            >
              {category.Name}
              {selectedCategory && selectedCategory.Id === category.Id && (
                <div className="items-list">
                  <ul>
                    {subCategories
                      .filter((subCat) => subCat.CategoryId === category.Id)
                      .map((subCat) => (
                        <li
                          key={subCat.id}
                          // eslint-disable-next-line no-restricted-globals
                          onClick={(event) => handleSubCatClick(subCat, event)}
                          className={
                            selectedSubCat === subCat ? "selected-item" : ""
                          }
                          style={{ listStyleType: "none" }}
                        >
                          {subCat.Name}
                          {selectedSubCat === subCat && (
                            <div className="subCat-items-list">
                              <ul>
                                {items
                                  .filter((item) => item.subCatId === subCat.id)
                                  .map((item) => (
                                    <li
                                      key={item.subCatItemId}
                                      // eslint-disable-next-line no-restricted-globals
                                      onClick={(event) =>
                                        handleItemClick(item, event)
                                      }
                                      className={
                                        selectedItem === item ? "selected" : ""
                                      }
                                      style={{
                                        // listStyleType: "revert-layerF",
                                        marginLeft: "20px",
                                      }}
                                    >
                                      {item.subCatItemName}
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          )}
                        </li>
                      ))}
                  </ul>
                </div>
              )}
            </li>
          ))}
        </ul>
        <div
          className="show-all-text"
          style={{ color: "blue", textAlign: "right", cursor: "pointer" }}
          onClick={handleToggleShowAll}
        >
          {showAll && categories.length > initialCategoriesToShow
            ? "Show Less"
            : categories.length === initialCategoriesToShow
            ? null
            : "Show All"}
        </div>
      </div>
      <div className="bwvoptions">
        <BwvOptions
          listOptions={listOptions}
          optionsData={optionsData}
          origibalBwv={origibalBwv}
          setBwv={setBwv}
          isReset={isReset}
        />
      </div>
      <div className="price-slider">
        <h6 style={{ marginBottom: "10px" }}>Price:</h6>
        <Slider
          min={1}
          max={10000}
          range
          value={priceRange}
          onChange={handlePriceChange}
          trackStyle={[{ backgroundColor: "#007bff" }]}
          handleStyle={[
            { borderColor: "#007bff", backgroundColor: "#007bff" },
            { borderColor: "#007bff", backgroundColor: "#007bff" },
          ]}
          railStyle={{ backgroundColor: "#c5c5c5" }}
          step={1}
        />
        <p style={{ display: "flex", justifyContent: "space-between" }}>
          <span
            style={{
              color:
                priceRange[0] < 0 || priceRange[0] > 10000 ? "red" : "black",
            }}
          >
            ${isNaN(priceRange[0]) ? 0 : priceRange[0]}
          </span>
          <span
            style={{
              color:
                priceRange[1] < 0 || priceRange[1] > 10000 ? "red" : "black",
            }}
          >
            ${isNaN(priceRange[1]) ? 0 : priceRange[1]}
          </span>
        </p>
        <p>set up manually</p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Input
            style={{ width: "90px" }}
            type="number"
            value={minPriceInput}
            onChange={handleMinPriceInputChange}
          />
          <Input
            style={{ width: "90px" }}
            type="number"
            value={maxPriceInput}
            onChange={handleMaxPriceInputChange}
          />
        </div>
      </div>

      <div className="location-filter">
        <h6 style={{ marginBottom: "10px" }}>Location:</h6>
        <Row>
          <Col xs={8} md={12}>
            <PlaceSearch
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          </Col>
          <Col xs={4} md={12}>
            <button
              className="btn btn-primary w-100 mobile-only m-0"
              onClick={filterByLocation}
            >
              Search
            </button>
          </Col>
        </Row>
      </div>

      {searchTerm && (
        <div className="radius-slider">
          <h6 style={{ marginBottom: "10px" }}>Radius:</h6>
          <Slider
            min={1}
            max={100}
            range
            value={radius} // Pass the entire radius array
            onChange={(value) => handleRadius(value)}
            trackStyle={[{ backgroundColor: "#007bff" }]}
            handleStyle={[
              { borderColor: "#007bff", backgroundColor: "#007bff" },
              { borderColor: "#007bff", backgroundColor: "#007bff" },
            ]}
            railStyle={{ backgroundColor: "#c5c5c5" }}
            step={1}
          />

          <p>{radius[1]} miles</p>
        </div>
      )}
    </div>
  );
};

export default BwvSideBar;
