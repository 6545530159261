import React, { useState, useEffect } from "react";
import { Row, Col, Label, Input } from "reactstrap";
import { FaPlusCircle, FaTrashAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";
import { setEventDetails } from "../../../redux/slices/RequestFormSlice";

const NewlyEngagedForm = ({ userDetails, setUserDetails }) => {
  const dispatch = useDispatch();
  const [events, setEvents] = useState([
    {
      eventDate: "",
      eventTime: "",
      eventType: "",
      eventLocation: "",
      totalGuestCount: "",
    },
  ]);

  useEffect(() => {
    if (userDetails?.eventDetails) {
      try {
        const initialEvents = JSON.parse(userDetails.eventDetails);
        if (Array.isArray(initialEvents)) {
          setEvents(initialEvents);
        }
      } catch (error) {
        console.error("JSON Parse Error:", error);
      }
    }
  }, []);

  useEffect(() => {
    if (userDetails?.engagedCouple && userDetails?.engagedCouple === 1) {
      dispatch(setEventDetails(JSON.stringify(events)));
    } else if (
      userDetails?.EngagedCoupled &&
      userDetails.EngagedCoupled === 1
    ) {
      const updatedUserDetails = {
        ...userDetails,
        eventDetails: JSON.stringify(events),
      };
      setUserDetails(updatedUserDetails);
    }
  }, [events]);

  const handleAddSlot = () => {
    const lastEvent = events[events.length - 1];
    const isEmpty = Object.values(lastEvent).some((value) => value === "");

    if (isEmpty) {
      toast.error("Please fill in all details");
      return;
    }
    const guestCount = lastEvent.totalGuestCount;
    if (isNaN(guestCount) || guestCount <= 0) {
      toast.error("Enter a valid Guest Count");
      return;
    }

    setEvents((prevEvents) => [
      ...prevEvents,
      {
        eventDate: "",
        eventTime: "",
        eventType: "",
        eventLocation: "",
        totalGuestCount: "",
      },
    ]);
  };

  const handleChange = (value, index, field) => {
    const updatedEvents = [...events];
    if (updatedEvents[index]) {
      updatedEvents[index] = {
        ...updatedEvents[index],
        [field]: value instanceof Date ? value.toISOString() : value,
      };
      setEvents(updatedEvents);
    } else {
      console.error(`Event at index ${index} does not exist.`);
    }
  };

  const handleRemoveSlot = (index) => {
    const updatedEvents = [...events];
    updatedEvents.splice(index, 1);
    setEvents(updatedEvents);
  };

  function formatDate(dateStr) {
    const date = new Date(dateStr);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
  }

  function convertTo12HourFormat(time24) {
    if (!time24) {
      return "";
    }
    const [hour24, minute] = time24.split(":").map(Number);
    if (isNaN(hour24) || isNaN(minute)) {
      return "";
    }
    const period = hour24 >= 12 ? "PM" : "AM";
    const hour12 = (hour24 % 12 || 12).toString().padStart(2, "0");
    return `${hour12}:${minute.toString().padStart(2, "0")} ${period}`;
  }

  return (
    <div className="Profile-Container form-row">
      <div className="">
        <Row
          className="d-flex flex-row"
          style={{
            // marginLeft: 0,
            color: "black",
            padding: 0,
          }}
        >
          <Col sm={3} className="d-none d-lg-block">
            Event Date<b className="text-danger">*</b>
          </Col>
          <Col sm={2} className="d-none d-lg-block">
            Event Time<b className="text-danger">*</b>
          </Col>
          <Col sm={2} className="d-none d-lg-block">
            Event Type<b className="text-danger">*</b>
          </Col>
          <Col sm={2} className="d-none d-lg-block">
            Event Location<b className="text-danger">*</b>
          </Col>
          <Col sm={2} className="d-none d-lg-block">
            Guest Count<b className="text-danger">*</b>
          </Col>
          <Col sm={1} className="d-none d-lg-block"></Col>
        </Row>
        {events.map((event, index) => (
          <Row key={index}>
            <Col
              sm={3}
              xs="12"
              className="d-flex flex-column mt-3 justify-content-around "
            >
              <Label className="text-nowrap d-block d-lg-none">
                Event Date <b className="text-danger">*</b>
              </Label>
              {index === events.length - 1 ? (
                <DatePicker
                  name="eventDate"
                  dateFormat="MMM/dd/yyyy"
                  selected={event.eventDate ? new Date(event.eventDate) : null}
                  onChange={(date) => handleChange(date, index, "eventDate")}
                  className="form-control "
                  minDate={new Date()}
                  placeholderText="mmm/dd/yyyy"
                />
              ) : (
                <span style={{ fontWeight: "bold" }} className="">
                  {event.eventDate
                    ? formatDate(new Date(event.eventDate).toLocaleDateString())
                    : ""}
                </span>
              )}
            </Col>
            <Col
              sm={2}
              xs="12"
              className="d-flex flex-column mt-3 justify-content-around"
            >
              <Label className="text-nowrap d-block d-lg-none">
                Event Time <b className="text-danger">*</b>
              </Label>
              {index === events.length - 1 ? (
                <Input
                  type="time"
                  name="eventTime"
                  className="form-control "
                  value={event?.eventTime}
                  onChange={(event) =>
                    handleChange(event.target.value, index, "eventTime")
                  }
                />
              ) : (
                <span style={{ fontWeight: "bold" }} className="">
                  {convertTo12HourFormat(event.eventTime)}
                </span>
              )}
            </Col>
            <Col
              sm={2}
              xs="12"
              className="d-flex flex-column mt-3 justify-content-around"
            >
              <Label className="text-nowrap d-block d-lg-none">
                Event Type <b className="text-danger">*</b>
              </Label>
              {index === events.length - 1 ? (
                <Input
                  type="text"
                  name="eventType"
                  className="form-control "
                  value={event.eventType}
                  placeholder="Enter event type..."
                  onChange={(event) =>
                    handleChange(event.target.value, index, "eventType")
                  }
                />
              ) : (
                <span style={{ fontWeight: "bold" }} className="">
                  {event.eventType}
                </span>
              )}
            </Col>
            <Col
              sm={2}
              xs="12"
              className="d-flex flex-column mt-3 justify-content-around"
            >
              <Label className="text-nowrap d-block d-lg-none">
                Event Location <b className="text-danger">*</b>
              </Label>
              {index === events.length - 1 ? (
                <Input
                  type="text"
                  name="eventLocation"
                  className="form-control "
                  placeholder="Enter city..."
                  value={event.eventLocation}
                  onChange={(event) =>
                    handleChange(event.target.value, index, "eventLocation")
                  }
                />
              ) : (
                <span style={{ fontWeight: "bold" }} className="">
                  {event.eventLocation}
                </span>
              )}
            </Col>
            <Col
              sm={2}
              xs="12"
              className="d-flex flex-column mt-3 justify-content-around"
            >
              <Label className="text-nowrap d-block d-lg-none">
                Guest Count <b className="text-danger">*</b>
              </Label>
              {index === events.length - 1 ? (
                <Input
                  type="number"
                  name="totalGuestCount"
                  className="form-control "
                  value={event.totalGuestCount}
                  placeholder="No of guests.."
                  max={10}
                  maxLength={10}
                  onChange={(event) =>
                    handleChange(event.target.value, index, "totalGuestCount")
                  }
                />
              ) : (
                <span style={{ fontWeight: "bold" }} className="">
                  {event.totalGuestCount}
                </span>
              )}
            </Col>
            <Col
              sm="1"
              xs="12"
              style={{ cursor: "pointer" }}
              className="d-flex flex-row align-items-center justify-content-center"
            >
              {index === events.length - 1 && (
                <span onClick={handleAddSlot} style={{ marginTop: "14px" }}>
                  <FaPlusCircle size={23} />
                </span>
              )}
              {index !== events.length - 1 && (
                <span
                  onClick={() => handleRemoveSlot(index)}
                  style={{ marginTop: "14px" }}
                >
                  <FaTrashAlt />
                </span>
              )}
            </Col>
          </Row>
        ))}
      </div>
    </div>
  );
};

export default NewlyEngagedForm;
