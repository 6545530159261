import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Sidebar from "../../SideBar";
import { Row, Col } from "reactstrap";
import { Button } from "reactstrap";
import { BiPlusCircle } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { FadeLoader } from "react-spinners"; // Import FadeLoader
import {
	fetchAllServices,
	fetchMyNeedsOrRequests,
	fetchUserDetails,
} from "../../../components/Header/Data";
import "../DashboardPages.css";
import AllNeeds from "./AllNeeds";
import RecentEngagedNeeds from "./RecentEngagedNeeds";
import bids from "../../../assets/images/get-bids.png";
import BizzList from "../ServicePages/BizzListings";

const MyNeedsOrRequests = () => {
	const [myNeeds, setMyNeeds] = useState([]);
	const [active, setActive] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [engagedNeeds, setEngagedNeeds] = useState([]);
	const navigate = useNavigate();
	const userinfo = useSelector((state) => state.user.userInfo);
	const islogin = useSelector((state) => state.user.isLogin);
	const user = islogin ? JSON.parse(userinfo) : null;
	const [userDetails, setUserDetails] = useState([]);
	const [activeTab, setActiveTab] = useState("AllNeeds");
	const isAdmin = user && user.Role === "admin"; // Check if user is admin
	const bizzServices = active.filter(
		(ad) => ad?.bizzValue === 1 || ad?.bizzValue === 2 || ad?.bizzValue === 3
	);
	const fetchData = async () => {
		setIsLoading(true);
		if (islogin && user.RoleId === 1) {
			const data = {
				roleId: user.RoleId,
			};
			const needs = await fetchMyNeedsOrRequests(data);
			setMyNeeds(needs);
			const filterEngaged = needs.filter((need) => need.engagedCouple === 1);
			setEngagedNeeds(filterEngaged);
			const services = await fetchAllServices();
			setActive(services);
		} else {
			const data = {
				createdBy: user.UserID,
			};
			const needs = await fetchMyNeedsOrRequests(data);
			setMyNeeds(needs);
			const filterEngaged = needs.filter((need) => need.engagedCouple === 1);
			setEngagedNeeds(filterEngaged);
		}
		const userData = await fetchUserDetails(user?.UserID);
		setUserDetails(userData);
		setIsLoading(false);
	};
	useEffect(() => {
		fetchData();
	}, []);
	console.log(myNeeds, "myNeeds");
	return (
		<section className="home-after-login bg-white">
			<div className="container">
				<Row>
					<Col sm={3}>
						<Sidebar />
					</Col>
					<Col sm={9} className="requests-container position-relative ">
						{isLoading && (
							<FadeLoader // FadeLoader component
								css={{ margin: "0 auto" }}
								color={"#36D7B7"}
								loading={isLoading}
								className="position-absolute top-50 start-50 translate-middle"
							/>
						)}
						{myNeeds.length > 0 ? (
							<div>
								<div className="row ">
									<div className="text-end">
										<Button
											type="button"
											className="btn btn-danger"
											onClick={() => {
												navigate("/needs-requests/request");
											}}
										>
											<BiPlusCircle /> Add New
										</Button>{" "}
										{userDetails.EngagedCoupled === 1 && (
											<Button
												type="button"
												className="btn btn-danger"
												onClick={() => {
													navigate("/needs-requests/request");
												}}
											>
												<BiPlusCircle /> Engaged Couple Post Your Vendor Needs
												Ad
											</Button>
										)}
									</div>
								</div>
								<div
									className=" col-lg-6 d-flex row my-3 wc_ib-tabs w-100"
									style={{ marginLeft: "0px" }}
								>
									<h5
										className={`col-sm-4 ${
											activeTab === "AllNeeds" ? "active " : ""
										} mx-0`}
										onClick={() => {
											setActiveTab("AllNeeds");
										}}
										style={{
											textDecoration: activeTab !== "AllNeeds" && "none", // Apply underline when active
											cursor: "pointer", // Add cursor style to indicate clickable
											width: isAdmin ? "34%" : "50%",
										}}
									>
										All Needs
									</h5>
									<h5
										className={`col-sm-4 ${
											activeTab === "RecentEngaged" ? "active" : ""
										} mx-0`}
										onClick={() => setActiveTab("RecentEngaged")}
										style={{
											textDecoration: activeTab !== "AllNeeds" && "none", // Apply underline when active
											cursor: "pointer", // Add cursor style to indicate clickable
											width: isAdmin ? "33%" : "50%",
										}}
									>
										Recently Engaged Needs
									</h5>
									{isAdmin && (
										<h5
											className={`col-sm-4 ${
												activeTab === "Bizz_Listings" ? "active" : ""
											} mx-0`}
											onClick={() => {
												setActiveTab("Bizz_Listings");
												fetchData();
											}}
											style={{
												textDecoration: activeTab !== "AllNeeds" && "none", // Apply underline when active
												cursor: "pointer", // Add cursor style to indicate clickable
												width: "33%",
											}}
										>
											Bizz Listings
										</h5>
									)}
								</div>

								<div className="tab-content">
									{activeTab === "AllNeeds" && (
										<div>
											<AllNeeds myNeeds={myNeeds} fetchData={fetchData} />
										</div>
									)}
									{activeTab === "RecentEngaged" && (
										<div>
											<RecentEngagedNeeds
												engagedNeeds={engagedNeeds}
												fetchData={fetchData}
											/>
										</div>
									)}
									{activeTab === "Bizz_Listings" && (
										<div>
											<BizzList
												activeServices={bizzServices}
												user={user}
												fetchData={fetchData}
												isLoading={isLoading}
											/>
										</div>
									)}
								</div>
							</div>
						) : (
							!isLoading && (
								<div className="ads">
									<img
										src={bids}
										alt="img"
										style={{ width: "10rem", marginBottom: "20px" }}
									/>
									<h4>Do you have a specific Need/Request?</h4>
									<p>
										Post an Ad of your need & reach the local Indian Community
									</p>
									<Button
										type="button"
										className="btn btn-danger"
										onClick={() => {
											navigate("/needs-requests/request");
										}}
									>
										<BiPlusCircle /> Post Your Ad
									</Button>
								</div>
							)
						)}
					</Col>
				</Row>
			</div>
		</section>
	);
};
export default MyNeedsOrRequests;
